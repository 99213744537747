<template>
  <div class="option-list-container">
    <el-popover popper-class="option-list-popper" placement="bottom-end" v-model="visible" :popper-options="popperOptions" @show="show" @hide="hide">
      <div class="header" v-text="roleName"></div>
      <ul class="option-list">
        <li class="option-item" @click="setting">账号设置</li>
        <li class="option-item" v-if="showPlateOrganize" @click="jumpPlateOrganize">平台组织</li>
        <li class="option-item" @click="logout">退出</li>
      </ul>
      <template v-slot:reference>
        <slot name="option"></slot>
      </template>
    </el-popover>
    <el-dialog custom-class="setting-dialog" title="账号设置" @open="open" @closed="closed" width="680px" :visible.sync="settingVisible">
      <div class="setting-dialog-content">
        <div>账号信息：</div>
        <div class="info">
          <v-uploader
            operate="none"
            :action="uploadAction"
            :fileList.sync="headPic"
          />
          <div class="introduction">
            <span v-text="'登录账号：' + userInfo.loginName"></span>
            <span v-text="'角色：' + roleName"></span>
            <span v-text="'所属组织：' + (userInfo.userOrganization || '无')"></span>
            <span v-text="'职务：' + (userInfo.adminDuty || '无')"></span>
          </div>
        </div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="个人信息" name="info">
            <el-form ref="info" :model="info" :hide-required-asterisk="true" label-width="100px" :rules="infoRules">
              <el-form-item label="姓名：" prop="userName">
                <v-input v-model="info.userName" :maxlength="20"></v-input>
              </el-form-item>
              <el-form-item label="手机号：" prop="mobileNum">
                <v-input v-model="info.mobileNum" :disabled="!editMobile"></v-input>
              </el-form-item>
              <el-form-item label="昵称：" prop="nickName">
                <v-input v-model="info.nickName"></v-input>
                <span class="tips">注：该昵称将用于app上,与业主沟通交流</span>
              </el-form-item>
            </el-form>
            <v-button class="save-button" text="保存" @click="saveInfo"></v-button>
          </el-tab-pane>
          <el-tab-pane label="修改密码" name="password">
            <el-form ref="password" :model="password" :hide-required-asterisk="true" label-width="100px" :rules="passwordRules">
              <el-form-item label="旧密码：" prop="oldPassword">
                <v-input v-model="password.oldPassword" type="password" autocomplete="new-password"></v-input>
              </el-form-item>
              <el-form-item label="新密码：" prop="newPassword">
                <v-input v-model="password.newPassword" type="password" autocomplete="new-password"></v-input>
              </el-form-item>
              <el-form-item label="确认密码：" prop="newPasswordRepeat">
                <v-input v-model="password.newPasswordRepeat" type="password" autocomplete="new-password"></v-input>
              </el-form-item>
            </el-form>
            <v-button class="save-button" text="保存" @click="savePassword"></v-button>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapMutations } from 'vuex'
import { Tabs, TabPane } from 'element-ui'
import { vUploader } from 'components/control/index'
import { mobileRegular } from 'common/regular'
import { _localStorage, sessionStorageHelper } from 'common/utils'
Vue.use(Tabs)
Vue.use(TabPane)

export default {
  name: 'option-list',
  components: {
    vUploader
  },
  props: {
    userInfo: {
      type: Object,
      required: true
    }
  },
  data () {
    let validateMobileNum = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
        return
      }
      // if (!value.match(/^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[1-9]|19[9])[0-9]{8}$/)) {
      if (!value.match(mobileRegular)) {
        callback(new Error('不符合手机号码格式，请重新填写'))
        return
      }
      callback()
    }
    let validateNewPassword = (rule, value, callback) => {
      if (!value.match(/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,12}$/)) {
        callback(new Error('需要8至12位包含英文、字符、数字的密码'))
      } else {
        callback()
      }
    }
    let validateNewPasswordRepeat = (rule, value, callback) => {
      if (value !== this.password.newPassword) {
        callback(new Error('两次密码必须一致'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      settingVisible: false,
      activeName: 'info',
      roleName: '',
      headPic: [],
      uploadAction: `${API_CONFIG.uploadURL}?module=head`,
      info: {
        userName: '',
        mobileNum: '',
        nickName: ''
      },
      password: {
        oldPassword: '',
        newPassword: '',
        newPasswordRepeat: ''
      },
      infoRules: {
        userName: [{
          required: true, message: '请输入姓名', trigger: 'change'
        }],
        mobileNum: [{
          required: true, message: '请输入手机号', trigger: 'change'
        }, {
          validator: validateMobileNum, trigger: 'change'
        }],
        nickName: [{
          required: true, message: '请输入昵称', trigger: 'change'
        }]
      },
      passwordRules: {
        oldPassword: [{
          required: true, message: '请输入旧密码', trigger: 'change'
        }, {
          min: 6, max: 12, message: '密码长度需在6-12位之间', trigger: 'change'
        }],
        newPassword: [{
          required: true, message: '请输入新密码', trigger: 'change'
        }, {
          validator: validateNewPassword, trigger: 'change'
        }],
        newPasswordRepeat: [{
          required: true, message: '请输入确认密码', trigger: 'change'
        }, {
          validator: validateNewPasswordRepeat, trigger: 'change'
        }]
      },
      popperOptions: {
        boundariesElement: '.option-list-container'
      }
    }
  },
  computed: {
    editMobile () {
      const permission = this.$store.getters['menuStore/getMenuPermission']('tenantOrganizeList')
      return permission && permission.editMobile
    },
    showPlateOrganize () {
      const { userType } = this.userInfo;
      if ([106, 100].includes(+userType)) {
        return true
      }
      return false
    }
  },
  watch: {
    userInfo (newVal) {
      this.roleName = newVal ? newVal.roleName : ''
      this.headPic = [{
        url: newVal.headPicName
      }]
    }
  },
  methods: {
    ...mapMutations('searchStore', [
      'clearListSearch'
    ]),
    setting () {
      this.visible = false
      this.settingVisible = true
    },
    open () {
      this.headPic = [{
        url: this.userInfo.headPicName
      }]
      this.info.userName = this.userInfo.userName
      this.info.mobileNum = this.userInfo.mobileNum
      this.info.nickName = this.userInfo.nickName
    },
    closed () {
      this.activeName = 'info'
      this.$refs.info.clearValidate()
      this.$refs.password.clearValidate()
      this.$refs.password.resetFields()
    },
    saveInfo () {
      let _this = this
      let _valid = true
      _this.$refs.info.validate((valid) => {
        _valid = valid
      })
      if (!_valid) {
        return
      }
      let url = `${API_CONFIG.butlerBaseURL}manager/profile/update`
      let headPic = _this.headPic && _this.headPic.length > 0 ? _this.headPic[0].url : ''
      let postData = {
        'userId': _this.userInfo.id,
        'userType': _this.userInfo.userType,
        'headPicName': headPic,
        'userName': _this.info.userName,
        'mobileNum': _this.info.mobileNum,
        'nickName': _this.info.nickName
      }
      _this.$axios({
        method: 'put',
        url: url,
        data: postData
      }).then(res => {
        if (res.status === 100) {
          _this.$store.commit('setUserInfo', res.data)
          _this.$message({
            type: 'success',
            message: '保存成功',
            center: true
          })
          _this.settingVisible = false
        }
      })
    },
    savePassword () {
      let _this = this
      let _valid = true
      _this.$refs.password.validate((valid) => {
        _valid = valid
      })
      if (!_valid) {
        return
      }
      let url = `${API_CONFIG.butlerBaseURL}manager/changePwd`
      let headPic = _this.headPic && _this.headPic.length > 0 ? _this.headPic[0].url : ''
      let postData = {
        'userId': _this.userInfo.id,
        'headPicName': headPic,
        'oldPassword': _this.password.oldPassword,
        'newPassword': _this.password.newPassword
      }
      _this.$axios({
        method: 'put',
        url: url,
        data: postData
      }).then(res => {
        if (res.status === 100) {
          _this.$store.commit('setUserInfo', res.data)
          _this.$message({
            type: 'success',
            message: '保存成功',
            center: true
          })
          _this.settingVisible = false
        }
      })
    },
    logout () {
      let _this = this
      let url = `${API_CONFIG.butlerBaseURL}logout`
      _this.$axios.post(url).then(res => {
        if (res.status == 100) {
          let newLogin = _localStorage.getItem('newLogin')
          if (newLogin) {
            _this.$router.push({
              name: `login${newLogin}`
            })
          } else {
            _this.$router.push({
              name: 'login'
            })
          }
          _this.$nextTick(() => {
            _this.clearListSearch()
            _this.$store.commit('clearTheme')
          }, 20)
        }
      })
    },
    show () {
      this.$emit('statusChange', true)
    },
    hide () {
      this.$emit('statusChange', false)
    },
    jumpPlateOrganize () {
      this.$router.push({
        name: 'plateformOrganizeList'
      })
    }
  }
}
</script>
<style lang="scss">
  .option-list-popper {
    padding: 1px 0 0 0 !important;
    border: none !important;
  }
</style>
<style scoped lang="scss">
  .option-list-popper {
    .header {
      padding: 0 24px;
      height: 44px;
      line-height: 44px;
      color: #AAA;
      background-color: #e3f0ff;
    }
    .option-list {
      width: 180px;
      .option-item {
        color: #23527c;
        padding: 10px 0 10px 15px;
        cursor: pointer;
        &:not(:last-child) {
          border-bottom: 1px solid #F9EEEE;
        }
      }
    }
  }
  .option-list-container {
    .setting-dialog-content {
      text-align: left;
      .info {
        display: flex;
        margin: 10px 0 20px 0;
        .introduction {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin-left: 20px;
        }
      }
      .tips {
        margin-left: 20px;
      }
      .save-button {
        margin-left: 100px;
      }
    }
  }
</style>
