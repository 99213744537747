var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-select-container" },
    [
      _vm.label
        ? _c("label", { domProps: { textContent: _vm._s(_vm.label) } })
        : _vm._e(),
      _c(
        "el-select",
        {
          ref: "select",
          style: { width: _vm.width + "px" },
          attrs: {
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
            filterable: _vm.filterable,
            multiple: _vm.multiple,
            clearable: _vm.clearable,
            "multiple-limit": _vm.multipleLimit,
          },
          model: {
            value: _vm.model,
            callback: function ($$v) {
              _vm.model = $$v
            },
            expression: "model",
          },
        },
        [
          !_vm.optionGroup
            ? _c(
                "span",
                _vm._l(_vm.options, function (option) {
                  return _c("el-option", {
                    key: option.value,
                    attrs: {
                      value: option.value,
                      label: option.text,
                      disabled: option.disabled,
                    },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm.optionGroup
            ? _c(
                "span",
                _vm._l(_vm.options, function (group) {
                  return _c(
                    "span",
                    { key: group.value },
                    [
                      group.options && group.options.length
                        ? _c(
                            "el-option-group",
                            { attrs: { label: group.label } },
                            _vm._l(group.options, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }