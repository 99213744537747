var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "v-control v-select2-container" }, [
    _vm.label
      ? _c("label", {
          staticClass: "select2-text",
          domProps: { textContent: _vm._s(_vm.label) },
        })
      : _vm._e(),
    !_vm.isText
      ? _c(
          "div",
          { staticClass: "select2Parent", style: { width: _vm.width + "px" } },
          [
            _c(
              "vSelect",
              {
                ref: "select",
                staticClass: "vue-select2",
                attrs: {
                  options: _vm.selectOption,
                  placeholder: _vm.defaultPlaceholder,
                  onSearch: _vm.onSearch,
                  disabled: _vm.disabled,
                  filterable: false,
                  inputId: _vm.randomId,
                  resetOnOptionsChange: _vm.resetOnOptionsChange,
                },
                on: {
                  change: _vm.onChange,
                  "search:blur": _vm.onblur,
                  "search:focus": _vm.onfocus,
                },
                model: {
                  value: _vm.defaultBackText,
                  callback: function ($$v) {
                    _vm.defaultBackText = $$v
                  },
                  expression: "defaultBackText",
                },
              },
              [
                _c(
                  "span",
                  { attrs: { slot: "no-options" }, slot: "no-options" },
                  [_vm._v(_vm._s(_vm.noOptions))]
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.isText
      ? _c("div", { staticClass: "selectSpan" }, [
          _c("span", [_vm._v(_vm._s(_vm.defaultBackText))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }