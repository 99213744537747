<template>
  <div class="menu-group">
    <div class="menu-group-list" :class="item.level == 0 ? 'menu-group-list-root' : 'menu-group-list-child'" v-for="(item, index) in group" :key="item.id">
      <el-popover
        v-if="item.level == 0"
        placement="right"
        trigger="hover"
        :disabled="status"
        :visible-arrow="false"
      >
        <div style="color:#43628A;font-size:14px;">{{ item.name }}</div>
        <div class="menu-group-item" slot="reference" :class="setNameClass(item)" @click="itemClick(item, index)">
          <img
            class="menu-group-item-icon"
            v-if="item.level == 0"
            :src="item.icon"
            referrerPolicy="no-referrer"
          />
          <img
            class="menu-group-item-icon"
            v-else-if="item.menuType == 1"
            :src=" activeMenuId[item.level + 1] && activeMenuId[item.level + 1].includes(item.id) ? arrowupSrc : arrowdownSrc"
          />
          <div
            class="menu-group-item-icon"
            v-else
          />
          <div class="menu-group-item-name" :title="item.name">
            {{ item.name }}
          </div>
        </div>
      </el-popover>
      <div v-else class="menu-group-item" slot="reference" :class="setNameClass(item)" @click="itemClick(item, index)">
          <img
            class="menu-group-item-icon"
            v-if="item.level == 0"
            :src="item.icon"
            referrerPolicy="no-referrer"
          />
          <img
            class="menu-group-item-icon"
            v-else-if="item.menuType == 1"
            :src=" activeMenuId[item.level + 1] && activeMenuId[item.level + 1].includes(item.id) ? arrowupSrc : arrowdownSrc"
          />
          <div
            class="menu-group-item-icon"
            v-else
          />
          <div class="menu-group-item-name" :title="item.name">
            {{ item.name }}
          </div>
        </div>
      <menu-group
        v-show="status && item.menuType == 1 &&  activeMenuId[item.level + 1] && activeMenuId[item.level + 1].includes(item.id) && item.specialNo !== 'system_menuGroup'"
        :group="item.childMenus"
        :status="status"
        :centerItem="centerItem"
        :firstItem="firstItem"
        :secondItem="secondItem"
        :thirdItem="thirdItem"
        :activeMenuId="activeMenuId"
      />
    </div>
  </div>
</template>

<script>
import arrowdownSrc from './images/arrowdown.png'
import arrowupSrc from './images/arrowup.png'
import { menuJump, internalMenuJump, findFirstMenu } from 'common/menuHandler'

export default {
  name: 'menu-group',
  props: {
    group: Array,
    status: Boolean,
    centerItem: Object,
    firstItem: Object,
    secondItem: Object,
    thirdItem: Object,
    activeMenuId: Object
  },
  data () {
    return {
      arrowdownSrc,
      arrowupSrc,
      itemMap: {
        0: 'centerItem',
        1: 'firstItem',
        2: 'secondItem',
        3: 'thirdItem'
      }
    }
  },
  methods: {
    setNameClass (item) {
      const { menuType, level, name, id, specialNo } = item
      let classList = ['menu-group-item_' + id]
      if (level === 0) {
        classList.push('menu-group-item-root')
      } else {
        classList.push('menu-group-item-child')
      }
      const key = this.itemMap[level]
      if (key && this[key] && this[key].id === id) {
        if (level === 0) {
          classList.push('active-root')
        } else {
          classList.push('active-child')
        }
      }
      return classList
    },
    navigate (item) {
      const { menuType, urlJumpType, name, id, specialNo, level, url, childMenus } = item
      if (menuType === 1) {
        const activeMenuId = this.activeMenuId[level + 1]
        if (level === 0) {
          if (specialNo === 'system_menuGroup') {
            if (childMenus && childMenus.length) {
              const firstChildMenuId = childMenus[0].id
              internalMenuJump(firstChildMenuId)
            }
            return
          }
          if (activeMenuId && activeMenuId.length) {
            if (activeMenuId.includes(id)) {
              activeMenuId.splice(0, activeMenuId.length)
            } else {
              activeMenuId.splice(0, activeMenuId.length, id)
            }
          } else {
            activeMenuId.push(id)
          }
        } else {
          const index = activeMenuId.indexOf(id)
          if (index !== -1) {
            activeMenuId.splice(index, 1)
          } else {
            activeMenuId.push(id)
          }
        }
      } else if (menuType === 2) {
        if ([1, 2].includes(urlJumpType)) {
          menuJump(item)
        } else {
          internalMenuJump(id)
        }
      }
    },
    itemClick (item) {
      if (this.status) {
        this.navigate(item)
      } else {
        const menuItem = findFirstMenu(item)
        if (menuItem) {
          this.navigate(menuItem)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.menu-group {
  padding-left: 15px;
  .menu-group-list {
    .menu-group-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 40px;
      outline: none;
      cursor: pointer;
      .menu-group-item-icon {
        min-width: 16px;
        max-width: 16px;
        height: 16px;
        padding: 0 16px;
      }
      .menu-group-item-name {
        font-size: 14px;
        color: #43628A;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
    }
  }
  .menu-group-list-root {
    margin-bottom: 10px;
    .menu-group-item-root {
      border-radius: 10px;
      &:not(.active-root) {
        z-index: 1;
        position: relative;
        overflow: hidden;
        transition: box-shadow 0.5s ease-in-out;
        &::after {
          content: "";
          z-index: -1;
          position: absolute;
          background: #FFF;
          opacity: 0.8;
          top: -50%;
          left: 0%;
          bottom: -50%;
          width: 20px;
          transform: translate3d(-50px, 0, 0) rotate(35deg);
        }
        &:hover {
          background: #F1F5FA;
          box-shadow: 0px 10px 15px 0px rgba(108,145,185,0.1), 0px -10px 10px 0px rgba(255,255,255,0.7), 0px 5px 10px 0px rgba(121,183,255,0.03);
          border-radius: 10px;
          &::after {
            transition: transform 0.5s ease-in-out;
            transform: translate3d(300px, 0, 0) rotate(35deg);
          }
        }
      }
      &.active-root {
        background: linear-gradient(90deg,rgba(67,168,255,1) 0%,rgba(82,159,255,1) 99%);
        box-shadow: 0px 5px 10px 0px rgba(115,171,224,0.5), 0px -10px 10px 0px rgba(255,255,255,0.7);
        border-radius: 10px;
        .menu-group-item-name {
          color: #FFF;
        }
      }
    }
  }
  .menu-group-list-child {
    .menu-group-item-child {
      &:hover {
        .menu-group-item-name {
          color: #1B8CF2;
        }
      }
      &.active-child {
        .menu-group-item-name {
          color: #1B8CF2;
        }
      }
    }
  }
}
</style>
