import { render, staticRenderFns } from "./block.vue?vue&type=template&id=bffd8d70&scoped=true&lang=html"
import script from "./block.vue?vue&type=script&lang=js"
export * from "./block.vue?vue&type=script&lang=js"
import style0 from "./block.vue?vue&type=style&index=0&id=bffd8d70&lang=scss&scoped=true"
import style1 from "./block.vue?vue&type=style&index=1&id=bffd8d70&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bffd8d70",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\teamHonorWork\\tianheyun\\managerforshushunew\\node_modules\\_vue-hot-reload-api@2.3.4@vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bffd8d70')) {
      api.createRecord('bffd8d70', component.options)
    } else {
      api.reload('bffd8d70', component.options)
    }
    module.hot.accept("./block.vue?vue&type=template&id=bffd8d70&scoped=true&lang=html", function () {
      api.rerender('bffd8d70', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/bussiness/col2-detail/block.vue"
export default component.exports