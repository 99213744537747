<template>
  <div class='jump-wrapper'>

  <v-button type="default" @click="openDialog" :disabled="disabled">
    <div class="jumpBtn">
      <i class="btn-icon" v-show="iconShow"></i>
      <label class='btn-txt' > {{ btnText }} </label>
    </div>
  </v-button>

  <el-dialog title="跳转" :visible.sync="visible" width="900px" append-to-body>
    <jump-go-switcher :skipType="skipType" :communityIds="communityIds" @callback="submit"></jump-go-switcher>
  </el-dialog>

  </div>
</template>

<script>
import jumpGoSwitcher from './jump-go'
// @group 业务组件
// @title jumpGo 跳转组件
export default {
  name: 'jump-go',
  props: {
    // 按钮上图标的显示隐藏
    iconShow: {
      type: Boolean,
      default: true
    },
    // 按钮上显示的文字
    btnText: {
      type: String,
      default: '插入跳转'
    },
    // 按钮的禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 跳转的类型（1为详情页 2为功能页）
    skipType: {
      type: Number,
      default: 1
    },
    // qelect2 园区筛选参数
    communityIds: {
      type: Array
    },
    // 确认成功后callback (skipType=1的时候，callback（skipdata，css，html） skipType=2的时候，callback（skipdata，name）)
    callback: {
      type: Function,
      dafault: () => {}
    }
  },
  components: {
    jumpGoSwitcher
  },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    submit (skipdata, css, html) {
      this.$emit('callback', skipdata, css, html)
      this.visible = false
    },
    openDialog () {
      this.visible = true
    }
  }

}
</script>

<style lang="scss" scoped>
.jump-wrapper {
  .jumpBtn {
    display: flex;
    align-items: center;
    .btn-icon {
      background-image: url('./images/jump_btn.png');
      display: inline-block;
      width: 22px;
      height: 22px;
      background-repeat: no-repeat;
      background-size: 100%;
      margin-right: 5px;
    }
    .btn-txt{
      padding: 0 10px;
    }
  }
}
</style>
