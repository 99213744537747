var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-col", { attrs: { span: _vm.span } }, [
    _c("div", { staticClass: "col2-item" }, [
      _vm.label
        ? _c(
            "span",
            { staticClass: "label", class: { required: _vm.required } },
            [_vm._v(_vm._s(_vm.label) + "：")]
          )
        : _vm._e(),
      _c(
        "span",
        { staticClass: "text" },
        [
          _vm._t("default", function () {
            return [_vm._v(_vm._s(_vm.text))]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }