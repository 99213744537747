var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "importTemplate-container" },
    [
      _c(
        "form-panel",
        {
          ref: "formPanel",
          staticClass: "form-container",
          scopedSlots: _vm._u([
            {
              key: "footerSlot",
              fn: function () {
                return [
                  _c("v-button", {
                    attrs: { text: "保存", type: "success" },
                    on: { click: _vm.saveFile },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "col2-detail",
            { attrs: { span: 24 } },
            [
              _c(
                "col2-block",
                { attrs: { title: _vm.title } },
                [
                  _c("div", { staticClass: "cell-box" }, [
                    _c("div", { staticClass: "space-left" }, [
                      _c("span", [_vm._v("*")]),
                      _c("label", [
                        _vm._v(" " + _vm._s(_vm.uploadLabel) + "："),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "space-right" },
                      [
                        _c("v-uploader", {
                          ref: "myUpload",
                          attrs: {
                            action: _vm.uploadUrl,
                            onlyImage: false,
                            fileSize: _vm.maxFileSize,
                            multiple: true,
                            extraData: _vm.extraData,
                            "auto-upload": _vm.autoUpload,
                            fileList: _vm.fileList,
                            limit: 1,
                            isOrder: true,
                            isAlertErrorMsg: false,
                            onError: _vm.onError,
                            onSuccess: _vm.onSuccess,
                          },
                          on: {
                            "update:fileList": function ($event) {
                              _vm.fileList = $event
                            },
                            "update:file-list": function ($event) {
                              _vm.fileList = $event
                            },
                            storageChange: _vm.storageChange,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.downloadUrl
                      ? _c(
                          "div",
                          { staticClass: "rightBtn" },
                          [
                            _c("v-button", {
                              attrs: { text: _vm.downloadText },
                              on: { click: _vm.downloadTemplate },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._t("formContent"),
                  _c("div", { staticClass: "cell-box" }, [
                    _c("div", { staticClass: "space-left" }, [
                      _c("label", [_vm._v(_vm._s(_vm.remarkLabel))]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "space-right" },
                      [_vm._t("remark")],
                      2
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }