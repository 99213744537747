var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "amap-page-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "地图标注",
            width: "80%",
            visible: _vm.showMapDialog,
            "before-close": _vm.hideMapDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showMapDialog = $event
            },
            open: _vm.onOpenDialog,
          },
        },
        [
          _c("div", { staticClass: "toolbar" }, [
            _vm._v(
              "经度: " +
                _vm._s(_vm.lng) +
                ", 纬度: " +
                _vm._s(_vm.lat) +
                ", 地址: " +
                _vm._s(_vm.address)
            ),
          ]),
          _c("el-amap-search-box", {
            staticClass: "search-box-wrapper",
            attrs: {
              "search-option": _vm.searchOption,
              "on-search-result": _vm.onSearchResult,
            },
          }),
          _c(
            "el-amap",
            {
              staticClass: "remaim-vue-map",
              attrs: {
                vid: _vm.amapVid,
                center: _vm.center,
                zoom: _vm.zoom,
                plugin: _vm.plugin,
                events: _vm.events,
              },
            },
            [_c("el-amap-marker", { attrs: { position: _vm.center } })],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("v-button", {
                staticClass: "margin-right-20",
                attrs: { type: "", text: "取 消" },
                on: { click: _vm.hideMapDialog },
              }),
              _c("v-button", {
                attrs: { type: "primary", text: "确 定" },
                on: { click: _vm.onSaveLngAndLat },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }