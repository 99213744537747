<template>
  <div class="v-control v-button-container" :style="{ width: width + 'px' }" v-if="handlePermission(permission)">
    <el-button
      :type="type"
      :icon="icon"
      :plain="plain"
      :round="round"
      :circle="circle"
      :disabled="disabled"
      @click="click"
    >
      <template>
        <slot v-if="text">{{ text }}</slot>
        <slot v-else></slot>
      </template>
    </el-button>
  </div>
</template>
<script>
import Vue from 'vue'
import { Button } from 'element-ui'

Vue.use(Button)

// @group 基础组件
// @title Button 按钮
export default {
  name: 'v-button',
  props: {
    // 类型
    type: {
      // `'primary'` / `'success'` / `'warning'` / `'danger'` / `'info'` / `'text'`
      type: String,
      // `primary`
      default: 'primary'
    },
    // 按钮名称
    text: String,
    // 按钮宽度
    width: Number,
    // 图标类名
    icon: String,
    // 是否朴素按钮
    plain: Boolean,
    // 是否圆角按钮
    round: Boolean,
    // 是否圆形按钮
    circle: Boolean,
    // 是否禁用
    disabled: Boolean,
    // 根据权限控制是否渲染按钮，传递true / false直接控制按钮渲染，传递字符串将使用该字符串作为key去判断是否具有当前菜单对应权限， 根据权限控制按钮渲染
    permission: {
      type: [Boolean, String],
      // `true`
      default: true
    }
  },
  methods: {
    handlePermission (permission) {
      return this.$store.getters.getPermission(permission)
    },
    click (event) {
      // 按钮点击事件
      // @arg event
      this.$emit('click', event)
    }
  }
}
</script>
<style scoped lang="scss">
  .v-button-container {
    display: inline-block;
    .el-button {
      width: 100%;
      height: 100%;
      min-width: 74px;
      padding: 8px;
      font-size: inherit;
    }
    .is-circle {
      min-width: 0;
    }
    .el-button--text {
      min-width: 0;
      padding: 0;
    }
  }
</style>
