const search = {
  namespaced: true,
  state: {
    listSearch: {},
    listCurPage: {},  // list的curPage
  },
  mutations: {
    setListSearch (state, listSearch) {
      let name = listSearch.name
      let params = listSearch.params
      state.listSearch[name] = {
        ...state.listSearch[name],
        ...params
      }
    },
    clearListSearch (state) {
      state.listSearch = {}
    },
    setListCurPage (state, listParams) {
      let name = listParams.name
      let curPage = listParams.curPage
      state.listCurPage[name] = {
        name,
        curPage
      }
    },
    clearListCurPage (state) {
      state.listCurPage = {}
    },
  },
  getters: {
    getListSearch: (state) => (key) => {
      return state.listSearch[key]
    },
    getListCurPage: (state) => (key) => {
      return state.listCurPage[key]
    },
  }
}

export default search
