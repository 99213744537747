<!--
  组件暂时不支持手动上传
-->
<template>
<div class="v-uploader-wrapper">
  <div class="v-controls">
    <el-upload
      ref="upload"
      class="upload-demo"
      :style="fileStyle"
      :disabled="disabled"
      :data="extraData"
      :action="action"
      :multiple="multiple"
      :show-file-list="false"
      :before-upload="beforeAvatarUpload"
      :drag="false"
      :auto-upload="true"
      :on-success="successUpload"
      :on-error="errorUpload"
      :list-type="listType"
      :on-change="onChange"
      :headers="importHeaders"
      :http-request="httpRequest"
      :name="name"
      :limit="limitValue"
      :accept="acceptFileType"
    >
      <div class="box" @click="btnClick">
        <div class="box-top" v-if="!disabled" v-show="limit - value.length - uploadListShow.length > 0">
          <v-button

            v-loading="loading"
            type="primary"
          >点击上传
          </v-button>
          <div class="fill-empty" @click.stop>&nbsp;剩余可上传数为&nbsp;{{/* 禁止这片空白区域被点击 */ limitValue}}，超出将取消本次上传!</div>
        </div>
        <div class="box-content" @click.stop>
<!--          <slot name="tip">-->
<!--            <div class="tip">&nbsp;*&nbsp;{{tip}}</div>-->
<!--          </slot>-->
          <div class="file-list" :style="{ width }"
            v-for="(item, index) in uploadListShow" :key="`upload_${index}`" @click.stop
          >
            <div class="pre-icon"><i class="el-icon-loading" /></div>
            <div class="file-name" v-if="item !== '' && (typeof item === 'object')">文件正在上传中...</div>
            <div class="file-name" v-else-if="(typeof item === 'string') && item !== ''">
              文件上传成功,等待其他文件上传完成中...
            </div>
            <div class="file-name" v-else>文件上传失败,等待其他文件上传完成中...</div>
            <!-- <div class="down file-icon" @click.stop="downFile(item)"><i class="el-icon-download" /></div>
            <div class="delete file-icon" @click.stop="deleteFile(index, item)"><i class="el-icon-close" /></div> -->
          </div>
          <div class="file-list" :style="{ width }"
            v-for="(item, index) in fileListShow" :key="index"
            @click.stop="previewUpload"
          >
            <div class="pre-icon"><i class="el-icon-document" /></div>
            <div class="file-name" :title="item.name">{{ item.name }}</div>
            <div class="down file-icon" @click.stop="downFile(item)"><i class="el-icon-download" /></div>
            <div class="delete file-icon" v-if="!disabled" @click.stop="deleteFile(index, item)"><i class="el-icon-close" /></div>
          </div>
        </div>
      </div>
      <!-- <div slot="tip" class="el-upload__tip">{{tip}}</div> -->
    </el-upload>
  </div>
</div>
</template>
<script>
import { Upload } from 'element-ui'
import _ from 'lodash'
import { _localStorage, downloadHelper } from 'common/utils'
// @group 基础组件
// @title Uploader 上传组件
export default {
  name: 'v-uploader',
  model: {
    prop: 'value',
    event: 'input'
  },
  components: {
    elUpload: Upload
    // elProgress: Progress,
    // SlickList,
    // SlickItem
  },
  props: {
    value: Array, // v-model绑定值
    tip: String, // 提示文字
    // 上传时附带的额外参数
    extraData: {
      type: Object,
      // `{}`
      default: () => {
        return {
          type: ''
        }
      }
    },
    // 必选参数，上传的地址
    action: {
      type: String,
      required: true
    },
    showFileList: {
      type: Boolean,
      default: false
    },
    // 是否多选
    multiple: {
      type: Boolean,
      // `true`
      default: true
    },
    // 接收的文件类型
    acceptFileType: {
      type: String
    },
    // 上传文件大小  单位M
    fileSize: {
      type: Number,
      // `50`
      default: 50
    },
    // 最大允许上传个数
    limit: {
      type: Number,
      // `1`
      default: 1
    },
    // 是否允许拖拽
    // drag: {
    //   type: Boolean,
    //   // `false`
    //   default: false
    // },
    // showFileList为true是所显示的type
    listType: {
      // `'text'` / `'picture'` / `'picture-card'`
      type: String,
      // `text`
      default: 'text'
    },
    // 是否禁用
    disabled: Boolean,
    // 文件列表移除文件时的钩子 function(file, fileList)
    onSuccess: Function,
    // 文件上传失败时的钩子function(err, file, fileList)
    onError: Function,
    // 点击文件列表中已上传的文件时的钩子function(file)
    onPreview: Function,
    // 删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止上传。function(file, fileList)
    beforeRemove: Function,
    // 上传文件前钩子
    beforeUpload: Function,
    // 是否支持排序 limit > 1 时可选
    isOrder: {
      type: Boolean,
      // `false`
      default: false
    },
    setHttpRequest: Function,
    // 请求头部
    headers: Object,
    // 是否弹出错误提示
    isAlertErrorMsg: {
      type: Boolean,
      // `true`
      default: true
    },
    // 数据返回url前缀
    prefixUrl: String,
    // 上传的文件字段名
    name: {
      type: String,
      // `file`
      default: 'file'
    },
    // 显示的按钮
    operate: {
      type: String,
      // `'all'` / `'delete'` / `'none'`
      default: 'all'
    },
    fileStyle: {
      type: [Object, Array, String]
    },
    filePath: { // 自定义业务目录名
      type: String,
      required: true
    },
    isCover: { // 指定false不覆盖同名文件,不传默认就是false
      type: Boolean,
      default: false
    },
    width: { // 内容区的宽度
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      // addImg,
      fileName: '',
      loading: false,
      percentage: 0,
      onOffNum: true, // 上传文件数量的开关, true 超出数量时提示
      // onOffSize: true, // 上传文件大小的开关, true 超出大小时提示
      // fileListAll: [],
      fileList: [],
      uploadListShow: [], // 上传中的文件
      // limitValue: 0,
      // dragText: '排序',
      // manualUploadList: [],
      // successUidList: [],
      // errUidList: [],
      token: this.$store ? this.$store.state.token : '',
      playTime: 0
      // outputType: 'file'
    }
  },
  computed: {
    limitValue () {
      return this.limit - this.value.length
    },
    importHeaders () {
      let head
      if (this.headers) {
        head = this.headers
      } else {
        head = {
          token: this.$store ? this.$store.state.token : '',
          companyCode: _localStorage.getItem('companyCode')
        }
      }
      return head
    },
    fileListShow () {
      return this.value.map(v => {
        console.log(v, 'v---')
        let vArr = v.split('/')
        let name = vArr[vArr.length - 1]
        return {
          name,
          url: v
        }
      })
    }
  },
  watch: {
    // value: {
    //   handler (val) {
    //     this.fileListAll = val
    //   },
    //   deep: true
    // }
  },
  filters: {
    fileSize (val) {
      let text = ''
      if (val < 1024000) {
        text = `${(val / 1024).toFixed(2)}K`
      } else if (val < (1024000 * 1024)) {
        text = `${(val / 1024 / 1024).toFixed(2)}M`
      } else {
        text = `${(val / 1024 / 1024 / 1024).toFixed(2)}KM`
      }
      return text
    }
  },
  created () {
    // this.limitValue = this.limit - this.value.length
  },
  methods: {
    beforeAvatarUpload (file) { // 上传前先进行验证
      // 一次选择多个文件将会重复调用该方法
      // console.log(file, 'file')
      // const result = this.beforeUpload ? await this.beforeUpload(file) : true
      // const result = this.baseVaild(file)
      // if (result) {
      //   this.httpRequest(file)
      // }
      if(file.name.substring(file.name.lastIndexOf(".")+1)=='mp4'){
        let _this=this
        let url = URL.createObjectURL(file)
        var audioElement = new Audio(url)
        audioElement.addEventListener('loadedmetadata',function(){
　　　　  _this.playTime = audioElement.duration.toFixed(); //playTime就是当前视频长度
        })
      }

      return this.baseVaild(file)
    },
    baseVaild (file) {
      let _this = this
      const isSizeover = file.size / 1024 / 1024 < _this.fileSize
      const isNumNoOver = _this.value.length < _this.limit
      // console.log(this.onOffSize, this.onOffNum, '验证')
      const isFormat = this.acceptFileType? this.acceptFileType.includes(file.name.substring(file.name.lastIndexOf(".")+1)):true
      if(!isFormat){
        _this.$message('请上传正确格式文件')
      }
      if (!isSizeover) {
        setTimeout(() => {
          _this.$message(`${file.name}文件的大小超过${_this.fileSize}M`)
        })
      }
      if (!isNumNoOver && this.onOffNum) {
        _this.$message(`数量超出，不能超过${_this.limit}个`)
        this.onOffNum = false
      }
      // console.log(file, isSizeover, 'isSizeover && isNumNoOver')
      return isSizeover && isNumNoOver && isFormat
    },
    /** 功能未完成 */
    successUpload (response, file, fileList) { // 文件上传成功时的钩子
      // console.log('触发上传成功的钩子')
      this.onSuccess && this.onSuccess(response, file, fileList)
    },
    errorUpload (response, file, fileList) { // 文件上传失败时的钩子
      // console.log('触发上传失败的钩子')
      this.onError && this.onError(response, file, fileList)
    },
    previewUpload (file) { // 点击文件列表中已上传的文件时的钩子
      // console.log(file)
      this.onPreview && this.onPreview(file)
    },
    submitUpload () { // 上传至服务器，手动上传时需要
      this.$refs.upload.submit()
    },
    clearFiles () { // 上传至服务器，手动上传时需要
      this.$refs.upload.clearFiles()
    },
    /** end */
    progressUpload (event, file, fileList) { // 文件上传时的钩子
      // console.log('触发文件上传时的钩子')
    },
    btnClick () {
      // console.log('成功触发了btnClick')
      // this.limitValue = this.limit - this.value.length
      // if (this.limit) {
      //   this.$message.warning(`剩余可选择数为:${this.limitValue},超出将取消本次上传!`)
      // }
      this.clearFiles()
      this.onOffNum = true
    },
    httpRequest (file) { // 替换原有请求
      console.log(file, 'sync---')
      if (this.setHttpRequest) {
        this.setHttpRequest(file.file, this)
        return
      }
      // const loadArr = ['', ...this.value]
      // const loadLength = loadArr.length
      // this.$emit('input', loadArr)
      const len = this.uploadListShow.unshift(file)
      const formData = new FormData()
      formData.append('files', file.file)
      formData.append('isCover', this.isCover)
      formData.append('filePath', this.filePath)
      this.$axios.post(this.action, formData, {
        headers: {
          ...this.importHeaders
        }
      }).then((res) => {
        const index = this.uploadListShow.length - len
        if (res.status === 100 && res.data) {
          // this.uploadListShow[index] = res.data
          this.uploadListShow.splice(index, 1, res.data)
          // let result = false
          // this.uploadListShow.forEach(v => {
          //   if (typeof v !== 'string') {
          //     result = true
          //   }
          // })
          // if (result) {
          //   return
          // }
          console.log('async----', this.uploadListShow)
          // const oldArr = [...this.value]
          // // const newArr = [res.data]
          // const newArr = this.uploadListShow.map(v => v !== '')
          // this.$emit('input', [...newArr, ...oldArr])
          // this.uploadListShow = []
          // const resLength = oldArr.length
          // const index = resLength - loadLength
          // const newArr = oldArr.splice(index, 1, res.data)
          // this.$emit('input', newArr)
        } else {
          // this.uploadListShow[index] = ''
          this.uploadListShow.splice(index, 1, '')
        }
      }).catch(() => {
        const index = this.uploadListShow.length - len
        // this.uploadListShow[index] = ''
        this.uploadListShow.splice(index, 1, '')
      }).finally(() => {
        console.log('finally---')
        let result = false
        this.uploadListShow.forEach(v => {
          if (typeof v !== 'string') {
            result = true
          }
        })
        if (result) {
          return
        }
        const oldArr = [...this.value]
        // const newArr = [res.data]
        const newArr = this.uploadListShow.filter(v => v !== '' && (typeof v !== 'object'))
        this.$emit('input', [...newArr, ...oldArr], file, this.playTime)

        this.uploadListShow = []
      })
    },
    onChange (file, fileList) { // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
      this.fileList = fileList
      // console.log(file, fileList)
      // console.log(fileList, '文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用')
      // this.httpRequest([file])
    },
    async deleteFile (index, item) {
      let result = this.beforeRemove ? await this.beforeRemove(index, item) : true
      if (result) {
        // 代码
        const newArr = _.cloneDeep(this.value)
        newArr.splice(index, 1)
        this.$emit('input', newArr)
        this.onOffNum = true
      }
    },
    downFile (item) {
      let url = item.url
      let fileName = item.name
      // downloadHelper.downloadFile({
      //   data: url,
      //   fileName
      // })
      downloadHelper.downloadBySrc({
        src: url,
        // target: '_block',
        target: '_block',
        fileName
      })
    }
  }
}
</script>

<style scoped lang="scss">
.v-uploader-wrapper {
  ::v-deep .el-upload {
    width: 100%;
    text-align: left;
    .box {
      // position: relative;
      width: 100%;
      .box-top {
        display: flex;
        .fill-empty {
          color: #606266;
          flex: 1;
          cursor: default;
        }
        // position: absolute;
        // left: 0;
        // top: 0;
        // display: inline-block;
      }
      .box-content {
        // padding-top: 20px;
        .tip {
          cursor: default;
          color: #606266;
        }
        // 插槽内容
        .file-list {
          display: flex;
          height: 30px;
          line-height: 30px;
          font-size: 16px;
          margin-top: 5px;
          color: #606266;
          &:hover {
            background-color: #f5f7fa;
            .file-name {
              color: #409eff;
            }
            .file-icon {
              display: flex;
            }
          }
          .pre-icon {
            width: 14px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 2px;
            margin-left: 2px;
          }
          .file-name {
            flex: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-left: 10px;
          }
          .file-icon {
            width: 30px;
            height: 30px;
            display: none;
            align-items: center;
            justify-content: center;
            margin-left: 2px;
            opacity: .75;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
</style>
