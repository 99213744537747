<!--
 * @Author: jianying.wan
 * @Date: 2019-07-25 16:14:06
 * @LastEditors: 小广
 * @LastEditTime: 2020-05-22 17:03:48
 * @Description: home
 -->
<template>
  <div class="home-container" :class="show ? '' : 'home-full-screen'">
    <div class="top" v-if="show" :class="showCockpit ? 'top-cockpit' : ''">
      <top :logoSrc="logoSrc"></top>
    </div>
    <div
      :class="['bottom', {'workTop':isWorkTop}]"
      :style="{'background':isWorkTop ? theme.worktop_background || '#fff' : ''}"
    >
      <nav-menu-panel v-if="menuGroupType =='left'" />
      <div class="views" :class="{'views-JSC': isJSC}">
        <div
          ref="content"
          class="content"
          @click="iotCommunityViewControl"
          :style="{ 'paddingTop' : isWorkTop ? 0 : isJSC ? 0 : '72px' }"
        >
          <room-tree ref="room-tree" v-show="showRoomTreeView&&shouldGetTree"></room-tree>
          <div class="title-panel" v-show="!isWorkTop && !isJSC">
            <title-panel></title-panel>
          </div>
          <!-- <transition name="component-fade"
                      mode="out-in"
                      v-on:enter="enter"
                      v-on:leave="leave"
          v-bind:css="false">-->
          <!-- <keep-alive
            :include="[
              'ValidHouseList',
              'HouseProfileList',
              'ChargeBillManageList',
              'HandOverBillList',
              'WriteOffBillList',
              'WashBillList',
            ]"
          > -->
          <!-- <keep-alive
            :include="['ValidHouseList','HouseProfileList']"
          > -->
            <router-view class="router-view" v-if="isLoaed" />
          <!-- </keep-alive> -->
          <!-- </transition> -->
        </div>
      </div>
      <iot-community-tree ref="iotTree" v-if="showIotCommunityView"></iot-community-tree>
    </div>
    <!--<audio ref='audioPlay' hidden='true'>-->
    <!--  <source src='./media/youhavemessage.wav' type="audio/mpeg" />-->
    <!--</audio>-->
    <el-dialog
      custom-class="updatePwd-dialog"
      :title="updatePwdTitle"
      width="500px"
      :visible.sync="updatePwdVisible"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="updatePwd-dialog-content">
        <div v-if="dialogStatus === 0">您的账号当前有安全风险，请修改密码（首次登录系统/初始密码/账号三个月未修改密码）</div>
        <div class="form">
          <el-form
            v-if="dialogStatus === 1"
            ref="password"
            :model="password"
            :hide-required-asterisk="true"
            label-width="100px"
            :rules="passwordRules"
          >
            <el-form-item label="新密码：" prop="newPassword">
              <v-input
                v-model="password.newPassword"
                type="password"
                :width="160"
                autocomplete="new-password"
              ></v-input>
            </el-form-item>
            <el-form-item label="确认密码：" prop="newPasswordRepeat">
              <v-input
                v-model="password.newPasswordRepeat"
                type="password"
                :width="160"
                autocomplete="new-password"
              ></v-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="ops">
          <v-button class="logout" text="登出" @click="logout" type="default"></v-button>
          <v-button class="update" text="修改" @click="updatePassword"></v-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import top from 'components/top/top'
import navMenuPanel from 'components/nav-menu-panel/nav-menu-panel'
import titlePanel from 'components/title-panel/title-panel'
import iotCommunityTree from 'components/iot-community-tree/iot-community-tree'
import roomTree from 'components/room-tree'

import { _localStorage, sessionStorageHelper } from 'common/utils'
import { mapMutations } from 'vuex'
import { getCurrentOrgListURL } from 'common/api'
import {queryTodoCountURL} from './api'
const defaultLogoSrc = require('assets/images/logo.png')
// 品质云logo 当newLogin 为8且为集团账号时使用
const defaultLogoSrc8 = require('assets/images/logo8.png')

export default {
  name: 'home',
  components: {
    top,
    navMenuPanel,
    titlePanel,
    iotCommunityTree,
    roomTree
  },
  data() {
    let validateNewPassword = (rule, value, callback) => {
      if (
        !value.match(/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,12}$/)
      ) {
        callback(new Error('需要8至12位包含英文、字符、数字的密码'))
      } else {
        callback()
      }
    }
    let validateNewPasswordRepeat = (rule, value, callback) => {
      if (value !== this.password.newPassword) {
        callback(new Error('两次密码必须一致'))
      } else {
        callback()
      }
    }
    return {
      show: true,
      shouldGetTree:false,
      hasMounted:false,
      showFullScreen: false,
      showCockpit: false,
      frameSrc: '',
      hasToken: false,
      isLoaed: false,
      logoSrc: '',
      userInfo: {},
      dialogStatus: 0,
      updatePwdTitle: '温馨提示',
      firstTreeLoad:true,
      updatePwdVisible: false,
      password: {
        newPassword: '',
        newPasswordRepeat: '',
      },
      passwordRules: {
        newPassword: [
          {
            required: true,
            message: '请输入新密码',
            trigger: 'change',
          },
          {
            validator: validateNewPassword,
            trigger: 'change',
          },
        ],
        newPasswordRepeat: [
          {
            required: true,
            message: '请输入确认密码',
            trigger: 'change',
          },
          {
            validator: validateNewPasswordRepeat,
            trigger: 'change',
          },
        ],
      },
      // interval: null
    }
  },
  watch: {
    showRoomTreeView(val){
        if(val){
           if(this.shouldGetTree){
            this.initTreeDrawer()
           }
        }
    },
    $route: {
      handler (route) {
        if ((route.query.src === 'top-cockpit' || route.query.src === 'top-alicockpit' || route.query.src === 'top-xlwcockpit' || route.query.src === 'cockpitNeUrl' || route.query.src === 'cockpitLanLvUrl') && route.name === 'iframePanel') {
          this.showCockpit = true
        } else {
          this.showCockpit = false
        }
      },
      deep: true,
    },
    shouldInitTree(val){
      if(val){
        this.initTreeDrawer()
      }
    },
  },
  mounted(){
    this.hasMounted = true
  },
  created() {
    const { newLogin, companyCode, token } = this.$route.query
    if (newLogin) {
      _localStorage.setItem('newLogin', newLogin)
      if (companyCode) {
        _localStorage.setItem('companyCode', companyCode)
      }
      if (token) {
        _localStorage.setItem('token', token)
      }
    } else {
      _localStorage.removeItem('newLogin')
    }
    let companyCode1 = _localStorage.getItem('companyCode')
    if (!companyCode1) {
      this.$router.push({
        name: 'login',
      })
      return
    }
    const { mode } = this.$route.query
    if (mode === 'fullScreen') {
      this.show = false
    }
    this.$axios.defaults.headers.companyCode = companyCode1
    this.getData()

  },
  computed: {
    menuGroupType(){
      return this.$store.state.theme['menu-group-type'] || 'left'
    },
    isWorkTop() {
      return this.$route.name === 'worktop' || this.$route.name === 'menuGroup'
    },
    // 是否是驾驶舱页面
    isJSC() {
      let whiteList = ['top-cockpit', 'top-alicockpit', 'top-xlwcockpit', 'cockpitNeUrl', 'cockpitLanLvUrl']
      return this.$route.name === 'iframePanel' && whiteList.includes(this.$route.query.src)
    },
    miniLogoSrc() {
      return this.$store.state.baseConfig.miniLogoSrc
    },
    showIotCommunityView() {
      // let cid = this.$route.query.cid
      let userType = this.$store.state.userInfo.userType
      let iotRightFloat = this.$route.meta.iotRightFloat
      let isIotMenu = this.$route.meta.iotMenu
      let hasComunitySearch =
        userType === '106' || userType === '100' || userType === '102'
      if (isIotMenu && hasComunitySearch && iotRightFloat) {
        return true
      } else {
        let { communityId, communityName } = this.$store.state.userInfo
        this.$store.commit('iotCommunityStore/setCurrentIotCommunity', {
          id: communityId,
          name: communityName,
        })
        return false
      }
    },
    showRoomTreeView() {
      // let cid = this.$route.query.cid
      let showRoomTreeList =[
              'communityRoomAction!list.action2',
              'communityRoomAction!list.action4',
              // 'chargingBillManage1',
              'handOver',
              'billCheck',
              'writeOff',
              'GenerateFinancial'
            ]
      return showRoomTreeList.some(v=>v == this.$route.name)

    },
    shouldInitTree(){
      return this.hasMounted && this.shouldGetTree
    },
    theme() {
      return this.$store.state.theme
    },
  },
  methods: {
    // enter (el, done) {
    //   const elementContent = this.$refs.content
    //   elementContent.style.opacity = 1
    //   setTimeout(() => {
    //     done()
    //   }, 300)
    // },
    // leave (el, done) {
    //   const elementContent = this.$refs.content
    //   elementContent.style.opacity = 0
    //   setTimeout(() => {
    //     done()
    //   }, 300)
    // },
    initTreeDrawer(){
        this.$refs['room-tree'].$refs.roomtree.initDrawer()
        if(this.firstTreeLoad){
          this.$refs['room-tree'].getNodeFirstList().then(()=>{
            this.firstTreeLoad = false
          })
        }
    },
    async getData() {
      // await this.getCookie()
      let token = _localStorage.getItem('token')
      if (token && token.length > 0) {
        this.hasToken = true
        this.$axios.defaults.headers.token = token
        this.$store.commit('setToken', token)
      }
      await this.getConfigDocValue()
      if (this.hasToken) {
        await this.$store.dispatch('queryTheme')
        const result = await this.getUserInfo()
        if (!result) {
          return
        }
        await this.getMenuResource()
        await this.getSystemConfig()
        await this.fetchOrgList()
        this.getDownloadURL()
        this.getLogoURL()
        const intervalId = this.$store.state.intervalId
        if (intervalId === undefined) {
          const newIntervalId = setInterval(this.checkSession, 1000 * 60)
          this.$store.commit('setIntervalId', newIntervalId)
        }
        const userType = this.$store.state.userInfo.userType
        this.shouldGetTree = !(userType === '104' || userType === '105') //非商家和门店
        console.log('get shouldGetTree')
        this.isLoaed = true
      }

    },
    async getConfigDocValue() {
      const paramList = ['ORG_NAME', 'BILL_HOTLINE_REMARK']
      const { status, data } = await this.$axios.post(
        `${API_CONFIG.butlerBaseURL}systemConfigDoc/listConfigDocValue`,
        {
          codeList: paramList,
        }
      )
      if (status === 100) {
        const docConfig = {}
        paramList.forEach((item, index) => {
          docConfig[item] = data[index].value
        })
        this.$store.commit('setBaseConfig', {
          docConfig,
        })
      }
    },
    async getCookie() {
      let res = await this.$axios.get(
        `${API_CONFIG.baseURL}userAction!getCookie.action`
      )
      console.log(res, '用于老接口请求时携带cookie,不要删除')
      // let token = res && res.trim && res.trim()
      // if (!token || token.length !== 32) {
      //   this.$message({
      //     type: 'error',
      //     message: '账号已过期, 请重新登录',
      //     center: true
      //   })
      //   this.$router.push({
      //     name: 'login'
      //   })
      //   return
      // }
      // this.hasToken = true
      // this.$axios.defaults.headers.token = token
      // this.$store.commit('setToken', token)
    },
    async getUserInfo() {
      const { status, data } = await this.$axios.get(
        `${API_CONFIG.butlerBaseURL}manager/info`
      )
      if (status === 100) {
        const defaultPassword = [
          'ad3b7923a41e19d011f5bf8f42bcf07b',
          'e10adc3949ba59abbe56e057f20f883e',
        ]
        this.userInfo = data
        this.$store.commit('setUserInfo', data)

        // 项目账号才有提醒
        // if(data.userType == '101') {
        //   console.log('准备开始提醒')
        //   this.noticeOrder()
        // }

        if (
          defaultPassword.indexOf(data.loginPwd) !== -1 ||
          data.pwdInit === 1
        ) {
          this.updatePwdVisible = true
        }
        return true
      }
      return false
    },
    handleMenu({
      pageList,
      pageItem,
      menuInfo,
      menuItem,
      menuMap,
      menuPermission,
      map,
      i,
    }) {
      const permissionList = pageItem.childMenus
      // 没有权限的时候也返回了子菜单，等老代码全部更新，接口修改
      if (pageItem.isSelected === 0) {
        pageList.splice(i, 1)
      } else {
        const menuId = pageItem.id
        const permission = {}
        menuItem[pageItem.url] = pageItem
        menuMap[pageItem.url] = map
        const menuPermissionKey = pageItem.url
        menuPermission[menuPermissionKey] = {}
        permissionList.forEach((permissionItem) => {
          menuPermission[menuPermissionKey][permissionItem.code] =
            permissionItem.isSelected
          permission[permissionItem.code] = permissionItem.isSelected
        })
        menuInfo[menuId] = {
          item: pageItem,
          map,
          permission,
        }
      }
    },
    findFirstPageURL(item, result) {
      if (item.menuType === 2 && item.url) {
        result.push(item.url)
      } else if (item && item.childMenus && item.childMenus.length) {
        this.findFirstPageURL(item.childMenus[0], result)
      }
    },
    async getMenuResource() {
      let routeQuery = this.$route.query
      let subPlatformId = routeQuery.pid
      let res = await this.$axios.get(
        `${API_CONFIG.controlBaseURL}sysRole/menuResource`
      )
      let menuInfo = {}
      let menuItem = {}
      let menuMap = {}
      let menuPermission = {}
      let menuResourceList = res.data
      //请求中房的菜单权限列表
      var zfMenuResourceList =[]
      await this.$axios.get(`${API_CONFIG.butlerBaseURL}zf/getMainMenu`).then(res => {
        zfMenuResourceList = res.data
      })
      console.log(zfMenuResourceList)
      var zfMenuList = []
      zfMenuResourceList.forEach(item => {
        var url = encodeURIComponent(item.url);
        var menu = {
          authType: 1,
          childMenus: [{
            authType: -1,
            childMenus: [],
            code: "view",
            helpUrl: "",
            icon: "",
            id: 95036,
            isRegion: 0,
            isSelected: 1,
            level: 2,
            menuScope: 0,
            menuType: 3,
            name: "可见",
            pId: 0,
            reportUrl: "",
            roleType: 1,
            sort: 0,
            specialNo: item.url+"view",
            url: "",
            urlJumpType: -1,
            visible: 1
          }],
          code: "",
          helpUrl: "",
          icon: "",
          id: 95035,
          isRegion: 0,
          isSelected: 1,
          level: 1,
          menuScope: 0,
          menuType: 2,
          name: item.name,
          pId: 0,
          reportUrl: "",
          roleType: 0,
          sort: 1,
          specialNo: item.url,
          url: `${API_CONFIG.origin}`+'thH5/#/pages/zf/excess?url='+url,
          urlJumpType: 2,
          visible: 1
        }
        if(item.name === '人事管理') {
          menu.icon = 'https://an.teamhonor.cn//img/icon/zfHr.png'
        }
        if(item.name === '考勤管理') {
          menu.icon = 'https://an.teamhonor.cn//img/icon/zfAttendanceManagement.png'
        }
        if(item.name === '综合管理') {
          menu.icon = 'https://an.teamhonor.cn//img/icon/zFManagement.png'
        }
        zfMenuList.push(menu)
      })
      var zfMenu = {
        authType:-1,
        childMenus:zfMenuList,
        code:"",
        helpUrl:"",
        icon:"/img/202011/shopgoods/shopgoods160507943272851.png",
        id:-100,
        isRegion:0,
        isSelected:1,
        level:0,
        menuScope:0,
        menuType:1,
        name:"办公系统",
        pId:0,
        reportUrl:"",
        roleType:0,
        sort:2,
        specialNo:"",
        url:"",
        urlJumpType:-1,
        visible:1
    }


      if (Array.isArray(menuResourceList) && menuResourceList.length) {
        menuResourceList.forEach((menuResourceItem) => {
          if (zfMenuList.length && menuResourceItem.id === 1) {
            menuResourceItem.childMenus.push(zfMenu)
          }
          menuResourceItem.childMenus.forEach((centerItem) => {
            for (let i = centerItem.childMenus.length - 1; i >= 0; i--) {
              let firstItem = centerItem.childMenus[i]
              if (firstItem.menuType === 2) {
                this.handleMenu({
                  pageList: centerItem.childMenus,
                  pageItem: firstItem,
                  menuInfo,
                  menuItem,
                  menuMap,
                  menuPermission,
                  map: {
                    pid: menuResourceItem.id,
                    cid: centerItem.id,
                    fid: firstItem.id,
                  },
                  i,
                })
              } else {
                for (let i = firstItem.childMenus.length - 1; i >= 0; i--) {
                  let secondItem = firstItem.childMenus[i]
                  if (secondItem.menuType === 2) {
                    this.handleMenu({
                      pageList: firstItem.childMenus,
                      pageItem: secondItem,
                      menuInfo,
                      menuItem,
                      menuMap,
                      menuPermission,
                      map: {
                        pid: menuResourceItem.id,
                        cid: centerItem.id,
                        fid: firstItem.id,
                        sid: secondItem.id,
                      },
                      i,
                    })
                  } else {
                    for (
                      let i = secondItem.childMenus.length - 1;
                      i >= 0;
                      i--
                    ) {
                      let thirdItem = secondItem.childMenus[i]
                      if (thirdItem.menuType === 2) {
                        this.handleMenu({
                          pageList: secondItem.childMenus,
                          pageItem: thirdItem,
                          menuInfo,
                          menuItem,
                          menuMap,
                          menuPermission,
                          map: {
                            pid: menuResourceItem.id,
                            cid: centerItem.id,
                            fid: firstItem.id,
                            sid: secondItem.id,
                            tid: thirdItem.id,
                          },
                          i,
                        })
                      }
                    }
                  }
                }
              }
            }
          })
        })
      }
      let menuResource =
        menuResourceList.find((menuResourceItem) => {
          return menuResourceItem.id == subPlatformId // eslint-disable-line
        }) || menuResourceList[0]
      this.setMenuResourceList(menuResourceList)
      this.setMenuInfo(menuInfo)
      this.setMenuItem(menuItem)
      this.setMenuMap(menuMap)
      this.setMenuPermission(menuPermission)
      let hash = location.hash.split('?')[1]
      let query = this.$qs.parse(hash)
      if (query.login) {
        let url = routeQuery.url
        if (url) {
          const _query = {}
          Object.keys(routeQuery).forEach((key) => {
            if (!['login', 'url'].includes(key)) {
              _query[key] = routeQuery[key]
            }
          })
          this.$router.replace({
            name: url,
            query: _query,
            params: {
              _replace: true,
            },
          })
        } else {
          let cid = routeQuery.cid
          let currentCenter = menuResource.childMenus.find((item) => {
            return item.id == cid // eslint-disable-line
          })
          if (!currentCenter) {
            // eslint-disable-line
            currentCenter = menuResource.childMenus[0]
            routeQuery.cid = currentCenter.id
          }
          // 如果是平台集团租户项目账号 跳转到工作台，其他跳到中心第一个页面
          let firstUrl = ''
          // const userType = this.userInfo.userType
          // if (userType === '106' || userType === '100' || userType === '102' || userType === '101') {
          //   firstUrl = 'worktop'
          // } else {
          // 21.3.12修改: 全部账号统一跳全域平台
          const result = []
          this.findFirstPageURL(currentCenter, result)
          firstUrl = result.length ? result[0] : 'login'
          // }
          const _query = {}
          Object.keys(routeQuery).forEach((key) => {
            if (!['login'].includes(key)) {
              _query[key] = routeQuery[key]
            }
          })
          this.$router.push({
            name: firstUrl,
            query: _query,
            params: {
              _replace: true,
            },
          })
        }
      } else {
        let routeName = this.$route.name
        let menu = menuMap[routeName]
        // 检验路由参数中菜单相关信息是否正确
        if (
          menu &&
          (menu.pid != query.pid ||
            menu.cid != query.cid ||
            menu.fid != query.fid ||
            menu.sid != query.sid ||
            menu.tid != query.tid)
        ) {
          // eslint-disable-line
          ;['pid', 'cid', 'fid', 'sid', 'tid'].forEach((key) => {
            routeQuery[key] = menu[key]
          })
          this.$message.info('菜单发生变化，已为你跳转')
        }
        this.$router.replace({
          name: routeName,
          query: {
            ...routeQuery,
            ...{
              _timeline: new Date().getTime(),
            },
          },
        })
      }
    },
    async getSystemConfig() {
      let res = await this.$axios.get(
        `${API_CONFIG.controlBaseURL}systemConfig/getScSystemConfiguration`
      )
      if (res.status === 100) {
        let dynamicParams = res.data || {}
        this.$store.commit('setBaseConfig', {
          dynamicParams,
        })
      }
    },
    async fetchOrgList() {
      let { data } = await this.$axios.get(getCurrentOrgListURL)
      this.$store.commit('setOrgOps', data)
      // let currentOrg = []
      // let lastOrg = sessionStorageHelper.getItem(this.userId)
      // if (lastOrg && lastOrg.length) {
      //   // 从当前返回的结果集中选中上次选择
      //   currentOrg = data.filter(orgId => lastOrg.includes(orgId))
      // } else {
      //   currentOrg = data.map(item => item.orgId)
      // }
      // this.$store.commit('setCurOrgIds', currentOrg)
    },
    iotCommunityViewControl() {
      // 物联网页面如果右边栏已经弹出来了，收起
      if (this.showIotCommunityView && this.$refs.iotTree.open) {
        this.$refs.iotTree.open = false
      }
    },
    // 轮询后台老接口, 保证session不会过期
    checkSession() {
      this.$axios.get(
        `${API_CONFIG.baseURL}serverUserAction!checkSession.action`
      )
    },
    getDownloadURL() {
      let _this = this
      _this.$axios
        .post(`${API_CONFIG.butlerBaseURL}product/region/list/product/app`)
        .then((res) => {
          let _qrcodeList = res.data
          if (_qrcodeList && _qrcodeList.length > 0) {
            let qrcodeList = []
            let keys = ['userApp', 'businessApp', 'serviceApp']
            _qrcodeList.forEach((item) => {
              keys.forEach(function (key) {
                if (item[key]) {
                  qrcodeList.push({
                    name: item[key].appName,
                    src: item[key].appDownloadUrl,
                  })
                }
              })
            })
            _this.$store.commit('setBaseConfig', {
              qrcodeList,
            })
          }
        })
    },
    getLogoURL() {
      let _this = this
      _this.$axios
        .get(`${API_CONFIG.butlerBaseURL}businessRegion/logo`)
        .then((res) => {
          const logoSrc = res.data
          const { newLogin } = this.$route.query
          let userType = this.$store.state.userInfo.userType
          // 品质云logo展示  当从登录页 login8 登录(newLogin === 8) && 集团账号(userType === 100)时使用
          if (newLogin == 8 && userType === '100') {
             _this.logoSrc = defaultLogoSrc8
          } else {
            _this.logoSrc = logoSrc || this.miniLogoSrc || defaultLogoSrc
          }
        })
    },
    logout() {
      let _this = this
      let url = `${API_CONFIG.butlerBaseURL}logout`
      _this.$axios.post(url).then((res) => {
        if (res.status === 100) {
          _this.$router.push({
            name: 'login',
          })
        }
      })
    },
    updatePassword() {
      if (this.dialogStatus === 0) {
        this.dialogStatus = 1
        this.updatePwdTitle = '修改密码'
        return
      }
      let _this = this
      let _valid = true
      _this.$refs.password.validate((valid) => {
        _valid = valid
      })
      if (!_valid) {
        return
      }
      let url = `${API_CONFIG.butlerBaseURL}manager/changePwd`
      let postData = {
        isInit: true,
        userId: _this.userInfo.id,
        headPicName: _this.userInfo.headPicName,
        oldPassword: _this.userInfo.loginPwd,
        newPassword: _this.password.newPassword,
      }
      _this
        .$axios({
          method: 'put',
          url: url,
          data: postData,
        })
        .then((res) => {
          if (res.status === 100) {
            _this.$store.commit('setUserInfo', res.data)
            _this.$message({
              type: 'success',
              message: '保存成功',
              center: true,
            })
            _this.updatePwdVisible = false
          }
        })
    },
    ...mapMutations('menuStore', [
      'setMenuResourceList',
      'setMenuInfo',
      'setMenuItem',
      'setMenuMap',
      'setMenuPermission',
    ]),
    // noticeOrder() {
    //   this.interval = setInterval(() => {
    //     this.$axios.post(queryTodoCountURL,{
    //       modelIds: [17, 18, 19]
    //     }).then(res => {
    //       res.data.some(item => {
    //         if(item.count > 0) {
    //           let audio = this.$refs.audioPlay
    //           audio.play()
    //           console.log('播放声音')
    //           return true
    //         }
    //       })
    //     })
    //   },1000 * 60 * 2)
    // }
  },
  destroyed() {
    clearInterval(this.interval)
  }
}
</script>
<style lang="scss">
.home-container {
  >.top {
    margin-top: -48px;
    background: #f0f3f7;
  }
}
</style>
<style scoped lang="scss">
.home-container {
  height: 100%;
  padding-top: 48px;
  box-sizing: border-box;
  .top-cockpit {
    background: #0c0647;
    ::v-deep .left-item {
      color: #fff !important;
    }
  }
  .bottom-cockpit {
    height: 100%;
    &.full-screen {
      margin-top: -80px;
      height: calc(100% + 80px);
    }
    .cockpit-iframe {
      width: 100%;
      height: 100%;
    }
  }
  .bottom {
    display: flex;
    height: 100%;
    .views {
      flex: 1;
      margin: 16px 16px 0;
      overflow: hidden;
      border-radius: 6px;
      background: #fff;
      .content {
        position: relative;
        height: 100%;
        padding-top: 72px;
        box-sizing: border-box;
        // opacity: 0;
        // transition: opacity 0.3s cubic-bezier(1, 0.5, 0.8, 1);
        .title-panel {
          margin-top: -72px;
        }
        .router-view {
          height: 100%;
        }
      }
    }
    // 驾驶舱页样式修改
    .views-JSC {
      margin: 0;
      border-radius: 0;
      .iframe-panel-container {
        padding: 0;
      }
    }
  }
  .workTop {
    .views {
      margin: 30px;
      background: rgba(0, 0, 0, 0);
    }
  }
  .updatePwd-dialog {
    .updatePwd-dialog-content {
      .form {
        text-align: left;
        margin-left: 50px;
      }
      .ops {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        .logout {
          margin-right: 5px;
          ::v-deep .el-button:focus,::v-deep .el-button:hover {
            background-color: #fff;
          }
        }
        .update {
          margin-left: 5px;
        }
      }
    }
  }


}
.home-full-screen {
  padding-top: 0;
  .bottom {
    .views {
      margin: 0;
    }
  }
}

</style>
