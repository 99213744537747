<template>
  <span class="field-tips-wrapper">
      {{tipsText}}
      <slot name="customFieldTips"></slot>
  </span>
</template>

<script>

// 字段的解释说明组件
// @group 业务组件
// @title Field-Tips 字段的解释说明
export default {
  name: 'field-tips',
  props: {
    // 提示文案
    tipsText: {
      type: String,
      // ``
      default: ''
    }
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
  .field-tips-wrapper {
    font-size: 12px;
    color: #aaa;
    margin-left: 10px;
  }
</style>
