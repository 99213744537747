import Col2Block from './block'
import Col2Detail from './detail'
import Col2Progress from './progress'
import Col2Item from './item'
export {
  Col2Block,
  Col2Detail,
  Col2Progress,
  Col2Item
}
