<template>
  <div class="qrcode-collection-wrapper">
    <el-dialog
      width="940px"
      :title="title"
      :visible.sync="dialogVisible"
      @open="open"
      @closed="closed">
      <div v-if="contentShow" class="qrcode-collection" id="qrcode-collection-print">
        <el-row v-for="(itemList, index) in twoDimensionalArray" :key="index">
          <el-col
            :span="6"
            v-for="(item, itemIndex) in itemList"
            :key="itemIndex">
            <div class="qrcode-item">
              <div class="qrcode-image-wrapper">
                <div :id="'qrcode_img_' + item.encryCode" class="img"></div>
              </div>
              <div class="qrcode-content">
                <!-- 自定义内容：位于二维码图片下方 -->
                <slot name="qrcodeSlot" :row="item">
                  <span>二维码内容</span>
                </slot>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-if="printable" slot="footer" class="dialog-footer print-button">
        <v-button text="打印二维码" @click="bottomClick"></v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import Vue from 'vue'
import { Row, Col } from 'element-ui'
import _ from 'lodash'
Vue.use(Row)
Vue.use(Col)

// @group 业务组件
// @title QrcodeCollection 二维码宫格
export default {
  name: 'qrcode-collection',
  props: {
    // 是否显示 qrcode-collection，
    visible: {
      type: Boolean,
      // `false`
      default: false
    },
    // 标题
    title: {
      type: String,
      // `二维码`
      default: '二维码'
    },
    // 二维码数组,里面是二维码对象，其中对象属性`encryCode`必传（生成二维的value）
    qrcodeList: {
      type: Array,
      // `[]`
      default: () => {
        return []
      }
    },
    // 是否显示打印按钮，默认显示
    printable: {
      type: Boolean,
      // `true`
      default: true
    }
  },

  watch: {
    visible (newValue) {
      this.dialogVisible = newValue
    },

    dialogVisible (newValue) {
      this.$emit('update:visible', newValue)
    }
  },

  data () {
    return {
      dialogVisible: this.visible,
      // 二维数组
      twoDimensionalArray: [],
      contentShow: false
    }
  },

  methods: {
    // Dialog 打开的回调
    open () {
      this.dealWithQRCodeShow()
      this.contentShow = true
    },

    // Dialog 关闭动画结束时的回调
    closed () {
      this.contentShow = false
    },

    // 处理二维码数组
    dealWithQRCodeShow () {
      // 目前都是每行4个二维码，其余的个数以后用到再扩展
      let list = _.chunk(this.qrcodeList, 4)
      if (list && list.length > 0) {
        list.forEach((itemList, index) => {
          itemList.forEach(item => {
            let encryCode = item.encryCode
            if (encryCode && encryCode.length) {
              let id = `qrcode_img_${item.encryCode}`
              this.$nextTick(() => {
                new QRCode(id, { // eslint-disable-line
                  width: 180,
                  height: 180,
                  text: item.encryCode
                })
              }, 20)
            }
          })
        })
      }
      this.twoDimensionalArray = list
    },

    // 打印
    bottomClick () {
      if (this.qrcodeList && this.qrcodeList.length) {
        let newWindow = window.open()
        let docStr = document.getElementById('qrcode-collection-print')
          .innerHTML
        let printHtmlStr = `<style>
                            .qrcode-item {
                              float: left;
                              width: 195px;
                              height: 370px;
                              padding: 5px 5px;
                              margin: 20px 10px;
                            }
                            .qrcode-item .qrcode-content {
                                padding-top: 10px;
                                width: 180px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                text-align: center;
                            }
                            </style><div style="width: 900px; height: 100%;">${docStr}</div>`
        newWindow.window.document.body.innerHTML = printHtmlStr
        setTimeout(function () {
          newWindow.print()
          newWindow.close()
        }, 100)
      } else {
        console.error('无可打印内容')
      }
    }
  }
}
</script>

<style>
</style>

<style lang="scss">
.qrcode-collection-wrapper {
  display: flex;

  .qrcode-collection {
    height: 520px;
    overflow: auto;
    z-index: 100;

    .qrcode-image-wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    .qrcode-content {
      margin: 0 20px 40px 20px;
      display: flex;
      justify-content: center;
      word-break: break-word;
    }
  }

  .print-button {
    text-align: center;
  }
}
</style>
