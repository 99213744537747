<template>
<div class="v-uploader-wrapper">
  <div class="v-controls">
    <SlickList :lockToContainerEdges="true" class="SortableList" helperClass="helperClass" v-show="limit > 1 && useDragHandle"  axis="x" lockAxis="x" v-model="fileList2" @input="getChangeLists">
      <SlickItem class="SortableItem" v-for="(item, index) in fileList2" :index="index" :key="item.url">
        <div class="imgBox imgActive" :style="fileStyle">
          <img v-if="!item.isOther" class="v-img" :src="item.url" alt="">
          <div class="box" v-else>
            <i class="el-icon-document document"></i>
            <span class="ellipsis">{{item.name}}</span>
            <span v-if="item.size">size:{{item.size | fileSize}}</span>
          </div>
        </div>
      </SlickItem>
    </SlickList>
    <div style="display:flex;flex-wrap: wrap;" v-if="limit > 1 && !useDragHandle" >
      <div style="margin-bottom: 10px;" v-for="(item, index) in fileList1" :key="index">
        <div class="imgBox" :style="fileStyle" v-if="!item.percentage">
          <img v-if="!item.isOther" class="v-img" :src="item.url" alt="">
          <div class="box" v-else>
            <i class="el-icon-document document"></i>
            <span class="ellipsis">{{item.name}}</span>
            <span class="ellipsis" v-if="item.size">size:{{item.size | fileSize}}</span>
          </div>
          <div class="screen" v-if="operate === 'all'">
            <i v-if="!item.isOther" class="el-icon-zoom-in icon" @click="toPreview(item.url)"></i>
            <i class="el-icon-delete icon" @click="removeFile(item.url)"></i>
          </div>
          <div class="screen" v-if="operate === 'delete'">
            <i class="el-icon-delete icon" @click="removeFile(item.url)"></i>
          </div>
        </div>
        <div class="imgBox" :style="fileStyle" v-else>
          <div class="preogressBox">
            <el-progress type="circle" class="preogress" :percentage="item.percentage" :width="progressW"></el-progress>
            <span class="ellipsis">{{item.name}}</span>
          </div>
        </div>
      </div>
      <el-upload
        ref="upload"
        class="v-upload"
        :style="fileStyle"
        v-if="(fileList1.length + manualUploadList.length) < limit && limit > 1"
        :disabled="disabled"
        :data="extraData"
        :action="action"
        :multiple="multiple"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
        :before-remove="beforeRemoveFile"
        :drag="drag"
        :on-success="successUpload"
        :on-error="errorUpload"
        :auto-upload="autoUpload"
        :on-preview="previewUpload"
        :on-progress="progressUpload"
        :list-type="listType"
        :on-change="onChange"
        :headers="importHeaders"
        :name="name"
      >
        <img v-if="fileWidth === fileHeight || !fileHeight" class="v-img" :style="fileStyle" :src="addImg" alt="">
        <div v-else class="noImg" :style="fileStyle">
          <span class="addIcon">+</span>
          <span class="addText">点击或拖拽上传</span>
        </div>
      </el-upload>
    </div>
    <!-- 手动上传列表 -->
    <div v-for="(item, index) in manualUploadList" v-show="limit > 1" :key="index">
        <div class="imgBox" :style="fileStyle">
          <div class="preogressBox">
            <span class="ellipsis">{{item.name}}</span>
          </div>
          <div class="screen">
            <i class="el-icon-delete icon" @click="deleteFile(item.uid)"></i>
          </div>
      </div>
    </div>
    <el-upload
      ref="upload"
      class="v-upload"
      :style="fileStyle"
      v-if="limit === 1"
      :disabled="disabled"
      :data="extraData"
      :action="action"
      :multiple="multiple"
      :show-file-list="false"
      :before-upload="beforeAvatarUpload"
      :before-remove="beforeRemoveFile"
      :drag="drag"
      :on-success="successUpload"
      :on-error="errorUpload"
      :auto-upload="autoUpload"
      :on-preview="previewUpload"
      :on-progress="progressUpload"
      :list-type="listType"
      :on-change="onChange"
      :headers="importHeaders"
      :name="name"
      :accept="acceptFileType"

    >
    <div v-if="limit===1">
      <div v-if="fileList1.length === 1" class="imgBox" :style="fileStyle">
        <div v-if="!fileList1[0].percentage">
          <img v-if="!fileList1[0].isOther" class="v-img" :src="fileList1[0].url" alt="">
          <div class="box" v-else>
            <i class="el-icon-document document"></i>
            <span class="ellipsis">{{fileList1[0].name}}</span>
            <span class="ellipsis" v-if="fileList1[0].size">size:{{fileList1[0].size | fileSize}}</span>
          </div>
          <div class="screen" v-if="operate === 'all'" @click.stop>
            <i v-if="!fileList1[0].isOther" class="el-icon-zoom-in icon" @click.stop="toPreview(fileList1[0].url)"></i>
            <i class="el-icon-delete icon" @click.stop="removeFile(fileList1[0].url)"></i>
          </div>
          <div class="screen" v-if="operate === 'delete'" @click.stop>
            <i class="el-icon-delete icon" @click.stop="removeFile(fileList1[0].url)"></i>
          </div>
        </div>
        <div class="imgBox" :style="fileStyle" v-else>
          <div class="preogressBox">
            <el-progress style="height: auto;" type="circle" :percentage="fileList1[0].percentage" :width="progressW"></el-progress>
            <span class="ellipsis">{{fileList1[0].name}}</span>
          </div>
        </div>
      </div>
      <div  v-else-if="manualUploadList.length === 1" class="imgBox" :style="fileStyle" @click.stop>
        <div class="box">
            <i class="el-icon-document document"></i>
            <span class="ellipsis">{{manualUploadList[0].name}}</span>
            <span>未上传</span>
          </div>
          <div class="screen">
            <i class="el-icon-delete icon" @click="deleteFile(manualUploadList[0].uid)"></i>
          </div>
      </div>
      <div v-else>
        <img v-if="fileWidth === fileHeight || !fileHeight" class="v-img" :style="fileStyle" :src="addImg" alt="">
        <div v-else class="noImg" :style="fileStyle">
          <span class="addIcon">+</span>
          <span class="addText">点击或拖拽上传</span>
        </div>
      </div>
      <!-- <img v-else class="v-img" :style="fileStyle" :src="addImg" alt="">
      <div v-else class="noImg" :style="fileStyle">
        <span class="addIcon">+</span>
        <span class="addText">点击或拖拽上传</span>
      </div> -->
    </div>
    <div v-else>
      <img v-if="fileWidth === fileHeight || !fileHeight" class="v-img" :style="fileStyle" :src="addImg" alt="">
      <div v-else class="noImg" :style="fileStyle">
        <span class="addIcon">+</span>
        <span class="addText">点击或拖拽上传</span>
      </div>
    </div>
    <!-- <div v-else class="noImg" :style="fileStyle">
      <span class="addIcon">+</span>
      <span class="addText">点击或拖拽上传</span>
    </!--> -->
    <!-- <img v-else class="v-img" :style="fileStyle" :src="addImg" alt=""> -->
    </el-upload>
  </div>
  <div>
    <v-button class="marTop" v-if="isOrder && fileList1.length > 1" :text="dragText" @click="toChangeOrder"></v-button>
    <div class="rightTip" v-if="$slots.tip">
      <!-- 下边提示文字 -->
      <slot name="tip"></slot>
    </div>
  </div>
</div>
</template>
<script>
import { Upload, Progress } from 'element-ui'
import addImg from './images/icon_add_img_132_132.png'
import _ from 'lodash'
import { SlickList, SlickItem } from 'vue-slicksort'
import { _localStorage } from 'common/utils'
import emitter from 'element-ui/src/mixins/emitter'
// @group 基础组件
// @title Uploader 上传组件
export default {
  name: 'v-uploader',
  components: {
    elUpload: Upload,
    elProgress: Progress,
    SlickList,
    SlickItem
  },
  mixins: [emitter],
  props: {
  //  上传时附带的额外参数
    extraData: {
      type: Object,
      // `{}`
      default: () => {
        return {
          type: ''
        }
      }
    },
    // 必选参数，上传的地址
    action: {
      type: String,
      required: true
    },
    // 是否多选
    multiple: {
      type: Boolean,
      // `true`
      default: true
    },
    // 是否只上传图片
    onlyImage: {
      type: Boolean,
      // `true`
      default: true
    },
    // 接收的文件类型
    acceptFileType: {
      type: String
    },
    // 上传文件大小  单位M
    fileSize: {
      type: Number,
      // `100`
      default: 100
    },
    // 最大允许上传个数
    limit: {
      type: Number,
      // `1`
      default: 1
    },
    // 是否允许拖拽
    drag: {
      type: Boolean,
      // `true`
      default: true
    },
    // 是否自动上传
    autoUpload: {
      type: Boolean,
      // `true`
      default: true
    },
    // 上传列表 多个上传（limit>1）时需要传
    fileList: {
      type: [Array, String],
      // `[]`
      default: () => {
        return []
      }
    },
    // 图片url队列 与fileList二选一 不可同时存在 同时存在的请客fileLis无效
    imgUrls: {
      type: [Array, String]
    },
    // showFileList为true是所显示的type
    listType: {
      // `'text'` / `'picture'` / `'picture-card'`
      type: String,
      // `text`
      default: 'text'
    },
    // 是否禁用
    disabled: Boolean,
    // 文件列表移除文件时的钩子 function(file, fileList)
    onSuccess: Function,
    // 文件上传失败时的钩子function(err, file, fileList)
    onError: Function,
    // 点击文件列表中已上传的文件时的钩子function(file)
    onPreview: Function,
    // 删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止上传。function(file, fileList)
    beforeRemove: Function,
    // 上传文件前钩子
    beforeUpload: Function,
    // 是否支持排序 limit > 1 时可选
    isOrder: {
      type: Boolean,
      // `false`
      default: false
    },
    // 请求头部
    headers: Object,
    // 是否弹出错误提示
    isAlertErrorMsg: {
      type: Boolean,
      // `true`
      default: true
    },
    // 数据返回url前缀
    prefixUrl: String,
    // 上传的文件字段名
    name: {
      type: String,
      // `file`
      default: 'file'
    },
    // 图片上传后的操作
    operate: {
      type: String,
      // `'all'` / `'delete'` / `'none'`
      default: 'all'
    },
    // 显示图片宽度
    fileWidth: {
      type: Number,
      // `'140'`
      default: 140
    },
    // 显示图片高度
    fileHeight: {
      type: Number
    },
    // 图片上传的尺寸校验 [width, height]
    imageWH: {
      type: Array
    }
  },
  data () {
    return {
      addImg,
      fileName: '',
      loading: false,
      percentage: 0,
      fileLoading: [],
      useDragHandle: false,
      fileList1: [],
      fileList2: [],
      dragText: '排序',
      manualUploadList: [],
      successUidList: [],
      errUidList: [],
      token: this.$store ? this.$store.state.token : '',
      outputType: 'file'
    }
  },
  computed: {
    importHeaders () {
      let head
      if (this.headers) {
        head = this.headers
      } else {
        head = {
          token: this.$store ? this.$store.state.token : '',
          companyCode: _localStorage.getItem('companyCode')
        }
      }
      return head
    },
    fileStyle () {
      return {
        width: `${this.fileWidth}px`,
        height: `${this.fileHeight || this.fileWidth}px`
      }
    },
    progressW () {
      return this.fileHeight && this.fileWidth > this.fileHeight ? this.fileHeight * 0.7 : this.fileWidth * 0.7
    }
  },
  filters: {
    fileSize (val) {
      let text = ''
      if (val < 1024000) {
        text = `${(val / 1024).toFixed(2)}K`
      } else if (val < (1024000 * 1024)) {
        text = `${(val / 1024 / 1024).toFixed(2)}M`
      } else {
        text = `${(val / 1024 / 1024 / 1024).toFixed(2)}KM`
      }
      return text
    }
  },
  created () {
    if (this.imgUrls === undefined) {
      this.outputType = 'file'
      this.fileList1 = _.cloneDeep(this.fileList)
      this.fileList2 = _.cloneDeep(this.fileList)
    } else {
      this.outputType = 'image'
      if (this.limit === 1) {
        let img = this.imgUrls ? [ { url: this.imgUrls } ] : []
        this.fileList1 = img
      } else {
        this.fileList1 = this.imgUrls.map(url => { return { url } })
      }
    }
  },
  methods: {
    async beforeAvatarUpload (file) {
      let aaa = await this.sizeVaild(file)
      console.log(aaa)
    },
    baseVaild (file, resolve, reject) {
      console.log(file)
      let _this = this
      if (!_this.autoUpload) {
        let uidList = _this.manualUploadList.map((value) => value.uid)
        if (uidList.indexOf(file.uid) < 0) {
          reject()
        }
      }
      if (_this.beforeUpload && !_this.beforeUpload(file)) {
        reject()
        return
      }
      const isFormat = _this.onlyImage ? file.type.indexOf('image') > -1 : true
      const isSizeover = file.size / 1024 / 1024 < _this.fileSize
      const isNumNoOver = _this.fileList1.length < _this.limit || _this.limit === 1
      console.log('acceptFileType', _this.acceptFileType)
      if (!isFormat) {
        setTimeout(() => {_this.$message('格式上传不正确')})
      }
      if (!isSizeover) {
        setTimeout(() => {_this.$message(`文件过大，不能超过${_this.fileSize}M`)})
      }
      if (!isNumNoOver) {
        setTimeout(() => {_this.$message(`数量超出，不能超过${_this.limit}个`)})
      }
      if (isFormat && isSizeover && isNumNoOver) {
        let fileInfo = {
          name: file.name,
          uid: file.uid,
          size: file.size,
          percentage: 0
        }
        if (_this.limit === 1) {
          let arr = [ fileInfo ]
          _this.updateFile(arr)
          _this.fileList1 = arr
        } else {
          _this.fileList1.push(fileInfo)
          _this.updateFile(_this.fileList1)
        }
        _this.manualUploadList = _this.manualUploadList.filter(value => value.uid !== file.uid)
        resolve()
      } else {
        _this.manualUploadList = _this.manualUploadList.filter(value => value.uid !== file.uid)
        reject()
      }
    },
    sizeVaild (file) {
      let _this = this
      return new Promise((resolve, reject) => {
        if (_this.imageWH) {
          let reader = new FileReader()
          reader.onloadend = (event) => {
            let imgObj = new Image()
            imgObj.src = event.target.result
            imgObj.onload = (imgEvent) => {
              if (this.imageWH[0] === imgObj.width && this.imageWH[1] === imgObj.height) {
                // resolve(true)
                this.baseVaild(file, resolve, reject)
              } else {
                _this.$message(`图片尺寸不合格，仅支持宽${this.imageWH[0]}px高${this.imageWH[1]}px`)
                reject()
              }
            }
          }
          reader.readAsDataURL(file)
        } else {
          this.baseVaild(file, resolve, reject)
        }
      })
    },
    successUpload (response, file, fileList) {
      if (response.status != 100) { // eslint-disable-line
        this.errorUpload(response, file, fileList)
        return
      }
      let isImg = file.raw.type.indexOf('image') > -1
      let voidList = _.cloneDeep(this.fileList1)
      voidList.forEach(item => {
        if (item.uid === file.uid) {
          item.url = this.prefixUrl ? `${this.prefixUrl}${response.data[0]}` : response.data[0]
          item.percentage = false
          item.isOther = !isImg
        }
      })
      this.fileList1 = voidList
      this.updateFile(voidList)
      this.successUidList.push(file.uid)
      this.onSuccess && this.onSuccess(response, file, fileList)
    },
    errorUpload (response, file, fileList) {
      let list = _.cloneDeep(this.fileList1)
      list = list.filter(value => value.uid !== file.uid)
      this.fileList1 = list
      this.updateFile(list)
      let msg = response.message ? response.message : response.msg
      if (msg && this.isAlertErrorMsg) {
        this.$message({
          type: 'error',
          message: msg,
          center: true
        })
      }
      this.errUidList.push(file.uid)
      this.onError && this.onError(response, file, fileList)
    },
    previewUpload (file) {
      this.onPreview && this.onPreview(file)
    },
    beforeRemoveFile (file, fileList) {
      this.beforeRemove && this.beforeRemove(file, fileList)
    },
    removeFile (url) {
      let list = _.cloneDeep(this.fileList1)
      list = _.remove(list, (item) => {
        return url !== item.url
      })
      this.fileList1 = list
      this.updateFile(list)
      this.$nextTick(() => {
        this.dispatch('ElFormItem', 'el.form.change')
      })
    },
    progressUpload (event, file, fileList) {
      let voidList = _.cloneDeep(this.fileList1)
      voidList.forEach((value) => {
        if (value.uid && value.uid === file.uid) {
          value.percentage = parseInt(event.percent)
        }
      })
      this.fileList1 = voidList
      this.updateFile(voidList)
    },
    // @vuese
    // 上传至服务器，手动上传时需要
    submitUpload () {
      this.$refs.upload.submit()
    },
    toPreview (url) {
      let previewList = []
      this.fileList1.forEach(item => {
        if (!item.isOther) {
          previewList.push(item.url)
        }
      })
      let index = previewList.indexOf(url)
      this.$previewBox(previewList, index)
    },
    toChangeOrder () {
      this.fileList2 = _.cloneDeep(this.fileList1)
      this.useDragHandle = !this.useDragHandle
      this.dragText = this.useDragHandle ? '确定' : '排序'
    },
    getChangeLists (val) {
      this.fileList1 = val
      this.updateFile(val)
    },
    onChange (file, fileList) {
      this.$nextTick(() => {
        this.dispatch('ElFormItem', 'el.form.change')
      })
      if (!this.autoUpload) {
        if ((this.manualUploadList.length + this.fileList1.length) >= this.limit) {
          return false
        }
        let isSuccess = this.successUidList.indexOf(file.uid) > -1
        let isErr = this.errUidList.indexOf(file.uid) > -1
        if (!isSuccess && !isErr) {
          this.manualUploadList.push(file)
          // 手动上传时 fileList发生变化出发
          // @arg function(fileList)
          this.$emit('storageChange', this.manualUploadList)
        }
      }
    },
    deleteFile (uid) {
      this.manualUploadList = this.manualUploadList.filter(value => value.uid !== uid)
      this.$emit('storageChange', this.manualUploadList)
    },
    updateFile (val) {
      if (this.outputType === 'file') {
        // @arg fileList 文件列表 双向绑定
        this.$emit('update:fileList', val)
      } else {
        if (this.limit === 1) {
          if (val.length > 0) {
            // @arg imgUrls 图片列表 双向绑定 limit为1时String，>1时Array
            val[0].url && this.$emit('update:imgUrls', val[0].url)
          } else {
            this.$emit('update:imgUrls', '')
          }
        } else {
          let urlArr = val.map(item => item.url)
          this.$emit('update:imgUrls', urlArr)
        }
      }
    },
    toChangeImg (list) {
      if (this.limit === 1) {
        let img = [ { url: list } ]
        this.fileList1 = img
      } else {
        this.fileList1 = list.map(url => { return { url } })
      }
    }
  },
  watch: {
    fileList (val) {
      this.fileList1 = val
    },
    imgUrls (val) {
      if (this.limit === 1) {
        if (val) {
          let img = [ { url: val } ]
          this.fileList1 = img
        } else {
          this.fileList1 = []
        }
      } else {
        if (val.length !== this.fileList1.length) {
          this.fileList1 = val.map(url => { return { url } })
        } else {
          let isChange = false
          this.fileList1.forEach((item, index) => {
            if (item.url !== val[index]) {
              isChange = true
            }
          })
          if (isChange) {
            this.fileList1 = val.map(url => { return { url } })
          }
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.v-uploader-wrapper {
  display: inline-block;
}
.v-controls {
  display: flex;
  // flex-wrap: wrap;
  .imgBox{
    div {
      height: 100%;
    }
    span {
      color: #333;
    }
    position: relative;
    width: 140px;
    height: 140px;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 10px;
    background: #fff;
    text-align: left;
    .close {
      position: absolute;
      right: 5px;
      top: 5px;
      color: #eaeaea;
      cursor: pointer;
    }
    .box{
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-items: center;
      padding: 10px;
      border: 1px solid #dedede;
      border-radius: 6px;
      .document{
        font-size: 80px;
        color: #dedede;
        margin-left: 22px;
      }
    }
    .screen{
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.7);
      top: 0;
      left: 0;
       :first-child{
        margin-right: 25px;
      }
      .icon {
        color: #fff;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }
  .imgBox:hover {
    .screen{
      display: flex;
    }
  }
  .v-img{
    width: 100%;
    height: 100%;
  }
}
.preogressBox{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #dedede;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 10px;
  box-sizing: border-box;
  .preogress {
    height: auto !important;
  }
}
.v-upload {
  width: 140px;
  height: 140px;
  // display: inline-block;
}
.rightTip{
  display: inline-block;
  line-height: 30px;
  div {
    display: inline-block;
  }
}
.preview-content{
  text-align: center;
  img {
    max-width: 100%;
  }
}
.SortableList {
  display: flex;
  white-space: nowrap;
  max-height: 80vh;
  padding: 0;
  border-radius: 3;
}
.SortableItem {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  user-select: none;
  color: #333;
  font-weight: 400;
}
.marTop {
  margin-top: 10px;
}
.imgActive {
  animation: gogogo 0.25s linear 0s infinite;
}
@keyframes gogogo {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes gogogo {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.helperClass {
  .imgBox {
    width: 140px;
    height:140px;
    border-radius: 6px;
    overflow: hidden;
    .v-img{
      width: 100%;
      height:100%;
    }
    .box{
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-items: center;
      padding: 10px;
      background: #fff;
      border: 1px solid #dedede;
      border-radius: 6px;
      .document{
        font-size: 84px;
        color: #dedede;
        margin-left: 20px;
      }
    }
  }
}
.ellipsis{
  min-height: 20px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  // padding: 0 5px;
  box-sizing: border-box;
}
.noImg {
    display: flex;
    flex-direction: column;
    background: #f8f8f8;
    border: 2px dashed #dedede;
    box-sizing: border-box;
    color: #999;
    justify-content: center;
    .addIcon{
      font-size: 100px;
      height: 60px;
    }
    .addText {
      font-size: 17px;
    }
}
</style>
<style scoped>
>>> .el-upload {
  width: 100%;
  height: 100%;
}
>>> .el-upload-dragger {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
}
>>> .el-upload-dragger:hover {
    border: none;
}
>>> .el-upload-dragger.is-dragover {
    border: 2px dashed #409EFF;
}
</style>
<style lang="scss">
.is-error {
  .noImg {
    border: 1px solid #F56C6C;
    border-radius: 6px;
  }
}
</style>
