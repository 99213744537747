<template>
  <div class="data-brain-list-container">
    <el-popover trigger="hover" popper-class="data-brain-list-popper" v-model="visible" :popper-options="popperOptions" @show="show" @hide="hide">
      <ul class="data-brain-list">
        <template v-for="item in dataBrainMenu">
          <li slot="reference" class="data-brain-item" @click="itemClick(item)" :key="item.id">
            <div class="name" v-text="item.name"></div>
          </li>
        </template>
      </ul>
      <template v-slot:reference>
        <slot name="dataBrain"></slot>
      </template>
    </el-popover>
  </div>
</template>

<script>
import { menuJump } from 'common/menuHandler'

export default {
  name: 'data-brain-list',
  props: {
    dataBrainMenu: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      visible: false,
      popperOptions: {
        boundariesElement: '.data-brain-list-container'
      }
    }
  },
  methods: {
    show () {
      this.$emit('statusChange', true)
    },
    hide () {
      this.$emit('statusChange', false)
    },
    itemClick (item) {
      this.visible = false
      if (item.childMenus && item.childMenus.length) {
        const menu = item.childMenus[0]
        if ([1, 2].includes(menu.urlJumpType)) {
          menuJump(menu)
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .data-brain-list-popper {
    padding: 1px 0 0 0 !important;
    min-width: 120px !important;
  }
</style>
<style scoped lang="scss">
  .data-brain-list-popper {
    .data-brain-list {
      .data-brain-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 43px;
        font-size: 14px;
        color: #333;
        cursor: pointer;
        .icon {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 16px;
        }
        &:hover {
          color: #1B8CF2;
        }
        &:not(:last-child) {
          border-bottom: 1px solid #F9EEEE;
        }
      }
    }
  }
</style>
