import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import cacheManager from './cacheManager'
import { _localStorage, sessionStorageHelper } from 'common/utils'
let header = {
  timeout: 200000,
  headers: {
    companyCode: '',
    token: ''
  }
}

// let commonParam = {
// token: 'xxx'
// }

let _axios = axios.create(header)

_axios.interceptors.request.use(config => {
  let url = config.url
  // 兼容老接口
  if (url.indexOf('!') !== -1 && config.method === 'post') {
    const { headers } = config
    config.transformRequest = [function (data) {
      return Vue.prototype.$qs.stringify(data)
    }]
    config.headers = {
      'Content-Type': headers['Content-Type'] || 'application/x-www-form-urlencoded'
    }
  }

  // else {
  //   if (config.headers['Content-Type'] && config.headers['Content-Type'].indexOf('application/x-www-form-urlencoded') !== -1) {
  //     config.data = Vue.prototype.$qs.stringify(config.data)
  //   }
  // }
  if (url) {
    encodeURI(config.url)
  }
  // if (config.method === 'post') {
  //   config.data = {
  //     ...config.data,
  //     ...commonParam
  //   }
  // } else if (config.method === 'get') {
  //   config.params = {
  //     ...config.params,
  //     ...commonParam
  //   }
  // }
  return config
}, function (error) {
  return Promise.reject(error)
})

_axios.interceptors.response.use(res => {
  if (res.status !== 200) {
    return Promise.reject(res)
  }
  let data = res.data
  if (data === null) {
    return {}
  }
  if (data.status) {
    if (data.status != 100 && data.status != 112) { // eslint-disable-line
      if (!res.config.hideErrorMessage) {
        Vue.prototype.$message({
          type: 'error',
          message: data.msg,
          center: true
        })
      }
      if (data.status == 102) { // eslint-disable-line
        _axios.defaults.headers.token = ''
        sessionStorageHelper.removeItem('token')
        let newLogin = _localStorage.getItem('newLogin')
        if (newLogin) {
          router.push({
            name: `login${newLogin}`
          })
        }else {
          router.push({
            name: 'login'
          })
        }
      }
    }
  }
  return data
}, error => {
  return error
})

// 缓存get
_axios.cacheGet = (url, params) => {
  return new Promise((resolve, reject) => {
    params = params || {}
    let expiredTime = params.expiredTime
    let successHandler = params.successHandler
    if (cacheManager.hasCache(url)) {
      try {
        successHandler(cacheManager.cachedData(url))
      } catch (error) {
        console.log(`cache data error ${url}`)
      }
    }
    _axios.get(url, {
      params: params.params
    }).then(res => {
      cacheManager.cache(url, res, expiredTime)
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export default _axios
