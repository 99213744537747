var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isEdit
        ? _c(
            "form-panel",
            {
              ref: "question",
              staticClass: "question-wrapper",
              attrs: { form: _vm.question },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "投票问题" + (_vm.index + 1),
                    rules: [
                      {
                        required: true,
                        message: "请输入投票题目",
                        trigger: "blur",
                      },
                    ],
                    prop: "questionDes",
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      placeholder: "请输入投票问题题目",
                      maxlength: 150,
                      width: 450,
                    },
                    model: {
                      value: _vm.question.questionDes,
                      callback: function ($$v) {
                        _vm.$set(_vm.question, "questionDes", $$v)
                      },
                      expression: "question.questionDes",
                    },
                  }),
                  _vm.question.noRequired !== undefined
                    ? _c("v-checkbox", {
                        staticClass: "checkbox",
                        attrs: { type: "default", label: "选填题" },
                        model: {
                          value: _vm.question.noRequired,
                          callback: function ($$v) {
                            _vm.$set(_vm.question, "noRequired", $$v)
                          },
                          expression: "question.noRequired",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.question.optionType !== 3
                ? _c(
                    "div",
                    [
                      _vm._l(_vm.question.options, function (option, idx) {
                        return _c(
                          "el-form-item",
                          {
                            key: "option" + idx,
                            attrs: {
                              label: idx === 0 ? "问题选项" : "",
                              required: "",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { display: "inline-block" },
                                attrs: {
                                  rules: [
                                    {
                                      required: _vm.question.optionType !== 3,
                                      message: "请输入选项",
                                      trigger: "blur",
                                    },
                                  ],
                                  prop: `options.${idx}.optionDes`,
                                },
                              },
                              [
                                _c("v-input", {
                                  attrs: {
                                    placeholder: "请输入选项",
                                    maxlength: 150,
                                    width: 250,
                                  },
                                  model: {
                                    value: option.optionDes,
                                    callback: function ($$v) {
                                      _vm.$set(option, "optionDes", $$v)
                                    },
                                    expression: "option.optionDes",
                                  },
                                }),
                              ],
                              1
                            ),
                            option.score !== undefined
                              ? _c("span", { staticClass: "optPadding" }, [
                                  _vm._v("分数"),
                                ])
                              : _vm._e(),
                            option.score !== undefined
                              ? _c("v-input-number", {
                                  attrs: { max: 999, min: 0, width: 80 },
                                  model: {
                                    value: option.score,
                                    callback: function ($$v) {
                                      _vm.$set(option, "score", $$v)
                                    },
                                    expression: "option.score",
                                  },
                                })
                              : _vm._e(),
                            _c("v-button", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.question.options.length > 2,
                                  expression: "question.options.length > 2",
                                },
                              ],
                              staticClass: "delOpt",
                              attrs: {
                                type: "default",
                                icon: "el-icon-close",
                                circle: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.delOps(idx)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "v-button",
                            {
                              attrs: { width: 250, plain: "" },
                              on: { click: _vm.addOps },
                            },
                            [_vm._v("+新增选项")]
                          ),
                        ],
                        1
                      ),
                      _vm.question.hasOwnProperty("isSupplement")
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "问题补充" } },
                            [
                              _c("v-input", {
                                attrs: {
                                  placeholder:
                                    "请输入显示在用户界面上的默认文字",
                                  disabled: !_vm.question.isSupplement,
                                  maxlength: 150,
                                  width: 450,
                                },
                                model: {
                                  value: _vm.question.supplementPlaceholder,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.question,
                                      "supplementPlaceholder",
                                      $$v
                                    )
                                  },
                                  expression: "question.supplementPlaceholder",
                                },
                              }),
                              _c("v-checkbox", {
                                staticClass: "checkbox",
                                attrs: {
                                  type: "default",
                                  label: "使用问题补充",
                                },
                                model: {
                                  value: _vm.question.isSupplement,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.question, "isSupplement", $$v)
                                  },
                                  expression: "question.isSupplement",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.question.optionType === 2 ? "选项模式" : "",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "multi-modal" },
                    [
                      _vm.question.optionType === 2
                        ? _c(
                            "div",
                            [
                              _vm._v("\n          至少选\n          "),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { display: "inline-block" },
                                  attrs: {
                                    rules: [
                                      {
                                        required: _vm.question.optionType === 2,
                                        message: "请输入最小选项个数",
                                        trigger: "blur",
                                      },
                                    ],
                                    prop: "minSelectCnt",
                                  },
                                },
                                [
                                  _c("v-input-number", {
                                    staticClass: "optPadding",
                                    attrs: {
                                      width: 100,
                                      controls: true,
                                      "controls-position": "right",
                                      min: _vm.minSelectCnt,
                                      max: _vm.question.maxSelectCnt,
                                    },
                                    model: {
                                      value: _vm.question.minSelectCnt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.question,
                                          "minSelectCnt",
                                          $$v
                                        )
                                      },
                                      expression: "question.minSelectCnt",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" 项，至多选\n          "),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { display: "inline-block" },
                                  attrs: {
                                    rules: [
                                      {
                                        required: _vm.question.optionType === 2,
                                        message: "请输入最大选项个数",
                                        trigger: "blur",
                                      },
                                    ],
                                    prop: "maxSelectCnt",
                                  },
                                },
                                [
                                  _c("v-input-number", {
                                    staticClass: "optPadding",
                                    attrs: {
                                      width: 100,
                                      controls: true,
                                      "controls-position": "right",
                                      min: _vm.question.minSelectCnt,
                                      max: _vm.maxSelectCnt,
                                    },
                                    model: {
                                      value: _vm.question.maxSelectCnt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.question,
                                          "maxSelectCnt",
                                          $$v
                                        )
                                      },
                                      expression: "question.maxSelectCnt",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v("\n          项\n        "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-button",
                        {
                          class:
                            _vm.question.optionType === 2
                              ? "delQue-multi"
                              : "delQue-single",
                          attrs: {
                            type: "danger",
                            round: "",
                            plain: "",
                            icon: "el-icon-delete",
                          },
                          on: { click: _vm.delQuestion },
                        },
                        [_vm._v("删除该问题")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isEdit
        ? _c(
            "form-panel",
            { staticClass: "question-wrapper", attrs: { form: _vm.question } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "投票问题" + (_vm.index + 1) } },
                [
                  _c("span", { staticClass: "input-control" }, [
                    _vm._v(_vm._s(_vm.question.questionDes)),
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.question.noRequired ? "(选填题)" : "(必填题)")
                    ),
                  ]),
                ]
              ),
              _vm.question.optionType !== 3
                ? _c(
                    "div",
                    [
                      _vm._l(_vm.question.options, function (option, index) {
                        return _c(
                          "el-form-item",
                          {
                            key: "option" + index,
                            attrs: { label: index === 0 ? "问题选项" : "" },
                          },
                          [
                            _c("span", { staticClass: "input-control" }, [
                              _vm._v(_vm._s(option.optionDes)),
                            ]),
                            option.score
                              ? _c("span", { staticClass: "optPadding" }, [
                                  _vm._v("(分数" + _vm._s(option.score) + ")"),
                                ])
                              : _vm._e(),
                            option.associatedSorts &&
                            option.associatedSorts.length > 0
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#1b8cf2",
                                      display: "inline-block",
                                      "margin-left": "10px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n          [选择后显示\n          "
                                    ),
                                    _vm._l(
                                      option.associatedSorts,
                                      function (associated, assIdx) {
                                        return _c(
                                          "label",
                                          { key: "associatedSorts_" + assIdx },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "问题" +
                                                  _vm._s(associated + 1) +
                                                  _vm._s(
                                                    option.associatedSorts
                                                      .length ===
                                                      assIdx + 1
                                                      ? ""
                                                      : "、"
                                                  )
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v("]\n        "),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      _vm.question.supplementPlaceholder
                        ? _c("el-form-item", { attrs: { label: "问题补充" } }, [
                            _c("span", { staticClass: "input-control" }, [
                              _vm._v(
                                _vm._s(_vm.question.supplementPlaceholder)
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.question.optionType === 1 ? "" : "选项模式",
                          },
                        },
                        [
                          _c("div", { staticClass: "multi-modal" }, [
                            _vm.question.optionType === 2
                              ? _c("div", [
                                  _vm._v("\n            至少选\n            "),
                                  _c("span", { staticClass: "input-control" }, [
                                    _vm._v(_vm._s(_vm.question.minSelectCnt)),
                                  ]),
                                  _vm._v(" 项，至多选\n            "),
                                  _c("span", { staticClass: "input-control" }, [
                                    _vm._v(_vm._s(_vm.question.maxSelectCnt)),
                                  ]),
                                  _vm._v("项\n          "),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }