var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-control v-cascader-wrapper" },
    [
      _c("el-cascader", {
        ref: "cascader",
        style: { width: _vm.width + "px" },
        attrs: {
          clearable: _vm.clearable,
          props: _vm.staProps,
          placeholder: _vm.placeholder,
          disabled: _vm.disabled,
          filterable: _vm.filterable,
          separator: _vm.separator,
        },
        on: { change: _vm.change },
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }