import { generateMapByOpts } from 'common/utils'
const topicType = [{
  text: '邻里互助',
  value: 1
}, {
  text: '组局活动',
  value: 2
}, {
  text: '车位库房',
  value: 3
}, {
  text: '议事堂',
  value: 4
}, {
  text: '悬赏',
  value: 5
}, {
  text: '爱分享',
  value: 6
}, {
  text: '工作室',
  value: 7
}, {
  text: '社群',
  value: 8
}, {
  text: '红黑榜',
  value: 9
}, {
  text: '红黑榜',
  value: 10
}]

const topicTypeMap = generateMapByOpts(topicType)

export {
  topicTypeMap
}
