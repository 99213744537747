<template>
  <div class="provinces-menu-wrapper">
    <div class="pro-panel">
      <div class="pro-menu" :class="{'active' : provincesIndex === index}" v-for="(item, index) in provinces" :key="index" @click="provinceChange(index)">{{item.name}}{{ item | selectCount }}</div>
    </div>
    <div class="content-panel" v-if="provinces[provincesIndex]">
      <div class="l-city">
        <div class="city-panel" v-for="(item, index) in provinces[provincesIndex].childAreaList" :key="index">
          <div class="top">{{item.name}}</div>
          <div class="mid">
            <v-checkbox v-model="items.checked" v-for="(items, cIndex) in item.childAreaList" :key="cIndex" :index="cIndex" type='border' :label="items.name" @change="(val) => {return citySelect(val, items, cIndex)}"/>

          </div>
        </div>
      </div>
      <div class="r-button" v-if="showCityAllSelectBtn">
        <v-button type="default" @click="toCitySelectAll">
          <div slot class="select-btn">
            <div class="select-icon">
              <div v-if="citySelectAll[provincesIndex]"></div>
            </div>
            <span>全选</span>
          </div>
        </v-button>
      </div>
    </div>
  </div>
</template>
<script>
import vCheckbox from 'components/control/v-checkbox/v-checkbox'
import { getProvincesMenu } from './api'
import _ from 'lodash'
// @group 业务组件
// @title ProvincesMenu 行政范围选择器
export default {
  name: 'provinces-menu',
  components: {
    vCheckbox
  },
  props: {
    // value/v-model双向绑定数据
    value: {
      type: Array,
      // `'[]'`
      default: () => {
        return []
      }
    },
    // 是否需要全选按钮
    showCityAllSelectBtn: {
      type: Boolean,
      // `'true'`
      default: true
    }
  },
  data () {
    return {
      provinces: [],
      provincesIndex: 0,
      citySelectAll: []
    }
  },
  created () {
    this.$axios.get(getProvincesMenu).then(res => {
      this.initData(res.data)
    })
  },
  filters: {
    selectCount (obj) {
      let count = 0
      obj.childAreaList.forEach(item => {
        item.childAreaList.forEach(childItem => {
          childItem.checked && count++
        })
      })
      return count ? `(${count})` : ''
    }
  },
  methods: {
    initData (obj) {
      let provinces = this.handleData(obj)
      this.provinces = provinces
      // this.citySelectAll = new Array(this.provinces.length)
      setTimeout(() => {
        let citySelectAll = []
        provinces.forEach((item, index) => {
          let cityArr = this.concatCity(item.childAreaList)
          let count = cityArr.filter(childValue => {
            return childValue.checked
          })
          citySelectAll[index] = count.length === cityArr.length
        })
        this.$nextTick(() => {
          this.citySelectAll = citySelectAll
        })
      }, 1000)
    },
    concatCity (data) {
      let newData = []
      data.forEach(item => {
        newData = [
          ...newData,
          ...item.childAreaList
        ]
      })
      return newData
    },
    handleData (obj) {
      let _this = this
      obj.forEach(item => {
        if (item.childAreaList && item.childAreaList.length) {
          item = _this.handleData(item.childAreaList)
        } else {
          item.checked = _this.value.indexOf(item.code) > -1
        }
      })
      return obj
    },
    provinceChange (index) {
      this.provincesIndex = index
    },
    citySelect (value, item, index) {
      let code = item.code
      if (value) {
        this.value.push(code)
      } else {
        let index = this.value.indexOf(code)
        this.value.splice(index, 1)
      }
      // 选项改变触发
      // @arg val选中状态 item选中对象
      this.$emit('onChange', value, item)
    },
    toCitySelectAll () {
      this.citySelectAll[this.provincesIndex] = !this.citySelectAll[this.provincesIndex]
      this.provinces[this.provincesIndex].childAreaList.forEach(item => {
        item.childAreaList.forEach(childItem => {
          childItem.checked = this.citySelectAll[this.provincesIndex]
          let valIndex = this.value.indexOf(childItem.code)
          if (this.citySelectAll[this.provincesIndex]) {
            if (valIndex < 0) {
              this.value.push(childItem.code)
            }
          } else {
            if (valIndex > -1) {
              this.value.splice(valIndex, 1)
            }
          }
        })
      })
      this.$emit('onChange', this.citySelectAll[this.provincesIndex], this.provinces[this.provincesIndex])
    }
  },
  watch: {
    value (val) {
      this.provinces = this.handleData(this.provinces)
    }
  }
}
</script>
<style lang="scss" scoped>
.provinces-menu-wrapper {
  padding: 0 20px;
  box-sizing: border-box;
  text-align: left;
  .pro-panel {
    display: flex;
    flex-flow: wrap;
    .pro-menu {
      width: 120px;
      line-height: 30px;
      text-align: center;
      border-radius: 3px;
      cursor: pointer;
    }
    .active {
      color: #fff;
      background: #3ebcf0;

    }
    .pro-menu:hover {
      color: #fff;
      background: #3ebcf0;
    }
  }
  .content-panel {
    display: flex;
    .l-city {
      flex: 1;
      .city-panel {
        .top {
          color: #333333;
          font-weight: 700
        }
        .mid {
          display: flex;
          flex-flow: wrap;
          padding: 10px;
          .checkbox-wrapper {
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
      }
    }
    .r-button{
      width: 100px;
      padding-right: 20px;
      box-sizing: border-box;
    }
  }
}
.select-btn {
  display: flex;
  .select-icon {
    width: 14px;
    height: 14px;
    border: 1px solid #dedede;
    border-radius: 2px;
    box-sizing: border-box;
    margin-right: 5px;
    text-align: center;
    div {
      width: 8px;
      height: 8px;
      box-sizing: border-box;
      background: #1B8CF2;
      border-radius: 2px;
      margin-top: 2px;
      margin-left: 2px;
    }
  }
}

</style>
