import Vue from 'vue'
import previewVue from './v-preview.vue'
const PreviewBoxConstructor = Vue.extend(previewVue)

let PreviewBoxInstance

const PreviewBox = function (urls, index) {
  let imgs = urls
  if (!index) {
    index = 0
  }
  if (!PreviewBoxInstance) {
    PreviewBoxInstance = new PreviewBoxConstructor({
      propsData: {
        imgs,
        previewMode: 1
      }
    })
    // 挂载但是并未插入dom，是一个完整的Vue实例
    PreviewBoxInstance.vm = PreviewBoxInstance.$mount()
    PreviewBoxInstance.dom = PreviewBoxInstance.vm.$el
    // 将dom插入body
    document.body.appendChild(PreviewBoxInstance.dom)
  } else {
    PreviewBoxInstance._props.imgs = imgs
  }
  return PreviewBoxInstance.vm.actionPreview(index)
}

export default PreviewBox
