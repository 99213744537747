<template>
  <div class="v-control v-cascader-wrapper">
    <el-cascader
      :popper-class="extraClass"
      v-model="model"
      :style="{ width: width + 'px' }"
      :clearable="clearable"
      :options="sourceOps"
      :props="props"
      :placeholder="placeholder"
      :disabled="disabled"
      :expand-trigger="expandTrigger"
      :filterable="filterable"
      :change-on-select="changeOnSelect"
      :separator="separator"
      @change="change"
      @active-item-change="activeItemChange"
      @visible-change="visibleChange"
    >
    </el-cascader>
  </div>
</template>

<script>
import Vue from 'vue'
import { Cascader } from 'element-ui'

Vue.use(Cascader)

// @group 基础组件
// @title Cascader 级联选择器
export default {
  name: 'v-cascader',
  props: {
    // 选中项的value数组，支持`.sync`
    value: Array,
    // 可选项数据源，键名可通过 props 属性配置
    options: {
      type: Array,
      required: true,
      // `[]`
      default: () => {
        return []
      }
    },
    // props 配置选项，具体见Cascader的props说明 ` https://element.eleme.cn/#/zh-CN/component/cascader `
    props: {
      type: Object
    },
    // 额外class类名
    extraClass: {
      type: String,
      default: ''
    },
    // 选项分隔符
    separator: {
      type: String,
      // `横杠'-'`
      default: '-'
    },
    // 输入框占位文本
    placeholder: String,
    // 是否禁用
    disabled: {
      type: Boolean,
      // `false`
      default: false
    },
    // 是否显示清除按钮
    clearable: {
      type: Boolean,
      // `true`
      default: true
    },
    // 显示宽度
    width: {
      type: Number,
      // `160`
      default: 160
    },
    // 次级菜单的展开方式 可选值 `click / hover`
    expandTrigger: {
      type: String,
      // `click`
      default: 'click'
    },
    // 是否可搜索选项
    filterable: {
      type: Boolean,
      // `false`
      default: false
    },
    // 是否允许选择任意一级的选项
    changeOnSelect: {
      type: Boolean,
      // `false`
      default: false
    }
  },
  data () {
    return {
      // 获取props中value对应的key
      propsValueKey: this.getPropsValueKey(),
      // 获取props中children对应的key
      propsChildrenKey: this.getPropsChildrenKey(),
      model: this.value,
      sourceOps: this.options || []
    }
  },

  watch: {
    model (newValue) {
      // 返回当前所选值的value数组
      this.$emit('update:value', newValue)
    },

    value (newValue, oldValue) {
      this.model = newValue
      if (this.sourceOps.length && this.model && this.model.length) {
        // 回显触发change
        this.change(this.value)
      }
    },

    // 数据源
    options (newValue) {
      this.sourceOps = newValue || []
      if (this.sourceOps.length && this.model && this.model.length) {
        // 回显触发change
        this.change(this.value)
      }
    }
  },

  methods: {
    // 获取props中value对应的key
    getPropsValueKey () {
      let key = 'value'
      if (this.props) {
        key = this.props.value ? this.props.value : key
      }
      return key
    },

    // 获取props中children对应的key
    getPropsChildrenKey () {
      let key = 'children'
      if (this.props) {
        key = this.props.children ? this.props.children : key
      }
      return key
    },

    change (values) {
      let selectData = this.getSelectData(values)
      // 当绑定值变化时触发的事件， 返回当前所选值的对象数组
      this.$emit('change', selectData)
    },

    activeItemChange (values) {
      let selectData = this.getSelectData(values)
      // 当父级选项变化时触发的事件，仅在 `change-on-select` 为 `false` 时可用, 返回值是各父级选项组成的对象数组
      this.$emit('active-item-change', selectData)
    },

    visibleChange (visible) {
      // 下拉框出现/隐藏时触发，返回值 出现则为 true，隐藏则为 false
      this.$emit('visible-change', visible)
    },

    // 根据key，获取选择的对象数组
    getSelectData (values) {
      let selectList = []
      let len = values.length
      if (len && this.sourceOps.length) {
        let sourceArray = this.sourceOps
        values.forEach((value, index) => {
          if (sourceArray && sourceArray.length) {
            let selectObject = sourceArray.find(
              item => item[this.propsValueKey] === value
            )
            if (selectObject) {
              // 重新赋值查询的数组
              sourceArray = selectObject[this.propsChildrenKey]
              // 删除传出的对象的children，这样数据不那么冗杂
              let tempSelect = Object.assign({}, selectObject)
              delete tempSelect[this.propsChildrenKey]
              selectList.push(tempSelect)
            }
          }
        })
      }
      return selectList
    }
  }
}
</script>

<style scoped lang="scss">
.v-cascader-wrapper {
  display: inline-block;
}
</style>
