<template>
  <div class="v-control v-switch-container">
    <el-switch
			:value="value"
			:disabled="disabled"
			:width="width"
			:active-text="activeText"
			:inactive-text="inactiveText"
			:active-value="activeValue"
			:inactive-value="inactiveValue"
			:active-color="activeColor"
			:inactive-color="inactiveColor"
			:name="name"
			:validate-event="validateEvent"
			@change="onChange"
			@focus="onFocus"
    >
    </el-switch>
  </div>
</template>
<script>
import Vue from 'vue'
import { Switch } from 'element-ui'

Vue.use(Switch)

// @group 基础组件
// @title Switch 切换控件
export default {
	name: 'v-switch',
  props: {
    // 类型
    value: [String, Boolean, Number],
    // switch 宽度
    width: Number,
    // switch 打开时的文字描述
    activeText: String,
    // switch 关闭时的文字描述
    inactiveText: String,
    // switch 打开时的值
    activeValue: [String, Boolean, Number],
    // switch 关闭时的值
		inactiveValue: [String, Boolean, Number],
		// switch 打开时的背景色
		activeColor: {
			type: String,
			default: '#13ce66'
		},
		// switch 关闭时的背景色
		inactiveColor: {
			type: String,
			default: '#ff4949' 
		},
    // 是否禁用
		disabled: Boolean,
		// switch 对应的 name 属性
		name: String,
    // 改变 switch 状态时是否触发表单的校验
    validateEvent: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onChange (event) {
			// switch 状态发生变化时的回调函数
      this.$emit('change', event)
    },
    onFocus (event) {
			// 使 Switch 获取焦点
      this.$emit('focus', event)
    }
  }
}
</script>
<style scoped lang="scss">
  .v-switch-container {
    display: inline-block;
  }
</style>
