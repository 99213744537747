var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-control v-switch-container" },
    [
      _c("el-switch", {
        attrs: {
          value: _vm.value,
          disabled: _vm.disabled,
          width: _vm.width,
          "active-text": _vm.activeText,
          "inactive-text": _vm.inactiveText,
          "active-value": _vm.activeValue,
          "inactive-value": _vm.inactiveValue,
          "active-color": _vm.activeColor,
          "inactive-color": _vm.inactiveColor,
          name: _vm.name,
          "validate-event": _vm.validateEvent,
        },
        on: { change: _vm.onChange, focus: _vm.onFocus },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }