<template>
    <div class="tree-wrapper">
      <el-tree
        ref="elTree"
        :node-key="nodeKey"
        :default-checked-keys="defaultCheckedKeys"
        :data="data"
        :props="defaultProps"
        :filter-node-method="filterNodeMethod"
        :empty-text="emptyText"
        :lazy="lazy"
        :load="loadData"
        :default-expanded-keys="defaultExpandedKeys"
        :default-expand-all="expandAll"
        :expand-on-click-node="expandOnClickNode"
        :accordion="accordion"
        :highlight-current="highlightCurrent"
        :show-checkbox="showCheckbox"
        :check-on-click-node="checkOnClickNode"
        :draggable="draggable"
        :allow-drag="allowDrag"
        :allow-drop="allowDrop"
        :indent="indent"
        @node-click="nodeClick"
        @node-contextmenu="nodeContextMenu"
        @check-change="checkChange"
        @check="check"
        @current-change="currentChange"
        @node-expand="nodeExpand"
        @node-collapse="nodeCollapse"
        @node-drag-start="nodeDragStart"
        @node-drag-enter="nodeDragEnter"
        @node-drag-leave="nodeDragLeave"
        @node-drag-over="nodeDragOver"
        @node-drag-end="nodeDragEnd"
        @node-drop="nodeDrop">
      <template slot-scope="{ node, data }">
        <div class="custom-tree-node">
          <span class="icon" v-if="data.icon" :style="handleBG(data, node)"></span>
          <div class="node-content" ref="nodeContent" @contextmenu.stop.prevent="contentContextMenu(data, node, $event)">
            <!--传入html-->
            <div style="display: inline-block;" v-if="data && data.html" v-html="data.value"></div>
            <!--传入自定义组件-->
            <component v-else-if="data && data.component" :is="data.componentName" :row="scope.row"></component>
            <!--传入普通文本-->
            <div style="display: inline-block;" v-else v-text="data[defaultProps.label]"></div>
          </div>
        </div>
      </template>
      </el-tree>
    </div>
</template>
<script>
import { Tree } from 'element-ui'
// @group 基础组件
// @title Tree 树形组件
export default {
  name: 'v-tree',
  components: {
    'el-tree': Tree
  },
  props: {
    // 每个树节点用来作为唯一标识的属性，整棵树应该是唯一的,建议业务方传入这个属性
    nodeKey: {
      type: String,
      default: 'id'
    },
    // 默认勾选的节点的 key 的数组
    defaultCheckedKeys: {
      type: Array
    },
    // 与data配套使用,详情请参考element-ui的tree组件的props介绍:http://element-cn.eleme.io/#/zh-CN/component/tree
    defaultProps: {
      type: Object,
      default: () => {
        return {
          id: 'id',
          // label 指定节点标签为节点对象的某个属性值 string, function(data, node)
          label: 'label',
          // children 指定子树为节点对象的某个属性值 string
          children: 'children',
          // disabled 指定节点选择框是否禁用为节点对象的某个属性值 boolean, function(data, node)
          disabled: 'disabled',
          // isLeaf 指定节点是否为叶子节点，仅在指定了 lazy 属性的情况下生效 boolean, function(data, node)
          isLeaf: 'isLeaf'
        }
      }
    },
    // 展示数据
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 对树节点进行筛选时执行的方法，返回 true 表示这个节点可以显示，返回 false 则表示这个节点会被隐藏
    filterNodeMethod: {
      type: Function
    },
    // 内容为空的时候展示的文本
    emptyText: {
      type: String,
      default: '暂无数据'
    },
    // 是否懒加载子节点，需与 load 方法结合使用
    lazy: {
      type: Boolean,
      default: false
    },
    // 默认展开的节点的 key 的数组
    defaultExpandedKeys: {
      type: Array
    },
    // 是否默认展开所有节点
    expandAll: {
      type: Boolean,
      default: false
    },
    // 是否在点击节点的时候展开或者收缩节点， 默认值为 true，如果为 false，则只有点箭头图标的时候才会展开或者收缩节点。
    expandOnClickNode: {
      type: Boolean,
      default: true
    },
    // 是否每次只打开一个同级树节点展开
    accordion: {
      type: Boolean,
      default: false
    },
    // 是否高亮当前选中节点，默认值是 false。
    highlightCurrent: {
      type: Boolean,
      default: false
    },
    // 节点是否可被选择
    showCheckbox: {
      type: Boolean,
      default: false
    },
    // 是否在点击节点的时候选中节点，默认值为 false，即只有在点击复选框时才会选中节点。
    checkOnClickNode: {
      type: Boolean,
      default: false
    },
    // 是否开启拖拽节点功能
    draggable: {
      type: Boolean,
      default: false
    },
    // 判断节点能否被拖拽
    allowDrag: {
      type: Function
    },
    // 拖拽时判定目标节点能否被放置。type 参数有三种情况：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
    allowDrop: {
      type: Function
    },
    // 相邻级节点间的水平缩进，单位为像素
    indent: {
      type: Number,
      default: 16
    },
    // 自定义树节点的图标
    iconClass: {
      type: String,
      default: 'el-icon-plus'
    },
    // 用于树节点过滤
    filterText: {
      type: String,
      default: ''
    }
  },
  watch: {
      filterText(val) {
        this.$refs.elTree.filter(val);
      }
  },
  methods: {
    loadData (node, resolve) {
      // 加载子树数据的方法，仅当 lazy 属性为true 时生效
      // @arg (node, resolve) 接收两个参数，1. 当前节点 2. 渲染数据的方法
      this.$emit('loadData', node, resolve)
    },
    // @vuese
    // 自定义树节点图标的方法
    handleBG (data, node) {
      let expanded = node.expanded
      let bgUrl
      if (!expanded) {
        // 未打开
        bgUrl = `url(${data.icon.iconOpen})`
      } else {
        // 已打开
        bgUrl = `url(${data.icon.iconClose})`
      }
      return {
        backgroundImage: bgUrl
      }
    },
    // @vuese
    // 若节点可被选择（即 show-checkbox 为 true），则返回目前被选中的节点所组成的数组
    // @arg (leafOnly, includeHalfChecked)接收两个参数 1.leafOnly 是否只是叶子节点，默认值为 false
    // @arg 2.includeHalfChecked 是否包含半选节点，默认值为 false
    getCheckedNodes (leafOnly, includeHalfChecked) {
      return this.$refs.elTree.getCheckedNodes(leafOnly, includeHalfChecked)
    },

    getHalfCheckedKeys() {
      return this.$refs.elTree.getHalfCheckedKeys()
    },
    // @vuese
    // 设置目前勾选的节点，使用此方法必须设置 node-key 属性
    // @arg (nodes)接收一个参数 1.nodes 接收勾选节点数据的数组
    setCheckedNodes (nodes) {
      this.$refs.elTree.setCheckedNodes(nodes)
    },
    // @vuese
    // 若节点可被选择（即 show-checkbox 为 true），则返回目前被选中的节点的 key 所组成的数组
    // @arg (leafOnly)接收一个参数 1.leafOnly 若为 true 则仅返回被选中的叶子节点的 keys，默认值为 false
    getCheckedKeys (leafOnly) {
      return this.$refs.elTree.getCheckedKeys(leafOnly)
    },
    // @vuese
    // 通过 keys 设置目前勾选的节点，使用此方法必须设置 node-key 属性
    // @arg (keys, leafOnly)接收两个参数 1.keys 勾选节点的 key 的数组
    // @arg 2.leafOnly 若为 true 则仅设置叶子节点的选中状态，默认值为 false
    setCheckedKeys (keys, leafOnly) {
      this.$refs.elTree.setCheckedKeys(keys, leafOnly)
    },
    // @vuese
    // 获取当前被选中节点的 key，使用此方法必须设置 node-key 属性，若没有节点被选中则返回 null
    getCurrentKey () {
      return this.$refs.elTree.getCurrentKey()
    },
    // @vuese
    // 获取当前被选中节点的 data，若没有节点被选中则返回 null
    getCurrentNode () {
      return this.$refs.elTree.getCurrentNode()
    },
    // @vuese
    // 通过 key 设置某个节点的当前选中状态，使用此方法必须设置 node-key 属性
    // @arg (key)接收一个参数 1.key 待被选节点的 key，若为 null 则取消当前高亮的节点
    setCurrentKey (key) {
      this.$refs.elTree.setCurrentKey(key)
    },
    // @vuese
    // 通过 node 设置某个节点的当前选中状态，使用此方法必须设置 node-key 属性
    // @arg (node)接收一个参数 1.node 待被选节点的 node
    setCurrentNode (node) {
      this.$refs.elTree.setCurrentNode(node)
    },
    // @vuese
    // 根据 data 或者 key 拿到 Tree 组件中的 node
    // @arg (data)接收一个参数 1.data 要获得 node 的 key 或者 data
    getNode (data) {
      return this.$refs.elTree.getNode(data)
    },
    // @vuese
    // 删除 Tree 中的一个节点，使用此方法必须设置 node-key 属性
    // @arg (data)接收一个参数 1.data 要删除的节点的 data 或者 node
    remove (data) {
      this.$refs.elTree.remove(data)
    },
    // @vuese
    // 为 Tree 中的一个节点追加一个子节点
    // @arg (data, parentNode)接收两个参数 1.data 要增加的节点的 data
    // @arg 2.parentNode 要增加的节点的后一个节点的 data、key 或者 node
    append (data, parentNode) {
      this.$refs.elTree.append(data, parentNode)
    },
    // @vuese
    // 为 Tree 的一个节点的前面增加一个节点
    // @arg (data, refNode)接收两个参数 1.data 要增加的节点的 data
    // @arg 2.refNode 要增加的节点的后一个节点的 data、key 或者 node
    insertBefore (data, refNode) {
      this.$refs.elTree.insertBefore(data, refNode)
    },
    // @vuese
    // 为 Tree 的一个节点的后面增加一个节点
    // @arg (data, refNode)接收两个参数 1.data 要增加的节点的 data
    // @arg 2.refNode 要增加的节点的前一个节点的 data、key 或者 node
    insertAfter (data, refNode) {
      this.$refs.elTree.insertAfter(data, refNode)
    },
    nodeClick (data, node, nodeVue) {
      // 节点被点击时的回调
      // @arg (data, node, nodeVue)接收三个参数 1.data 传递给 data 属性的数组中该节点所对应的对象
      // @arg 2.node 节点对应的 Node
      // @arg 3.nodeVue 节点组件本身
      this.$emit('nodeClick', data, node, nodeVue)
    },
    nodeContextMenu (event, data, node, nodeVue) {
      // 当某一节点被鼠标右键点击时会触发该事件
      // @arg (event, data, node, nodeVue)接收四个参数 1.event 事件对象
      // @arg 2.data 传递给 data 属性的数组中该节点所对应的对象
      // @arg 3.node 节点对应的 Node
      // @arg 4.nodeVue 节点组件本身
      this.$emit('nodeContextMenu', event, data, node, nodeVue)
    },
    contentContextMenu (data, node, event) {
      // 当某一节点内容被鼠标右键点击时会触发该事件
      // @arg (event, data, node)接收三个参数 1.event 事件对象
      // @arg 2.data 传递给 data 属性的数组中该节点所对应的对象
      // @arg 3.node 节点对应的 Node
      this.$emit('contentContextMenu', event, data, node)
    },
    checkChange (data, checked, childrenChecked) {
      // 节点选中状态发生变化时的回调
      // @arg (data, checked, childrenChecked)接收三个参数 1.data 传递给 data 属性的数组中该节点所对应的对象
      // @arg 2.checked 节点本身是否被选中
      // @arg 3.childrenChecked 节点的子树中是否有被选中的节点
      this.$emit('checkChange', data, checked, childrenChecked)
    },
    check (data, checkedObj) {
      // 当复选框被点击的时候触发
      // @arg (data, checkedObj)接收两个参数 1.data 传递给 data 属性的数组中该节点所对应的对象
      // @arg 2.checkedObj 树目前的选中状态对象，包含 checkedNodes、checkedKeys、halfCheckedNodes、halfCheckedKeys 四个属性
      this.$emit('check', data, checkedObj)
    },
    currentChange (data, node) {
      // 当前选中节点变化时触发的事件
      // @arg (data, node)接收两个参数 1.data 当前节点的数据
      // @arg 2.node 当前节点的 Node 对象
      this.$emit('currentChange', data, node)
    },
    nodeExpand (data, node, nodeVue) {
      // 节点被展开时触发的事件
      // @arg (data, node, nodeVue)接收三个参数 1.data 传递给 data 属性的数组中该节点所对应的对象
      // @arg 2.node 节点对应的 Node
      // @arg 3.nodeVue 节点组件本身
      this.$emit('nodeExpand', data, node, nodeVue)
    },
    nodeCollapse (data, node, nodeVue) {
      // 节点被关闭时触发的事件
      // @arg (data, node, nodeVue)接收三个参数 1.data 传递给 data 属性的数组中该节点所对应的对象
      // @arg 2.node 节点对应的 Node
      // @arg 3.nodeVue 节点组件本身
      this.$emit('nodeCollapse', data, node, nodeVue)
    },
    nodeDragStart (node, event) {
      // 节点开始拖拽时触发的事件
      // @arg (node, event)接收两个参数 1.node 被拖拽节点对应的 Node
      // @arg 2.event 事件对象
      this.$emit('nodeCollapse', node, event)
    },
    nodeDragEnter (node, enterNode, event) {
      // 拖拽进入其他节点时触发的事件
      // @arg (node, enterNode, event)接收三个参数 1.node 被拖拽节点对应的 Node
      // @arg 2.enterNode 所进入节点对应的 Node
      // @arg 3.event 事件对象
      this.$emit('nodeDragEnter', node, enterNode, event)
    },
    nodeDragLeave (node, leaveNode, event) {
      // 拖拽离开某个节点时触发的事件
      // @arg (node, leaveNode, event)接收三个参数 1.node 被拖拽节点对应的 Node
      // @arg 2.leaveNode 所离开节点对应的 Node
      // @arg 3.event 事件对象
      this.$emit('nodeDragLeave', node, leaveNode, event)
    },
    nodeDragOver (node, enterNode, event) {
      // 在拖拽节点时触发的事件（类似浏览器的 mouseover 事件）
      // @arg (node, enterNode, event)接收三个参数 1.node 被拖拽节点对应的 Node
      // @arg 2.enterNode 当前进入节点对应的 Node
      // @arg 3.event 事件对象
      this.$emit('nodeDragOver', node, enterNode, event)
    },
    nodeDragEnd (node, overNode, pos, event) {
      // 拖拽结束时（可能未成功）触发的事件
      // @arg (node, overNode, pos, event)接收四个参数 1.node 被拖拽节点对应的 Node
      // @arg 2.overNode 结束拖拽时最后进入的节点（可能为空）
      // @arg 3.pos 被拖拽节点的放置位置（before、after、inner）
      // @arg 4.event 事件对象
      this.$emit('nodeDragEnd', node, overNode, pos, event)
    },
    nodeDrop (node, overNode, pos, event) {
      // 拖拽成功完成时触发的事件
      // @arg (node, overNode, pos, event)接收四个参数 1.node 被拖拽节点对应的 Node
      // @arg 2.overNode 结束拖拽时最后进入的节点
      // @arg 3.pos 被拖拽节点的放置位置（before、after、inner）
      // @arg 4.event 事件对象
      this.$emit('nodeDrop', node, overNode, pos, event)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .tree-wrapper {
    height: 100%;
    .el-tree-node>.el-tree-node__children {
      overflow: initial;
    }
    .el-tree-node__content {
      display: inline-block;
      height: 30px;
    }
    .custom-tree-node {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      .icon {
        margin: 0 15px;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: text-top;
        border: 0 none;
        cursor: pointer;
        outline: none;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: contain;
      }
      .node-content {
        display: inline-block;
        padding: 0 8px;
      }
    }
    .el-tree-node__expand-icon.is-leaf {
      opacity: 0;
    }
    .el-tree-node__expand-icon {
      flex: 0 0 20px;
      width: 20px;
      height: 20px;
      line-height: 20px;
      margin: 0;
      padding: 0 !important;
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
      border: 0 none;
      cursor: pointer;
      outline: none;
      background-color: transparent;
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-image: url("./img/open_button.png");
      background-size: contain;
    }
    .el-icon-caret-right:before {
      content: '';
    }
    .expanded {
      transform: rotate(0) !important;
      background-image: url("./img/close_button.png");
      background-size: contain;
    }
  }
</style>
