<template>
  <div class="cascader-plus-container">
    <div class="transfer-panel" v-for="(list, index) in data" :key="index">
      <div class="title">选择 {{ index+1 }} 级{{label}}</div>
      <div class="scroll-panel">
        <li v-for="(item, childIndex) in list" :class="{ 'active': (ids[index] && item.id === ids[index].id) }" :key="childIndex" @click="chooseItem(index, item.id, item)">{{item.name}}</li>
      </div>
    </div>
    <div class="btn-panel">
      <v-button class="add-btn" text="添加>>" @click="addData"></v-button>
      <v-button text="<<删除" type="danger" @click="removeData"></v-button>
    </div>
    <div class="transfer-panel selected-panel">
      <div class="title">已选中 <span class="colorRed">{{selectedList.length}}</span> 个{{label}}</div>
      <div class="scroll-panel">
        <li v-for="(item, index) in selectedList" :class="{ 'active': selectedItem.id === item.id }" :key="index" @click="chooseSelectItem(index, item.id, item)">{{item.name}}</li>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
// @group 基础组件
// @title Cascade 级联选择器
export default {
  name: 'v-cascader-plus',
  props: {
    // label
    label: {
      type: String,
      // `''`
      default: ''
    },
    // 列表数据
    data: Array,
    // 已选择列表
    selectedList: {
      type: Array,
      // `[]`
      default: () => {
        return []
      }
    },
    // 选择事件
    beforeAdd: Function
  },
  data () {
    return {
      firstLevelList: [],
      firstSelected: null,
      ids: [],
      nowSelectedItem: {},
      selectedItem: ''
    }
  },
  methods: {
    chooseItem (index, id, item) {
      this.nowSelectedItem = item
      let newArray = _.cloneDeep(this.ids)
      newArray = newArray.slice(0, index)
      newArray.push(item)
      this.ids = newArray
      // 选择事件
      // @arg function (index, id, item) index标识几级0为1级，id选中项id,item选中项
      this.$emit('onSelect', index, id, item)
    },
    addData () {
      if (this.ids.length < this.data.length) {
        this.$alert(`请选择您需要添加的${this.label}`)
        return false
      } else {
        // 新增事件
        // @arg function (items, selectedList) items选中数列（各个层级已数组形式） selectedList已选的列表
        this.$emit('onAdd', this.ids, this.selectedList)
      }
    },
    chooseSelectItem (index, id, item) {
      this.selectedItem = item
    },
    removeData () {
      let _index = _.findIndex(this.selectedList, value => {
        return value.id === this.selectedItem.id
      })
      if (_index > -1) {
        // 删除事件
        // @arg function (index, item, list) index索引 item删除列表选择对象
        this.$emit('onRemove', _index, this.selectedItem, this.selectedList)
      } else {
        this.$alert(`请选择您需要删除的${this.label}`)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.cascader-plus-container{
  display: flex;
  .transfer-panel {
    width: 180px;
    margin-right: 15px;
    .title{
      text-align: center;
      font-size: 18px;
      padding: 5px 0;
      .colorRed{
        color: #ff0000
      }
    }
    .scroll-panel{
      height: 200px;
      overflow: auto;
      border: 1px solid #d5dbeb;
      border-radius: 4px;
      li {
        padding-left: 10px;
        line-height: 32px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
      }
      li:hover {
        background-color: #eaeaea;
      }
      li.active {
        color: #fff;
        background-color: #1B8CF2;
      }
    }
  }
  .selected-panel {
    width: 220px;
  }
  .btn-panel {
    display: flex;
    flex-direction: column;
    width: 90px;
    justify-content: center;
    margin-right: 15px;
    .add-btn {
      margin-bottom: 20px;
    }
  }
}
</style>
