var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-tree", class: { "min-w": _vm.label } },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("v-input", {
            ref: "input",
            staticClass: "search",
            attrs: {
              label: _vm.label,
              placeholder: _vm.placeholder,
              width: _vm.width,
              "suffix-icon": _vm.showTree
                ? "el-icon-arrow-up"
                : "el-icon-arrow-down",
              disabled: _vm.disabled,
            },
            on: { focus: _vm.onFocus, blur: _vm.onBlur, clear: _vm.clear },
            model: {
              value: _vm.filterText,
              callback: function ($$v) {
                _vm.filterText = $$v
              },
              expression: "filterText",
            },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTree,
                  expression: "showTree",
                },
              ],
              ref: "treeGroup",
              staticClass: "hx-tree-group",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.treeData.length !== 0,
                      expression: "treeData.length !== 0",
                    },
                  ],
                  staticClass: "tree-group__box",
                  style: { width: `${_vm.width}px` },
                },
                [
                  _c("button-tree", {
                    key: _vm.key,
                    ref: "buttonTree",
                    staticClass: "button-tree",
                    attrs: {
                      data: _vm.treeData,
                      defaultProps: _vm.treeProps,
                      nodeKey: _vm.nodeKey,
                      showCheckbox: _vm.showCheckbox,
                      defaultCheckedKeys: _vm.defaultCheckedKeys,
                      filterText: _vm.filterText,
                      highlightCurrent: _vm.highlightCurrent,
                      filterNodeMethod: _vm.filterNodeMethod,
                      "expand-on-click-node": false,
                    },
                    on: { nodeClick: _vm.nodeClick },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.treeData.length === 0,
                      expression: "treeData.length === 0",
                    },
                  ],
                  staticClass: "tree-group__box is-empty",
                  style: { width: `${_vm.width}px` },
                },
                [_vm._v("暂无数据")]
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }