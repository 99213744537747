const routes = [
  {
    path: '/home/worktop', // 工作台
    name: 'worktop',
    // component: resolve => require(['@/views/workTop/index'], resolve)
    component: resolve => require(['@/views/workTopNew/index'], resolve)
  },
  {
    path: '/home/houseInfo', // 房屋信息页（空间检索）
    name: 'houseInfo',
    // component: resolve => require(['@/views/workTop/index'], resolve)
    component: resolve => require(['@/views/workTopNew/house/houseInfo'], resolve)
  }
]

export default routes
