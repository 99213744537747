var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "jump-wrapper" },
    [
      _c(
        "v-button",
        {
          attrs: { type: "default", disabled: _vm.disabled },
          on: { click: _vm.openDialog },
        },
        [
          _c("div", { staticClass: "jumpBtn" }, [
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.iconShow,
                  expression: "iconShow",
                },
              ],
              staticClass: "btn-icon",
            }),
            _c("label", { staticClass: "btn-txt" }, [
              _vm._v(" " + _vm._s(_vm.btnText) + " "),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "跳转",
            visible: _vm.visible,
            width: "900px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c("jump-go-switcher", {
            attrs: { skipType: _vm.skipType, communityIds: _vm.communityIds },
            on: { callback: _vm.submit },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }