<template>
  <div class="form-panel-container">
    <div class="header" v-if="hasHeader">
      <!-- 配置右上角操作按钮 -->
      <slot name="headerSlot">
        <!-- 返回按钮 -->
        <v-button text="返回" @click="previous"></v-button>
      </slot>
    </div>
    <div class="content" ref="content">
      <el-form :ref="formName" :model="form" :rules="rules" :label-position="labelPosition" :label-width="labelWidth" label-suffix="：" size="medium" :disabled="disabled" @submit.native.prevent>
        <!-- 配置表单控件 -->
        <slot></slot>
      </el-form>
    </div>
    <div class="footer" v-if="footerShow">
      <div class="footer-button">
        <!-- 在默认保存按钮左侧配置其他操作按钮 -->
        <slot name="saveBeforeSlot"></slot>
        <!-- 配置底部操作按钮 -->
        <slot name="footerSlot">
          <!-- 保存按钮 -->
          <v-button v-if="submitUrl" :text="submitText" type="success" :disabled="submitDisabled" @click="submitClick"></v-button>
        </slot>
        <!-- 在默认保存按钮右侧配置其他操作按钮 -->
        <slot name="saveAfterSlot"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

// 标准表单组件
// @group 业务组件
// @title Form 表单
export default {
  name: 'form-panel',
  props: {
    // 是否显示右上角操作按钮
    hasHeader: {
      type: Boolean,
      // `true`
      default: true
    },
    // 是否禁用全部表单控件
    disabled: {
      type: Boolean,
      // `false`
      default: false
    },
    // 表单域标签的位置
    labelPosition: {
      type: String,
      // `right`
      default: 'right'
    },
    // 表单域标签的宽度
    labelWidth: {
      type: String,
      // `168px`
      default: '168px'
    },
    // 表单数据查询接口
    queryUrl: String,
    // 表单数据查询接口的Request Method
    queryMethod: {
      type: String,
      // `get`
      default: 'get'
    },
    // 表单数据查询接口的Content-Type
    queryContentType: String,
    // 表单数据查询接口的transformRequest
    queryTransformRequest: Array,
    // 表单数据提交接口
    submitUrl: String,
    // 表单数据提交接口的Request Method
    submitMethod: {
      type: String,
      // `post`
      default: 'post'
    },
    // 表单数据提交接口的Content-Type
    submitContentType: String,
    // 表单数据提交接口的transformRequest
    submitTransformRequest: Function,
    submitUseParams: {
      type: Boolean,
      default: false
    },
    // 表单数据集合
    form: {
      type: Object,
      // `{}`
      default () {
        return {}
      }
    },
    // 是否显示底部操作按钮
    footerShow: {
      type: Boolean,
      // `true`
      default: true
    },
    // 底部默认按钮文案
    submitText: {
      type: String,
      // `保存`
      default: '保存'
    },
    // 表单数据提交前的回调函数，参数为当前表单数据，对此参数的操作不会影响原数据
    submitBefore: Function,
    // 表单数据提交的成功回调，两个参数分别为接口返回的data和msg
    submitSuccess: Function,
    // 表单数据提交的失败回调
    submitError: Function,
    // 保存按钮禁用
    saveBtnDisabled: {
      default: false,
      type: Boolean,
    },
    backAfterSave:{
      default:true,
      type:Boolean
    },
    previousMethod: {
      type: Function
    }
  },
  data () {
    return {
      formName: 'form',
      submitDisabled: false,
      rules: {}
    }
  },
  methods: {
    previous () {
      if(this.previousMethod) {
        this.previousMethod()
      }else {
        this.$router.go(-1)
      }
    },
    // @vuese
    // 获取表单数据
    // @arg 请求参数对象
    getData (params) {
      this.$nextTick(() => {
        let _this = this
        let queryConfig = {
          method: _this.queryMethod,
          url: _this.queryUrl
        }
        if (_this.queryMethod.toLowerCase() === 'get') {
          queryConfig.params = params
        } else {
          queryConfig.data = params
        }
        if (_this.queryContentType) {
          queryConfig.headers['Content-Type'] = _this.queryContentType
        }
        if (_this.queryTransformRequest) {
          queryConfig.transformRequest = _this.queryTransformRequest
        }
        _this.$axios(queryConfig).then(res => {
          if (res.status == 100) { // eslint-disable-line
            let data = res.data
            // 表单数据查询的成功回调函数
            // @arg 接口返回的数据
            _this.$emit('update', data)
          }
        })
      }, 20)
    },
    // @vuese
    // 重置表单数据
    resetForm () {
      this.$refs[this.formName].resetFields()
    },
    // @vuese
    // 移除表单项的校验结果
    clearValidate (props) {
      this.$refs[this.formName].clearValidate(props)
    },
    // @vuese
    // 对部分表单字段进行校验的方法
    validateField (props, callback) {
      this.$refs[this.formName].validateField(props, callback)
    },

    // @vuese
    // 触发表单保存校验的方法
    validate () {
      let _this = this
      let _result = true
      // let _object
      let formEle = _this.$refs[_this.formName]
      formEle.validate((result, object) => {
        _result = result
        if (!result) {
          let fields = formEle.fields
          if (fields && fields.length) {
            let scrollTarget = fields.find(field => {
              return field.validateState === 'error'
            })
            scrollTarget && scrollTarget.$el && _this.scrollTo(scrollTarget.$el)
          }
        }
        // _object = object
      })
      return _result
    },
    // @vuese
    // 控制底部默认按钮是否禁用
    setSubmitDisabled (status) {
      this.submitDisabled = status
    },
    async submitClick () {
      let _this = this
      let result = _this.validate()
      if (!result) {
        this.$emit('update:saveBtnDisabled', false)
        return
      }
      let submitData = _.cloneDeep(_this.form)
      result = _this.submitBefore ? await _this.submitBefore(submitData) : true
      if (!result) {
        this.$emit('update:saveBtnDisabled', false)
        return
      }
      _this.$nextTick(() => {
        if (result !== true) {
          submitData = result
        }
        let submitConfig = {
          method: _this.submitMethod,
          url: _this.submitUrl,
          headers: {
            'Content-Type': _this.submitContentType || 'application/json;charset=UTF-8'
          }
        }
        if (this.submitUseParams) {
          submitConfig.params = submitData
        } else {
          submitConfig.data = submitData
        }
        let contentType = submitConfig.headers['Content-Type'].trim()
        if (contentType === 'application/json;charset=UTF-8') {
          submitConfig.transformRequest = [function (data) {
            return JSON.stringify(data)
          }]
        // 注意有 contentType 为 application/x-www-form-urlencoded; charset=UTF-8 的情况走不进下面的if条件，需要手动将自定义的 Content-Type 中的空格去除
        } else if (contentType === 'application/x-www-form-urlencoded;charset=UTF-8') {
          submitConfig.transformRequest = [function (data) {
            return _this.$qs.stringify(data)
          }]
        }
        if (_this.submitTransformRequest) {
          submitConfig.transformRequest = [_this.submitTransformRequest]
        }
        _this.setSubmitDisabled(true)
        _this.$axios(submitConfig).then(async res => {
          this.$emit('update:saveBtnDisabled', false)
          _this.setSubmitDisabled(false)
          if (res.status == 100) { // eslint-disable-line
            let data = res.data
            let msg = res.msg
            result = _this.submitSuccess ? await _this.submitSuccess(data, msg) : true
            if (!result) {
              return
            }
            _this.$message.success('保存成功')
            if(this.backAfterSave){
              _this.previous()
            }
          } else {
            _this.submitError && _this.submitError(res)
          }
        }).catch(() => {
          this.$emit('update:saveBtnDisabled', false)
        })
      }, 20)
    },
    getOffsetTop (ele) {
      let offsetTop = 0
      while (ele) {
        offsetTop += ele.offsetTop
        ele = ele.offsetParent
      }
      return offsetTop
    },
    scrollTo (ele) {
      let contentEle = this.$refs.content
      let eleOffsetTop = this.getOffsetTop(ele)
      let contentOffsetTop = this.getOffsetTop(contentEle)
      let offsetTop = eleOffsetTop - contentOffsetTop
      let step = 0
      if (contentEle.scrollTop >= offsetTop) { // 向上滚动
        let interval = setInterval(() => {
          if (contentEle.scrollTop + step <= offsetTop) {
            contentEle.scrollTop = offsetTop
            clearInterval(interval)
            return
          }
          step -= 10
          contentEle.scrollTop += step
        }, 20)
      } else { // 向下滚动
        let lastScrollTop
        let interval = setInterval(() => {
          if (contentEle.scrollTop + step >= offsetTop || contentEle.scrollTop === lastScrollTop) {
            contentEle.scrollTop = offsetTop
            clearInterval(interval)
            return
          }
          step += 10
          lastScrollTop = contentEle.scrollTop
          contentEle.scrollTop += step
        }, 20)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .form-panel-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    max-height: 100%;
    padding: 0 20px;
    background-color: #FFF;
    .header {
      position: absolute;
      top: 20px;
      right: 20px;
      :not(:last-child) {
        margin-right: 8px;
      }
    }
    .content {
      overflow: auto;
      margin-bottom: 20px;
    }
    .footer {
      .footer-button {
        padding-left: 168px;
        margin-bottom: 20px;
        > :nth-child(n) {
          min-width: 168px;
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }
</style>
