<template>
  <div class="right-float-wrapper">
    <div class="iot-community-tree" :class="{'view-open': open}">
    <div id="community-select-wrapper">
      <div id="community-name-wrapper">
          <a id="community-search">
            <img src="./images/iot-community-search.png">
          </a>
          <input id="community-search-field" v-model="searchFieldText" autocomplete="off" type="text" placeholder="输入搜索选择项目">
      </div>
      <v-tree ref="tree" id="community-tree"
        :nodeKey="nodeKey"
        :data="communityList"
        :defaultProps="treeProps"
        :filterNodeMethod="filterNode"
        :highlightCurrent="true"
        :defaultExpandedKeys="defaultExpandedKeys"
        @nodeClick="nodeClick">
      </v-tree>
    </div>
    <a class="floating-open" :class="{'floatin-open-show': !open}"  @click="actionOpenFloating">
      <img src="./images/iot-community-select.png">
    </a>
    <a class="floating-close" :class="{'floatin-close-show': open}" @click="actionCloseFloating">
      <img src="./images/iot-community-select.png">
    </a>
  </div>
  </div>
</template>

<script>
import { getIotCommunityListURL, getDefaultCommunityURL, setDefaultCommunityURL } from './api'
import { vTree } from 'components/control/index'
import { mapMutations } from 'vuex'
import qs from 'qs'

export default {
  name: 'iot-community-tree',
  components: {
    vTree
  },
  data () {
    return {
      open: false,
      nodeKey: 'id',
      autoFill: false,
      defaultExpandedKeys: [],
      searchFieldText: '',
      treeProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  computed: {
    communityList () {
      let self = this
      let list = []
      let treeData = this.$store.state.iotCommunityStore.iotCommunityList
      if (this.metaMenuSystemType === -1 || !this.metaMenuSystemType) {
        // 全量系统数据
        list = treeData.allTreeList
      } else {
        let systemTreeList = treeData.systemTreeList || []
        systemTreeList.forEach(function (v, i) {
          if (v.systemType === self.metaMenuSystemType) {
            list = v.systemTree || []
          }
        })
      }
      return list
    },
    communityInfo () {
      // 选择的默认园区
      return this.$store.state.iotCommunityStore.currentIotCommunity
    },
    metaMenuSystemType () {
      return this.$route.meta.menuSystemType
    }
  },
  mounted () {
    this.fetchComunityList()
    this.fetchDefaultCommunity()
  },
  watch: {
    searchFieldText (val) {
      if (!this.autoFill) {
        this.$refs.tree.$refs.elTree.filter(val)
      }
      this.autoFill = false
    },
    communityList () {
      this.selectDefaultCommunity()
    }
  },
  methods: {
    fetchComunityList () {
      // 获取物联网园区树
      const self = this
      this.$axios.get(getIotCommunityListURL).then(res => {
        if (String(res.status) === '100') {
          self.setIotCommunityList(res.data)
          self.selectDefaultCommunity()
        }
      })
    },
    fetchDefaultCommunity () {
      // 获取已选择的默认园区
      const self = this
      this.$axios.get(getDefaultCommunityURL).then(res => {
        if (String(res.status) === '100') {
          let data = res.data
          let communityInfo = {
            id: data.communityId,
            name: data.communityName
          }
          this.setCurrentIotCommunity(communityInfo)
          self.selectDefaultCommunity()
        } else {
          this.actionOpenFloating()
        }
      })
    },
    selectDefaultCommunity () {
      // 选中默认园区
      let communityKey = this.communityInfo.id
      let communityName = this.communityInfo.name
      this.defaultExpandedKeys = [communityKey]
      const self = this
      setTimeout(() => {
        self.autoFill = true
        self.searchFieldText = communityName
        if (self.$refs.tree && communityKey) {
          self.$refs.tree.setCurrentKey(communityKey)
        }
      }, 300)
    },
    setDefaultCommunity () {
      // 设置默认园区
      let self = this
      let postData = {
        communityId: this.communityInfo.id
      }
      self.$axios.post(setDefaultCommunityURL, qs.stringify(postData)).then(res => {
        if (String(res.status) === '100') {
          console.log('设置默认园区成功')
        }
      })
    },
    filterNode (value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1 && !data.children
    },
    nodeClick (data, node, dom) {
      if (data.level === 3) {
        // 代表是园区 1 集团 2 分子公司 3 园区
        this.autoFill = true
        this.searchFieldText = data.name
        let communityInfo = {
          id: data.id,
          name: data.name
        }
        this.setCurrentIotCommunity(communityInfo)
        this.setDefaultCommunity()
      }
    },
    actionOpenFloating () {
      this.open = true
    },
    actionCloseFloating () {
      this.open = false
    },
    ...mapMutations('iotCommunityStore', [
      'setCurrentIotCommunity',
      'setIotCommunityList'
    ])
  }
}
</script>

<style scoped>
.right-float-wrapper {
  z-index: 6000;
  position: fixed;
  top: 80px;
  right: 0;
  height: calc(100% - 80px);
}

.right-float-wrapper >>> .el-tree {
  background-color: transparent;
  color: white;
}

.right-float-wrapper >>> .node-content {
  max-width: 180px;
  text-overflow:ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.right-float-wrapper >>> .el-tree-node__content:hover {
  background-color: #414F57;
}

.right-float-wrapper >>> .el-tree-node__content {
  display: flex;
  align-items: flex-start;
}

.right-float-wrapper >>> .el-tree-node__content span {
  margin-top: 12px;
  margin-left: 12px;
  margin-right: 0 !important;
}

.right-float-wrapper >>> .el-tree-node.is-current.is-focusable:not(.is-expanded) {
  background-color: #414F57 !important;
}

.right-float-wrapper >>> .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content:not(.is-expanded) {
  background-color: #414F57 !important;
}

.right-float-wrapper >>> .el-tree-node:focus > .el-tree-node__content {
  background-color: transparent !important;
}

.right-float-wrapper >>> .el-tree-node__expand-icon {
  background-image: url("./images/iot-btn-open.png") !important;
  width: 8px;
  height: 8px;
}

.right-float-wrapper >>> .el-tree-node__expand-icon.expanded {
  background-image: url("./images/iot-btn-close.png") !important;
  width: 8px;
  height: 8px;
}

.iot-community-tree {
  position: absolute;
  width: 263px;
  right: -220px;
  -webkit-transition: all .5s ease-in;
  -moz-transition: all .5s ease-in;
  transition: all .5s ease-in;
  pointer-events: none;
  height:100%;
  display: flex;
}

.iot-community-tree.view-open {
  right: 0;
  pointer-events: auto;
}

#community-select-wrapper {
  width: calc(100% - 43px);
  height: 100%;
  right: 0;
  position: absolute;
  background-color: rgba(65,79,87,0.90);
  flex-direction: column;
  display: flex;
}

#community-name-wrapper {
  margin: 24px 16px 16px 16px;
  background-color: #2D3A40;
  flex: 0 0 36px;
  position: relative;
}

#community-search {
  position: absolute;
  top: 6px;
  left: 10px;
  width: 22px;
  height: 23px;
  display: flex;
  align-items: center;
}

#community-search img {
  width: 13px;
  height: 14px;
}

#community-search img {
  vertical-align: middle;
}

#community-search-field {
  position: absolute;
  left: 36px;
  top: 8px;
  width: 136px;
  height: 20px;
  background-color: #2D3A40;
  border: none;
  color: white;
  outline: none;
}

.floating-open, .floating-close {
  position: absolute;
  top: calc(50% - 22px);
  left: 0;
  cursor:  pointer;
  width: 43px;
  height: 92px;
  pointer-events: auto;
  display: none;
}

.floatin-open-show {
  display: block;
}

.floatin-close-show {
  display: block;
}

</style>
