var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-control v-ueditor-container" },
    [
      _c("vue-ueditor-wrap", {
        ref: "ueditorWrap",
        attrs: { config: _vm.mixedConfig, id: "ueditor-container" },
        on: { ready: _vm.ready },
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }