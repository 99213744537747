<template>
  <div class="list-option-wrapper">
    <el-popover
      popper-class="list-option-popper"
      placement="bottom-start"
      width="180"
      v-model="popoverShow"
      trigger="click"
    >
      <div class="header">
        <div class="left">
          <checkbox v-model="allChecked" :disabled="allChecked" @change="updateAllCheckStatus"></checkbox>
          <span class="text" @click="allCheckClick">全选</span>
        </div>
        <div class="close" @click="hidePopper">
          <span style="color: #fff; font-size: 12px;">X</span>
        </div>
      </div>
      <div class="list">
        <div class="item" v-for="(header, index) in tableHeaders" :key="index">
          <checkbox
            v-model="header.show"
            :disabled="header.disabled"
            :index="index"
            @change="updateCheckStatus"
          ></checkbox>
          <span v-text="header.label" class="label" @click="updateCheckStatus(!header.show, index)"></span>
        </div>
      </div>
      <div class="footer-wrapper">
        <div class="footer-left">
          <span>已选择</span>
          <span class="column-select-count">{{showCount}}</span>
          <span>条</span>
        </div>
      </div>
      <v-button slot="reference" text="列表选项"></v-button>
    </el-popover>
  </div>
</template>

<script>
import { vCheckbox } from 'components/control/index'
import { _localStorage } from 'common/utils'
// 列表选项local的key
const menuListOption = 'menu_list_option'
export default {
  name: 'ListOption',
  props: {
    headers: {
      type: Array,
      default() {
        return []
      },
    },
  },
  components: {
    checkbox: vCheckbox,
  },
  created() {
    this.getHeaders()
    this.$nextTick(() => {
      this.handleAllChecked(this.tableHeaders)
    })
  },
 
  data() {
    return {
      popoverShow: false,
      allChecked: true,
      min: 4, // 最少显示4项
    }
  },
  computed: {
    showCount() {
      let len = this.tableHeaders.filter((item) => {
        return item.show
      }).length
      return len
    },
    tableHeaders:{
      get(){
        return this.headers
      },
      set(val){
        return this.$emit('updateTableHeaders',val)
      }
    }
  },
  methods: {
    getHeaders() {
      let path = this.$route.path
      let userId = this.$store.state.userInfo.id
      // 把路径和用户id作为键
      let key = `${encodeURIComponent(path)}_${userId}`
      this.listOptionKey = key
      let localData = _localStorage.getItem(menuListOption)
      if (localData) {
        localData = JSON.parse(localData) || {}
        let localListOptionData = localData[key]
        if (localListOptionData) {
          if (this.tableHeaders && this.tableHeaders.length) {

            this.handleHeaders(localListOptionData, this.tableHeaders)
          } else {
            this.tableHeaders = []
          }
        } else {
          this.initHeaders()
        }
      } else {
        this.initHeaders()
      }
    },
    handleHeaders(localHeaders, headers) {
      headers.forEach((item) => {
        let one = localHeaders.filter((localItem) => {
          return localItem.prop === item.prop
        })[0]
        if (one) {
          item.show = item.show !== undefined ? item.show : one.show
          item.disabled = one.disabled
        } else {
          item.show = item.show !== undefined ? item.show : true
          item.disabled = false
        }
      })
      this.tableHeaders = headers
      // 更新local
      this.updateLocalListOptionData(headers)
      // 分发到list组件,进而分发到table组件
    },
    initHeaders() {
      let headers = this.tableHeaders
      let len = headers.length
      if (len > 0) {
        if (len <= 4) {
          headers.forEach((item) => {
            item.show = item.show !== undefined ? item.show : true
            item.disabled = true
          })
        } else {
          headers.forEach((item) => {
            item.show = item.show !== undefined ? item.show : true
            item.disabled = false
          })
        }
        this.tableHeaders = headers
        this.updateLocalListOptionData(headers)
        // 分发到list组件,进而分发到table组件
      } else {
        this.tableHeaders = []
      }
    },
    getLocalListOptionData() {
      let localListOptionData = _localStorage.getItem(menuListOption)
      if (localListOptionData) {
        localListOptionData = JSON.parse(localListOptionData)
      } else {
        localListOptionData = {}
      }
      return localListOptionData
    },
    // 更新列表选项的本地数据
    updateLocalListOptionData(val) {
      let localData = {}
      localData[this.listOptionKey] = val
      let localListOptionData = this.getLocalListOptionData()
      // 同时,植入到local
      _localStorage.setItem(
        menuListOption,
        JSON.stringify(Object.assign(localListOptionData, localData))
      )
    },
    hidePopper() {
      this.popoverShow = false
    },
    allCheckClick() {
      if (this.allChecked) {
        return
      }
      this.updateAllCheckStatus(true)
      this.allChecked = true
    },
    updateAllCheckStatus(value) {
      if (value) {
        // 全选
        this.handleAllChecked()
        
        if ( this.tableHeaders &&  this.tableHeaders.length) {
           this.tableHeaders.forEach((item,index) => {
            // item.show = true
            // item.disabled = false
            this.updateCheckStatus(value, index)
          })
        }
        // 将列表项的选中状态保存到local
        this.updateLocalListOptionData(this.tableHeaders)
      }
    },
    updateCheckStatus(value, index) {
      if (this.tableHeaders[index].disabled) {
        return
      }

      let showCount = this.tableHeaders.filter((item) => {
              return item.show
      }).length
    
      //修改属性后更换整个元素触发setter 不能通过index直接修改值
      let  changeItem = this.tableHeaders.slice(index)[0]
      changeItem.show = value
      this.tableHeaders.splice(index, 1 , changeItem)

      if (!value) {
          showCount --
          if (showCount === this.min) {
              this.tableHeaders.forEach((item, index) => {
                if (item.show) {
                  item.disabled = true
                }
              })
          }
      } else {
          this.tableHeaders.forEach((item, index) => {
              if (item.show) {
                item.disabled = false
              }
          })
      }
      this.updateLocalListOptionData(this.tableHeaders)
      this.handleAllChecked(this.tableHeaders)

      // let headers = this.tableHeaders
      // let disabled = headers[index].disabled
      // if (disabled) {
      //   return
      // }
      // let showCount = headers.filter((item) => {
      //   return item.show
      // }).length
      // // 数组里面对象的更新,不能只更新其中的某个属性
      //   headers[index].show=value,
 
      // if (!value) {
      //   showCount--
      //  this.tableHeaders[index] = newItem
      //   this.$nextTick(() => {
      //     // 判断当前显示项是否小于最小值
      //     // 剩余的显示列数已经到达最小值
      //     // 此时,必须禁用,不能再隐藏了
      //     if (showCount === this.min) {
      //       this.tableHeaders.forEach((item, index) => {
      //         if (item.show) {
      //           item.disabled = true
      //              this.tableHeaders[index] = newItem
      //           // _this.$set(_this.tableHeaders, index, item)
      //         }
      //       })
      //       this.$forceUpdate()

      //     }
      //     this.$forceUpdate()
      //     // 将列表项的选中状态保存到local
      //     this.updateLocalListOptionData(this.tableHeaders)
      //     this.handleAllChecked(this.tableHeaders)
      //     this.$emit('updateTableHeaders', this.tableHeaders)
      //   })
      // } else {
      //   // this.$set(this.tableHeaders, index, newItem)
      //   this.tableHeaders[index] = newItem
        
      //   this.$nextTick(() => {
      //     // 此时,必须启用刚才被禁用掉的列
      //     this.tableHeaders.forEach((item, index) => {
      //       if (item.show) {
      //         item.disabled = false
      //         // _this.$set(_this.tableHeaders, index, item)
      //         this.tableHeaders[index] = item
      //         this.$forceUpdate()
      //       }
      //     })
      //     this.$forceUpdate()

      //     // 将列表项的选中状态保存到local
      //     this.updateLocalListOptionData(this.tableHeaders)
      //     this.handleAllChecked(this.tableHeaders)
      //     this.$emit('updateTableHeaders', this.tableHeaders)
      //   })
      // }
    },
    handleAllChecked(headers) {
      if (headers && headers.length) {
        for (let i = 0; i < headers.length; i++) {
          let header = headers[i]
          if (!header.show) {
            this.allChecked = false
            break
          }
          this.allChecked = true
        }
      } else {
        this.allChecked = false
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.list-option-popper {
  .header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    .left {
      cursor: pointer;
      display: flex;
      align-items: center;
      .text {
        padding-left: 15px;
      }
    }
    .close {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #aaa;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
  .list {
    height: 308px;
    overflow: auto;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    padding: 12px 8px 8px 0;
    .item {
      cursor: pointer;
      height: 44px;
      display: flex;
      align-items: center;
      .label {
        padding-left: 15px;
      }
    }
  }
  .footer-wrapper {
    display: flex;
    align-items: center;
    height: 51px;
    position: relative;
    .footer-left {
      color: #1b8cf2;
    }
    .footer-right {
      position: absolute;
      right: 8px;
    }
  }
}
</style>
