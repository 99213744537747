var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "checkbox-plus-wrapper",
        { "check-item-vertical": _vm.align === "vertical" },
        _vm.checkClass,
      ],
      style: _vm.checkStyle,
    },
    _vm._l(_vm.myOptions, function (item, index) {
      return _c(
        "div",
        { key: index, staticClass: "check-item" },
        [
          _vm.type === "radio"
            ? _c("v-checkbox", {
                ref: `checkbox${index}`,
                refInFor: true,
                attrs: {
                  type: _vm.type,
                  index: index,
                  label: item.label,
                  labelValue: item.value,
                  disabled: item.disabled,
                  canInvert: _vm.canInvert,
                },
                on: { pick: _vm.onChange },
                model: {
                  value: _vm.model,
                  callback: function ($$v) {
                    _vm.model = $$v
                  },
                  expression: "model",
                },
              })
            : _c("v-checkbox", {
                ref: `checkbox${index}`,
                refInFor: true,
                attrs: {
                  type: _vm.type,
                  index: index,
                  label: item.label,
                  disabled: item.disabled,
                  canInvert: _vm.canInvert,
                },
                on: { pick: _vm.onChange },
                model: {
                  value: _vm.checkboxValue[index],
                  callback: function ($$v) {
                    _vm.$set(_vm.checkboxValue, index, $$v)
                  },
                  expression: "checkboxValue[index]",
                },
              }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }