<template>
  <div class="oAuth-container"></div>
</template>

<script>
import { _localStorage } from 'common/utils'
import { externalLoginURL } from './api'

export default {
  name: 'oAuth',
  data () {
    return {
      loginName: '',
      token: ''
    }
  },
  created () {
    const { loginName, token } = this.$route.query
    if (loginName && token) {
      const params = {
        loginName,
        token
      }
      this.externalLogin(params)
    }
  },
  methods: {
    async externalLogin (params) {
      const { status, data } = await this.$axios.get(externalLoginURL, { params })
      if (status === '100') {
        let companyCode = data.companyCode
        let subPlatformId = data.subPlatformId
        let childPlatformId = data.childPlatformId
        let availablePlatformIds = data.availablePlatformIds
        if (!availablePlatformIds || !availablePlatformIds.length) {
          this.$message({
            type: 'error',
            message: '子平台权限出错了, 请联系管理员',
            center: true
          })
          return
        }

        _localStorage.setItem('companyCode', companyCode)

        this.$router.push({
          name: 'home',
          query: {
            login: 1,
            cid: childPlatformId,
            pid: subPlatformId === 0 ? availablePlatformIds[0] : subPlatformId
          }
        })
      }
    }
  }
}
</script>
