<template>
  <div class="checkbox-wrapper">
    <div class="common-wrapper" v-if="type === 'default'">
      <el-checkbox v-model="myValue" class="my-checkbox" :disabled="disabled" :indeterminate="indeterminate" @change="onChange">{{label || ''}}</el-checkbox>
    </div>
    <div class="common-wrapper" v-if="type === 'radio'">
      <el-radio v-model="myValue" class="my-radio" :disabled="disabled" :label="labelValue" @change="onChange">{{label || ''}}</el-radio>
    </div>
    <div class="border-wrapper" v-if="type === 'border'" @click="onMyChange">
      <button type="button" :class="['btn', {'btn-active' : myValue}, {'btn-disabled' : disabled}]" v-text="label"></button>
    </div>
  </div>
</template>

<script>
import { Checkbox, Radio } from 'element-ui'
// @group 基础组件
// @title Checkbox 选择框
export default {
  name: 'v-checkbox',
  components: {
    'el-checkbox': Checkbox,
    'el-radio': Radio
  },
  props: {
    // 类型,支持两种类型:1.default 2. border
    type: {
      type: String,
      default: 'default'
    },
    // 是否可以反选
    canInvert: {
      type: Boolean,
      default: true
    },
    // 标题
    label: {
      type: String,
      default: ''
    },
    labelValue: {
      type: [String, Number, Boolean]
    },
    // 此处一定是value
    value: {
      type: [String, Number, Boolean],
      default: false
    },
    // 循环使用这个组件时,需要传递,其他不需要
    index: {
      type: Number
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 设置 indeterminate 状态，只负责样式控制
    indeterminate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      myValue: this.value
    }
  },
  methods: {
    onMyChange () {
      if (this.disabled) {
        return
      }
      if (!this.canInvert) {
        if (this.myValue) {
          return
        } else {
          this.myValue = true
        }
      } else {
        this.myValue = !this.myValue
      }
      let index = this.index
      if (index !== undefined) {
        // checkbox值发生改变时,触发的回调
        // @arg (value, index)接收两个参数 1.value 改变后的value
        // @arg 2.index 当前索引(循环使用本组件时,会有此参数)
        this.$emit('change', this.myValue, index)
        // checkbox值发生改变时,触发的回调
        // @arg (value, index)接收两个参数 1.value 改变后的value
        // @arg 2.index 当前索引(循环使用本组件时,会有此参数)
        this.$emit('pick', this.myValue, index)
      } else {
        this.$emit('change', this.myValue)
        this.$emit('pick', this.myValue)
      }
    },
    onChange () {
      let index = this.index
      if (index !== undefined) {
        // checkbox值发生改变时,触发的回调
        // @arg (value, index)接收两个参数 1.value 改变后的value
        // @arg 2.index 当前索引(循环使用本组件时,会有此参数)
        this.$emit('change', this.myValue, index)
        // checkbox值发生改变时,触发的回调
        // @arg (value, index)接收两个参数 1.value 改变后的value
        // @arg 2.index 当前索引(循环使用本组件时,会有此参数)
        this.$emit('pick', this.myValue, index)
      } else {
        this.$emit('change', this.myValue)
        this.$emit('pick', this.myValue)
      }
    },
    changeValue (val) {
      this.myValue = val
    }
  },
  watch: {
    // 监听prop传的checked，如果父级有变化了，将子组件的value也跟着变，达到父变子变的效果
    value (newVal) {
      this.myValue = newVal
    },
    // 监听value，如果子组件中的内容变化了，通知父级组件，将新的值告诉父级组件，我更新了，父级组件接受到值后页就跟着变了
    myValue (newVal) {
      this.$emit('input', newVal)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .checkbox-wrapper {
    cursor: pointer;
    .common-wrapper {
      display: flex;
      align-items: center;
      .label {
        padding-left: 15px;
      }
    }
    .border-wrapper {
      .btn {
        min-width: 182px;
        height: 36px;
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        user-select: none;
        outline: none;
        -webkit-appearance: none;
        background-image: none;
        border: 1px solid #d3d3d3;
        color: #333;
        background-color: #f8f8f8;
        border-radius: 4px;
      }
      .btn-active {
        color: #1B8CF2;
        border: 1px solid #1B8CF2;
        position: relative;
      }
      .btn-active:after {
        content: '';
        display: inline-block;
        position: absolute;
        right: -1px;
        bottom: -1px;
        width: 18px;
        height: 12px;
        background: url(./images/choosed.png);
        background-size: contain;
      }
      .btn-disabled {
        background-color: #aaa;
      }
    }
  }
</style>
