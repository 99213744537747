<template>
  <div class="jumpGo-container">
    <div class="jumpGo-body">
      <div class="paperType">
        <span class="jump-title">页面类型</span>
        <nav class="nav nav-default" data-bind="click: getDetail">
          <ul class="jump-ul">
            <li v-for="(type,index) in typeList" :key="index" @click="switchType(index)" :class="index === curTypeIndex?'active':''" >{{ type.name}}</li>
          </ul>
        </nav>
      </div>
      <div class="classification">
        <span class="jump-title">分类</span>
        <nav class="nav nav-default">
          <ul class="jump-ul" data-bind="foreach:classificationList">
            <li v-for="(classification,idx) in classificationList" :key="idx" @click="switchCategory(classification,idx)" :class="idx === curIndex?'active':''" > {{classification.name}}</li>
          </ul>
        </nav>
      </div>
      <div class='jumo-content'>
        <span class="jump-title">对象</span>
        <div>
          <v-select2 ref="select2" v-model='select' v-bind="selectParams" class="jump-select" @onChange='getSelectedObj'></v-select2>
        </div>
      </div>
    </div>
    <template-go v-show="false" ref="goTemplate" :classification="selectedClassification" :selectedObject='selectedObj'></template-go>

    <div class="jumpGo-footer">
      <v-button type="success" class="submitBtn" text='确定' @click="getHtml"></v-button>
    </div>

  </div>
</template>

<script>
import * as jumpGoObject from './jumpGo'
import { getSubjectUrl } from './api'
import templateGo from './moduleTem.vue'
export default {
  name: 'jumpGo-container',
  props: {
    skipType: {
      type: Number,
      default: 1
    },
    communityIds: {
      type: Array
    },
    callback: {
      type: Function,
      dafault: () => {}
    }
  },
  data () {
    return {
      // 页面类型
      typeList: jumpGoObject.typeList,
      // 页面类型当前选择
      curTypeIndex: 0,
      // 分类
      classificationList: jumpGoObject.typeList[0].children,
      // 分类当前选择
      curIndex: 0,
      // 选中的分类
      selectedClassification: {},
      // select2 绑定的值
      select: '',
      // select2 的配置参数
      selectParams: jumpGoObject.select2List[0].select2Params,
      // 获取应用的url
      getSubjectUrl: getSubjectUrl,
      // select2 选中data
      selectedObj: {}
    }
  },
  components: {
    templateGo
  },
  mounted () {
    const userType = this.$store.state.userInfo.userType
    if (userType === '106' || userType === '100') {
      jumpGoObject.typeList[0].children = [
        {
          name: '全部版块',
          code: undefined,
          visible: true,
          type: 'sub'
        }
      ]
      this.switchType(0)
    } else {
      this.getSubject()
    }
  },
  methods: {
    // 获取应用列表
    getSubject () {
      let _this_ = this
      _this_.$axios.get(getSubjectUrl).then(res => {
        if (res.status === 100) {
          jumpGoObject.typeList[0].children = []
          let rdata = res.data
          if (rdata.length) {
            rdata.forEach(item => {
              if (item.sectionLevel === 1) {
                jumpGoObject.typeList[0].children.push({
                  name: item.sectionName,
                  code: item.id,
                  visible: true,
                  type: 'sub'
                })
              }
            })
            _this_.switchType(0)
          }
        }
      })
    },
    // 页面类型选择
    switchType (index) {
      this.classificationList = jumpGoObject.typeList[index].children
      this.curTypeIndex = index
      this.curIndex = 0
      this.selectedClassification = this.classificationList[0]
      this.switchCategory(this.selectedClassification, this.curIndex)
    },
    // 分类选择
    switchCategory (classification, index) {
      this.curIndex = index
      // 清空select2的选项
      this.$refs.select2.clearValue()
      this.selectedClassification = classification
      if (classification.type === 'sub') {
        this.selectParams.subjoin.sectionId = classification.code
      }
      let select2 = jumpGoObject.select2List.find(item => {
        return item.type === classification.type
      })
      let extraParams = {
        skipObjectType: 1,
        communityIds: JSON.stringify(this.communityIds)
      }
      select2.select2Params.subjoin = Object.assign(select2.select2Params.subjoin, extraParams)
      this.selectParams = select2.select2Params
    },
    getSelectedObj (data) {
      if (data) {
        console.log(this.selectedClassification)
        const classification = this.selectedClassification
        let select2 = jumpGoObject.select2List.find(item => {
          return item.type === classification.type
        })
        this.selectedObj = select2.formatterData(data)
      }
    },
    // 获取html
    getHtml () {
      let htmlTemplate = this.$refs.goTemplate.$el.innerHTML
      let skipData = this.$refs.goTemplate.skipData
      let _this_ = this
      if (_this_.skipType === 1) {
        this.$axios.get('static/jumpGo/templateCss.scss').then(res => {
          _this_.css = '<style>' + res + '</style>'
          _this_.$emit('callback', skipData, _this_.css, htmlTemplate + '<p></p>')
        })
      } else if (_this_.skipType === 2) {
        _this_.$emit('callback', skipData, skipData.name, this.selectedObj)
      }
    }
  }

}
</script>

<style lang="scss" >

.jumpGo-container {
  background-color: #fff;
  height: 590px;
  .jumpGo-body {
    display: flex;
    height: 528px;
    .paperType {
      width: 120px;
      background-color: #f8f8f8;
    }
    .classification {
      margin: 0 2px;
      width: 140px;
      background-color: #f2f2f2;
      overflow: auto;
    }
    .jump-title {
      height: 35px;
      background-color: #e3f0ff;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .jump-ul {
      list-style: none;
      -webkit-padding-start: 0px;
      width: 100%;
      padding: 16px 0;
      li {
        height: 45px;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      li:hover {
        background-color: #1b8cf2;
        color: #Fff;
      }
      .active {
        background-color: #1b8cf2;
        color: #Fff;
      }
    }
    .jumo-content {
      flex: 1;
      background-color: #f8f8f8;
      .jump-select {
        margin: 16px;
      }
    }
  }

  .jumpGo-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    .submitBtn {
      width: 300px
    }
  }
}

</style>
