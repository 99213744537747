var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-control v-autocomplete-container" },
    [
      _vm.label
        ? _c("label", { domProps: { textContent: _vm._s(_vm.label) } })
        : _vm._e(),
      _c("el-autocomplete", {
        style: { width: _vm.width + "px" },
        attrs: {
          disabled: _vm.disabled,
          placeholder: _vm.defaultPlaceholder,
          maxlength: _vm.maxlength,
          minlength: _vm.minlength,
          "value-key": _vm.valueKey,
          debounce: _vm.debounce,
          placement: _vm.placement,
          "popper-class": _vm.popperClass,
          "trigger-on-focus": _vm.triggerOnFocus,
          "select-when-unmatched": _vm.selectWhenUnmatched,
          "prefix-icon": _vm.prefixIcon,
          "suffix-icon": _vm.suffixIconName,
          "hide-loading": _vm.hideLoading,
          "popper-append-to-body": _vm.popperAppendToBody,
          clearable: _vm.clearable,
          "highlight-first-item": _vm.highlightFirstItem,
          "fetch-suggestions": _vm.querySearch,
        },
        on: {
          select: _vm.handleSelect,
          clear: _vm.clearValue,
          change: _vm.change,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function ({ item }) {
                return [_vm._t("advise-slot", null, { row: item })]
              },
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }