var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "v-uploader-wrapper" }, [
    _c(
      "div",
      { staticClass: "v-controls" },
      [
        _c(
          "SlickList",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.limit > 1 && _vm.useDragHandle,
                expression: "limit > 1 && useDragHandle",
              },
            ],
            staticClass: "SortableList",
            attrs: {
              lockToContainerEdges: true,
              helperClass: "helperClass",
              axis: "x",
              lockAxis: "x",
            },
            on: { input: _vm.getChangeLists },
            model: {
              value: _vm.fileList2,
              callback: function ($$v) {
                _vm.fileList2 = $$v
              },
              expression: "fileList2",
            },
          },
          _vm._l(_vm.fileList2, function (item, index) {
            return _c(
              "SlickItem",
              {
                key: item.url,
                staticClass: "SortableItem",
                attrs: { index: index },
              },
              [
                _c(
                  "div",
                  { staticClass: "imgBox imgActive", style: _vm.fileStyle },
                  [
                    !item.isOther
                      ? _c("img", {
                          staticClass: "v-img",
                          attrs: { src: item.url, alt: "" },
                        })
                      : _c("div", { staticClass: "box" }, [
                          _c("i", { staticClass: "el-icon-document document" }),
                          _c("span", { staticClass: "ellipsis" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                          item.size
                            ? _c("span", [
                                _vm._v(
                                  "size:" +
                                    _vm._s(_vm._f("fileSize")(item.size))
                                ),
                              ])
                            : _vm._e(),
                        ]),
                  ]
                ),
              ]
            )
          }),
          1
        ),
        _vm.limit > 1 && !_vm.useDragHandle
          ? _c(
              "div",
              { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
              [
                _vm._l(_vm.fileList1, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { "margin-bottom": "10px" } },
                    [
                      !item.percentage
                        ? _c(
                            "div",
                            { staticClass: "imgBox", style: _vm.fileStyle },
                            [
                              !item.isOther
                                ? _c("img", {
                                    staticClass: "v-img",
                                    attrs: { src: item.url, alt: "" },
                                  })
                                : _c("div", { staticClass: "box" }, [
                                    _c("i", {
                                      staticClass: "el-icon-document document",
                                    }),
                                    _c("span", { staticClass: "ellipsis" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                    item.size
                                      ? _c(
                                          "span",
                                          { staticClass: "ellipsis" },
                                          [
                                            _vm._v(
                                              "size:" +
                                                _vm._s(
                                                  _vm._f("fileSize")(item.size)
                                                )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                              _vm.operate === "all"
                                ? _c("div", { staticClass: "screen" }, [
                                    !item.isOther
                                      ? _c("i", {
                                          staticClass: "el-icon-zoom-in icon",
                                          on: {
                                            click: function ($event) {
                                              return _vm.toPreview(item.url)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _c("i", {
                                      staticClass: "el-icon-delete icon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeFile(item.url)
                                        },
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                              _vm.operate === "delete"
                                ? _c("div", { staticClass: "screen" }, [
                                    _c("i", {
                                      staticClass: "el-icon-delete icon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeFile(item.url)
                                        },
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "imgBox", style: _vm.fileStyle },
                            [
                              _c(
                                "div",
                                { staticClass: "preogressBox" },
                                [
                                  _c("el-progress", {
                                    staticClass: "preogress",
                                    attrs: {
                                      type: "circle",
                                      percentage: item.percentage,
                                      width: _vm.progressW,
                                    },
                                  }),
                                  _c("span", { staticClass: "ellipsis" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                    ]
                  )
                }),
                _vm.fileList1.length + _vm.manualUploadList.length <
                  _vm.limit && _vm.limit > 1
                  ? _c(
                      "el-upload",
                      {
                        ref: "upload",
                        staticClass: "v-upload",
                        style: _vm.fileStyle,
                        attrs: {
                          disabled: _vm.disabled,
                          data: _vm.extraData,
                          action: _vm.action,
                          multiple: _vm.multiple,
                          "show-file-list": false,
                          "before-upload": _vm.beforeAvatarUpload,
                          "before-remove": _vm.beforeRemoveFile,
                          drag: _vm.drag,
                          "on-success": _vm.successUpload,
                          "on-error": _vm.errorUpload,
                          "auto-upload": _vm.autoUpload,
                          "on-preview": _vm.previewUpload,
                          "on-progress": _vm.progressUpload,
                          "list-type": _vm.listType,
                          "on-change": _vm.onChange,
                          headers: _vm.importHeaders,
                          name: _vm.name,
                        },
                      },
                      [
                        _vm.fileWidth === _vm.fileHeight || !_vm.fileHeight
                          ? _c("img", {
                              staticClass: "v-img",
                              style: _vm.fileStyle,
                              attrs: { src: _vm.addImg, alt: "" },
                            })
                          : _c(
                              "div",
                              { staticClass: "noImg", style: _vm.fileStyle },
                              [
                                _c("span", { staticClass: "addIcon" }, [
                                  _vm._v("+"),
                                ]),
                                _c("span", { staticClass: "addText" }, [
                                  _vm._v("点击或拖拽上传"),
                                ]),
                              ]
                            ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        _vm._l(_vm.manualUploadList, function (item, index) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.limit > 1,
                  expression: "limit > 1",
                },
              ],
              key: index,
            },
            [
              _c("div", { staticClass: "imgBox", style: _vm.fileStyle }, [
                _c("div", { staticClass: "preogressBox" }, [
                  _c("span", { staticClass: "ellipsis" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                ]),
                _c("div", { staticClass: "screen" }, [
                  _c("i", {
                    staticClass: "el-icon-delete icon",
                    on: {
                      click: function ($event) {
                        return _vm.deleteFile(item.uid)
                      },
                    },
                  }),
                ]),
              ]),
            ]
          )
        }),
        _vm.limit === 1
          ? _c(
              "el-upload",
              {
                ref: "upload",
                staticClass: "v-upload",
                style: _vm.fileStyle,
                attrs: {
                  disabled: _vm.disabled,
                  data: _vm.extraData,
                  action: _vm.action,
                  multiple: _vm.multiple,
                  "show-file-list": false,
                  "before-upload": _vm.beforeAvatarUpload,
                  "before-remove": _vm.beforeRemoveFile,
                  drag: _vm.drag,
                  "on-success": _vm.successUpload,
                  "on-error": _vm.errorUpload,
                  "auto-upload": _vm.autoUpload,
                  "on-preview": _vm.previewUpload,
                  "on-progress": _vm.progressUpload,
                  "list-type": _vm.listType,
                  "on-change": _vm.onChange,
                  headers: _vm.importHeaders,
                  name: _vm.name,
                  accept: _vm.acceptFileType,
                },
              },
              [
                _vm.limit === 1
                  ? _c("div", [
                      _vm.fileList1.length === 1
                        ? _c(
                            "div",
                            { staticClass: "imgBox", style: _vm.fileStyle },
                            [
                              !_vm.fileList1[0].percentage
                                ? _c("div", [
                                    !_vm.fileList1[0].isOther
                                      ? _c("img", {
                                          staticClass: "v-img",
                                          attrs: {
                                            src: _vm.fileList1[0].url,
                                            alt: "",
                                          },
                                        })
                                      : _c("div", { staticClass: "box" }, [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-document document",
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "ellipsis" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.fileList1[0].name)
                                              ),
                                            ]
                                          ),
                                          _vm.fileList1[0].size
                                            ? _c(
                                                "span",
                                                { staticClass: "ellipsis" },
                                                [
                                                  _vm._v(
                                                    "size:" +
                                                      _vm._s(
                                                        _vm._f("fileSize")(
                                                          _vm.fileList1[0].size
                                                        )
                                                      )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                    _vm.operate === "all"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "screen",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                              },
                                            },
                                          },
                                          [
                                            !_vm.fileList1[0].isOther
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-zoom-in icon",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.toPreview(
                                                        _vm.fileList1[0].url
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            _c("i", {
                                              staticClass:
                                                "el-icon-delete icon",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.removeFile(
                                                    _vm.fileList1[0].url
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.operate === "delete"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "screen",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-delete icon",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.removeFile(
                                                    _vm.fileList1[0].url
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _c(
                                    "div",
                                    {
                                      staticClass: "imgBox",
                                      style: _vm.fileStyle,
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "preogressBox" },
                                        [
                                          _c("el-progress", {
                                            staticStyle: { height: "auto" },
                                            attrs: {
                                              type: "circle",
                                              percentage:
                                                _vm.fileList1[0].percentage,
                                              width: _vm.progressW,
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "ellipsis" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.fileList1[0].name)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                            ]
                          )
                        : _vm.manualUploadList.length === 1
                        ? _c(
                            "div",
                            {
                              staticClass: "imgBox",
                              style: _vm.fileStyle,
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "box" }, [
                                _c("i", {
                                  staticClass: "el-icon-document document",
                                }),
                                _c("span", { staticClass: "ellipsis" }, [
                                  _vm._v(_vm._s(_vm.manualUploadList[0].name)),
                                ]),
                                _c("span", [_vm._v("未上传")]),
                              ]),
                              _c("div", { staticClass: "screen" }, [
                                _c("i", {
                                  staticClass: "el-icon-delete icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteFile(
                                        _vm.manualUploadList[0].uid
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]
                          )
                        : _c("div", [
                            _vm.fileWidth === _vm.fileHeight || !_vm.fileHeight
                              ? _c("img", {
                                  staticClass: "v-img",
                                  style: _vm.fileStyle,
                                  attrs: { src: _vm.addImg, alt: "" },
                                })
                              : _c(
                                  "div",
                                  {
                                    staticClass: "noImg",
                                    style: _vm.fileStyle,
                                  },
                                  [
                                    _c("span", { staticClass: "addIcon" }, [
                                      _vm._v("+"),
                                    ]),
                                    _c("span", { staticClass: "addText" }, [
                                      _vm._v("点击或拖拽上传"),
                                    ]),
                                  ]
                                ),
                          ]),
                    ])
                  : _c("div", [
                      _vm.fileWidth === _vm.fileHeight || !_vm.fileHeight
                        ? _c("img", {
                            staticClass: "v-img",
                            style: _vm.fileStyle,
                            attrs: { src: _vm.addImg, alt: "" },
                          })
                        : _c(
                            "div",
                            { staticClass: "noImg", style: _vm.fileStyle },
                            [
                              _c("span", { staticClass: "addIcon" }, [
                                _vm._v("+"),
                              ]),
                              _c("span", { staticClass: "addText" }, [
                                _vm._v("点击或拖拽上传"),
                              ]),
                            ]
                          ),
                    ]),
                _vm._v(" -->\n    "),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
    _c(
      "div",
      [
        _vm.isOrder && _vm.fileList1.length > 1
          ? _c("v-button", {
              staticClass: "marTop",
              attrs: { text: _vm.dragText },
              on: { click: _vm.toChangeOrder },
            })
          : _vm._e(),
        _vm.$slots.tip
          ? _c("div", { staticClass: "rightTip" }, [_vm._t("tip")], 2)
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }