var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("w-room-tree", {
    directives: [
      {
        name: "loading",
        rawName: "v-loading",
        value: _vm.onLoading,
        expression: "onLoading",
      },
    ],
    ref: "roomtree",
    staticClass: "root-tree",
    style: { zIndex: 3000 },
    attrs: {
      lazy: true,
      defaultProps: _vm.treeProps,
      visible: _vm.treeShow,
      treeData: _vm.treeData,
      "render-after-expand": false,
      filterNode: _vm.filterNode,
    },
    on: {
      loadData: _vm.treeLoad,
      "update:visible": function ($event) {
        _vm.treeShow = $event
      },
      "current-change": _vm.selectNode,
    },
    scopedSlots: _vm._u([
      {
        key: "search",
        fn: function () {
          return [
            _c("div", { staticClass: "tree-header-wrapper" }, [
              _c(
                "div",
                { staticClass: "roomtree-input-wrap" },
                [
                  _c("v-input", {
                    staticClass: "roomtree-input",
                    attrs: { placeholder: "搜索", clearable: false },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  }),
                  _c("img", {
                    staticClass: "roomtree-input-search-icon",
                    attrs: { src: require("@/assets/images/search-icon.png") },
                  }),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "tree-refresh-btn",
                  on: { click: _vm.reloadTree },
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/images/refresh.png") },
                  }),
                  _vm._v(" 刷新"),
                ]
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "default",
        fn: function ({ node, data }) {
          return _c(
            "span",
            {
              staticClass: "custom-tree-node",
              class: { "bias-left": data.nodeLevel === 7 && !!data.roomStatus },
              staticStyle: { width: "100%" },
            },
            [
              data.nodeLevel === 7 && !!data.roomStatus
                ? _c(
                    "span",
                    {
                      staticClass: "status-icon",
                      class: [_vm.dataStateFormatter(data.roomStatus).class],
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.dataStateFormatter(data.roomStatus).word)
                      ),
                    ]
                  )
                : _vm._e(),
              data.nodeLevel !== 7 && data.nodeLevel >= 3 && data.isParent === 0
                ? _c(
                    "span",
                    {
                      staticClass: "status-icon",
                      class: [_vm.dataStateFormatter(data.roomStatus).class],
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.dataStateFormatter(data.roomStatus).word)
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    enterable: false,
                    "popper-class": "zindex5000",
                    placement: "top",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function () {
                          return [_vm._v(_vm._s(node.label))]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_c("span", [_vm._v(_vm._s(node.label))])]
              ),
            ],
            1
          )
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }