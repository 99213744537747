<template>
  <div class="menu-group-top">
    <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      :modal="modal"
      :size="size"
      :direction="direction"
      :withHeader="withHeader"
      :show-close="showClose"
    >
      <nav-menu-panel
        ref="nav-menu-panel"
        :activeMenuId="activeMenuId"
        :centerItem="centerItem"
        :firstItem="firstItem"
        :secondItem="secondItem"
        :thirdItem="thirdItem"
        :group="computedMenuItem"
        :menuListCache="computedMenuItem.childMenus"
        :status="status"
      ></nav-menu-panel>
    </el-drawer>
  </div>
</template>
<script>
import Vue from "vue";
import { Drawer } from "element-ui";
import NavMenuPanel from '../nav-menu-panel/nav-menu-panel-top.vue'
import { mapGetters, mapMutations } from "vuex";


Vue.use(Drawer);
export default {
  name: "menu-group-top-bar",
  components: {
    NavMenuPanel
  },
  provide(){
    return {menubar:this}
  },
  data() {
    return {
      drawer: false,
      direction: "ttb", // Drawer 打开的方向
      modal: false, // 是否显示遮罩层
      size: "50%",
      withHeader: false,
      showClose: false,

      status: true,
      activeMenuId: {
        0: [],
        1: [],
        2: [],
        3: [],
      },
      centerItem: {},
      firstItem: {},
      secondItem: {},
      thirdItem: {},
      menuList: [],
      menuItem: {},
      rootId: ""
    };
  },
  computed: {
    menuGroupType() {
      return this.$store.state.theme['menu-group-type'] || 'left'
    },
    computedMenuItem() {
      return this.menuResourceList.find(v => v.id == this.rootId)
    },
    ...mapGetters("menuStore", {
      menuResourceList: "getMenuResourceList",
    })
  },
  watch: {
    $route(route) {
      this.menuStatusChange(route.query);
      this.setActiveMenuId(route.query);
      let pageId = route.query.tid || route.query.sid || route.query.fid;
      if (pageId) {
        this.$axios.defaults.headers.pageId = pageId;
        let pageItem = {};
        let breadcrumb = [];
        if (this.thirdItem.name) {
          pageItem = this.thirdItem;
          breadcrumb = [
            this.centerItem.name,
            this.firstItem.name,
            this.secondItem.name,
            this.thirdItem.name,
          ];
        } else if (this.secondItem.name) {
          pageItem = this.secondItem;
          breadcrumb = [
            this.centerItem.name,
            this.firstItem.name,
            this.secondItem.name,
          ];
        } else if (this.firstItem.name) {
          pageItem = this.firstItem;
          breadcrumb = [this.centerItem.name, this.firstItem.name];
        }
        console.log(pageItem, breadcrumb)
        this.setPageItem(pageItem);
        this.$store.commit("setBreadcrumb", {
          reset: true,
          breadcrumb,
        });
        let permission = {};
        pageItem.childMenus.forEach((item) => {
          permission[item.code] = item.isSelected;
        });
        this.$store.commit("setPermission", permission);
       
      } else {
        this.$axios.defaults.headers.pageId = "";
      }
    },
  },
  methods: {
    openDrawer(rootId) {
      /* 通过父组件掉用 */
      this.rootId = rootId
      this.drawer = true;
      this.$nextTick(() => {
        this.$refs['nav-menu-panel'].handleClickSec()

      })
    },
      /* 通过父组件掉用 */

    closeDrawer() {
      this.drawer = false;
    },
    setActiveMenuId(query) {
      const keys = {
        pid: 0,
        cid: 1,
        fid: 2,
        sid: 3,
      };
      Object.keys(keys).forEach((key) => {
        const menuId = query[key];
        const activeMenuId = this.activeMenuId[keys[key]];
        if (menuId !== undefined) {
          try {
            const id = Number(menuId);
            if (["pid", "cid"].includes(key)) {
              if (activeMenuId && activeMenuId.length) {
                activeMenuId.splice(0, activeMenuId.length, id);
              } else {
                activeMenuId.push(id);
              }
            } else {
              const index = activeMenuId.indexOf(id);
              if (index === -1) {
                activeMenuId.push(id);
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
      });
    },
    find(list, id) {
      let item = list.find((item) => {
        return item.id == id; // eslint-disable-line
      });
      return item;
    },
    menuStatusChange({ pid, cid, fid, sid, tid }) {
      let centerItem = {};
      let firstItem = {};
      let secondItem = {};
      let thirdItem = {};
      let menuItem =
        pid === undefined
          ? this.menuResourceList[0].childMenus
          : this.find(this.menuResourceList, pid)
      let menuList = menuItem.childMenus
      if (cid) {
        centerItem = this.find(menuList, cid);
      }
      if (fid) {
        firstItem = this.find(centerItem.childMenus, fid);
      }
      if (sid) {
        secondItem = this.find(firstItem.childMenus, sid);
      }
      if (tid) {
        thirdItem = this.find(secondItem.childMenus, tid);
      }
      this.menuList = menuList
      this.menuItem = menuItem
      this.centerItem = centerItem;
      this.firstItem = firstItem;
      this.secondItem = secondItem;
      this.thirdItem = thirdItem;
    },
    ...mapMutations("menuStore", ["setPageItem"]),
  }
};
</script>
<style lang="scss">
.menu-group-top {
  .el-drawer__wrapper {
    top: 60px;
  }
}
</style>