<template>
  <div class="manual-list-container">
    <el-popover popper-class="manual-list-popper" :popper-options="popperOptions" placement="bottom-end" :offset="-16">
      <div class="header" v-text="roleName"></div>
      <ul class="manual-list">
        <li class="manual-item" v-for="(item, index) in manualList" :key="index" @click="downloadManual(item)">{{item.name}}</li>
      </ul>
      <template v-slot:reference>
        <slot name="manual"></slot>
      </template>
    </el-popover>
  </div>
</template>
<script>
import { downloadHelper } from 'common/utils'
export default {
  name: 'helpManual-list',
  props: {
  },
  data () {
    return {
      popperOptions: {
        boundariesElement: '.manual-list-container'
      },
      roleName: '帮助手册',
      manualList: [
        { name: '融平台管理平台帮助手册', link: `${window.origin}/app/user/UserManual20200831.htm` }
        // { name: '云助推广话术版本（无硬件园区）', link: '/static/manual/云助推广话术版本（无硬件园区）.pdf' },
        // { name: '云助推广话术版本（有硬件园区）', link: '/static/manual/云助推广话术版本（有硬件园区）.pdf' },
        // { name: '云助推广运营标准流程及分工', link: '/static/manual/云助推广运营标准流程及分工.pdf' },
        // { name: '智慧管理后台spm手册', link: '/static/manual/智慧管理后台spm手册.pdf' },
        // { name: '智慧园区物联网一体化平台用户手册V1·1', link: '/static/manual/智慧园区物联网一体化平台用户手册V1.1.pdf' }
      ]
    }
  },
  methods: {
    downloadManual (item) {
      // downloadHelper.downloadByLocation(url)
      window.open(item.link, '_blank')
      // let loading = this.$loading({
      //   text: '下载中'
      // })
      // setTimeout(() => {
      //   loading.close()
      // }, 1000)
      // downloadHelper.downloadFile({
      //   data: item.link, // `${location.origin}${location.pathname.substr(0, location.pathname.lastIndexOf('/'))}${item.link}`,
      //   fileName: item.name
      // })
    }
  }
}
</script>
<style lang="scss">
  .manual-list-popper {
    padding: 1px 0 0 0 !important;
    border: none !important;
  }
</style>
<style scoped lang="scss">
  .manual-list-popper {
    .header {
      padding: 0 24px;
      height: 44px;
      line-height: 44px;
      color: #AAA;
      background-color: #e3f0ff;
    }
    .manual-list {
      width: 285px;
      .manual-item {
        color: #23527c;
        padding: 10px;
        cursor: pointer;
        &:not(:last-child) {
          border-bottom: 1px solid #F9EEEE;
        }
      }
    }
  }
  .manual-list-container {
    .setting-dialog-content {
      text-align: left;
      .info {
        display: flex;
        margin: 10px 0 20px 0;
        .introduction {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin-left: 20px;
        }
      }
      .tips {
        margin-left: 20px;
      }
      .save-button {
        margin-left: 100px;
      }
    }
  }
</style>
