<template>
  <div class="custom-view-container">
    <!-- 自定义内容 -->
    <slot></slot>
  </div>
</template>

<script>

// 自定义视图组件
// @group 业务组件
// @title custom-view 自定义视图
export default {
  name: 'custom-view',
  props: {
  },
  data () {
    return {
    }
  },
  methods: {
    previous () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.custom-view-container {
  display: flex;
  text-align: left;
  max-height: 100%;
  padding: 0 20px;
  background-color: #FFF;
}
</style>
