<template>
    <div class="mobile-preview-wrapper" @click="closeView" v-if="isShow">
        <div class="bg-mobile" @click.stop>
          <div class="closeIcon" @click="closeView"></div>
          <div class="content-mobile">
            <div class="con-top">{{title}}</div>
            <div class="con-iframe">
              <slot v-if="$slots.content" name="content"></slot>
              <iframe v-if="iframeUrl && !$slots.content" width="320" height="520" :src="iframeUrl"  frameborder="no"></iframe>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
// @group 业务组件
// @title MobilePreview h5预览
export default {
  name: 'mobile-preview',
  data () {
    return {}
  },
  props: {
    // 所预览的url (iframe)
    iframeUrl: {
      type: String
    },
    // 标题
    title: {
      type: String,
      // `'预览'`
      default: '预览'
    },
    // 显示预览
    isShow: Boolean
  },
  methods: {
    closeView () {
      // 点击阴影处
      this.$emit('update:isShow', false)
      this.$emit('closePreview')
    }
  }
}
</script>
<style lang="scss" scoped>
.mobile-preview-wrapper{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.75);
  z-index: 999;
  .bg-mobile{
    position: relative;
    width: 362px;
    height: 744px;
    background: url("./img/phone_preview.png") 0 0  no-repeat;
    .closeIcon {
      position: absolute;
      right: -16px;
      top: 0px;
      height: 52px;
      width: 52px;
      background: url("./img/phone_preview.png") 0 -773px no-repeat;
    cursor: pointer;
    }
    .content-mobile{
      position: relative;
      width: 321px;
      height: 565px;
      top: 93px;
      left: 20px;
      background: #f8f8f8;
      border-radius: 3px;
      overflow: hidden;
      .con-top{
        text-align: center;
        color: #fff;
        line-height: 38px;
        height: 38px;
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
        padding: 0 20px;
        background: #000;
      }
      .con-iframe{
        text-align: left;
        height: 524px;
        overflow: auto;
        img {
          max-width: 100%;
        }
      }
    }
  }
}

</style>
