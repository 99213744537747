var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-panel-container" }, [
    _vm.hasHeader
      ? _c(
          "div",
          { staticClass: "header" },
          [
            _vm._t("headerSlot", function () {
              return [
                _c("v-button", {
                  attrs: { text: "返回" },
                  on: { click: _vm.previous },
                }),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
    _c(
      "div",
      { ref: "content", staticClass: "content" },
      [
        _c(
          "el-form",
          {
            ref: _vm.formName,
            attrs: {
              model: _vm.form,
              rules: _vm.rules,
              "label-position": _vm.labelPosition,
              "label-width": _vm.labelWidth,
              "label-suffix": "：",
              size: "medium",
              disabled: _vm.disabled,
            },
            nativeOn: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [_vm._t("default")],
          2
        ),
      ],
      1
    ),
    _vm.footerShow
      ? _c("div", { staticClass: "footer" }, [
          _c(
            "div",
            { staticClass: "footer-button" },
            [
              _vm._t("saveBeforeSlot"),
              _vm._t("footerSlot", function () {
                return [
                  _vm.submitUrl
                    ? _c("v-button", {
                        attrs: {
                          text: _vm.submitText,
                          type: "success",
                          disabled: _vm.submitDisabled,
                        },
                        on: { click: _vm.submitClick },
                      })
                    : _vm._e(),
                ]
              }),
              _vm._t("saveAfterSlot"),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }