<template>
  <div class="chosen-list-panel">
    <div class="content">
      <el-row ref="elrow"
              :gutter="20">
        <el-col :span="6"
                v-for="(item, index) in data"
                :key="index">
          <el-tooltip class="item"
                      effect="dark"
                      :content="item.text"
                      placement="top">
            <div class="item">
              <span class="text"
                    v-text="item.text"></span>
              <div class="close"
                   @click="remove(index)"
                   v-if="!disabled">
                <i class="el-icon-error"></i>
                <div class="white"></div>
              </div>
            </div>
          </el-tooltip>
        </el-col>
      </el-row>
    </div>
    <div class="footer">
      <div>
        <span>已选择:</span>
        <strong v-text="data.length"></strong>
      </div>
      <div class="button"
           @click="select"
           v-if="!disabled">
        <img src="./images/icon-select.png"
             alt="">
        点击选择
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Row, Col, Tooltip } from 'element-ui'
import emitter from 'element-ui/src/mixins/emitter'
Vue.use(Row)
Vue.use(Col)
Vue.use(Tooltip)
// @group 业务组件
// @title ChosenListPanel 已选数据展示框
export default {
  name: 'chosen-list-panel',
  mixins: [emitter],
  props: {
    // 要展示数据数组{ text: '文本' }
    list: {
      type: Array,
      required: true
    },
    // 文本key的别名
    textAs: {
      type: String,
      default: 'text'
    },
    // 移除项确认 async function
    beforeRemove: Function,
    // 禁用
    disabled: Boolean
  },
  data: function () {
    return {
      data: this.list.map(item => ({ ...item, text: item[this.textAs] }))
    }
  },
  methods: {
    async remove (index) {
      if (this.beforeRemove) {
        let list = [...this.list]
        list.splice(index, 1)
        let result = await this.beforeRemove(list)
        if (result) {
          this.data.splice(index, 1)
          this.list.splice(index, 1)
          // 更新list数据
          this.$emit('update:list', this.list)
        }
      } else {
        this.data.splice(index, 1)
        this.list.splice(index, 1)
        // 更新list数据
        this.$emit('update:list', this.list)
      }
    },
    select () {
      // 选择按钮点击触发事件
      this.$emit('select')
    }
  },
  watch: {
    list: function (newValue, oldval) {
      if (Array.isArray(newValue)) {
        this.data = newValue.map(item => ({ ...item, text: item[this.textAs] }))
      } else {
        this.data = []
      }
      // 结果集数据发生变化，触发change事件
      this.$emit('change', newValue)
      // 用比较hack的方法联动表单校验
      this.dispatch('ElFormItem', 'el.form.change', [newValue])
    }
  }
}
</script>
<style scoped lang="scss">
.chosen-list-panel {
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  border-radius: 4px;
  min-height: 150px;
  width: 600px;
  padding: 10px 20px;
  padding-bottom: 50px;
  position: relative;
}
.content {
  overflow-y: auto;
  max-height: 240px;
  overflow-x: hidden;
  .item {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: #90b2ce;
    padding: 0 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .close {
      position: relative;
      cursor: pointer;
      color: #aaa;
      margin-right: 3px;
      margin-bottom: 3px;
      .white {
        background-color: #fff;
        height: 8px;
        width: 8px;
        margin-left: 3px;
        margin-top: 3px;
      }
      i {
        position: absolute;
      }
      // position: absolute;
    }
    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.footer {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 600px;
}
.button {
  &:hover {
    cursor: pointer;
    color: #90b2ce;
  }
  color: #aaa;
  vertical-align: middle;
  width: 112px;
  height: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 10px;
    width: 22px;
    height: 22px;
  }
}
.el-form-item.is-error .chosen-list-panel {
  border-color: #f56c6c;
}
.el-form-item.is-success .chosen-list-panel {
  border-color: #67c23a;
}
</style>
