import Store from '@/store'
import Axios from '@/axios'
import Router from '@/router'
import qs from 'querystring'

const userQueryParams = {
  token: () => {
    return Store.state.token
  },
  base_url: () => {
    return `${API_CONFIG.controlBaseURL}`
  },
  param_ftype: () => {
    return Store.state.userInfo.userType
  },
  param_fvalue: () => {
    let userType = Store.state.userInfo.userType
    let value = undefined
    if (['101'].includes(userType)) {
      value = Store.state.userInfo.communityId
    } else if (['102'].includes(userType)) {
      value = Store.state.userInfo.orgId
    }
    return value
  },
  regionShow: () => {
    let userType = Store.state.userInfo.userType
    let value = undefined
    if (['101', '102'].includes(userType)) {
      value = 0
    }
    return value
  },
  communityShow: () => {
    let userType = Store.state.userInfo.userType
    let value = undefined
    if (['101'].includes(userType)) {
      value = 0
    }
    return value
  }
}

const getQueryParams = (key) => {
  return userQueryParams[key] ? userQueryParams[key]() : ''
}

// 授权方式
const menuAuthMap = {
  0: (menu) => {
    let { url } = menu
    let pathname = location.pathname.substring(0, location.pathname.lastIndexOf('\/') + 1) + 'refresh.html'
    let src = `${location.origin}${pathname}#/home?login=1&mode=fullScreen&url=${encodeURIComponent(url)}`
    return src
  },
  1: (menu) => {
    let { url } = menu
    let args = url.split('?')
    let src = args[0]
    let queryParams = {}
    if (args && args.length > 1) {
      let queryStr = args[1]
      let items = queryStr.length ? queryStr.split('&') : []
      for (let i = 0; i < items.length; i++) {
        let item = items[i].split('=')
        let name = decodeURIComponent(item[0])
        let value = decodeURIComponent(item[1])
        if (name.length) {
          let param = value.match(/\${(\S*)}/)
          if (param) {
            value = getQueryParams(name)
            if (value === undefined) {
              break
            }
          }
          queryParams[name] = value
        }
      }
      src = src + '?' + qs.stringify(queryParams)
    }
    return src
  },
  2: async (menu) => {
    let { url } = menu
    let src = ''
    let searchURL = `${API_CONFIG.controlBaseURL}${url}`
    let { status, data } = await Axios.get(searchURL)
    if (status === 100) {
      if (data && data.redirectUrl) {
        src = data.redirectUrl
      }
    }
    return src
  }
}

// 打开方式
const menuApplyMap = {
  1: (src) => {
    window.location.href = src
  },
  2: (src) => {
    window.open(src, '_blank')
  }
}

const menuJump = async (menu) => {
  let { authType, urlJumpType } = menu
  let menuAuth = menuAuthMap[authType]
  if (menuAuth) {
    const src = await menuAuth(menu)
    let menuApply = menuApplyMap[urlJumpType]
    if (menuApply && src) {
      menuApply(src)
    }
  }
}

const internalMenuJump = (id) => {
  const menuInfo = Store.getters['menuStore/getMenuInfo'](id)
  const { item, map } = menuInfo
  const { url } = item
  Router.push({
    name: url,
    query: map
  })
}

const findFirstMenu = (menu) => {
  if (menu.menuType === 2) {
    return menu
  } else if (menu.childMenus && menu.childMenus.length) {
    return findFirstMenu(menu.childMenus[0])
  }
  return
}

export {
  menuAuthMap,
  menuApplyMap,
  menuJump,
  internalMenuJump,
  findFirstMenu
}
