const routes = [
  // 项目信息配置
  {
    path: '/home/projectConfigList', // 列表页
    name: 'communityAction!list.action1',
    component: resolve => require(['@/views/project/projectConfig/ProjectConfigList'], resolve)
  },
  {
    path: '/home/projectConfigForm', // 表单页
    name: 'projectConfigForm',
    component: resolve => require(['@/views/project/projectConfig/ProjectConfigForm'], resolve)
  },
  {
    path: '/home/ImproveDataConfigForm', // 表单页
    name: 'ImproveDataConfigForm',
    component: resolve => require(['@/views/project/projectConfig/ImproveDataConfigForm'], resolve)
  },
  {
    path: '/home/projectTypeConfig', // 表单页
    name: 'projectTypeConfig',
    component: resolve => require(['@/views/project/projectConfig/ProjectTypeConfig'], resolve)
  },
  {
    path: '/home/projectConfigImport', // 表单页
    name: 'projectConfigImport',
    component: resolve => require(['@/views/project/projectConfig/Import'], resolve)
  },
  {
    path: '/home/improveDataImport', // 表单页
    name: 'improveDataImport',
    component: resolve => require(['@/views/project/projectConfig/ImproveDataImport'], resolve)
  },
  // 待审核房号
  {
    path: '/home/houseAuditList', // 列表页
    name: 'communityRoomAction!list.action1',
    component: resolve => require(['@/views/project/projectHouseConfig/houseAudit/HouseAuditList'], resolve)
  },
  {
    path: '/home/houseAuditForm', // 表单页
    name: 'houseAuditForm',
    component: resolve => require(['@/views/project/projectHouseConfig/houseAudit/HouseAuditForm.vue'], resolve)
  },
  // 有效房号
  {
    path: '/home/validHouseList', // 列表页
    name: 'communityRoomAction!list.action2',
    component: resolve => require(['@/views/project/projectHouseConfig/validHouse/ValidHouseList'], resolve)
  },
  {
    path: '/home/validHouseForm', // 表单页
    name: 'validHouseForm',
    component: resolve => require(['@/views/project/projectHouseConfig/validHouse/ValidHouseForm'], resolve)
  },
  {
    path: '/home/validHouseImport', // 表单页
    name: 'validHouseImport',
    component: resolve => require(['@/views/project/projectHouseConfig/validHouse/Import'], resolve)
  },
  {
    path: '/home/validRoomImport', // 表单页
    name: 'validRoomImport',
    component: resolve => require(['@/views/project/projectHouseConfig/validHouse/RoomImport'], resolve)
  },
  {
    path: '/home/thirdPartyImport', // 表单页
    name: 'validThirdPartyImport',
    component: resolve => require(['@/views/project/projectHouseConfig/validHouse/thirdPartyImport'], resolve)
  },
  // 房屋剖面图
  {
    path: '/home/houseProfileList', // 列表页
    name: 'communityRoomAction!list.action4',
    component: resolve => require(['@/views/project/projectHouseConfig/houseProfile/HouseProfileList'], resolve)
  },
  // 房屋剖面图
  {
    path: '/home/houseProfileForm', // 编辑页
    name: 'houseProfileForm',
    component: resolve => require(['@/views/project/projectHouseConfig/houseProfile/HouseProfileForm'], resolve)
  },

  // 停车场厂商管理列表
  {
    path: '/home/parkingManufacturer', // 列表页
    name: 'communityParkingConfig2',
    component: resolve => require(['@/views/project/parkingManufacturer/parkingManufacturerList'], resolve)
  },
  // 停车场厂商管理表单
  {
    path: '/home/parkingManufacturerForm', // 列表页
    name: 'parkingManufacturerForm',
    component: resolve => require(['@/views/project/parkingManufacturer/parkingManufacturerForm'], resolve)
  },
  // 道闸设施配置列表
  {
    path: '/home/roadGate', // 列表页
    name: 'communityParkingConfig1',
    component: resolve => require(['@/views/project/roadGate/roadGateDeviceConfigList'], resolve)
  },
  // 道闸设施配置表单
  {
    path: '/home/roadGateForm', // 列表页
    name: 'roadGateForm',
    component: resolve => require(['@/views/project/roadGate/roadGateDeviceConfigForm'], resolve)
  },
  // 设备台账配置
  {
    path: '/home/deviceConfigList', // 列表页
    name: 'device1',
    component: resolve => require(['@/views/project/device/deviceConfig/DeviceConfigList'], resolve)
  },
  {
    path: '/home/BatchRelevanceQrcode', // 批量关联二维码
    name: 'batchRelevanceQrcode',
    component: resolve => require(['@/views/project/device/deviceConfig/BatchRelevanceQrcode'], resolve)
  },
  {
    path: '/home/batchEdit', // 批量修改
    name: 'batchEdit',
    component: resolve => require(['@/views/project/device/deviceConfig/BatchEdit'], resolve)
  },
  {
    path: '/home/deviceConfigForm', // 登记设备
    name: 'deviceConfigForm',
    component: resolve => require(['@/views/project/device/deviceConfig/DeviceConfigForm'], resolve)
  },
  {
    path: '/home/deviceConfigImport', // 导入台账
    name: 'deviceConfigImport',
    component: resolve => require(['@/views/project/device/deviceConfig/Import'], resolve)
  },
  // 设备房
  {
    path: '/home/deviceHouseList', // 列表页
    name: 'device2',
    component: resolve => require(['@/views/project/device/deviceHouse/DeviceHouseList'], resolve)
  },
  {
    path: '/home/deviceHouseForm', // 表单页
    name: 'deviceHouseForm',
    component: resolve => require(['@/views/project/device/deviceHouse/DeviceHouseForm'], resolve)
  },
  {
    path: '/home/deviceHouseImport', // 导入页
    name: 'deviceHouseImport',
    component: resolve => require(['@/views/project/device/deviceHouse/Import'], resolve)
  },

  // TODO 巡查管理
  {
    path: '/home/inspectManagerContentList', // 巡查通用内容列表页
    name: 'inspect1',
    component: resolve => require(['@/views/project/inspectManager/InspectManagerContentList'], resolve)
  },
  {
    path: '/home/inspectManagerContentForm', // 巡查通用内容form页
    name: 'inspectContentForm',
    component: resolve => require(['@/views/project/inspectManager/InspectManagerContentForm'], resolve)
  },
  {
    path: '/home/inspectManagerCategoryList', // 巡查点分类列表页
    name: 'inspect2',
    component: resolve => require(['@/views/project/inspectManager/InspectManagerCategoryList'], resolve)
  },
  {
    path: '/home/inspectManagerCategoryForm', // 巡查点分类form页
    name: 'inspectCategoryForm',
    component: resolve => require(['@/views/project/inspectManager/InspectManagerCategoryForm'], resolve)
  },
  {
    path: '/home/inspectManagerTaskList', // 巡查执行情况列表页
    name: 'inspect3',
    component: resolve => require(['@/views/project/inspectManager/InspectManagerTaskList'], resolve)
  },
  {
    path: '/home/inspectManagerTaskForm', // 巡查执行情况form页
    name: 'inspectTaskForm',
    component: resolve => require(['@/views/project/inspectManager/InspectManagerTaskForm'], resolve)
  },
  {
    path: '/home/inspectManagerPointList', // 巡查点管理列表页
    name: 'inspect4',
    component: resolve => require(['@/views/project/inspectManager/InspectManagerPointList'], resolve)
  },
  {
    path: '/home/inspectPointImport', // 巡查点导入页面
    name: 'inspectPointImport',
    component: resolve => require(['@/views/project/inspectManager/InspectPointImport'], resolve)
  },
  {
    path: '/home/inspectPointForm', // 巡查点form
    name: 'inspectPointForm',
    component: resolve => require(['@/views/project/inspectManager/InspectManagerPointForm'], resolve)
  },
  {
    path: '/home/inspectPointBatchEdit', // 批量修改巡查点
    name: 'inspectPointBatchEdit',
    component: resolve => require(['@/views/project/inspectManager/inspectPointBatchEdit'], resolve)
  },
  // TODO 巡检管理
  {
    path: '/home/patrolLogManagerList', // 列表页
    name: 'patrolLog1',
    component: resolve => require(['@/views/project/patrolLogManager/PatrolLogManagerList'], resolve)
  },
  {
    path: '/home/PatrolLogManagerForm', // 表单页面
    name: 'patrolLogManagerForm',
    component: resolve => require(['@/views/project/patrolLogManager/PatrolLogManagerForm'], resolve)
  },
  // 巡更管理 - 通用内容
  {
    path: '/home/comContentList', // 列表页
    name: 'electronic1',
    component: resolve => require(['@/views/project/patrolMgt/commonContent/list'], resolve)
  },
  {
    path: '/home/comContentForm', // form
    name: 'comContentForm',
    component: resolve => require(['@/views/project/patrolMgt/commonContent/form'], resolve)
  },
  // 巡更管理 - 巡更点分类
  {
    path: '/home/patrolCategoryList', // 列表页
    name: 'electronic2',
    component: resolve => require(['@/views/project/patrolMgt/patrolCategory/list'], resolve)
  },
  {
    path: '/home/patrolCategoryForm', // form
    name: 'patrolCategoryForm',
    component: resolve => require(['@/views/project/patrolMgt/patrolCategory/form'], resolve)
  },
  // 巡更管理 - 路线执行情况
  {
    path: '/home/pathExecutiveList', // 列表页
    name: 'electronic3',
    component: resolve => require(['@/views/project/patrolMgt/pathExecutive/list'], resolve)
  },
  {
    path: '/home/pathExecutiveForm', // form
    name: 'pathExecutiveForm',
    component: resolve => require(['@/views/project/patrolMgt/pathExecutive/form'], resolve)
  },
  // 巡更管理 - 巡更打点情况
  {
    path: '/home/patrolSignList', // 列表页
    name: 'electronic4',
    component: resolve => require(['@/views/project/patrolMgt/patrolSign/list'], resolve)
  },
  {
    path: '/home/patrolSignForm', // form
    name: 'patrolSignForm',
    component: resolve => require(['@/views/project/patrolMgt/patrolSign/form'], resolve)
  },
  // 巡更管理 - 巡更路线更新
  {
    path: '/home/pathUpdateList', // 列表页
    name: 'electronic5',
    component: resolve => require(['@/views/project/patrolMgt/pathUpdate/list'], resolve)
  },
  {
    path: '/home/pathUpdateForm', // 列表页
    name: 'pathUpdateForm',
    component: resolve => require(['@/views/project/patrolMgt/pathUpdate/form'], resolve)
  },
  // 巡更管理 - 巡更点管理
  {
    path: '/home/patrolDotMgtList', // 列表页
    name: 'electronic6',
    component: resolve => require(['@/views/project/patrolMgt/patrolDotMgt/list'], resolve)
  },
  {
    path: '/home/patrolDotMgtForm', // 列表页
    name: 'patrolDotMgtForm',
    component: resolve => require(['@/views/project/patrolMgt/patrolDotMgt/form'], resolve)
  },
  // 维保管理
  {
    path: '/home/maintenanceLogList', // 列表页
    name: 'maintenance1',
    component: resolve => require(['@/views/project/maintenanceLog/MaintenanceLogList'], resolve)
  },
  {
    path: '/home/maintenanceLogForm', // form
    name: 'maintenanceLogForm',
    component: resolve => require(['@/views/project/maintenanceLog/MaintenanceLogListForm'], resolve)
  },

  // 鹰眼组管理
  {
    path: '/home/eagleeyeList', // 列表页
    name: 'eagleeyeArray1',
    component: resolve => require(['@/views/project/eagleeye/EagleeyeList'], resolve)
  },
  {
    path: '/home/EagleeyeForm', // 鹰眼组Form
    name: 'eagleeyeForm',
    component: resolve => require(['@/views/project/eagleeye/EagleeyeForm'], resolve)
  },
  {
    path: '/home/PropertyMgEagleeyeForm', // 物管鹰眼form
    name: 'wgEagleeyeForm',
    component: resolve => require(['@/views/project/eagleeye/PropertyMgEagleeyeForm'], resolve)
  },
  // 鹰眼使用情况
  {
    path: '/home/eagleeyeUsageList', // 列表页
    name: 'eagleeyeArray2',
    component: resolve => require(['@/views/project/eagleeyeUsage/EagleeyeUsageList'], resolve)
  },
  {
    path: '/home/EagleeyeUsageForm', // 列表页
    name: 'eagleeyeUsageForm',
    component: resolve => require(['@/views/project/eagleeyeUsage/EagleeyeUsageForm'], resolve)
  },
  {
    path: '/home/deviceConfig/CommonList', // 通用内容
    name: 'deviceCfg1',
    component: resolve => require(['@/views/project/deviceConfig/Common/DeviceConfigCommonList'], resolve)
  },
  {
    path: '/home/deviceConfig/CommonForm', // 通用内容表单
    name: 'deviceConfigCommonForm',
    component: resolve => require(['@/views/project/deviceConfig/Common/DeviceConfigCommonForm'], resolve)
  },
  {
    path: '/home/deviceConfig/CommonEdit', // 通用内容编辑表单
    name: 'deviceConfigCommonEdit',
    component: resolve => require(['@/views/project/deviceConfig/Common/DeviceConfigCommonEdit'], resolve)
  },
  {
    path: '/home/deviceConfig/ClassifyList', // 设备分类列表
    name: 'deviceCfg2',
    component: resolve => require(['@/views/project/deviceConfig/Classify/DeviceConfigClassifyList'], resolve)
  },
  {
    path: '/home/deviceConfig/ClassifyEdit', // 设备分类编辑
    name: 'deviceConfigClassifyEdit',
    component: resolve => require(['@/views/project/deviceConfig/Classify/DeviceConfigClassifyEdit'], resolve)
  },
  {
    path: '/home/deviceConfig/ClassifyAdd', // 设备分类新增
    name: 'deviceConfigClassifyAdd',
    component: resolve => require(['@/views/project/deviceConfig/Classify/DeviceConfigClassifyEdit'], resolve)
  },
  {
    path: '/home/deviceConfig/importCommon', // 导入通用内容关联分类
    name: 'deviceConfigImportCommon',
    component: resolve => require(['@/views/project/deviceConfig/Classify/DeviceConfigImportCommon'], resolve)
  },
  {
    path: '/home/deviceConfig/importMaintain', // 导入维保单位并关联分类
    name: 'deviceConfigImportMaintain',
    component: resolve => require(['@/views/project/deviceConfig/Classify/DeviceConfigImportMaintain'], resolve)
  },
  {
    path: '/home/deviceConfig/classifyConfigList', // 配置分类列表
    name: 'deviceConfigClassifyConfigList',
    component: resolve => require(['@/views/project/deviceConfig/classifyConfig/ClassifyConfigList'], resolve)
  },
  {
    path: '/home/deviceConfig/classifyConfigAdd', // 配置分类新增分类
    name: 'deviceConfigClassifyConfigAdd',
    component: resolve => require(['@/views/project/deviceConfig/classifyConfig/ClassifyConfigForm'], resolve)
  },
  {
    path: '/home/deviceConfig/classifyConfigEdit', // 配置分类新增分类
    name: 'deviceConfigClassifyConfigEdit',
    component: resolve => require(['@/views/project/deviceConfig/classifyConfig/ClassifyConfigForm'], resolve)
  },
  // 绿植管理
  {
    path: '/home/plantManageList', // 绿植管理--列表页
    name: 'plant1',
    component: resolve => require(['@/views/project/plantManage/PlantManageList'], resolve)
  },
  {
    path: '/home/plantManageForm', // 绿植管理--表单页
    name: 'plantManageForm',
    component: resolve => require(['@/views/project/plantManage/PlantManageForm'], resolve)
  },
  {
    path: '/home/plantManageImport', // 绿植管理--导入页
    name: 'plantManageImport',
    component: resolve => require(['@/views/project/plantManage/PlantManageImport'], resolve)
  },
  // 内部联系人
  {
    path: '/home/InternalCommunicationList', // 列表页
    name: 'inMailList',
    component: resolve => require(['@/views/project/Communication/InternalCommunicationList'], resolve)
  },
  {
    path: '/home/InternalCommunicationForm', // 表单页
    name: 'InternalCommunicationForm',
    component: resolve => require(['@/views/project/Communication/InternalCommunicationForm'], resolve)
  },
  {
    path: '/home/InternalCommunicationImport', // 导入
    name: 'InternalCommunicationImport',
    component: resolve => require(['@/views/project/Communication/InternalCommunicationImport'], resolve)
  },
  // 外部联系人
  {
    path: '/home/ExternalCommunicationList', // 列表页
    name: 'outMailList',
    component: resolve => require(['@/views/project/Communication/ExternalCommunicationList'], resolve)
  },
  {
    path: '/home/ExternalCommunicationForm', // 表单页
    name: 'ExternalCommunicationForm',
    component: resolve => require(['@/views/project/Communication/ExternalCommunicationForm'], resolve)
  },
  {
    path: '/home/ExternalCommunicationImport', // 导入
    name: 'ExternalCommunicationImport',
    component: resolve => require(['@/views/project/Communication/ExternalCommunicationImport'], resolve)
  },
  //
  {
    path: '/home/areaSelect', // 区域组件测试
    name: 'areaSelect',
    component: resolve => require(['@/views/project/areaSelectTest/areaSelect'], resolve)
  },

  //高级配置
  {
    path: '/home/setList',
    name: 'setList',
    component: resolve => require(['@/views/project/set/setList'], resolve)
  }
]

export default routes
