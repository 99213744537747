const routes = [
  // 操作日志
  {
    path: '/home/operationLogList', // 列表页
    name: 'operation1',
    component: resolve => require(['@/views/common/operationLog/OperationLogList'], resolve)
  },
  // 登录日志
  {
    path: '/home/loginLogList', // 列表页
    name: 'operation2',
    component: resolve => require(['@/views/common/loginLog/LoginLogList'], resolve)
  },
  // 同步日报
  {
    path: '/home/syncDailyList', // 列表页
    name: 'synchroData1',
    component: resolve => require(['@/views/common/syncDaily/SyncDailyList'], resolve)
  },
  // 同步明细
  {
    path: '/home/syncDetailList', // 列表页
    name: 'synchroData2',
    component: resolve => require(['@/views/common/syncDetail/SyncDetailList'], resolve)
  },
  // TODO 账户管理
  {
    path: '/home/accountMgrList', // 列表页
    name: 'serverUserAction!viewList.action1',
    component: resolve => require(['@/views/common/accountMgr/AccountMgrList'], resolve)
  },
  {
    path: '/home/accountMgrForm', // 列表页
    name: 'accountMgrForm',
    component: resolve => require(['@/views/common/accountMgr/AccountMgrForm'], resolve)
  },
  // TODO 角色管理
  {
    path: '/home/roleMgrList', // 列表页
    name: 'serverRoleAction!viewList.action1',
    component: resolve => require(['@/views/common/roleMgr/RoleMgrList'], resolve)
  },
  {
    path: '/home/roleMgrForm', // 列表页
    name: 'roleMgrForm',
    component: resolve => require(['@/views/common/roleMgr/RoleMgrForm'], resolve)
  },
  {
    path: '/home/batchEmpower', // 批量授权
    name: 'batchEmpower',
    component: resolve => require(['@/views/common/roleMgr/BatchEmpower'], resolve)
  },
  {
    path: '/home/batchRecover', // 批量收权
    name: 'batchRecover',
    component: resolve => require(['@/views/common/roleMgr/BatchRecover'], resolve)
  },

  // TODO 管理组织
  {
    path: '/home/manageOrganizeList', // 列表页
    name: 'manageOrganizeList',
    component: resolve => require(['@/views/common/manageOrganize/manageOrganizeList'], resolve)
  },
  {
    path: '/home/memberInfoForm', // 表单页
    name: 'memberInfoForm',
    component: resolve => require(['@/views/common/manageOrganize/MemberInfoForm'], resolve)
  },
  {
    path: '/home/responsibilityForm', // 职责表单页
    name: 'responsibilityForm',
    component: resolve => require(['@/views/common/manageOrganize/ResponsibilityForm'], resolve)
  },
  // 产品域
  {
    path: '/home/productRegion', // 列表页
    name: 'productRegion',
    component: resolve => require(['@/views/common/productRegion/ProductRegionList'], resolve)
  },
  {
    path: '/home/productRegionForm', // 列表页
    name: 'productRegionForm',
    component: resolve => require(['@/views/common/productRegion/ProductRegionForm'], resolve)
  },
  // 业务域
  {
    path: '/home/businessDomainList', // 列表页
    name: 'businessRegion',
    component: resolve => require(['@/views/common/businessDomain/BusinessDomainList'], resolve)
  },
  {
    path: '/home/businessDomainForm', // form
    name: 'businessDomainForm',
    component: resolve => require(['@/views/common/businessDomain/BusinessDomainForm'], resolve)
  },
  // 协议配置
  {
    path: '/home/protocolMgrList', // 列表页
    name: 'protocolMgr1',
    component: resolve => require(['@/views/common/protocolMgr/ProtocolMgrList'], resolve)
  },
  {
    path: '/home/protocolMgrForm', // form
    name: 'protocolMgrForm',
    component: resolve => require(['@/views/common/protocolMgr/ProtocolMgrForm'], resolve)
  },
  // 第三方缴费配置
  {
    path: '/home/thirdEstatesList', // 列表页
    name: 'thirdEstatesConfig0',
    // component: resolve => require(['@/views/common/thirdEstates/ThirdEstatesList'], resolve)
    component: resolve => require(['@/views/common/communityInfoConfig/CommunityInfoConfigList'], resolve)
  },

  // 租户管理
  {
    path: '/home/tenantList', // 列表页
    name: 'tenantManagement',
    component: resolve => require(['@/views/common/tenant/TenantList'], resolve)
  },
  {
    path: '/home/tenantForm', // 表单页
    name: 'tenantForm',
    component: resolve => require(['@/views/common/tenant/TenantForm'], resolve)
  },
  // APP信息配置
  {
    path: '/home/appInfo', // 列表页
    name: 'appInfo',
    component: resolve => require(['@/views/common/appInfo/AppInfoList'], resolve)
  },
  {
    path: '/home/appInfoForm', // 表单页 - 新增
    name: 'appInfoForm',
    component: resolve => require(['@/views/common/appInfo/AppInfoForm'], resolve)
  },
  {
    path: '/home/updateConfigForm', // 表单页 - 升级配置
    name: 'updateConfigForm',
    component: resolve => require(['@/views/common/appInfo/UpdateConfigForm'], resolve)
  },
  // 项目信息配置
  {
    path: '/home/communityInfoConfigList', // 列表页
    name: 'communityMicroappConfig',
    component: resolve => require(['@/views/common/communityInfoConfig/CommunityInfoConfigList'], resolve)
  },
  {
    path: '/home/communityInfoConfigForm', // 表单页
    name: 'communityInfoConfigForm',
    component: resolve => require(['@/views/common/communityInfoConfig/CommunityInfoConfigForm'], resolve)
  },
  // 应用信息配置
  {
    path: '/home/appInfoConfigList', // 列表页
    name: 'microappApplicationConfig',
    component: resolve => require(['@/views/common/appInfoConfig/AppInfoConfigList'], resolve)
  },
   // 小程序信息配置
  {
    path: '/home/miniProgramInfoList', // 列表页
    name: 'miniProgramInfoList',
    component: resolve => require(['@/views/common/miniProgramInfo/MiniProgramInfoList'], resolve)
  },
  {
    path: '/home/miniProgramInfoForm', // 列表页
    name: 'miniProgramInfoForm',
    component: resolve => require(['@/views/common/miniProgramInfo/MiniProgramInfoForm'], resolve)
  },
  {
    path: '/home/AppInfoConfigForm', // 批量配置
    name: 'AppInfoConfigForm',
    component: resolve => require(['@/views/common/appInfoConfig/AppInfoConfigForm'], resolve)
  },
  // 敏感词管理
  {
    path: '/home/sensitiveWordList', // 列表
    name: 'sensitiveWord',
    component: resolve => require(['@/views/common/sensitiveWord/SensitiveWordList'], resolve)
  },
  {
    path: '/home/sensitiveWordForm', // 详情
    name: 'sensitiveWordForm',
    component: resolve => require(['@/views/common/sensitiveWord/SensitiveWordForm'], resolve)
  },
  {
    path: '/home/sensitiveWordFormImport', // 导入
    name: 'sensitiveWordFormImport',
    component: resolve => require(['@/views/common/sensitiveWord/SensitiveWordFormImport'], resolve)
  },
  // 配置渠道管理
  {
    path: '/home/configureChannelList', // 列表
    name: 'configureChannelList',
    component: resolve => require(['@/views/common/configureChannel/configureChannelList'], resolve)
  },
  {
    path: '/home/configureChannelEdit', // 编辑
    name: 'configureChannelEdit',
    component: resolve => require(['@/views/common/configureChannel/configureChannelEdit'], resolve)
  },
  // 自定义模板
  {
    path: '/home/templateList', // 列表页
    name: 'businessMould',
    component: resolve => require(['@/views/common/template/TemplateList'], resolve)
  },
  {
    path: '/home/templateForm', // 表单页
    name: 'templateForm',
    component: resolve => require(['@/views/common/template/TemplateForm'], resolve)
  },
  // 菜单管理
  {
    path: '/home/menuList', // 列表页
    name: 'menu',
    component: resolve => require(['@/views/common/menu/MenuList'], resolve)
  },
  {
    path: '/home/menuForm', // 表单页
    name: 'menuForm',
    component: resolve => require(['@/views/common/menu/MenuForm'], resolve)
  },
  {
    path: '/home/menuFormTree', // 菜单层级 - 编辑
    name: 'menuFormTree',
    component: resolve => require(['@/views/common/menu/MenuFormTree'], resolve)
  },
  // 资源管理
  {
    path: '/home/resourceList', // 列表页
    name: 'resource',
    component: resolve => require(['@/views/common/resource/ResourceList'], resolve)
  },
  {
    path: '/home/resourceForm', // 表单页
    name: 'resourceForm',
    component: resolve => require(['@/views/common/resource/ResourceForm'], resolve)
  },
  {
    path: '/home/resourceFormImport', // 导入
    name: 'resourceFormImport',
    component: resolve => require(['@/views/common/resource/ResourceFormImport'], resolve)
  },
  // 自定义表单
  {
    path: '/home/customFormList', // 列表页
    name: 'customForm',
    component: resolve => require(['@/views/common/customForm/CustomFormList'], resolve)
  },
  {
    path: '/home/customFormFormSubmit', // 表单子表新增页
    name: 'customFormFormSubmit',
    component: resolve => require(['@/views/common/customForm/CustomFormFormSubmit'], resolve)
  },
  {
    path: '/home/customFormForm', // 表单页
    name: 'customFormForm',
    component: resolve => require(['@/views/common/customForm/CustomFormForm'], resolve)
  },
  {
    path: '/home/customFormRecordList', // 表单记录 - 列表页
    name: 'customFormRecordList',
    component: resolve => require(['@/views/common/customForm/CustomFormRecordList'], resolve)
  },
  {
    path: '/home/customFormRecordForm', // 表单记录 - 详情页
    name: 'customFormRecordForm',
    component: resolve => require(['@/views/common/customForm/CustomFormRecordForm'], resolve)
  },
  {
    path: '/home/customFormRecordFormSubmit', // 表单记录 - 新增
    name: 'customFormRecordFormSubmit',
    component: resolve => require(['@/views/common/customForm/CustomFormRecordFormSubmit'], resolve)
  },
  {
    path: '/home/customFormRecordFormApproval', // 表单记录 - 审批
    name: 'customFormRecordFormApproval',
    component: resolve => require(['@/views/common/customForm/CustomFormRecordFormApproval'], resolve)
  },
  // 自定义流程
  {
    path: '/home/workFlowList', // 列表页
    name: 'workFlow',
    component: resolve => require(['@/views/common/workFlow/WorkFlowList'], resolve)
  },
  {
    path: '/home/workFlowForm', // 表单页
    name: 'workFlowForm',
    component: resolve => require(['@/views/common/workFlow/WorkFlowForm'], resolve)
  },
  // 我的待办
  {
    path: '/home/myToDoList', // 列表页
    name: 'myToDo',
    component: resolve => require(['@/views/common/myToDo/MyToDoList'], resolve)
  },
  // 应用包
  {
    path: '/home/applicationPackage', // 列表页
    name: 'applicationPackage',
    component: resolve => require(['@/views/common/applicationPackage/ApplicationPackageList'], resolve)
  },
  {
    path: '/home/applicationPackageForm', // 列表页
    name: 'applicationPackageForm',
    component: resolve => require(['@/views/common/applicationPackage/applicationPackageForm'], resolve)
  },
  {
    path: '/home/importBuildingMgter', // 导入楼宇管理员
    name: 'importBuildingMgter',
    component: resolve => require(['@/views/common/accountMgr/ImportBuildingMgter'], resolve)
  },
  {
    path: '/home/scheduleMgter', // 排班设置
    name: 'scheduleMgter',
    component: resolve => require(['@/views/common/accountMgr/scheduleMgter'], resolve)
  },
  {
    path: '/home/myToDoList', // 导入门岗
    name: 'importDoorer',
    component: resolve => require(['@/views/common/accountMgr/ImportDoorer'], resolve)
  },
  // 数据字典模块
  {
    path: '/home/dataDictionaryList', // 【数据字典--列表页】 【特殊编码：dataDictionaryList】
    name: 'dataDictionaryList',
    component: resolve => require(['@/views/common/dataDictionary/DataDictionaryList'], resolve)
  },
  {
    path: '/home/dataDictionaryOptionList', // 数据字典--选项列--表页
    name: 'dataDictionaryOptionList',
    component: resolve => require(['@/views/common/dataDictionary/DataDictionaryOptionList'], resolve)
  },
  {
    path: '/home/dataDictionaryOptionForm', // 数据字典--选项--表单页
    name: 'dataDictionaryOptionForm',
    component: resolve => require(['@/views/common/dataDictionary/DataDictionaryOptionForm'], resolve)
  },
  {
    path: '/home/MenuGroup', // 菜单组
    name: 'menuGroup',
    // component: resolve => require(['@/views/common/menuGroup/MenuGroup'], resolve)
    component: resolve => require(['@/views/common/menuGroupNew/index'], resolve)
  },
  // 客户管理
  {
    path: '/home/customerManageList', // 客户管理-列表页
    name: 'customerManageList',
    component: resolve => require(['@/views/common/customerManage/CustomerManageList'], resolve)
  },
  {
    path: '/home/customerManageForm', // 客户管理-表单页
    name: 'customerManageForm',
    component: resolve => require(['@/views/common/customerManage/CustomerManageForm'], resolve)
  },
  // 平台知识库
  {
    path: '/home/knowledgeLibraryManageForm', // 问答管理-表单
    name: 'knowledgeLibraryManageForm',
    component: resolve => require(['@/views/common/knowledgeLibraryManage/KnowledgeLibraryManageForm'], resolve)
  },
  {
    path: '/home/knowledgeLibraryManageList', // 问答管理-列表页
    name: 'knowledgeLibraryManageList',
    component: resolve => require(['@/views/common/knowledgeLibraryManage/KnowledgeLibraryManageList'], resolve)
  },
  {
    path: '/home/knowledgeLibraryManageView', // 问答管理-查看
    name: 'knowledgeLibraryManageView',
    component: resolve => require(['@/views/common/knowledgeLibraryManage/KnowledgeLibraryManageView'], resolve)
  },

  // 薪资信息
  {
    path: '/home/salaryInfoList', // 薪资信息-列表页
    name: 'salaryInfoList',
    component: resolve => require(['@/views/common/salaryInfo/SalaryInfoList'], resolve)
  },
  {
    path: '/home/salaryInfoForm', // 薪资信息-表单页
    name: 'salaryInfoForm',
    component: resolve => require(['@/views/common/salaryInfo/SalaryInfoForm'], resolve)
  },
  {
    path: '/home/salaryInfoDetail', // 薪资信息-详情页
    name: 'salaryInfoDetail',
    component: resolve => require(['@/views/common/salaryInfo/SalaryInfoDetail'], resolve)
  },
  // 员工管理-基本信息
  {
    path: '/home/staffManageList', // 基本信息-列表页
    name: 'staffManageList',
    component: resolve => require(['@/views/common/staffManage/StaffManageList'], resolve)
  },
  {
    path: '/home/staffManageForm', // 基本信息-表单页
    name: 'staffManageForm',
    component: resolve => require(['@/views/common/staffManage/StaffManageForm'], resolve)
  },
  {
    path: '/home/staffMoreList', // 基本信息-更多页
    name: 'staffMoreList',
    component: resolve => require(['@/views/common/staffManage/StaffMoreList'], resolve)
  },
  {
    path: '/home/staffManageDetail', // 基本信息-详情页
    name: 'staffManageDetail',
    component: resolve => require(['@/views/common/staffManage/StaffManageDetail'], resolve)
  },

  // 通用中心 - 积分管理
  {
    path: '/home/customIntegraRules', // 积分管理-自定义积分规则
    name: 'customIntegraRules',
    component: resolve => require(['@/views/common/integraManage/CustomIntegraRules'], resolve)
  },
  {
    path: '/home/groupIntegraInfo', // 积分管理-自定义积分规则
    name: 'groupIntegraInfo',
    component: resolve => require(['@/views/common/integraManage/GroupIntegraInfo'], resolve)
  },
  {
    path: '/home/customIntegraRulesForm', // 积分管理-自定义积分规则
    name: 'customIntegraRulesForm',
    component: resolve => require(['@/views/common/integraManage/CustomIntegraRulesForm'], resolve)
  },
  {
    path: '/home/systemIntegraRulesForm', // 积分管理-自定义积分规则
    name: 'systemIntegraRulesForm',
    component: resolve => require(['@/views/common/integraManage/SystemIntegraRulesForm'], resolve)
  },
  //GroupIntegraInfo CustomIntegraRules
  {
    path: '/home/integraSendRecords', // 积分管理-积分发放记录
    name: 'integraSendRecords',
    component: resolve => require(['@/views/common/integraManage/IntegraSendRecords'], resolve)
  },
  {
    path: '/home/integraExpendRecords', // 积分管理-积分消耗记录
    name: 'integraExpendRecords',
    component: resolve => require(['@/views/common/integraManage/IntegraExpendRecords'], resolve)
  },
  {
    path: '/home/personalIntegraLists', // 积分管理-个人积分列表
    name: 'personalIntegraLists',
    component: resolve => require(['@/views/common/integraManage/PersonalIntegraLists'], resolve)
  },
  {
    path: '/home/personalIntegraDetail', // 积分管理-个人积分详情
    name: 'personalIntegraDetail',
    component: resolve => require(['@/views/common/integraManage/PersonalIntegraDetail'], resolve)
  },
  // 积分商品--复制bussiness模块修改
  {
    path: '/home/integralProductList', // 积分商品列表页
    name: 'integralProductList',
    component: resolve => require(['@/views/common/integralGoods/IntegralGoodsList'], resolve)
  },
  {
    path: '/home/integralProductForm', // 积分商品Form页
    name: 'integralProductForm',
    component: resolve => require(['@/views/common/integralGoods/IntegralGoodsForm'], resolve)
  },
  // 积分订单--复制bussiness模块修改
  {
    path: '/home/integralOrderList', // 列表页
    name: 'integralOrderList',
    component: resolve => require(['@/views/common/bonusOrder/BonusOrderList'], resolve)
  },
  {
    path: '/home/integralOrderForm', // 列表页
    name: 'integralOrderForm',
    component: resolve => require(['@/views/common/bonusOrder/BonusOrderForm'], resolve)
  },


  // TODO 平台组织
  {
    path: '/home/plateformOrganizeList', // 列表页
    name: 'plateformOrganizeList',
    component: resolve => require(['@/views/common/plateformOrganize/PlateformOrganizeList'], resolve)
  },
  {
    path: '/home/plateformOrganizeForm', // 表单页
    name: 'plateformOrganizeForm',
    component: resolve => require(['@/views/common/plateformOrganize/PlateformOrganizeForm'], resolve)
  },
  //组织用户详情
  {
    path: '/home/organizeUserDeatil', // 列表页
    name: 'organizeUserDeatil',
    component: resolve => require(['@/views/common/plateformOrganize/OrganizeUserDeatil'], resolve)
  },
  // TODO 租户组织
  {
    path: '/home/tenantOrganizeList', // 列表页
    name: 'tenantOrganizeList',
    component: resolve => require(['@/views/common/tenantOrganize/TenantOrganizeList'], resolve)
  },
  {
    path: '/home/tenantOrganizeForm', // 表单页
    name: 'tenantOrganizeForm',
    component: resolve => require(['@/views/common/tenantOrganize/TenantOrganizeForm'], resolve)
  },
  {
    path: '/home/TenantTrySetForm', // 试用设置
    name: 'tenantTrySetForm',
    component: resolve => require(['@/views/common/tenantOrganize/TenantTrySetForm'], resolve)
  },
  // TODO 菜单名称
  {
    path: '/home/businessOrganizeList', // 列表页
    name: 'businessOrganize',
    component: resolve => require(['@/views/common/businessOrganize/BusinessOrganizeList'], resolve)
  },
  {
    path: '/home/businessOrganizeForm', // 表单页
    name: 'businessOrganizeForm',
    component: resolve => require(['@/views/common/businessOrganize/BusinessOrganizeForm'], resolve)
  },
  {
    path: '/home/batchAddUserList',
    name: 'batchAddUserList',
    component: resolve => require(['@/views/common/plateformOrganize/BatchAddUserList'], resolve)
  }
]

export default routes
