// 查询应用列表
const getSubjectUrl = `${API_CONFIG.controlBaseURL}subjectCommonShu/getSectionList`
// 查询应用select2
const subjectUrl = `${API_CONFIG.shopBaseURL}skipLifeShu/skipSubjectCommonSelectNew`
// 查询门店select2
const storeUrl = `${API_CONFIG.shopBaseURL}skipLifeShu/skipStoreSelectWithoutCommunity`
// 查询门店商品select2
const productUrl = `${API_CONFIG.shopBaseURL}skipLifeShu/skipStoreProductSelectWithoutCommunity`
// 查询秒杀select2
const discountUrl = `${API_CONFIG.shopBaseURL}discountTopic/getSecKillWithoutCommunity`
// 查询促销专题select2
const topicUrl = `${API_CONFIG.shopBaseURL}discountTopic/getCommunityCommonTopicWithoutCommunity`
// 查询资讯select2
const informationUrl = `${API_CONFIG.butlerBaseURL}information/getKnowledgeInfoWithoutCommunity`
// 查询帖子select2
const postUrl = `${API_CONFIG.butlerBaseURL}shu/neighborTabloid/topic/search`
// 查询社群select2
const groupUrl = `${API_CONFIG.butlerBaseURL}shu/neighborTabloid/group/search`
// 查询社区活动select2
const activityUrl = `${API_CONFIG.butlerBaseURL}activity/getActivityWithoutCommunity`
// 查询问卷调查select2
const surveyUrl = `${API_CONFIG.butlerBaseURL}shu/vote/search`
// 查询自治规约select2
const ruleUrl = `${API_CONFIG.butlerBaseURL}shu/neighborTabloid/self/rule/search`
// 查询自治表决select2
const voteUrl = `${API_CONFIG.butlerBaseURL}shu/vote/search`
// 查询社区工作室select2
const workRoomUrl = `${API_CONFIG.butlerBaseURL}shu/neighborTabloid/work/room/search`
export {
  subjectUrl,
  getSubjectUrl,
  storeUrl,
  productUrl,
  discountUrl,
  topicUrl,
  informationUrl,
  postUrl,
  groupUrl,
  activityUrl,
  surveyUrl,
  ruleUrl,
  voteUrl,
  workRoomUrl
}
