var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-control v-cascader-wrapper" },
    [
      _c("el-cascader", {
        style: { width: _vm.width + "px" },
        attrs: {
          "popper-class": _vm.extraClass,
          clearable: _vm.clearable,
          options: _vm.sourceOps,
          props: _vm.props,
          placeholder: _vm.placeholder,
          disabled: _vm.disabled,
          "expand-trigger": _vm.expandTrigger,
          filterable: _vm.filterable,
          "change-on-select": _vm.changeOnSelect,
          separator: _vm.separator,
        },
        on: {
          change: _vm.change,
          "active-item-change": _vm.activeItemChange,
          "visible-change": _vm.visibleChange,
        },
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }