const routes = [
  // 导出中心
  {
    path: '/home/dataExportCenterList', // 列表页
    name: 'exportcentre0',
    component: resolve => require(['@/views/data/dataExportCenter/DataExportCenterList'], resolve)
  },
  // 我的导出
  {
    path: '/home/myExportCenterList', // 列表页
    name: 'exportcentre1',
    component: resolve => require(['@/views/data/dataExportCenter/DataExportMyList'], resolve)
  },

  // 季度数据
  {
    path: '/home/reportSeasonDataList', // 列表页
    name: 'assessmentReport3',
    component: resolve => require(['@/views/data/reportSeasonData/ReportSeasonDataList'], resolve)
  },
  // 月数据
  {
    path: '/home/reportMonthDataList', // 列表页
    name: 'assessmentReport2',
    component: resolve => require(['@/views/data/reportMonthData/ReportMonthDataList'], resolve)
  },
  // 活跃率
  {
    path: '/home/activationRateList', // 列表页
    name: 'assessmentReport4',
    component: resolve => require(['@/views/data/activationRate/ActivationRateList'], resolve)
  },
  // APP注册审核及时率
  {
    path: '/home/registrationAuditList', // 列表页
    name: 'assessmentReport6',
    component: resolve => require(['@/views/data/registrationAudit/RegistrationAuditList'], resolve)
  },
  // 实名及时审核率
  {
    path: '/home/realNameAuditingRateList', // 列表页
    name: 'assessmentReport5',
    component: resolve =>
      require(['@/views/data/realNameAuditingRate/RealNameAuditingRateList'], resolve)
  },
  // 指标配置列表
  {
    path: '/home/kpiManagementList', // 列表页
    name: 'kpiManagement1',
    component: resolve => require(['@/views/data/kpiManagement/KpiManagementList'], resolve)
  },
  // 指标配置表单
  {
    path: '/home/kpiManagementForm', // 列表页
    name: 'kpiManagementForm',
    component: resolve => require(['@/views/data/kpiManagement/KpiManagementForm'], resolve)
  },
  // 人员进出记录
  {
    path: '/home/personPassRecordList', // 人员进出记录--列表页
    name: 'userPassageRecordAction!list.action1',
    component: resolve => require(['@/views/data/personPassRecord/PersonPassRecordList'], resolve)
  },
  // 访客记录
  {
    path: '/home/visitorRecordList', // 访客记录--列表页
    name: 'visitorAction!toVisitor.action1',
    component: resolve => require(['@/views/data/visitorRecord/VisitorRecordList'], resolve)
  },
  // 商家结算
  {
    path: '/home/businessClearingList', // 商家结算--列表页
    name: 'orderAction!orderClearing.action1',
    component: resolve => require(['@/views/data/businessClearing/BusinessClearingList'], resolve)
  },
  {
    path: '/home/businessClearingForm', // 商家结算--表单页
    name: 'businessClearingForm',
    component: resolve => require(['@/views/data/businessClearing/BusinessClearingForm'], resolve)
  },
  // 电子发票
  {
    path: '/home/electronicInvoiceList', // 电子发票--列表页
    name: 'orderInvoiceAction!list.action1',
    component: resolve => require(['@/views/data/electronicInvoice/ElectronicInvoiceList'], resolve)
  },
  // TODO 共享停车收入
  {
    path: '/home/statisticsParkList', // 列表页
    name: 'parkingReceive1',
    component: resolve => require(['@/views/data/statisticsPark/StatisticsParkList'], resolve)
  },

  // TODO 商业板块统计
  {
    path: '/home/statisticsBussinessList', // 列表页
    name: 'busniessStatistics2',
    component: resolve => require(['@/views/data/statisticsBussiness/StatisticsBussinessList'], resolve)
  },
  // TODO 分公司商业报表
  {
    path: '/home/operatingReportsList', // 列表页
    name: 'operatingReports1',
    component: resolve => require(['@/views/data/operatingReports/OperatingReportsList'], resolve)
  },


  // TODO 每日汇总
  {
    path: '/home/dailySummaryList', // 列表页
    name: 'smsAction!list.action1',
    component: resolve => require(['@/views/data/dailySummary/DailySummaryList'], resolve)
  },
  // TODO 短信详情
  {
    path: '/home/messageDetailList', // 列表页
    name: 'smsAction!list.action2',
    component: resolve => require(['@/views/data/messageDetail/MessageDetailList'], resolve)
  },
  // 员工进场报表
  {
    path: '/home/employeeApproach', // 列表页
    name: 'employeeApproach1',
    component: resolve => require(['@/views/data/employeeApproach/employeeApproach'], resolve)
  },
  // 商写项目统计
  {
    path: '/home/businessProjectEmergency', // 列表页
    name: 'businessProjectEmergency1',
    component: resolve => require(['@/views/data/businessProjectEmergency/businessProjectEmergency'], resolve)
  },
  // 员工健康报表
  {
    path: '/home/employeeHealth', // 列表页
    name: 'employeeHealth1',
    component: resolve => require(['@/views/data/employeeHealth/employeeHealth'], resolve)
  },
  // 访客进场报表
  {
    path: '/home/visitorApproach', // 列表页
    name: 'visitorApproach1',
    component: resolve => require(['@/views/data/visitorApproach/visitorApproach'], resolve)
  },
  // 员工健康汇总
  {
    path: '/home/resumptionWork', // 列表页
    name: 'resumptionWork1',
    component: resolve => require(['@/views/data/resumptionWork/resumptionWork'], resolve)
  },
  // 企业复工报表
  {
    path: '/home/enterpriseRework', // 列表页
    name: 'corpResumptionWork1',
    component: resolve => require(['@/views/data/enterpriseRework/enterpriseRework'], resolve)
  },
  // 大数据配置
  {
    path: '/home/bigView', // 列表页
    name: 'bigView1',
    component: resolve => require(['@/views/data/bigView/BigViewList'], resolve)
  },

]
export default routes
