var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.classification.type === "sub"
      ? _c(
          "div",
          {
            staticClass: "jumpResult",
            attrs: {
              pageId: _vm.skipData.pageId,
              detailId: _vm.skipData.detailId,
              url: _vm.skipData.url,
              pageType: _vm.skipData.pageType,
              isJoint: _vm.skipData.isJoint,
              H5NeedAuthorize: _vm.skipData.H5NeedAuthorize,
            },
          },
          [
            _c("div", { staticClass: "jumpService jumpBorder" }, [
              _c("span", { staticClass: "serviceIcon" }, [
                _c("img", { attrs: { src: _vm.selectedObject.subImg } }),
              ]),
              _c("div", { staticClass: "serviceInfo" }, [
                _c("span", { staticClass: "serviceTitle" }, [
                  _vm._v(" " + _vm._s(_vm.selectedObject.subName)),
                ]),
                _c("span", { staticClass: "ui-nowrap-1" }, [
                  _vm._v("服务简介:" + _vm._s(_vm.selectedObject.subNote)),
                ]),
              ]),
              _vm._m(0),
            ]),
          ]
        )
      : _vm.classification.type === "20000"
      ? _c(
          "div",
          {
            staticClass: "jumpResult",
            attrs: {
              pageId: _vm.skipData.pageId,
              detailId: _vm.skipData.detailId,
              url: _vm.skipData.url,
              pageType: _vm.skipData.pageType,
              isJoint: _vm.skipData.isJoint,
              H5NeedAuthorize: _vm.skipData.H5NeedAuthorize,
            },
          },
          [
            _c("div", { staticClass: "jumpStore" }, [
              _c("div", { staticClass: "body-wrapper" }, [
                _c("div", { staticClass: "left" }, [
                  _c("img", {
                    staticClass: "img",
                    attrs: { src: _vm.selectedObject.storeLogo, alt: "" },
                  }),
                ]),
                _c("div", { staticClass: "right" }, [
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.selectedObject.name)),
                    ]),
                    _c("div", { staticClass: "star-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "rate-wrapper" },
                        _vm._l(5, function (star, index) {
                          return _c(
                            "span",
                            {
                              key: index,
                              class: index < 5 ? "activeColor" : "starColor",
                            },
                            [_vm._v(" ★ ")]
                          )
                        }),
                        0
                      ),
                      _c("div", { staticClass: "point-value" }, [
                        _vm._v(_vm._s(_vm.selectedObject.overallScore)),
                      ]),
                    ]),
                    _c("div", { staticClass: "description" }, [
                      _vm._v(_vm._s(_vm.selectedObject.storeNotice)),
                    ]),
                  ]),
                  _c(
                    "button",
                    { staticClass: "btn store-btn", attrs: { type: "button" } },
                    [_vm._v("进店看看")]
                  ),
                ]),
              ]),
            ]),
          ]
        )
      : _vm.classification.type === "20001"
      ? _c(
          "div",
          {
            staticClass: "jumpResult",
            attrs: {
              pageId: _vm.skipData.pageId,
              detailId: _vm.skipData.detailId,
              url: _vm.skipData.url,
              pageType: _vm.skipData.pageType,
              isJoint: _vm.skipData.isJoint,
              H5NeedAuthorize: _vm.skipData.H5NeedAuthorize,
            },
          },
          [
            _c("div", { staticClass: "jumpProduct" }, [
              _c("div", { staticClass: "body-wrapper" }, [
                _c("div", { staticClass: "left" }, [
                  _c("img", {
                    staticClass: "img",
                    attrs: { src: _vm.selectedObject.productImg, alt: "" },
                  }),
                ]),
                _c("div", { staticClass: "right" }, [
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(" " + _vm._s(_vm.selectedObject.name)),
                    ]),
                    _c("div", { staticClass: "price-wrapper" }, [
                      _c("div", { staticClass: "symbol" }, [_vm._v("¥")]),
                      _c("div", { staticClass: "int-part" }, [
                        _vm._v(_vm._s(_vm.selectedObject.price)),
                      ]),
                      _c("div", { staticClass: "dot-part" }, [
                        _vm._v(_vm._s(_vm.selectedObject.price_dec)),
                      ]),
                    ]),
                    _c("div", {
                      staticClass: "description",
                      domProps: {
                        innerHTML: _vm._s(_vm.selectedObject.goodsDes),
                      },
                    }),
                    _c("div", { staticClass: "sold-count" }, [
                      _vm._v("\n              已售\n              "),
                      _c("div", { staticClass: "number" }, [
                        _vm._v(_vm._s(_vm.selectedObject.sold)),
                      ]),
                    ]),
                  ]),
                  _c(
                    "button",
                    { staticClass: "btn buy-btn", attrs: { type: "button" } },
                    [_vm._v("点击购买")]
                  ),
                ]),
              ]),
            ]),
          ]
        )
      : _vm.classification.type === "20019"
      ? _c(
          "div",
          {
            staticClass: "jumpResult",
            attrs: {
              pageId: _vm.skipData.pageId,
              detailId: _vm.skipData.detailId,
              url: _vm.skipData.url,
              pageType: _vm.skipData.pageType,
              isJoint: _vm.skipData.isJoint,
              H5NeedAuthorize: _vm.skipData.H5NeedAuthorize,
            },
          },
          [
            _c("div", { staticClass: "jumpSecKill" }, [
              _c("div", { staticClass: "body-wrapper" }, [
                _c("div", { staticClass: "top" }, [
                  _c("div", { staticClass: "title-wrapper" }, [
                    _c("span", { staticClass: "title" }, [_vm._v("限时秒杀")]),
                    _c(
                      "div",
                      {
                        staticClass: "status",
                        attrs: {
                          "data-bind":
                            "text: localData.customObj.secKillObj.statusStr",
                        },
                      },
                      [_vm._v(_vm._s(_vm.selectedObject.statusStr))]
                    ),
                  ]),
                  _vm.selectedObject.disable
                    ? _c("div", { staticClass: "time-wrapper" }, [
                        _c("div", { staticClass: "cutTime" }, [
                          _c(
                            "span",
                            {
                              staticClass: "countdown",
                              attrs: {
                                "data-bind":
                                  "css: {disabled: localData.customObj.secKillObj.disable}",
                              },
                            },
                            [_vm._v(_vm._s(_vm.selectedObject.month))]
                          ),
                          _c("span", { staticClass: "day" }, [_vm._v("月")]),
                          _c(
                            "span",
                            {
                              staticClass: "countdown",
                              attrs: {
                                "data-bind":
                                  "css: {disabled: localData.customObj.secKillObj.disable}",
                              },
                            },
                            [_vm._v(_vm._s(_vm.selectedObject.day))]
                          ),
                          _c("span", { staticClass: "day" }, [_vm._v("日")]),
                          _c(
                            "span",
                            {
                              staticClass: "countdown",
                              attrs: {
                                "data-bind":
                                  "css: {disabled: localData.customObj.secKillObj.disable}",
                              },
                            },
                            [_vm._v(_vm._s(_vm.selectedObject.hour))]
                          ),
                          _c("span", { staticClass: "colon" }, [_vm._v(":")]),
                          _c(
                            "span",
                            {
                              staticClass: "countdown",
                              attrs: {
                                "data-bind":
                                  "css: {disabled: localData.customObj.secKillObj.disable}",
                              },
                            },
                            [_vm._v(_vm._s(_vm.selectedObject.minute))]
                          ),
                        ]),
                        _c("div", { staticClass: "status" }, [
                          _vm._v(_vm._s(_vm.selectedObject.leftTimeTips)),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "product-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "swiper-container" },
                    _vm._l(
                      _vm.selectedObject.productList,
                      function (product, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "swiper-wrapper",
                            staticStyle: { display: "flex", overflow: "auto" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "product-item swiper-slide" },
                              [
                                _c("img", {
                                  staticClass: "img",
                                  attrs: {
                                    "data-bind": "attr: {src: productPic}",
                                    src: product.productPic,
                                  },
                                }),
                                _c("span", { staticClass: "name nowrap" }, [
                                  _c("b", [_vm._v(_vm._s(product.name))]),
                                ]),
                                _c("div", { staticClass: "real-price" }, [
                                  _vm._m(1, true),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "price",
                                      attrs: { "data-bind": "text: price" },
                                    },
                                    [_vm._v(_vm._s(product.price))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "price_dec",
                                      attrs: {
                                        "data-bind": "text: '.' + price_dec",
                                      },
                                    },
                                    [_vm._v("." + _vm._s(product.price_dec))]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "old-price",
                                    attrs: {
                                      "data-bind": "text: '¥' + normalPrice",
                                    },
                                  },
                                  [_vm._v(" ¥ " + _vm._s(product.normalPrice))]
                                ),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
              ]),
            ]),
          ]
        )
      : _vm.classification.type === "20002"
      ? _c(
          "div",
          {
            staticClass: "jumpResult",
            attrs: {
              pageId: _vm.skipData.pageId,
              detailId: _vm.skipData.detailId,
              url: _vm.skipData.url,
              pageType: _vm.skipData.pageType,
              isJoint: _vm.skipData.isJoint,
              H5NeedAuthorize: _vm.skipData.H5NeedAuthorize,
            },
          },
          [
            _c("div", { staticClass: "jumpPromotion" }, [
              _c("img", {
                attrs: { src: _vm.selectedObject.promotionCoverImg },
              }),
            ]),
          ]
        )
      : _vm.classification.type === "20012"
      ? _c(
          "div",
          {
            staticClass: "jumpResult",
            attrs: {
              pageId: _vm.skipData.pageId,
              detailId: _vm.skipData.detailId,
              url: _vm.skipData.url,
              pageType: _vm.skipData.pageType,
              isJoint: _vm.skipData.isJoint,
              H5NeedAuthorize: _vm.skipData.H5NeedAuthorize,
            },
          },
          [
            _c("div", { staticClass: "jumpInformation" }, [
              _vm.selectedObject.picUrl
                ? _c("div", { staticClass: "body-wrapper" }, [
                    _c("div", { staticClass: "top" }, [
                      _c("div", { staticClass: "left" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.selectedObject.title)),
                        ]),
                        _c("div", { staticClass: "description" }, [
                          _vm._v(_vm._s(_vm.selectedObject.nonHTMLContent)),
                        ]),
                      ]),
                      _c("img", {
                        staticClass: "img",
                        attrs: { src: _vm.selectedObject.picUrl, alt: "" },
                      }),
                    ]),
                    _c("div", { staticClass: "bottom" }, [
                      _c("div", { staticClass: "content" }, [
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.selectedObject.type)),
                        ]),
                        _c("div", { staticClass: "line" }),
                        _c("div", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.selectedObject.cardTime)),
                        ]),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "btn information-btn",
                          attrs: { type: "button" },
                        },
                        [_vm._v("进入")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.selectedObject.picUrl === ""
                ? _c("div", { staticClass: "body-wrapper" }, [
                    _c("div", { staticClass: "top" }, [
                      _c("div", { staticClass: "left" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.selectedObject.title)),
                        ]),
                        _c("div", { staticClass: "description" }, [
                          _vm._v(_vm._s(_vm.selectedObject.nonHTMLContent)),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "bottom" }, [
                      _c("div", { staticClass: "content" }, [
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.selectedObject.type)),
                        ]),
                        _c("div", { staticClass: "line" }),
                        _c("div", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.selectedObject.cardTime)),
                        ]),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "btn information-btn",
                          attrs: { type: "button" },
                        },
                        [_vm._v("进入")]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]
        )
      : _vm.classification.type === "20005" ||
        _vm.classification.type === "20006" ||
        _vm.classification.type === "20003" ||
        _vm.classification.type === "20004" ||
        _vm.classification.type === "20007"
      ? _c(
          "div",
          {
            staticClass: "jumpResult",
            attrs: {
              pageId: _vm.skipData.pageId,
              detailId: _vm.skipData.detailId,
              url: _vm.skipData.url,
              pageType: _vm.skipData.pageType,
              isJoint: _vm.skipData.isJoint,
              H5NeedAuthorize: _vm.skipData.H5NeedAuthorize,
            },
          },
          [
            _c("div", { staticClass: "jumpPost jumpBorder" }, [
              _c("div", { staticClass: "postInfo" }, [
                _vm.selectedObject.leftImg
                  ? _c("div", { staticClass: "leftImg" }, [
                      _c("img", { attrs: { src: _vm.selectedObject.leftImg } }),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "middleInfo" }, [
                  _c("span", { staticClass: "info-title ui-nowrap-1" }, [
                    _vm._v(" " + _vm._s(_vm.selectedObject.name)),
                  ]),
                  _c("span", { staticClass: "info-introduce ui-nowrap-2" }, [
                    _vm._v(_vm._s(_vm.selectedObject.note)),
                  ]),
                ]),
                _vm.selectedObject.rightImg
                  ? _c("div", { staticClass: "rightImg" }, [
                      _c("img", {
                        attrs: { src: _vm.selectedObject.rightImg },
                      }),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "card-buttom" }, [
                _c("div", { staticClass: "card-buttom-left" }, [
                  _c("span", [_vm._v(_vm._s(_vm.selectedObject.cardType))]),
                  _c("span", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selectedObject.cardTime,
                        expression: "selectedObject.cardTime",
                      },
                    ],
                    staticClass: "postline",
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.selectedObject.cardTime))]),
                ]),
                _c("span", { staticClass: "cardbtn" }, [_vm._v("进入")]),
              ]),
            ]),
          ]
        )
      : _vm.classification.type === "30031"
      ? _c(
          "div",
          {
            staticClass: "jumpResult",
            attrs: {
              pageId: _vm.skipData.pageId,
              detailId: _vm.skipData.detailId,
              url: _vm.skipData.url,
              pageType: _vm.skipData.pageType,
              isJoint: _vm.skipData.isJoint,
              H5NeedAuthorize: _vm.skipData.H5NeedAuthorize,
            },
          },
          [
            _c("div", { staticClass: "jumpActivity" }, [
              _c("div", { staticClass: "body-wrapper" }, [
                _c("div", { staticClass: "top" }, [
                  _c("img", {
                    staticClass: "img",
                    attrs: { src: _vm.selectedObject.activityPicture, alt: "" },
                  }),
                ]),
                _c("div", { staticClass: "bottom" }, [
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.selectedObject.activityName)),
                    ]),
                    _c("div", { staticClass: "description" }, [
                      _c("div", { staticClass: "name" }, [_vm._v("小区活动")]),
                      _c("div", { staticClass: "line" }),
                      _c("div", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm.selectedObject.activityTimeTag)),
                      ]),
                    ]),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn activity-btn",
                      attrs: { type: "button" },
                    },
                    [_vm._v("我要报名")]
                  ),
                ]),
              ]),
            ]),
          ]
        )
      : _vm.classification.type === "109"
      ? _c(
          "div",
          {
            staticClass: "jumpResult",
            attrs: {
              pageId: _vm.skipData.pageId,
              detailId: _vm.skipData.detailId,
              url: _vm.skipData.url,
              pageType: _vm.skipData.pageType,
              isJoint: _vm.skipData.isJoint,
              H5NeedAuthorize: _vm.skipData.H5NeedAuthorize,
            },
          },
          [
            _c("div", { staticClass: "jumpQuestionare" }, [
              _c("div", { staticClass: "body-wrapper" }, [
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.selectedObject.name)),
                  ]),
                  _c("div", { staticClass: "join-wrapper" }, [
                    _vm._v(
                      "\n            已有" +
                        _vm._s(_vm.selectedObject.joinUserCnt) +
                        "人参与调查\n          "
                    ),
                  ]),
                  _c("div", { staticClass: "description" }, [
                    _c("div", { staticClass: "name" }, [_vm._v("问卷调查")]),
                    _c("div", { staticClass: "line" }),
                    _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm.selectedObject.publishTimeTag)),
                    ]),
                  ]),
                ]),
                _c(
                  "button",
                  { staticClass: "btn vote-btn", attrs: { type: "button" } },
                  [_vm._v("立即投票")]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "servicebuttom" }, [
      _c("span", { staticClass: "cardbtn" }, [_vm._v("进入")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("b", [_vm._v("¥")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }