var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-group-top" },
    [
      _vm.level > 1
        ? _c(
            "div",
            {
              staticClass: "menu-group-item",
              class: _vm.setNameClass(_vm.group),
              on: {
                click: function ($event) {
                  return _vm.itemClick(_vm.group)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "menu-group-item-name",
                  class: _vm.group.urlJumpType < 0 && "is-sub-menu",
                },
                [_vm._v("\n     " + _vm._s(_vm.group.name) + "\n    ")]
              ),
            ]
          )
        : _vm._e(),
      _vm._l(_vm.group.childMenus, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "menu-group-list",
            class:
              item.level == 0
                ? "menu-group-list-root"
                : "menu-group-list-child",
          },
          [
            _c("menu-group-top", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.status &&
                    item.menuType >= 1 &&
                    item.menuType <= 2 &&
                    item.specialNo !== "system_menuGroup",
                  expression:
                    "\n        status &&\n          item.menuType >= 1 &&\n          item.menuType <= 2 &&\n          item.specialNo !== 'system_menuGroup'\n      ",
                },
              ],
              attrs: {
                group: item,
                status: _vm.status,
                centerItem: _vm.centerItem,
                firstItem: _vm.firstItem,
                secondItem: _vm.secondItem,
                thirdItem: _vm.thirdItem,
                activeMenuId: _vm.activeMenuId,
                level: item.level,
              },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }