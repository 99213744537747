const customTable = {
  namespaced: true,
  state: {
    tableData: {}
  },
  mutations: {
    setTableData (state, tableData) {
      state.tableData = tableData
    }
  },
  getters: {
    getTableData: state => state.tableData
  }
}

export default customTable
