var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "manual-list-container" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            "popper-class": "manual-list-popper",
            "popper-options": _vm.popperOptions,
            placement: "bottom-end",
            offset: -16,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "reference",
                fn: function () {
                  return [_vm._t("manual")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        [
          _c("div", {
            staticClass: "header",
            domProps: { textContent: _vm._s(_vm.roleName) },
          }),
          _c(
            "ul",
            { staticClass: "manual-list" },
            _vm._l(_vm.manualList, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "manual-item",
                  on: {
                    click: function ($event) {
                      return _vm.downloadManual(item)
                    },
                  },
                },
                [_vm._v(_vm._s(item.name))]
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }