var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-control v-input-number-container" },
    [
      _vm.label
        ? _c("label", { domProps: { textContent: _vm._s(_vm.label) } })
        : _vm._e(),
      _c("el-input-number", {
        style: { width: _vm.width + "px", textAlign: _vm.textAlign },
        attrs: {
          placeholder: _vm.defaultPlaceholder,
          disabled: _vm.disabled,
          controls: _vm.controls,
          "controls-position": _vm.controlsPosition,
          precision: _vm.precision,
          max: _vm.max,
          min: _vm.min,
          step: _vm.step,
        },
        on: { blur: _vm.onBlur, focus: _vm.onFocus, change: _vm.onChange },
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }