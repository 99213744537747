const routes = [
  {
    path: "/home/sharePage", // 表单页
    name: "sharePage",
    component: (resolve) =>
      require([
        "@/components/sharePage/sharePage",
      ], resolve),
  },
];
export default routes;
