import { render, staticRenderFns } from "./provinces-menu.vue?vue&type=template&id=9956cfe6&scoped=true"
import script from "./provinces-menu.vue?vue&type=script&lang=js"
export * from "./provinces-menu.vue?vue&type=script&lang=js"
import style0 from "./provinces-menu.vue?vue&type=style&index=0&id=9956cfe6&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9956cfe6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/智慧物业-前端-新服务器/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9956cfe6')) {
      api.createRecord('9956cfe6', component.options)
    } else {
      api.reload('9956cfe6', component.options)
    }
    module.hot.accept("./provinces-menu.vue?vue&type=template&id=9956cfe6&scoped=true", function () {
      api.rerender('9956cfe6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/bussiness/provinces-menu/provinces-menu.vue"
export default component.exports