<template>
  <div class="table-panel-wrapper">
    <el-table
      :data="tableData"
      ref="multipleTable"
      v-loading="isSearching"
      :style="tabStyle"
      :header-cell-style="headerCellStyle"
      :border="border || true"
      :height="autoHeight ? undefined : tableHeight"
      :max-height="maxHeight"
      :highlight-current-row="true"
      :row-class-name="tableRowClassName"
      :stripe="stripe"
      :show-header="isShowHeader"
      :span-method="spanMethod"
      @select="select"
      @select-all="selectAll"
      @selection-change="handleSelectionChange"
      @sort-change="sortChange"
      @row-contextmenu="rowContextMenu"
      @cell-click="cellClick"
      @cell-dblclick="cellDbClick"
      @header-dragend="headerDragend"
    >
      <el-table-column
        fixed="left"
        type="selection"
        :selectable="selectable"
        width="55"
        :align="checkAlign"
        v-if="isMultiSelect">
      </el-table-column>
      <!-- 展开列 begin -->
      <el-table-column type="expand" width="55" v-if="hasExpendColumn">
        <template slot-scope="props">
          <slot name="expendSlot" :row="props.row">
          </slot>
        </template>
      </el-table-column>
      <!--展开列 end -->
      <template v-for="(header, index) in headers">
        <el-table-column
          v-if="header.show !== false"
          :key="header.prop + index"
          :sortable="handleSort(header.sort)"
          :fixed="header.fixed"
          :prop="header.prop"
          :label="header.label"
          :width="header.width"
          :min-width="header.minWidth"
          :align="header.align"
          :show-overflow-tooltip="showOverflowTooltip">
          <template slot-scope="scope">
            <div v-if="header.formatter" class="vnode-wrapper">
              {{handleVNodeSlot(`${scope.$index}_${index}_slot`, header.formatter(scope.row, header.prop, scope.$index))}}
              <slot :name="`${scope.$index}_${index}_slot`">
              </slot>
            </div>
            <!--传入自定义VNode-->
            <div v-else-if="scope.row[header.prop] && isVNode(scope.row[header.prop].VNode)" class="vnode-wrapper">
              {{handleVNodeSlot(`${scope.$index}_${index}_slot`, scope.row[header.prop].VNode)}}
              <slot :name="`${scope.$index}_${index}_slot`">
              </slot>
            </div>
            <!--传入html-->
            <!-- <div style="display: inline-block;" v-else-if="scope.row[header.prop] && scope.row[header.prop].html" v-html="scope.row[header.prop].value"></div> -->
            <!--传入自定义组件-->
            <component v-else-if="scope.row[header.prop] && scope.row[header.prop].component" :is="scope.row[header.prop].component" :row="scope.row"></component>
            <!--传入普通文本-->
            <div style="display: inline;" v-else v-text="scope.row[header.prop]"></div>
          </template>
        </el-table-column>
      </template>

      <!-- 操作列 begin -->
      <el-table-column
        :label="operateColumnLabel"
        fixed="right"
        v-if="hasOperateColumn"
        :width="operateColumnWidth"
      >
        <template slot-scope="scope">
          <slot name="operateSlot" :row="scope.row"></slot>
        </template>
      </el-table-column>
      <!--操作列 end -->

      <!-- 占位图 begin -->
      <template #empty>
        <slot name="empty"></slot>
      </template>
      <!-- 占位图 end -->
    </el-table>
  </div>
</template>

<script>
import Vue from 'vue'
import { isVNode } from '../../../common/vdom.js'
import { _localStorage } from 'common/utils'
// @group 业务组件
// @title TablePanel 表格
export default {
  name: 'table-panel',
  components: {},
  props: {
    // 表格样式
    tabStyle: {
      type: Object,
      // `'{}'`
      default: () => {
        return {
          width: '100%'
        }
      }
    },
    // 是否带有纵向边框
    border: {
      type: Boolean,
      default: false
    },
    // Table 的高度，默认为自动高度。如果 height 为 number 类型，单位 px；如果 height 为 string 类型，则这个高度会设置为 Table 的 style.height 的值，Table 的高度会受控于外部样式。
    autoHeight: {
      type: Boolean,
      default: true
    },
    // Table 的最大高度
    maxHeight: {
      type: String
    },
    // 是否为斑马纹 table
    stripe: {
      type: Boolean,
      default: true
    },
    // 是否需要多选列
    isMultiSelect: {
      type: Boolean,
      default: false
    },
    checkAlign: {
      type: String,
      default: 'center'
    },
    // 当内容过长被隐藏时显示 tooltip
    showOverflowTooltip: {
      type: Boolean,
      default: true
    },
    // 仅对 type=selection 的列有效，类型为 Function，Function 的返回值用来决定这一行的 CheckBox 是否可以勾选
    selectable: {
      type: Function
    },
    // 行的 className 的回调方法，也可以使用字符串为所有行设置一个固定的 className
    tableRowClassName: {
      type: Function
    },
    // 不传,没有排序
    // '2' 代表静态排序,
    // '1' 代表服务端排序
    sort: {
      type: String
    },
    // 搜索中的标识
    isSearching: {
      type: Boolean,
      default: false
    },
    // 是否需要操作列
    hasOperateColumn: {
      type: Boolean,
      default: true
    },
    // 是否需要展开列
    hasExpendColumn: {
      type: Boolean,
      default: false
    },
    // 操作列名
    operateColumnLabel: {
      type: String,
      default: '操作'
    },
    // 操作列列宽
    operateColumnWidth: {
      type: String
    },
    // 是否显示表头
    showHeader: {
      type: Boolean,
      default: true
    },
    // 表头数据
    headers: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 列表数据
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 接口请求数据成功后的回调函数，可以对数据做一些预处理，第一个参数为当前行的数据对象，第二个参数为当前行数
    handleData: Function,
    // 合并行或列的计算方法
    spanMethod: Function
  },
  data () {
    return {
      tableHeight: '100%',
      headerCellStyle: {
        userSelect: 'auto'
      },
      selectedData: [],
      listOptionKey: ''
    }
  },
  watch: {
    tableData () {
      this.initSlot()
    },
    headers:{
      handler(val){
        this.$nextTick(()=>{
          this.$refs.multipleTable.layout.updateElsHeight = function(){


            if (!this.table.$ready) return Vue.nextTick(() => this.updateElsHeight());
            const { headerWrapper, appendWrapper, footerWrapper } = this.table.$refs;
            this.appendHeight = appendWrapper ? appendWrapper.offsetHeight : 0;

            if (this.showHeader && !headerWrapper) return;

            // fix issue (https://github.com/ElemeFE/element/pull/16956)
            const headerTrElm = headerWrapper ? headerWrapper.querySelector('.el-table__header tr') : null;
            const noneHeader = this.headerDisplayNone(headerTrElm);
            Vue.nextTick(() =>{
              const headerHeight = this.headerHeight = !this.showHeader ? 0 : headerWrapper.offsetHeight;
              if (this.showHeader && !noneHeader && headerWrapper.offsetWidth > 0 && (this.table.columns || []).length > 0 && headerHeight < 2) {

                return Vue.nextTick(() => this.updateElsHeight());
              }
              const tableHeight = this.tableHeight = this.table.$el.clientHeight;
              const footerHeight = this.footerHeight = footerWrapper ? footerWrapper.offsetHeight : 0;
              if (this.height !== null) {
                this.bodyHeight = tableHeight - headerHeight - footerHeight + (footerWrapper ? 1 : 0);
              }
              this.fixedBodyHeight = this.scrollX ? (this.bodyHeight - this.gutterWidth) : this.bodyHeight;

              const noData = !(this.store.states.data && this.store.states.data.length);
              this.viewportHeight = this.scrollX ? tableHeight - (noData ? 0 : this.gutterWidth) : tableHeight;

              this.updateScrollY();
              this.notifyObservers('scrollable');
            })
          }
          console.log(this.$refs.multipleTable.$refs.headerWrapper.offsetHeight)
        })
      },
      deep:true
    },
  },
  computed: {
    isShowHeader () {
      if (this.showHeader) {
        if (this.headers && this.headers.length) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  created() {
    this.initListOptionKey()
  },
  methods: {
    initSlot () {
      let tableData = this.tableData
      let _this = this
      Array.isArray(tableData) && tableData.forEach((row, rowIndex) => {
        if (_this.handleData && typeof _this.handleData === 'function') {
          _this.handleData(row, rowIndex)
        }
      })
    },
    // 判断传进来的是否VNode
    isVNode (vnode) {
      return isVNode(vnode)
    },
    // 建立VNode节点与slot的绑定关系
    handleVNodeSlot (slotName, VNode) {
      this.$slots[slotName] = VNode
    },
    // @vuese
    // 用于多选表格，切换某一行的选中状态，如果使用了第二个参数，则是设置这一行选中与否（selected 为 true 则选中）
    // @arg (rows, selected)接收两个参数 1.rows 需要切换选择状态的行
    // @arg 2.selected 若为 true 则将rows的选择状态置为选中
    toggleSelection (rows, selected) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row, selected)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    // @vuese
    // 用于多选表格，切换所有行的选中状态
    toggleAllSelection () {
      this.$refs.multipleTable.toggleAllSelection()
    },
    handleSelectionChange (val) {
      this.$set(this, 'selectedData', val)
      // 当选择项发生变化时会触发该事件
      // @arg (val)接收一个参数 1.val 改变后的值
      this.$emit('handleSelectionChange', val)
    },
    // @vuese
    // 获取当前选中的所有项
    getSelectedData () {
      return this.selectedData
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select (selection, row) {
      // 当用户手动勾选数据行的 Checkbox 时触发的事件
      // @arg (selection, row)接收两个参数 1.selection 所有选中行
      // 2.row 选中行的数据
      this.$emit('select', selection, row)
    },
    selectAll (selection) {
      // 当用户手动勾选全选 Checkbox 时触发的事件
      // @arg (selection)接收一个参数 1.selection 所有选中行
      this.$emit('selectAll', selection)
    },
    handleSort (sort) {
      if (sort) {
        if (sort === '1') {
          return 'custom'
        } else if (sort === '2') {
          return ''
        } else {
          return undefined
        }
      } else {
        return undefined
      }
    },
    sortChange (sortObj) {
      let orderBy = sortObj.order
      if (orderBy === 'ascending') {
        orderBy = 'ASC'
      } else {
        orderBy = 'DESC'
      }
      let sort = {
        colName: sortObj.prop,
        orderBy
      }
      this.$emit('sortChange', sort)
    },
    // @vuese
    // 高亮并滚动到选中的某一行
    setCurrentRow (index) {
      let len = this.tableData.length
      let contentHeight = this.$refs.multipleTable.bodyWrapper.scrollHeight
      this.$refs.multipleTable.bodyWrapper.scrollTop = contentHeight / len * index
      this.$refs.multipleTable.setCurrentRow(this.tableData[index])
    },
    // 当某一行被鼠标右键点击时会触发该事件
    rowContextMenu (row, column, event) {
      this.$emit('cellClick', {
        row,
        column,
        event
      })
    },
    // 当某个单元格被点击时会触发该事件
    cellClick (row, column, cell, event) {
      this.$emit('cellClick', {
        row,
        column,
        cell,
        event
      })
    },
    // 当某个单元格被双击击时会触发该事件
    cellDbClick (row, column, cell, event) {
      this.$emit('cellDbClick', {
        row,
        column,
        cell,
        event
      })
    },
    // 初始化key
    initListOptionKey () {
      let path = this.$route.path
      let userId = this.$store.state.userInfo.id
      // 把路径和用户id作为键
      let key = `${encodeURIComponent(path)}_${userId}`
      this.listOptionKey = key
    },
    // 当拖动表头改变了列的宽度的时候会触发该事件
    headerDragend(newWidth, oldWidth, column, event) {
      const optionItem = this.headers.find(item => item.prop === column.property)
      if(optionItem) {
        optionItem.width = newWidth
        this.updateLocalListOptionData(this.headers)
      }
    },
    getLocalListOptionData() {
      let localListOptionData = _localStorage.getItem('menu_list_option')
      if (localListOptionData) {
        localListOptionData = JSON.parse(localListOptionData)
      } else {
        localListOptionData = {}
      }
      return localListOptionData
    },
    // 更新列表选项的本地数据
    updateLocalListOptionData(val) {
      let localData = {}
      localData[this.listOptionKey] = val
      let localListOptionData = this.getLocalListOptionData()
      // 同时,植入到local
      _localStorage.setItem(
          'menu_list_option',
          JSON.stringify(Object.assign(localListOptionData, localData))
      )
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .table-panel-wrapper {
    width: 100%;
    height: 100%;
    .vnode-wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>
