<template>
  <!-- 左边导航 -->
  <div
    v-if="menuGroupType === 'left'"
    class="nav-menu-panel"
    :class="{ open: status }"
    v-show="themeSetFinish && !isWorkTop"
  >
    <div class="top">
      <menu-group
        :group="menuList"
        :status="status"
        :centerItem="centerItem"
        :firstItem="firstItem"
        :secondItem="secondItem"
        :thirdItem="thirdItem"
        :activeMenuId="activeMenuId"
      />
    </div>
    <div class="bottom">
      <div class="control" @click="statusChange">
        <div class="icon-status"></div>
      </div>
    </div>
  </div>
</template>
<script>
import MenuGroup from "./menu-group";
import { mapGetters, mapMutations } from "vuex";
import { cloneDeep } from "lodash";

export default {
  name: "nav-menu-panel",
  components: {
    MenuGroup,
  },
  data() {
    return {
      status: true,
      menuList: [],
      activeMenuId: {
        0: [],
        1: [],
        2: [],
        3: [],
      },
      centerItem: {},
      firstItem: {},
      secondItem: {},
      thirdItem: {},
    };
  },
  computed: {
    menuGroupType(){
      return this.$store.state.theme['menu-group-type'] || 'left'
    },
    ...mapGetters("menuStore", {
      menuResourceList: "getMenuResourceList",
    }),
    themeSetFinish() {
      return this.$store.state.themeSetFinish;
    },
    // 当为工作台或从iframe中展示时隐藏
    isWorkTop() {
      return (
        this.$route.name === 'worktop' ||
        this.$route.name === 'home' ||
        (this.$route.name === 'iframePanel' &&
          (this.$route.query.src === 'top-cockpit' ||
            this.$route.query.src === 'top-alicockpit' ||
            this.$route.query.src === 'top-xlwcockpit' ||
            this.$route.query.src === 'cockpitNeUrl' ||
            this.$route.query.src === 'cockpitLanLvUrl')) ||
        this.$route.name === 'houseInfo' //工作台的空间检索功能
      )
    },
  },
  watch: {
    $route(route) {
      this.menuStatusChange(route.query);
      this.setActiveMenuId(route.query);
      let pageId = route.query.tid || route.query.sid || route.query.fid;
      if (pageId) {
        this.$axios.defaults.headers.pageId = pageId;
        let pageItem = {};
        let breadcrumb = [];
        if (this.thirdItem.name) {
          pageItem = this.thirdItem;
          breadcrumb = [
            this.centerItem.name,
            this.firstItem.name,
            this.secondItem.name,
            this.thirdItem.name,
          ];
        } else if (this.secondItem.name) {
          pageItem = this.secondItem;
          breadcrumb = [
            this.centerItem.name,
            this.firstItem.name,
            this.secondItem.name,
          ];
        } else if (this.firstItem.name) {
          pageItem = this.firstItem;
          breadcrumb = [this.centerItem.name, this.firstItem.name];
        }
        this.setPageItem(pageItem);
        this.$store.commit("setBreadcrumb", {
          reset: true,
          breadcrumb,
        });
        let permission = {};
        pageItem.childMenus.forEach((item) => {
          permission[item.code] = item.isSelected;
        });
        this.$store.commit("setPermission", permission);
        this.$nextTick(() => {
          this.scrollIntoViewIfNeeded(pageId);
        });
      } else {
        this.$axios.defaults.headers.pageId = "";
      }
    },
  },
  methods: {
    setActiveMenuId(query) {
      const keys = {
        pid: 0,
        cid: 1,
        fid: 2,
        sid: 3,
      };
      Object.keys(keys).forEach((key) => {
        const menuId = query[key];
        const activeMenuId = this.activeMenuId[keys[key]];
        if (menuId !== undefined) {
          try {
            const id = Number(menuId);
            if (["pid", "cid"].includes(key)) {
              if (activeMenuId && activeMenuId.length) {
                activeMenuId.splice(0, activeMenuId.length, id);
              } else {
                activeMenuId.push(id);
              }
            } else {
              const index = activeMenuId.indexOf(id);
              if (index === -1) {
                activeMenuId.push(id);
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
      });
    },
    getBroswer() {
      var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
      var isIE =
        userAgent.indexOf("compatible") > -1 &&
        userAgent.indexOf("MSIE") > -1 &&
        !isOpera; //判断是否IE浏览器
      var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
      var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
      var isSafari =
        userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
      var isChrome =
        userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器

      if (isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
          return "IE7";
        } else if (fIEVersion == 8) {
          return "IE8";
        } else if (fIEVersion == 9) {
          return "IE9";
        } else if (fIEVersion == 10) {
          return "IE10";
        } else if (fIEVersion == 11) {
          return "IE11";
        } else {
          return "0";
        } //IE版本过低
      }
      if (isOpera) {
        return "Opera";
      }
      if (isEdge) {
        return "Edge";
      }
      if (isFF) {
        return "FF";
      }
      if (isSafari) {
        return "Safari";
      }
      if (isChrome) {
        return "Chrome";
      }
    },
    scrollIntoViewIfNeeded(pageId) {
      const selector = `.nav-menu-panel .menu-group-item_${pageId}`
      const dom = document.querySelector(selector)
      let broswer = this.getBroswer()
      if (dom) {
        if (broswer == "FF" || /^IE/.test(broswer)) {
          if (broswer === "IE7" || broswer === "0") return;
          dom.scrollIntoView({ block: "center" });
        } else {
          dom.scrollIntoViewIfNeeded();
        }
      }
    },
    statusChange() {
      console.log(111);
      this.status = !this.status;
    },
    find(list, id) {
      let item = list.find((item) => {
        return item.id == id; // eslint-disable-line
      });
      return item;
    },
    menuStatusChange({ pid, cid, fid, sid, tid }) {
      let centerItem = {};
      let firstItem = {};
      let secondItem = {};
      let thirdItem = {};
      let menuList =
        pid === undefined
          ? this.menuResourceList[0].childMenus
          : this.find(this.menuResourceList, pid).childMenus;
      if (cid) {
        centerItem = this.find(menuList, cid);
      }
      if (fid) {
        firstItem = this.find(centerItem.childMenus, fid);
      }
      if (sid) {
        secondItem = this.find(firstItem.childMenus, sid);
      }
      if (tid) {
        thirdItem = this.find(secondItem.childMenus, tid);
      }
      this.menuList = menuList;
      this.centerItem = centerItem;
      this.firstItem = firstItem;
      this.secondItem = secondItem;
      this.thirdItem = thirdItem;
    },
    ...mapMutations("menuStore", ["setPageItem"]),
  },
};
</script>

<style lang="scss">
.nav-menu-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 78px;
  background: #f0f3f7;
  user-select: none;
  // transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  // transition: all 200ms;
  .top {
    padding-right: 15px;
    padding-top: 16px;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    .control {
      display: flex;
      justify-content: center;
      width: 78px;
      height: 76px;
      background-image: url("./images/control.png");
      background-repeat: no-repeat;
      background-size: 100%;
      cursor: pointer;
      .icon-status {
        width: 16px;
        height: 16px;
        margin-top: 32px;
        background-image: url("./images/control_status.png");
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
  }
  &.open {
    // width: 290px;
    width: 240px;
    .bottom {
      .control {
        .icon-status {
          transform: rotate(90deg);
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: rgba(101, 155, 214, 0.1);
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #e4ecf3;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  background: #f0f3f7;
}
</style>
