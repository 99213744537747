<template>
  <div>
    <!-- 应用 -->
    <div class='jumpResult'
       :pageId="skipData.pageId"
       :detailId="skipData.detailId"
       :url="skipData.url"
       :pageType="skipData.pageType"
       :isJoint="skipData.isJoint"
       :H5NeedAuthorize="skipData.H5NeedAuthorize"
       v-if="classification.type === 'sub'">
      <div class='jumpService jumpBorder'>
        <span class='serviceIcon'>
          <img :src="selectedObject.subImg" />
        </span>
        <div class='serviceInfo'>
          <span class='serviceTitle'> {{selectedObject.subName}}</span>
          <span class='ui-nowrap-1'>服务简介:{{ selectedObject.subNote }}</span>
        </div>
        <div class='servicebuttom'>
          <span class='cardbtn'>进入</span>
        </div>
      </div>
    </div>
    <!-- 产品 -->
    <div class='jumpResult' :pageId="skipData.pageId" :detailId="skipData.detailId" :url="skipData.url" :pageType="skipData.pageType" :isJoint="skipData.isJoint" :H5NeedAuthorize="skipData.H5NeedAuthorize" v-else-if="classification.type === '20000'">
      <div class='jumpStore'>
        <div class="body-wrapper">
          <div class="left">
            <img class="img" :src="selectedObject.storeLogo" alt="">
          </div>
          <div class="right">
            <div class="content">
              <div class="title">{{ selectedObject.name }}</div>
              <div class="star-wrapper">
                <div class='rate-wrapper'>
                  <span v-for="(star,index) in 5" :key="index" :class="index < 5 ? 'activeColor':'starColor'"> ★ </span>
                </div>
                <div class="point-value">{{ selectedObject.overallScore }}</div>
              </div>
              <div class="description">{{ selectedObject.storeNotice }}</div>
            </div>
            <button class="btn store-btn" type="button">进店看看</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 商品 -->
    <div class='jumpResult' :pageId="skipData.pageId" :detailId="skipData.detailId" :url="skipData.url" :pageType="skipData.pageType" :isJoint="skipData.isJoint" :H5NeedAuthorize="skipData.H5NeedAuthorize" v-else-if="classification.type === '20001'">
      <div class='jumpProduct'>
        <div class="body-wrapper">
          <div class="left">
            <img class="img" :src="selectedObject.productImg" alt="">
          </div>
          <div class="right">
            <div class="content">
              <div class="title"> {{ selectedObject.name }}</div>
              <div class="price-wrapper">
                <div class="symbol">¥</div>
                <div class="int-part">{{ selectedObject.price }}</div>
                <div class="dot-part">{{ selectedObject.price_dec }}</div>
              </div>
              <div class="description" v-html="selectedObject.goodsDes"></div>
              <div class="sold-count">
                已售
                <div class="number">{{ selectedObject.sold }}</div>
              </div>
            </div>
            <button class="btn buy-btn" type="button">点击购买</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 秒杀 -->
    <div class='jumpResult' :pageId="skipData.pageId" :detailId="skipData.detailId" :url="skipData.url" :pageType="skipData.pageType" :isJoint="skipData.isJoint" :H5NeedAuthorize="skipData.H5NeedAuthorize" v-else-if="classification.type === '20019'">
      <div class='jumpSecKill'>
        <div class="body-wrapper">
          <div class="top">
            <div class="title-wrapper">
              <span class="title">限时秒杀</span>
              <div class="status" data-bind="text: localData.customObj.secKillObj.statusStr">{{ selectedObject.statusStr }}</div>
            </div>
            <div class="time-wrapper" v-if="selectedObject.disable">
              <div class="cutTime">
                <span class="countdown" data-bind="css: {disabled: localData.customObj.secKillObj.disable}">{{ selectedObject.month }}</span>
                <span class="day">月</span>
                <span class="countdown" data-bind="css: {disabled: localData.customObj.secKillObj.disable}">{{ selectedObject.day }}</span>
                <span class="day">日</span>
                <span class="countdown" data-bind="css: {disabled: localData.customObj.secKillObj.disable}">{{ selectedObject.hour}}</span>
                <span class="colon">:</span>
                <span class="countdown" data-bind="css: {disabled: localData.customObj.secKillObj.disable}">{{ selectedObject.minute }}</span>
              </div>
              <div class="status">{{ selectedObject.leftTimeTips }}</div>
            </div>
          </div>
          <div class="product-wrapper">
            <!-- Swiper -->
            <div class="swiper-container">
              <div class="swiper-wrapper" style="display: flex;overflow: auto;" v-for="(product,index) in selectedObject.productList" :key="index">
                <div class="product-item swiper-slide">
                  <img class="img" data-bind="attr: {src: productPic}" :src="product.productPic" />
                  <span class="name nowrap">
                    <b>{{ product.name }}</b>
                  </span>
                  <div class="real-price">
                    <span>
                      <b>¥</b>
                    </span>
                    <span class="price" data-bind="text: price">{{ product.price }}</span>
                    <span class="price_dec" data-bind="text: '.' + price_dec">.{{ product.price_dec }}</span>
                  </div>
                  <div class="old-price" data-bind="text: '¥' + normalPrice"> ¥ {{ product.normalPrice }}</div>
                </div>
              </div>
            </div>
            <!-- Swiper -->
          </div>
        </div>
      </div>
    </div>
    <!-- 专题 -->
    <div class='jumpResult' :pageId="skipData.pageId" :detailId="skipData.detailId" :url="skipData.url" :pageType="skipData.pageType" :isJoint="skipData.isJoint" :H5NeedAuthorize="skipData.H5NeedAuthorize" v-else-if="classification.type === '20002'">
      <div class='jumpPromotion'>
        <img :src="selectedObject.promotionCoverImg" />
      </div>
    </div>
    <!-- 资讯 -->
    <div class='jumpResult' :pageId="skipData.pageId" :detailId="skipData.detailId" :url="skipData.url" :pageType="skipData.pageType" :isJoint="skipData.isJoint" :H5NeedAuthorize="skipData.H5NeedAuthorize" v-else-if="classification.type === '20012'">
      <div class='jumpInformation'>
        <!--有封面图-->
        <div class="body-wrapper" v-if="selectedObject.picUrl">
          <div class="top">
            <div class="left">
              <div class="title">{{ selectedObject.title }}</div>
              <div class="description">{{ selectedObject.nonHTMLContent }}</div>
            </div>
            <img class="img" :src="selectedObject.picUrl" alt="">
          </div>
          <div class="bottom">
            <div class="content">
              <div class="name">{{ selectedObject.type }}</div>
              <div class="line"></div>
              <div class="time">{{ selectedObject.cardTime }}</div>
            </div>
            <button class="btn information-btn" type="button">进入</button>
          </div>
        </div>
        <!--没有封面图-->
        <div class="body-wrapper" v-if="selectedObject.picUrl === ''">
          <div class="top">
            <div class="left">
              <div class="title">{{ selectedObject.title }}</div>
              <div class="description">{{ selectedObject.nonHTMLContent }}</div>
            </div>
          </div>
          <div class="bottom">
            <div class="content">
              <div class="name">{{ selectedObject.type }}</div>
              <div class="line"></div>
              <div class="time">{{ selectedObject.cardTime }}</div>
            </div>
            <button class="btn information-btn" type="button">进入</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 帖子、社群、自治规约、自治表决、社区工作室 -->
    <div class='jumpResult' :pageId="skipData.pageId" :detailId="skipData.detailId" :url="skipData.url" :pageType="skipData.pageType" :isJoint="skipData.isJoint" :H5NeedAuthorize="skipData.H5NeedAuthorize" v-else-if="classification.type === '20005' || classification.type === '20006' || classification.type === '20003' || classification.type === '20004' || classification.type === '20007'">
      <div class='jumpPost jumpBorder'>
        <div class='postInfo'>
          <div class='leftImg' v-if="selectedObject.leftImg">
            <img :src="selectedObject.leftImg" />
          </div>
          <div class='middleInfo'>
            <span class='info-title ui-nowrap-1'> {{ selectedObject.name }}</span>
            <span class='info-introduce ui-nowrap-2'>{{ selectedObject.note }}</span>
          </div>
          <div class='rightImg' v-if="selectedObject.rightImg">
            <img :src="selectedObject.rightImg" />
          </div>
        </div>
        <div class='card-buttom'>
          <div class="card-buttom-left">
            <span>{{ selectedObject.cardType }}</span>
            <span class='postline' v-show="selectedObject.cardTime"></span>
            <span>{{ selectedObject.cardTime }}</span>
          </div>
          <span class='cardbtn'>进入</span>
        </div>
      </div>
    </div>
    <!-- 活动 -->
    <div class='jumpResult' :pageId="skipData.pageId" :detailId="skipData.detailId" :url="skipData.url" :pageType="skipData.pageType" :isJoint="skipData.isJoint" :H5NeedAuthorize="skipData.H5NeedAuthorize" v-else-if="classification.type === '30031'">
      <div class='jumpActivity'>
        <div class="body-wrapper">
          <div class="top">
            <img class="img" :src="selectedObject.activityPicture" alt="">
          </div>
          <div class="bottom">
            <div class="content">
              <div class="title">{{ selectedObject.activityName }}</div>
              <div class="description">
                <div class="name">小区活动</div>
                <div class="line"></div>
                <div class="time">{{ selectedObject.activityTimeTag }}</div>
              </div>
            </div>
            <button class="btn activity-btn" type="button">我要报名</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 问卷调查 -->
    <div class='jumpResult' :pageId="skipData.pageId" :detailId="skipData.detailId" :url="skipData.url" :pageType="skipData.pageType" :isJoint="skipData.isJoint" :H5NeedAuthorize="skipData.H5NeedAuthorize" v-else-if="classification.type === '109'">
      <div class='jumpQuestionare'>
        <div class="body-wrapper">
          <div class="content">
            <div class="title">{{ selectedObject.name}}</div>
            <div class="join-wrapper">
              已有{{ selectedObject.joinUserCnt }}人参与调查
            </div>
            <div class="description">
              <div class="name">问卷调查</div>
              <div class="line"></div>
              <div class="time">{{ selectedObject.publishTimeTag }}</div>
            </div>
          </div>
          <button class="btn vote-btn" type="button">立即投票</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jumpGoCode } from './code'
export default {
  props: {
    message: {
      type: String,
      default: 'test'
    },
    selectedObject: {
      type: Object,
      default: () => ({})
    },
    classification: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      skipData: {
        pageId: '',
        subCode: '',
        pageType: 1,
        url: '',
        H5NeedAuthorize: 0,
        detailId: '',
        name: '',
        isJoint: 0
      }
    }
  },
  created () {
  },
  methods: {
    getSkipData (data) {
      let classification = this.classification
      let skipData = this.skipData
      if (classification.type === 'sub') {
        skipData.pageId = data.subCode
        skipData.detailId = data.id
        skipData.pageType = 3
        skipData.url = data.subUrl
        skipData.H5NeedAuthorize = data.usedOauth ? 1 : 0
        skipData.name = data.subName
      } else {
        skipData.pageId = classification.code
        skipData.detailId = data.id
        skipData.pageType = 1
        skipData.name = data.name
        if (classification.type === jumpGoCode.secKillCode) {
          // 秒杀
          skipData.name = data.groupName
        } else if (classification.type === jumpGoCode.discountTopicCode) {
          // 促销
          skipData.url = data.promotionUrl
          skipData.pageType = 4
          skipData.isJoint = 1
        } else if (classification.type === jumpGoCode.knowledgeInfoCode) {
          // 资讯
          if (data.skipType !== 1) {
            // 不是详情页
            let skipDataFromApi = JSON.parse(data.skipData)
            skipData = Object.assign(skipData, skipDataFromApi)
          }
          skipData.name = data.title
        } else if (classification.type === jumpGoCode.postsCode) {
          // 帖子
          skipData.subCode = data.subCode
        } else if (classification.type === jumpGoCode.socialActivityCode) {
          // 活动
          skipData.name = data.activityName
          // 收费活动的h5地址
          skipData.url = API_CONFIG.h5BaseURL + 'h5/community/gzh/index.html#/activityDetail/' + skipData.detailId + '?isApp=1&out=1'
          skipData.pageType = 4
          skipData.isJoint = 1
        } else if (classification.type === jumpGoCode.surveyCode) {
          // 问卷调查
          skipData.name = data.title
          // 问卷调查h5地址
          skipData.url = API_CONFIG.h5BaseURL + 'h5/community/newVote/index.html#/skipPage?voteId=' + skipData.detailId
          skipData.pageType = 4
          skipData.isJoint = 1
          skipData.pageId = '109'
        } else if (classification.type === jumpGoCode.autonomyVoteCode) {
          // 自治表决
          // skipData.detailId = data.neiId
          skipData.detailId = data.id
        }
      }
    }
  },
  watch: {
    selectedObject: function (newVal, oldVal) {
      if (newVal) {
        console.log('get new value', newVal)
        this.getSkipData(newVal)
      }
    }
  }
}
</script>
