const routes= [
    // TODO 请假单
    {
        path: '/home/leaveList',
        name: 'leaveForm',
        component: resolve => require(['@/views/activiti/leaveManage/LeaveList'], resolve)
    },
    {
        path: '/home/leaveForm',
        name: 'leaveFormDetail',
        component: resolve => require(['@/views/activiti/leaveManage/LeaveForm'], resolve)
    },
    {
        path: '/home/historyFlowList',
        name: 'historyFlowList',
        component: resolve => require(['@/views/activiti/leaveManage/HistoryFlowList'], resolve)
    },
    // TODO 模型列表
    {
        path: '/home/modelList',
        name: 'processModel',
        component: resolve => require(['@/views/activiti/modelManage/ModelList'], resolve)
    },

    // TODO 部署流程列表
    {
        path: '/home/processModelList',
        name: 'processManage',
        component: resolve => require(['@/views/activiti/processModelManage/ProcessModelList'], resolve)
    },
    {
        path: '/home/processModelForm',
        name: 'processModelForm',
        component: resolve => require(['@/views/activiti/processModelManage/ProcessModelForm'], resolve)
    },
    // TODO 单据类型列表
    {
        path: '/home/billTypeList',
        name: 'billType',
        component: resolve => require(['@/views/activiti/billTypeManage/BillTypeList'], resolve)
    },
    {
        path: '/home/billTypeForm',
        name: 'billTypeForm',
        component: resolve => require(['@/views/activiti/billTypeManage/BillTypeForm'], resolve)
    },
    // TODO 单据类型配置列表
    {
        path: '/home/billTypeConfigList',
        name: 'billTypeConfig',
        component: resolve => require(['@/views/activiti/billTypeConfigManage/BillTypeConfigList'], resolve)
    },
    {
        path: '/home/billTypeConfigForm',
        name: 'billTypeConfigForm',
        component: resolve => require(['@/views/activiti/billTypeConfigManage/BillTypeConfigForm'], resolve)
    },
    // TODO 部署流程列表
    {
        path: '/home/formProcessRelavList',
        name: 'formManage',
        component: resolve => require(['@/views/activiti/formProcessManage/FormProcessRelavList'], resolve)
    },
    {
        path: '/home/formProcessRelavDetail',
        name: 'formManageDetail',
        component: resolve => require(['@/views/activiti/formProcessManage/FormProcessRelavDetail'], resolve)
    }
]

export default routes
  