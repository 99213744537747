const menu = {
  namespaced: true,
  state: {
    iotCommunityList: [],
    currentIotCommunity: {}
  },
  mutations: {
    setIotCommunityList (state, iotCommunityList) {
      state.iotCommunityList = iotCommunityList
    },
    setCurrentIotCommunity (state, currentIotCommunity) {
      state.currentIotCommunity = currentIotCommunity
    }
  },
  getters: {
    getIotCommunityList: state => state.iotCommunityList,
    getCurrentIotCommunity: state => state.currentIotCommunity
  }
}

export default menu
