<template>
  <div class="table-panel-wrapper">
    <table class="table-panel-dra">
      <draggable
        v-model="dataList"
        animation="500"
        force-fallback="true"
        filter=".forbid"
        handle=".mover"
        :move="onMove"
        @update="onUpdate"
        @start="onStart"
        @end="onEnd">
        <!-- 表头 -->
        <tr class="forbid" id="trForbid">
          <th v-if="isMultiSelect" class="forbid">
            <div class="cell">
              <input type="checkbox" v-model="isSelectAll" @change="selectAll">
            </div>
          </th>
          <th v-for="item in headers" :key="item.prop" >{{item.label}}</th>
        </tr>
        <tr class="item" v-for="(item, index) in usrDataList" :key="'tr' + index">
            <td v-if="isMultiSelect" class="forbid">
              <div class="cell">
                <input type="checkbox" v-model="item.isSelect" @change="select(index, item)">
              </div>
            </td>
            <td v-for="(items, i) in headers" :key="'td' + items.prop + index" :class="{ 'mover':!items.formatter}">
              <div
                class="cell el-tooltip"
                v-if="item.id !== 'head'"
                :style="{'width': cellWidth(item[items.prop])}">
                  <!--{{item && (items.formatter ? items.formatter(item, items.prop) : item[items.prop])}}-->
                  <div v-if="items.formatter" class="vnode-wrapper">
                      {{handleVNodeSlot(`${item.$index}_${i}_slot`, items.formatter(item, items.prop, item.$index))}}
                      <slot :name="`${item.$index}_${i}_slot`">
                      </slot>
                  </div>
                  <div v-else>
                      {{item && item[items.prop]}}
                  </div>
              </div>
            </td>
        </tr>
      </draggable>
    </table>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
// @group 业务组件
// @title TablePanel 表格
export default {
  name: 'table-panel-draggable',
  components: {
    draggable
  },
  props: {
    // 表格样式
    tabStyle: {
      type: Object,
      // `'{}'`
      default: () => {
        return {
          width: '100%'
        }
      }
    },
    // 是否带有纵向边框
    border: {
      type: Boolean,
      default: false
    },
    // Table 的高度，默认为自动高度。如果 height 为 number 类型，单位 px；如果 height 为 string 类型，则这个高度会设置为 Table 的 style.height 的值，Table 的高度会受控于外部样式。
    autoHeight: {
      type: Boolean,
      default: true
    },
    // Table 的最大高度
    maxHeight: {
      type: String
    },
    // 是否为斑马纹 table
    stripe: {
      type: Boolean,
      default: true
    },
    // 是否需要多选列
    isMultiSelect: {
      type: Boolean,
      default: false
    },
    checkAlign: {
      type: String,
      default: 'center'
    },
    // 当内容过长被隐藏时显示 tooltip
    showOverflowTooltip: {
      type: Boolean,
      default: true
    },
    // 仅对 type=selection 的列有效，类型为 Function，Function 的返回值用来决定这一行的 CheckBox 是否可以勾选
    selectable: {
      type: Function
    },
    // 行的 className 的回调方法，也可以使用字符串为所有行设置一个固定的 className
    tableRowClassName: {
      type: Function
    },
    // 不传,没有排序
    // '2' 代表静态排序,
    // '1' 代表服务端排序
    sort: {
      type: String
    },
    // 搜索中的标识
    isSearching: {
      type: Boolean,
      default: false
    },
    // 是否需要操作列
    hasOperateColumn: {
      type: Boolean,
      default: true
    },
    // 操作列名
    operateColumnLabel: {
      type: String,
      default: '操作'
    },
    // 操作列列宽
    operateColumnWidth: {
      type: String
    },
    // 是否显示表头
    showHeader: {
      type: Boolean,
      default: true
    },
    // 表头数据
    headers: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 列表数据
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 接口请求数据成功后的回调函数，可以对数据做一些预处理，第一个参数为当前行的数据对象，第二个参数为当前行数
    handleData: Function,
    // 合并行或列的计算方法
    spanMethod: Function
  },
  data () {
    return {
      tableHeight: '100%',
      headerCellStyle: {
        userSelect: 'auto'
      },
      selectedData: [],
      dataList: [{id: 'head'}],
      usrDataList: [],
      isSelectAll: false,
    }
  },
  watch: {
    tableData (newValue) {
      this.dataList = [{id: 'head'}, ...newValue]
      this.initSlot()
    }
  },
  computed: {
    isShowHeader () {
      if (this.showHeader) {
        if (this.headers && this.headers.length) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  methods: {
      // 建立VNode节点与slot的绑定关系
      handleVNodeSlot (slotName, VNode) {
          this.$slots[slotName] = VNode
      },
    cellWidth (text) {
      let maxWidth = 'auto'
      text = text + ''
      if (text.length > 15 ) {
        maxWidth = '126px'
      }
      return maxWidth
    },
    onUpdate (e, arr) {
      // console.log('onUpdate', e, arr)
    },
    onStart (e) {
      // console.log('onStart', e)
    },
    onEnd (e) {
      let dataList = []
      this.dataList.forEach((res) => {
        if (res.id !== 'head') dataList.push(res)
      })
      this.$emit('update:tableData', dataList)
    },
    onMove(e) {
      if (e.related.id == 'trForbid') return false;
      return true;
    },
    initSlot () {
      let tableData = this.tableData
      let _this = this
      this.usrDataList = []
      this.selectedData = []
      this.isSelectAll = false
      Array.isArray(tableData) && tableData.forEach((row, rowIndex) => {
        let data = row;
        data.isSelect = false
        this.usrDataList.push(data)
        if (_this.handleData && typeof _this.handleData === 'function') {
          _this.handleData(row, rowIndex)
        }
      })
    },
    handleSelectionChange (val) {
      console.log('handleSelectionChange', val)
      this.$emit('handleSelectionChange', val)
    },
    // @vuese
    // 获取当前选中的所有项
    getSelectedData () {
      return this.selectedData
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select (selection, row) {
      // 当用户手动勾选数据行的 Checkbox 时触发的事件
      // @arg (selection, row)接收两个参数 1.selection 所有选中行
      // 2.row 选中行的数据
      console.log(this.usrDataList[selection].isSelect)
      if (this.usrDataList[selection].isSelect) {
        this.selectedData.push(row)
      } else {
        this.selectedData.forEach((res, index) => {
          if (row.id === res.id) {
            this.selectedData.splice(index, 1)
          }
        })
      }
      if (this.selectedData.length === this.usrDataList.length) {
        this.isSelectAll = true
      } else {
        this.isSelectAll = false
      }
      this.handleSelectionChange(this.selectedData)
    },
    // 全选
    selectAll (val) {
      if (this.isSelectAll) {
        this.selectedData = [...this.usrDataList]
      } else {
        this.selectedData = []
      }
      this.usrDataList.forEach((res) => {
        if (this.isSelectAll) {
          res.isSelect = true
        } else {
          res.isSelect = false
        }
      })
      this.handleSelectionChange(this.selectedData)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .table-panel-wrapper {
    width: 100%;
    height: 100%;
    .table-panel-dra {
      // width: 100%;
      color: #606266;
      // table-layout: fixed;
      border-collapse: collapse;
      border-spacing: 0;
      tr {
        background-color: #FFF;
        td,th {
          padding: 12px 10px;
          min-width: 0;
          box-sizing: border-box;
          text-overflow: ellipsis;
          vertical-align: middle;
          text-align: left;
          border: 1px solid #EBEEF5;
        }
        th {
          background-color: #FFF;
          text-align: center;
          user-select: auto;
          overflow: hidden;
          color: #909399;
        }
        td {
          text-align: center;
          transition: background-color .25s ease;
          input[type="checkbox"] {
            border: 1px solid #DCDFE6;
          }
        }
      }
      tr:nth-of-type(odd) {
        td {
          background: #FAFAFA;
        }
      }
      th>.cell {
        display: inline-block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        vertical-align: middle;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
      }
      .cell {
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        word-break: break-all;
        line-height: 23px;
        padding-right: 14px;
        padding-left: 14px;
      }
      .cell.el-tooltip {
        white-space: nowrap;
        min-width: 50px;
      }

    }
    .vnode-wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

</style>
