import Vue from 'vue'
import Store from '@/store'
import VueI18n from 'vue-i18n'
import ElementLocale from 'element-ui/lib/locale'
import ElementEnLocale from 'element-ui/lib/locale/lang/en'
import ElementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import EnLocale from './lang/en'
import ZhLocale from './lang/zh-CN'
import { localStorageHelper } from 'common/utils'

Vue.use(VueI18n)

const messages = {
  en: {
    ...EnLocale,
    ...ElementEnLocale
  },
  zh: {
    ...ZhLocale,
    ...ElementZhLocale
  }
}

let locale = 'zh'
if (Store.state.baseConfig.i18n) {
  locale = localStorageHelper.getItem('lang') || 'zh'
}

const i18n = new VueI18n({
  locale,
  messages
})

ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n
