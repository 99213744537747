<template>
  <div class="v-control v-autocomplete-container">
      <label v-if="label" v-text="label"></label>
      <el-autocomplete
        v-model="model"
        :disabled="disabled"
        :style="{ width: width + 'px' }"
        :placeholder="defaultPlaceholder"
        :maxlength="maxlength"
        :minlength="minlength"
        :value-key="valueKey"
        :debounce="debounce"
        :placement="placement"
        :popper-class="popperClass"
        :trigger-on-focus="triggerOnFocus"
        :select-when-unmatched="selectWhenUnmatched"
        :prefix-icon="prefixIcon"
        :suffix-icon="suffixIconName"
        :hide-loading="hideLoading"
        :popper-append-to-body="popperAppendToBody"
        :clearable="clearable"
        :highlight-first-item="highlightFirstItem"
        :fetch-suggestions="querySearch"
        @select="handleSelect"
        @clear="clearValue"
        @change="change">
            <!-- 建议的插槽,如果要修改插槽的css，需要在业务页面单独写，不要带scoped -->
            <template slot-scope="{ item }">
                <slot name="advise-slot" :row="item"></slot>
            </template>
        </el-autocomplete>
  </div>
</template>

<script >
import Vue from 'vue'
import { Autocomplete } from 'element-ui'
import qs from 'querystring'
Vue.use(Autocomplete)

// @group 基础组件
// @title Autocomplete 可带输入建议的输入框
export default {
  name: 'v-autocomplete',
  props: {
    //  本地搜索，本地数据的ops（和searchUrl，同时存在时，searchUrl优先级高）
    options: {
      type: Array,
      // `[]`
      default: () => []
    },
    // 远程搜索，搜索的接口地址（有值则代表是网络请求）
    searchUrl: String,
    // 远程搜索，入参名称
    searchName: String,
    // 查询接口的Request Method
    searchMethod: {
      type: String,
      // `get`
      default: 'get'
    },
    // 请求的媒体类型
    searchContentType: String,
    // 绑定值
    value: [String, Number],
    // 输入框左侧label
    label: String,
    // 输入框占位文本
    placeholder: String,
    // 是否禁用
    disabled: Boolean,
    // 输入建议对象中用于显示的键名
    valueKey: {
      type: String,
      // `value`
      default: 'value'
    },
    // 输入框宽度
    width: {
      type: Number,
      // `180`
      default: 180
    },
    // 获取输入建议的去抖延时
    debounce: {
      type: Number,
      // `300`
      default: 300
    },
    // 菜单弹出位置
    placement: {
      type: String,
      // `top / top-start / top-end / bottom / bottom-start / bottom-end`
      default: 'bottom-start'
    },
    // Autocomplete 下拉列表的类名
    popperClass: String,
    // 是否在输入框 focus 时显示建议列表
    triggerOnFocus: {
      type: Boolean,
      // `true`
      default: true
    },
    // 原生属性，最大输入长度
    maxlength: {
      type: Number,
      // `30`
      default: 30
    },
    // 在输入没有任何匹配建议的情况下，按下回车是否触发 select 事件
    selectWhenUnmatched: Boolean,
    // 原生属性，最小输入长度
    minlength: Number,
    // 输入框头部图标
    prefixIcon: String,
    // 输入框尾部图标
    suffixIcon: String,
    // 是否隐藏远程加载时的加载图标
    hideLoading: {
      type: Boolean,
      // `true`
      default: true
    },
    // 是否默认突出显示远程搜索建议中的第一项
    highlightFirstItem: Boolean,
    // 是否将下拉列表插入至 body 元素。在下拉列表的定位出现问题时，可将该属性设置为 false
    popperAppendToBody: {
      type: Boolean,
      // `true`
      default: true
    },
    // 是否可清空
    clearable: {
      type: Boolean,
      // `true`
      default: true
    },
    // 是否实时搜索,需要设置'searchUrl'
    realTime: Boolean,
    // 额外参数
    extra: Object
  },
  data () {
    return {
      model: this.value,
      suffixIconName: this.suffixIcon,
      defaultPlaceholder: this.placeholder,
      //  搜索的数据源
      sourceArray: []
    }
  },

  watch: {
    value (newValue) {
      this.handleSuffixIcon(this.value)
      this.model = newValue
      // 输入框的值改变时触发
      // @arg 输入框的值
      this.$emit('change', newValue)
    },
    model (newValue) {
      // v-model event
      this.$emit('input', newValue)
    },

    placeholder (newValue) {
      this.defaultPlaceholder = newValue
    },

    options (newValue) {
      // searchUrl请求优先级高
      let list = newValue || []
      this.sourceArray = this.searchUrl ? [] : list
    },

    extra (newValue) {
      this.initData()
    }
  },

  // created函数
  created () {
    if (this.placeholder === undefined && this.label) {
      this.defaultPlaceholder = `请输入${this.label}`
    }
    this.handleSuffixIcon(this.value)
    this.initData()
  },

  // 方法
  methods: {

    initData () {
      if (this.searchUrl) {
        if (!this.realTime) {
        // 非实时搜索
          let _this = this
          this.requestForSearchData(undefined, (list) => {
            _this.sourceArray = list
          })
        }
      } else {
        this.sourceArray = []
      }
    },
    handleSuffixIcon (val) {
      if (this.clearable && this.suffixIcon) {
        if (val === '') {
          this.suffixIconName = this.suffixIcon
        } else {
          this.suffixIconName = ''
        }
      }
    },
    // 返回输入建议的方法
    async querySearch (queryString, cb) {
      let realTimeRequest = false
      if (this.realTime) {
        realTimeRequest = this.searchUrl && this.searchUrl.length > 0
      }
      if (realTimeRequest) {
        await this.requestForSearchData(queryString, cb)
      } else {
        let list = this.sourceArray
        let results = queryString ? list.filter(this.createFilter(queryString)) : list
        // 调用 callback 返回建议列表的数据
        cb(results)
      }
    },

    // 数据筛选
    createFilter (queryString) {
      return (item) => {
        if (item[`${this.valueKey}`] !== undefined) {
          return (item[`${this.valueKey}`].toLowerCase().indexOf(queryString.toLowerCase()) === 0)
        }
        return false
      }
    },

    // 远程搜索
    async requestForSearchData (queryString, cb) {
      let headers = {
        'Content-Type': this.searchContentType
      }
      let params = {}
      if (this.searchName && this.searchName.length) {
        params[this.searchName] = queryString
      }

      let method = this.searchMethod.toLocaleLowerCase()
      let getParams, postParams
      if (method === 'get') {
        // axios里get传参特殊
        getParams = params
      } else {
        postParams = params
      }

      if (this.extra) {
        getParams = { ...getParams, ...this.extra }
        postParams = { ...postParams, ...this.extra }
      }
      // formData格式处理
      let formDataType = this.searchContentType === 'application/x-www-form-urlencoded;charset=UTF-8'
      if (formDataType && method === 'post') {
        postParams = qs.stringify(postParams)
      }

      const { data, status } = await this.$axios({
        method: method,
        url: this.searchUrl,
        params: getParams,
        data: postParams,
        headers
      })
      let list = []
      if (status === 100) {
        list = data || []
      }
      cb(list)
    },

    handleSelect (item) {
      // 选中的建议的item
      this.$emit('select', item)
    },
    clearValue () {
      // 在点击由 clearable 属性生成的清空按钮时触发
      this.$emit('clear', undefined)
    },

    change (value) {
      // 在输入框值改变时触发
      this.$emit('change', value)
    }
  }
}
</script>

<style scoped lang="scss">
 .v-autocomplete-container {
    display: inline-block;
    label {
      margin-right: 5px;
      color: #333333;
    }
 }
</style>
