const menu = {
  namespaced: true,
  state: {
    currentParams: { _t: Date.now() }
  },
  mutations: {
    UPDATE_CURRENT(state, params) {

      let __VALUE__ = JSON.parse(JSON.stringify(params))
      // 加入部门回复详情

      // 更新，只能这样一波骚操作才能让computed和watch监听到。具体原因我稍后学习o(╥﹏╥)o。
      state.currentParams = __VALUE__
    }
  }
};

export default menu;
