<template>
  <div class="v-control v-datepicker-container"
       :class="{'range-wrapper': isCustomRange || range, 'single-wrapper': !(isCustomRange || range)}">
    <label v-if="label"
           v-text="label"></label>
    <date-picker v-if="!isCustomRange"
                 ref="dp"
                 v-model="time"
                 :valueType="valueType"
                 :format="valueFormat"
                 :width="width"
                 :editable="editable"
                 :disabled="disabled"
                 :type="type"
                 :disabled-days="disabledDays"
                 :time-picker-options="timePickerOptions"
                 :minuteStep="minuteStepInner"
                 :append-to-body="appendToBody"
                 :not-after="innerMaxDate"
                 :not-before="innerMinDate"
                 :clearable="clearable"
                 :placeholder="placeholder"
                 @change="onChange"
                 @confirm="onChange"
                 @calendar-change="onCalendarChange"
                 @clear="onClear">
      <template #footer>
        <div class="footer-wrapper"
             v-if="hasMonthEndBtn">
          <a class="link"
             :class="{active: isMEFormat}"
             @click="onMonthEndConfirm">月末</a>
        </div>
      </template>
    </date-picker>
    <div class="multip-container"
         v-if="isCustomRange">
      <date-picker ref="sdp"
                   v-model="sTime"
                   :type="rangType"
                   :valueType="valueType"
                   :format="valueFormat"
                   :editable="editable"
                   :disabled="disabled"
                   :disabled-days="sDisabledDays"
                   :minuteStep="minuteStepInner"
                   :not-before="innerMinDate"
                   :not-after="sMaxTime"
                   :time-picker-options="sTimePickerOptions"
                   :append-to-body="appendToBody"
                   :clearable="clearable"
                   :placeholder="sPlaceholder"
                   @change="onSTimeChange"
                   @confirm="onSTimeChange"
                   @clear="onSClear"></date-picker>
      <span class="range-separator"
            v-text="rangeSeparator"></span>
      <date-picker ref="edp"
                   v-model="eTime"
                   :type="rangType"
                   :valueType="valueType"
                   :format="valueFormat"
                   :editable="editable"
                   :disabled="disabled"
                   :disabled-days="eDisabledDays"
                   :minuteStep="minuteStepInner"
                   :not-before="eMinTime"
                   :not-after="innerMaxDate"
                   :time-picker-options="eTimePickerOptions"
                   :append-to-body="appendToBody"
                   :clearable="clearable"
                   :placeholder="ePlaceholder"
                   @change="onETimeChange"
                   @confirm="onETimeChange"
                   @clear="onEClear"></date-picker>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import moment from 'moment'
import emitter from 'element-ui/src/mixins/emitter'
Vue.use(DatePicker)

// @group 基础组件
// @title Datepicker 日期选择器

export default {
  name: 'v-datepicker',
  mixins: [emitter],
  props: {
    // 标签名称
    label: String,
    // 范围类型的开始时间
    startTime: String,
    // 范围类型的结束时间
    endTime: String,
    // 目前支持的类型: 'date', 'datetime', 'year', 'month', 'time'. 自定义范围类型： 'rangedate', 'rangedatetimer', 'rangemonth'
    type: {
      type: String,
      default: 'rangedate'
    },
    // 是否为范围
    range: {
      type: Boolean,
      default: false
    },
    // 单个日期类型使用v-model绑定的值
    value: String,
    // 绑定值的类型 date timestamp format
    valueType: {
      type: String,
      default: 'format'
    },
    // 显示确认按钮且需要确认才更新时间
    confirm: {
      type: Boolean,
      default: false
    },
    // 宽度
    width: {
      type: Number,
      default: 160
    },
    // 如果是false, 用户不能手动输入更新日期
    editable: {
      type: Boolean,
      default: false
    },
    // 是否有清空按钮
    clearable: {
      type: Boolean,
      default: true
    },
    // 禁用组件
    disabled: {
      type: Boolean,
      default: false
    },
    // 设置分钟的步进， 设置大于0不显示秒的选择(0-60)
    minuteStep: {
      type: Number
    },
    // 指定显示、值的日期格式。每个类型都有默认值，rangedate: YYYY-MM-DD rangedatetimer: YYYY-MM-DD HH:mm.只在不同于默认值时显示设置。时间格式的规则同moment.js
    format: String,
    // 最大可选日期
    maxDate: [String, Date],
    // 最小可选日期
    minDate: [String, Date],
    // 范围日期开始结束日期的分隔符
    rangeSeparator: {
      type: String,
      default: '至'
    },
    // 默认填写的时间范围，可选值有:week, month。若为week，即填写入开始时间为前一周的日期，结束时间为今天。month为最近一个月的时间。
    defaultTimeType: {
      type: String
    },
    // 自定义时间选择：开始，结束，步进 {start: '00:00', step:'00:30' , end: '23:30'} | () => Array<{ label: string; values: { hours: number; minutes: number } }>
    timePickerOptions: {
      type: Object
    },
    // type为'rangedate', 'rangedatetimer'时，开始时间的自定义时间选择
    sTimePickerOptions: {
      type: Object
    },
    // type为'rangedate', 'rangedatetimer'时，结束时间的自定义时间选择
    eTimePickerOptions: {
      type: Object
    },
    // 自定义禁止的日期 (date) => boolean
    disabledDays: {
      type: Function
    },
    // type为'rangedate', 'rangedatetimer'时，开始日期自定义禁止的日期
    sDisabledDays: {
      type: Function
    },
    // type为'rangedate', 'rangedatetimer'时，结束日期自定义禁止的日期
    eDisabledDays: {
      type: Function
    },
    // 是否在body下添加填出框
    appendToBody: {
      type: Boolean,
      default: true
    },
    // 是否含有月末按钮(定制需求)
    hasMonthEndBtn: {
      type: Boolean,
      default: false
    },
    // 是否使用月末格式
    isMonthEndFormat: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择时间'
    },
    sPlaceholder: {
      type: String,
      default: '请选择时间'
    },
    ePlaceholder: {
      type: String,
      default: '请选择时间'
    }
  },
  data () {
    return {
      time: this.value,
      sTime: this.startTime,
      eTime: this.endTime,
      sMaxTime: undefined, // 开始时间的可选最大日期
      eMinTime: undefined, // 结束时间的可选最小日期
      valueFormat: undefined,
      isMEFormat: this.isMonthEndFormat,
      MEFormat: 'YYYY-MM-DD',
      curPanelDate: '', // 当前面板月份
      isInit: false,
      isSInit: false, // 是否已经初始化时间值
      isEInit: false // 是否已经初始化时间值
    }
  },
  created () {
    let valueFormat = this.getValueFormat()
    if (this.isCustomRange) {
      let format = this.getValueFormat()
      let startTime
      let endTime = moment().format(format)
      if (this.defaultTimeType === 'week') {
        startTime = moment().subtract(7, 'days').format(format)
      }
      if (this.defaultTimeType === 'month') {
        startTime = moment().subtract(1, 'months').format(format)
      }
      if (this.defaultTimeType) {
        this.onSTimeChange(startTime)
        this.onETimeChange(endTime)
      }
      if (this.maxDate) {
        this.sMaxTime = this.innerMaxDate
      } else {
        this.sMaxTime = this.endTime
      }
      if (this.minDate) {
        this.eMinTime = this.innerMinDate
      } else {
        this.eMinTime = this.startTime
      }
    }
    if (this.hasMonthEndBtn) {
      let value = this.value || undefined
      let days = moment(value).daysInMonth() // 当前日期，当前月份天数
      this.curPanelDate = moment(value).set('date', days).format(this.MEFormat)
    }
    this.valueFormat = valueFormat
  },
  watch: {
    startTime (newValue) {
      if (this.isCustomRange) {
        this.sTime = newValue
      }
    },
    endTime (newValue) {
      if (this.isCustomRange) {
        this.eTime = newValue
      }
    },
    value (newValue) {
      if (!this.isCustomRange) {
        if (this.hasMonthEndBtn) {
          let value = newValue || undefined
          let days = moment(value).daysInMonth() // 当前日期，当前月份天数
          this.curPanelDate = moment(value).set('date', days).format(this.MEFormat)
        }
        if (!this.isInit && this.minuteStepInner && this.minuteStepInner > 1) {
          this.time = this.getJustifyTime(newValue)
          this.isInit = true
        } else {
          this.time = newValue
        }
        this.$emit('input', this.time)
      }
    },
    sTime (newValue) {
      if (!this.isSInit && this.minuteStepInner && this.minuteStepInner > 1) {
        // 修正当前时间可能不处于步长当中的值
        this.sTime = this.getJustifyTime(newValue)
        this.isSInit = true
      } else {
        this.sTime = newValue
      }
      this.$emit('update:startTime', this.sTime)
      // 在调整初始值后，当前一定会处于某个步长值，所以结束时间只需要加一个步长
      if (newValue) {
        this.eMinTime = moment(newValue).add('m', this.minuteStepInner).format(this.getValueFormat())
      } else {
        this.eMinTime = this.innerMinDate || undefined
      }
    },
    eTime (newValue) {
      if (!this.isEInit && this.minuteStepInner && this.minuteStepInner > 1) {
        // 修正当前时间可能不处于步长当中的值
        this.eTime = this.getJustifyTime(newValue)
        this.isEInit = true
      } else {
        this.eTime = newValue
      }
      this.$emit('update:endTime', this.eTime)
      // 同理，开始时间的最大值需要加一个步长
      if (newValue) {
        this.sMaxTime = moment(newValue).subtract('m', this.minuteStepInner).format(this.getValueFormat())
      } else {
        this.sMaxTime = this.innerMaxDate || undefined
      }
    },
    isMonthEndFormat (newValue) {
      this.isMEFormat = newValue
      if (newValue) {
        this.valueFormat = this.getValueFormat()
      }
    }
  },
  computed: {
    isCustomRange () {
      // 自定义日期范围
      return ['rangedate', 'rangedatetimer', 'rangemonth'].includes(this.type)
    },
    rangType () {
      let type = ''
      switch (this.type) {
        case 'rangedatetimer':
          type = 'datetime'
          break
        case 'rangemonth':
          type = 'month'
          break
        default:
          type = 'date'
      }
      return type
    },
    minuteStepInner () {
      let minuteStep
      if (this.minuteStep) {
        minuteStep = this.minuteStep
      } else if (this.valueFormat === 'YYYY-MM-DD HH:mm') {
        minuteStep = 1
      }
      return minuteStep
    },
    innerMinDate () {
      let valueFormat = this.isMEFormat ? this.MEFormat : this.getValueFormat()
      return this.minDate && moment(this.minDate).format(valueFormat)
    },
    innerMaxDate () {
      let valueFormat = this.isMEFormat ? this.MEFormat : this.getValueFormat()
      return this.maxDate && moment(this.maxDate).format(valueFormat)
    }
  },
  methods: {
    onChange (value) {
      if (this.range) {
        let [startTime, endTime] = value
        // 更新开始时间
        this.$emit('update:startTime', startTime)
        // 更新结束时间
        this.$emit('update:endTime', endTime)
      } else {
        // 更新v-model的值
        this.isMEFormat = false
        this.$emit('update:isMonthEndFormat', this.isMEFormat)
        this.valueFormat = this.getValueFormat()
        if (value.indexOf('月末') > -1) {
          this.$emit('input', value.replace('-月末', ''))
        } else {
          this.$emit('input', value)
        }
      }
      this.dispatch('ElFormItem', 'el.form.change', [value])
      this.$emit('change', value)
    },
    onSTimeChange (value) {
      this.$emit('update:startTime', value)
      this.$emit('schange', value)
    },
    onETimeChange (value) {
      this.$emit('update:endTime', value)
      this.$emit('echange', value)
    },
    onCalendarChange (now, oldNow) {
      let days = moment(now).daysInMonth() // 当前日期，当前月份天数
      this.curPanelDate = moment(now).set('date', days).format(this.MEFormat)
    },
    onMonthEndConfirm () {
      this.isMEFormat = true
      this.$emit('update:isMonthEndFormat', this.isMEFormat)
      this.$emit('input', this.curPanelDate)
      this.$emit('change', this.curPanelDate)
      this.valueFormat = this.getValueFormat()
      this.$refs.dp.closePopup()
    },
    getValueFormat () {
      let format = 'YYYY-MM-DD'
      if (this.hasMonthEndBtn) {
        // 对绑定值判断
        if (this.isMEFormat) {
          format = `YYYY-MM-DD-月末`
        } else {
          format = this.MEFormat
        }
      } else {
        if (this.format) {
          format = this.format
        } else {
          if (this.type === 'month') {
            format = 'YYYY-MM'
          }
          if (this.type === 'year') {
            format = 'YYYY'
          }
          if (this.type === 'datetime') {
            format = 'YYYY-MM-DD HH:mm'
          }
          if (this.type === 'rangedatetimer') {
            format = 'YYYY-MM-DD HH:mm'
          }
          if (this.type === 'rangemonth') {
            format = 'YYYY-MM'
          }
        }
      }
      return format
    },
    onClear () {
      this.$refs.dp.closePopup()
      this.$emit('clear')
    },
    onSClear () {
      this.$refs.sdp.closePopup()
      this.$emit('sclear')
    },
    onEClear () {
      this.$refs.edp.closePopup()
      this.$emit('eclear')
    },
    getJustifyTime (timeValue) {
      let time = moment(timeValue)
      let minutes = time.get('minute')
      let plusValue = this.minuteStepInner - minutes % this.minuteStepInner
      if (minutes % this.minuteStepInner === 0) plusValue = 0
      return time.add(plusValue, 'm').format(this.valueFormat)
    }
  }
}
</script>

<style scoped lang="scss">
.v-datepicker-container {
  &.range-wrapper {
    width: 540px;
  }
  &.single-wrapper {
    width: 261px;
  }
  label {
    margin-right: 5px;
    color: #333333;
  }
  .range {
    width: 424px;
  }
  .single {
    width: 144px;
  }
  .multip-container {
    width: 424px;
    display: flex;
    align-items: center;
  }
  .range-separator {
    margin: 0 10px;
  }
}
.el-form-item__content {
  .single-wrapper,
  .range-wrapper {
    width: auto;
    display: inline-block;
  }
}
.link {
  cursor: pointer;
  color: #1284e7;
  font-size: 14px;
  &.active {
    color: #fff;
    background-color: #1284e7;
    padding: 3px 8px;
  }
}
.footer-wrapper {
  padding: 10px;
  padding-top: 0;
  clear: both;
  text-align: right;
}
</style>
<style lang="scss">
.mx-input {
  border: 1px solid #dcdfe6;
  height: 40px;
  color: #606266;
  box-shadow: none;
  font-size: 13px;
  padding-right: 20px;
}
.mx-input-append {
  display: none;
}
</style>
