<template>
  <w-room-tree
    class="root-tree"
    ref="roomtree"
    :lazy="true"
    :style="{ zIndex: 3000 }"
    @loadData="treeLoad"
    :defaultProps="treeProps"
    :visible.sync="treeShow"
    v-loading="onLoading"
    @current-change="selectNode"
    :treeData="treeData"
    :render-after-expand="false"
    :filterNode="filterNode"
  >
    <template #search>
      <div class="tree-header-wrapper"> 
        <div class="roomtree-input-wrap">
          <v-input v-model="filterText" class="roomtree-input" placeholder="搜索" :clearable="false"></v-input>
          <img class="roomtree-input-search-icon" src="@/assets/images/search-icon.png" />
        </div>
        <span class="tree-refresh-btn"  @click="reloadTree"><img src="@/assets/images/refresh.png" />&nbsp;刷新</span>
      </div>
    </template>
    <span
      slot-scope="{ node, data }"
      class="custom-tree-node"
      style="width:100%"
      :class="{ 'bias-left': data.nodeLevel === 7 && !!data.roomStatus }"
    >
      <!-- <img v-if="data.nodeLevel === 0" src="./root.png" alt="" /> -->
      <span
        class="status-icon"
        :class="[dataStateFormatter(data.roomStatus).class]"
        v-if="data.nodeLevel === 7 && !!data.roomStatus"
      >{{ dataStateFormatter(data.roomStatus).word }}</span>
      <span
        class="status-icon"
        :class="[dataStateFormatter(data.roomStatus).class]"
        v-if="
          data.nodeLevel !== 7 && data.nodeLevel >= 3 && data.isParent === 0
        "
      >{{ dataStateFormatter(data.roomStatus).word }}</span>
      <!-- <Marquee :msg="node.label"></Marquee> -->
      <el-tooltip :enterable="false" popper-class="zindex5000" placement="top">
        <template #content>{{ node.label }}</template>
        <span>{{ node.label }}</span>
      </el-tooltip>
    </span>
  </w-room-tree>
</template>

<script>
import { getProjectTreeURL, getRegionAndCommunityTreeURL } from './api'
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      dataStateMap: [
        { code: '2', class: 'no', word: '未领' },
        { code: '1', class: 'empty', word: '空置' },
        { code: '4', class: 'remodel', word: '装修' },
        { code: '5', class: 'live', word: '入住' },
        { code: '6', class: 'rend', word: '入伙' },
        { code: '3', class: 'close', word: '空关' },
        { code: '10', class: 'other', word: '其他' },
        { code: '11', class: 'other', word: '其他' },
        { code: '12', class: 'other', word: '其他' },
        { code: '13', class: 'other', word: '其他' }
      ],
      rootNode: [],
      treeShow: false,
      onLoading: false,
      nodeKey: 'id',
      treeData: [],
      filterText: '',
      treeProps: {
        children: 'subTree',
        label: 'name',
        isLeaf: (data, node) => {
          if (data.nodeLevel == 0) {
            return data.subTree.length == 0
          }
          return !data.isParent
        }
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.roomtree.$refs.tree.filter(val)
    }
  },
  computed: {
    dataStateFormatter() {
      return val => {
        return this.dataStateMap.find(item => item.code == val)
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('roomTreeStore/UPDATE_CURRENT', {})
  },
  methods: {
    filterNode(value, data, node) {
      if (node.expanded) {
        node.expanded = false
      }
      if (!value) return true
      if (data.nodeLevel == 0 && node.label.indexOf(value) !== -1) {
        return true
      }
      return this.findSearKey(node, value, data)
    },

    // 递归搜索父级是否包含关键字
    findSearKey(node, key, data) {
      if (node.label.indexOf(key) !== -1) {
        if (data && data.nodeLevel == 1) {
          node.parent.expand()
        }
        return true
      } else {
        if (node.parent == null || Array.isArray(node.parent.data)) {
          node.expanded = this.findSearChildKey(node, key)
          return false
        } else {
          let result = this.findSearKey(node.parent, key, node.parent.data)
          return result
        }
      }
    },
    findSearChildKey(node, key) {
      if (node.childNodes && node.childNodes.length > 0) {
       return node.childNodes.some(v => {
          if (v.label.indexOf(key) !== -1) {
            return true
          } else {
            return this.findSearChildKey(v, key)
          }
        })
      } else {
        return false
      }
    },
    // filterNode(value, data, node) {
    //   if (node.expanded) {
    //     node.expanded = false
    //   }
    //   if (!value) return true
    //   if (data.name.indexOf(value) !== -1) {
    //     if (data.nodeLevel == 0) {
    //       node.expand()
    //     } else if (data.nodeLevel == 1) {
    //       node.parent.expand()
    //     }
    //   }
    //   return data.name.indexOf(value) !== -1 && data.nodeLevel <= 1
    // },
    async treeLoad(node, resolve) {
      if (node.level == 0) {
        node.loaded = true

        // 初次渲染
      } else if (node.level > 0 && node.level < 2) {
        node.loaded = true
        resolve(node.data.subTree)
      } else {
        let { nodeLevel, id } = node.data

        let list = await this.getProjectTree(nodeLevel, id)
        resolve(list)
      }
    },
    getRegionAndCommunityTree() {
      return new Promise((resolve, reject) => {
        this.$axios.get(getRegionAndCommunityTreeURL).then(res => {
          if (res.status === 100) {
            let { data } = res
            // data.forEach((val) => {
            //   val.icon = treeIconMap[val.iconSkin];
            // });
            resolve(data)
          }
        })
      })
    },
    getProjectTree(level, id) {
      let params = {
        nodeLevel: level
      }
      id && (params.id = id)
      return new Promise((resolve, reject) => {
        this.$axios.get(getProjectTreeURL, { params }).then(res => {
          if (res.status === 100) {
            let { data } = res
            // data.forEach((val) => {
            //   val.icon = treeIconMap[val.iconSkin];
            // });
            resolve(data)
          }
        })
      })
    },

    async getNodeFirstList() {
      const LoadingIns = Loading.service({
        target:this.$refs.roomtree.$refs['scrollbar'].$el,
        background:'#000c'
      })
      let list = await this.getProjectTree(0)
      LoadingIns.close()
      this.treeData = list
      console.log('treeChange')
      // this.operaIsShow = true
    },
    selectNode(data) {
      this.$store.commit('roomTreeStore/UPDATE_CURRENT', data)
    },
    async reloadTree() {
      await this.getNodeFirstList()
      this.filterText = ''
      this.$store.commit('roomTreeStore/UPDATE_CURRENT', {})
    }
  }
}
</script>

<style lang="scss" scoped>
.root-tree {
  .tree-header-wrapper{
    display:flex
  }
  .tree-refresh-btn{
    cursor: pointer;
    align-self: stretch;
    display: flex;
    font-size:15px;
    padding-top: 20px;
    align-items: center;
  }
  .roomtree-input-wrap {
    width: 80%;
    position: relative;
    padding: 20px 16px 0 16px;
    display: inline-block;
    box-sizing: border-box;
    .roomtree-input {
      width: 100%;
    }
    .roomtree-input-search-icon {
      position: absolute;
      right: 24px;
      top: 27px;
      width: 18px;
      height: 18px;
    }
  }
}
.roomtree-input ::v-deep .el-input {
  background: transparent;
  width: 100% !important;
}
.roomtree-input ::v-deep .el-input__inner {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 2px;
  height: 32px;
  line-height: 32px;
  padding: 0 34px 0 12px;
  color: #fff;
}
.custom-tree-node {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tree-group {
  width: 100%;
  padding: 10px;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  position: relative;
  .v-tree {
    width: 240px;
    height: 100%;
  }
  .tree-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    height: 100%;
  }
}
</style>
<style>
.zindex5000 {
  z-index: 5000 !important;
  max-width: 260px;
}
</style>