const menu = {
  namespaced: true,
  state: {
    menuResourceList: [],
    menuResource: {},
    menuInfo: {},
    menuItem: {},
    pageItem: {},
    menuMap: {},
    menuPermission: {}
  },
  mutations: {
    setMenuResourceList (state, menuResourceList) {
      state.menuResourceList = menuResourceList
    },
    setMenuResource (state, menuResource) {
      state.menuResource = menuResource
    },
    setMenuInfo (state, menuInfo) {
      state.menuInfo = menuInfo
    },
    setMenuItem (state, menuItem) {
      state.menuItem = menuItem
    },
    setPageItem (state, pageItem) {
      state.pageItem = pageItem
    },
    setMenuMap (state, menuMap) {
      state.menuMap = menuMap
    },
    setMenuPermission (state, menuPermission) {
      state.menuPermission = menuPermission
    }
  },
  getters: {
    getMenuResourceList: state => state.menuResourceList,
    getMenuResource: state => state.menuResource,
    getMenuInfo: (state) => (key) => {
      return state.menuInfo[key]
    },
    getMenuItem: state => state.menuItem,
    getPageItem: state => state.pageItem,
    getMenuMap: state => state.menuMap,
    getMenuPermission: (state) => (key) => {
      return state.menuPermission[key]
    }
  }
}

export default menu
