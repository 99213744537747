var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-control v-input-container" },
    [
      _vm.label
        ? _c("label", { domProps: { textContent: _vm._s(_vm.label) } })
        : _vm._e(),
      _c(
        "el-input",
        _vm._b(
          {
            ref: "input",
            attrs: {
              disabled: _vm.disabled,
              clearable: _vm.clearable,
              placeholder: _vm.defaultPlaceholder,
              maxlength: _vm.maxlength == -1 ? undefined : _vm.maxlength,
              minlength: _vm.minlength,
              size: _vm.size,
              autocomplete: _vm.autocomplete,
              "prefix-icon": _vm.prefixIcon,
              "suffix-icon": _vm.suffixIconName,
            },
            on: { blur: _vm.onBlur, focus: _vm.onFocus, clear: _vm.clear },
            model: {
              value: _vm.model,
              callback: function ($$v) {
                _vm.model = $$v
              },
              expression: "model",
            },
          },
          "el-input",
          { ..._vm.bind, ..._vm.nativeProps },
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }