<template>
  <div class="table-panel-wrapper">
    <vxe-tooltip ref="xTip"></vxe-tooltip>
    <vxe-table ref="multipleTable"
               :data="tableData"
               :max-height="maxHeight"
               :height="tableHeight"
               :loading="isSearching"
               show-overflow
               auto-resize
               resizable
               show-header-overflow
               highlight-hover-row
               highlight-current-row
               :border="border"
               :stripe="stripe"
               :row-class-name="tableRowClassName"
               :show-header="showHeader"
               :span-method="spanMethod"
               :header-cell-style="headerCellStyle"
               :cell-style="rowCellStyle"
               :checkbox-config="{ checkMethod: checkboxSelectable}"
               @cell-click="cellClick"
               @cell-dblclick="cellDbClick"
               @sort-change="sortChange"
               @select-change="select"
               @select-all="selectAll"
               @scroll="scroll">
      <vxe-table-column fixed="left"
                        type="checkbox"
                        width="55"
                        :align="checkAlign"
                        v-if="isMultiSelect">
      </vxe-table-column>
      <template v-for="(header, index) in headers">
        <vxe-table-column :key="index"
                          v-if="header.show !== false"
                          :field="header.prop"
                          :title="header.label"
                          :fixed="header.fixed"
                          show-overflow
                          :remote-sort="!!header.sort"
                          header-class-name="table-column-header"
                          :class-name="index === 0 ? '' : 'table-column'"
                          :width="header.width"
                          :min-width="header.minWidth || 80"
                          :align="header.align">
          <template slot-scope="scope">
            <div v-if="header.formatter"
                 class="vnode-wrapper">
              {{handleVNodeSlot(`${scope.$index}_${index}_slot`, header.formatter(scope.row, header.prop, scope.$index))}}
              <slot :name="`${scope.$index}_${index}_slot`">
              </slot>
            </div>
            <!--传入自定义VNode-->
            <div v-else-if="scope.row[header.prop] && isVNode(scope.row[header.prop].VNode)"
                 class="vnode-wrapper">
              {{handleVNodeSlot(`${scope.$index}_${index}_slot`, scope.row[header.prop].VNode)}}
              <slot :name="`${scope.$index}_${index}_slot`">
              </slot>
            </div>
            <!--传入html-->
            <!-- <div style="display: inline-block;" v-else-if="scope.row[header.prop] && scope.row[header.prop].html" v-html="scope.row[header.prop].value"></div> -->
            <!--传入自定义组件-->
            <component v-else-if="scope.row[header.prop] && scope.row[header.prop].component"
                       :is="scope.row[header.prop].component"
                       :row="scope.row"></component>
            <!--传入普通文本-->
            <div style="display: inline;"
                 v-else
                 v-text="scope.row[header.prop]"></div>
          </template>
        </vxe-table-column>
      </template>
      <!-- 操作列 begin -->
      <vxe-table-column
        v-if="hasOperateColumn"
        :title="operateColumnLabel"
        fixed="right"
        :width="operateColumnWidth"
        min-width="120"
      >
        <template slot-scope="scope">
          <slot name="operateSlot" :row="scope.row"></slot>
        </template>
      </vxe-table-column>
      <!--操作列 end -->
    </vxe-table>
  </div>
</template>

<script>
import { isVNode } from '../../../common/vdom.js'
// @group 业务组件
// @title TablePanel 表格
export default {
  name: 'table-panel',
  components: {},
  props: {
    // 表格样式
    tabStyle: {
      type: Object,
      // `'{}'`
      default: () => {
        return {
          width: '100%',
          backgroundColor: '#001d54'
        }
      }
    },
    // 表格高度
    tableHeight: {
      type: String,
      default: 'auto'
    },
    // 是否带有纵向边框
    border: {
      type: Boolean,
      default: true
    },
    // Table 的高度，默认为自动高度。如果 height 为 number 类型，单位 px；如果 height 为 string 类型，则这个高度会设置为 Table 的 style.height 的值，Table 的高度会受控于外部样式。
    autoHeight: {
      type: Boolean,
      default: true
    },
    // Table 的最大高度
    maxHeight: {
      type: String
    },
    // 是否为斑马纹 table
    stripe: {
      type: Boolean,
      default: true
    },
    // 是否需要多选列
    isMultiSelect: {
      type: Boolean,
      default: false
    },
    checkAlign: {
      type: String,
      default: 'center'
    },
    // 当内容过长被隐藏时显示 tooltip
    showOverflowTooltip: {
      type: Boolean,
      default: true
    },
    // 仅对 type=selection 的列有效，类型为 Function，Function 的返回值用来决定这一行的 CheckBox 是否可以勾选
    selectable: {
      type: Function
    },
    // rowCellStyle: {
    //   type: Function,
    //   default: ({ row, index }) => {
    //     // let table = this.$refs.multipleTable
    //     console.log(this)
    //     return {
    //       height: '50px'
    //       // lineHeight: '54px',
    //       // padding: '0',
    //       // border: 'none',
    //       // fontSize: '12px',
    //       // color: '#fff'
    //     }
    //   }
    // },
    // 行的 className 的回调方法，也可以使用字符串为所有行设置一个固定的 className
    tableRowClassName: {
      type: Function,
      default: ({ row, rowIndex }) => {
        if (rowIndex % 2 === 0) {
          // 奇数行
          return 'table-row-odd'
        } else {
          // 偶数行
          return 'table-row-even'
        }
      }
    },
    // 不传,没有排序
    // '2' 代表静态排序,
    // '1' 代表服务端排序
    sort: {
      type: String
    },
    // 搜索中的标识
    isSearching: {
      type: Boolean,
      default: false
    },
    // 是否需要操作列
    hasOperateColumn: {
      type: Boolean,
      default: true
    },
    // 操作列名
    operateColumnLabel: {
      type: String,
      default: '操作'
    },
    // 操作列列宽
    operateColumnWidth: {
      type: String
    },
    // 是否显示表头
    showHeader: {
      type: Boolean,
      default: true
    },
    // 表头数据
    headers: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 列表数据
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 接口请求数据成功后的回调函数，可以对数据做一些预处理，第一个参数为当前行的数据对象，第二个参数为当前行数
    handleData: Function,
    // 合并行或列的计算方法
    spanMethod: Function
  },
  data () {
    return {
      headerCellStyle: {
        // userSelect: 'auto',
        // backgroundColor: '#203a7f',
        // color: '#28DAFF',
        // fontSize: '12px',
        // height: '30px',
        // margin: 0,
        // padding: 0,
        // border: 'none'
      },
      selectedData: []
    }
  },
  watch: {
    tableData () {
      this.initSlot()
    }
  },
  computed: {
    isShowHeader () {
      if (this.showHeader) {
        if (this.headers && this.headers.length) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    rowCellStyle () {
      return ({ rowIndex }) => {
        let table = this.$refs.multipleTable.$el.getElementsByClassName('vxe-table--main-wrapper')[0].getElementsByClassName('vxe-table--body')[0].getElementsByClassName('vxe-body--row')[rowIndex]
        let cellStyle = {}
        if (table) {
          cellStyle.height = table.offsetHeight + 'px'
        }
        return cellStyle
      }
    }
  },
  mounted () {
  },
  methods: {
    initSlot () {
      let tableData = this.tableData
      let _this = this
      Array.isArray(tableData) && tableData.forEach((row, rowIndex) => {
        if (_this.handleData && typeof _this.handleData === 'function') {
          _this.handleData(row, rowIndex)
        }
      })
      this.recalculate()
    },
    // 判断传进来的是否VNode
    isVNode (vnode) {
      return isVNode(vnode)
    },
    // 建立VNode节点与slot的绑定关系
    handleVNodeSlot (slotName, VNode) {
      this.$slots[slotName] = VNode
    },
    // @vuese
    // 用于多选表格，切换某一行的选中状态，如果使用了第二个参数，则是设置这一行选中与否（selected 为 true 则选中）
    // @arg (rows, selected)接收两个参数 1.rows 需要切换选择状态的行
    // @arg 2.selected 若为 true 则将rows的选择状态置为选中
    toggleSelection (rows, selected) {
      if (rows) {
        setTimeout(() => {
          this.$refs.multipleTable.setSelection(rows, selected)
        }, 30)
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    // @vuese
    // 用于多选表格，切换所有行的选中状态
    toggleAllSelection () {
      setTimeout(() => {
        this.$refs.multipleTable.setAllSelection(true)
        this.selectAll({
          selection: this.tableData
        })
      }, 30)
    },
    handleSelectionChange (val) {
      this.$set(this, 'selectedData', val)
      // 当选择项发生变化时会触发该事件
      // @arg (val)接收一个参数 1.val 改变后的值
      this.$emit('handleSelectionChange', val)
    },
    // @vuese
    // 获取当前选中的所有项
    getSelectedData () {
      return this.selectedData
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select ({ selection, row }) {
      // 当用户手动勾选数据行的 Checkbox 时触发的事件
      // @arg (selection, row)接收两个参数 1.selection 所有选中行
      // 2.row 选中行的数据
      this.$emit('select', selection, row)
      this.handleSelectionChange(selection)
    },
    selectAll ({ selection }) {
      // 当用户手动勾选全选 Checkbox 时触发的事件
      // @arg (selection)接收一个参数 1.selection 所有选中行
      this.$emit('selectAll', selection)
      this.handleSelectionChange(selection)
    },
    handleSort (sort) {
      if (sort) {
        if (sort === '1') {
          return 'custom'
        } else if (sort === '2') {
          return ''
        } else {
          return undefined
        }
      } else {
        return undefined
      }
    },
    sortChange (sortObj) {
      let orderBy = sortObj.order
      if (orderBy === '"asc"') {
        orderBy = 'ASC'
      } else {
        orderBy = 'DESC'
      }
      let sort = {
        colName: sortObj.property,
        orderBy
      }
      this.$emit('sortChange', sort)
    },
    // @vuese
    // 高亮并滚动到选中的某一行
    setCurrentRow (index) {
      // let len = this.tableData.length
      // let contentHeight = this.$refs.multipleTable.bodyWrapper.scrollHeight
      // this.$refs.multipleTable.bodyWrapper.scrollTop = contentHeight / len * index
      let multipleTable = this.$refs.multipleTable
      multipleTable.scrollTo(0, index * 48)
      multipleTable.setCurrentRow(this.tableData[index])
    },
    // 当某个单元格被点击时会触发该事件
    cellClick ({ row, column, cell }, event) {
      this.$emit('cellClick', {
        row,
        column,
        cell,
        event
      })
    },
    // 当某个单元格被双击击时会触发该事件
    cellDbClick ({ row, column, cell }, event) {
      this.$emit('cellDbClick', {
        row,
        column,
        cell,
        event
      })
    },
    // 鼠标移入单元格
    cellMouceEnter ({ row, column, cell }, event) {
      this.$emit('cellMouceEnter', {
        row,
        column,
        cell,
        event
      })
    },
    checkboxSelectable ({ row, rowIndex }) {
      return this.selectable ? this.selectable(row, rowIndex) : true
    },
    scrollToRow () {
      // let thisTable = this.$refs.multipleTable
      // let row = this.tableData[40]
      // let index = thisTable.getTableData()
    },
    scroll () {
      // console.log(123)
    },
    recalculate () {
      this.$nextTick(() => {
        if (this.tableHeight === 'auto') {
          let a = this.$refs.multipleTable.$el.getElementsByClassName('vxe-table--body-wrapper')[0].getElementsByClassName('vxe-table--body')[0]
          let height = this.maxHeight && this.maxHeight < a.offsetHeight ? this.maxHeight : a.offsetHeight < 60 && this.tableData.length === 0 ? 60 : a.offsetHeight
          this.$refs.multipleTable.$el.getElementsByClassName('vxe-table--body-wrapper')[0].style.height = height + 'px'
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.table-panel-wrapper {
  width: 100%;
  height: 100%;
  font-size: 12px;
  color: #fff;
  .vnode-wrapper {
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
::v-deep {
  .vxe-table .vxe-body--row.row--hover {
    background-color: #f5f7fa !important;
  }
  .vxe-checkbox.is--indeterminate>input:not(:checked)+.vxe-checkbox--icon:before {
    left: 0;
  }
  .vxe-table td,th {
    padding: 5px 0;
  }
  .vxe-table--loading {
    background: rgba(255,255,255,0.5);
  }
}
</style>
<style lang="scss">
.vxe-table--tooltip-wrapper {
  z-index: 3000 !important;
}
</style>
