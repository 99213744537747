var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.menuGroupType === "left"
    ? _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.themeSetFinish && !_vm.isWorkTop,
              expression: "themeSetFinish && !isWorkTop",
            },
          ],
          staticClass: "nav-menu-panel",
          class: { open: _vm.status },
        },
        [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("menu-group", {
                attrs: {
                  group: _vm.menuList,
                  status: _vm.status,
                  centerItem: _vm.centerItem,
                  firstItem: _vm.firstItem,
                  secondItem: _vm.secondItem,
                  thirdItem: _vm.thirdItem,
                  activeMenuId: _vm.activeMenuId,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "bottom" }, [
            _c(
              "div",
              { staticClass: "control", on: { click: _vm.statusChange } },
              [_c("div", { staticClass: "icon-status" })]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }