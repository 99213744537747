var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "v-control v-timepicker-contianer",
      class: _vm.isRange ? "range-wrapper" : "single-wrapper",
    },
    [
      _vm.label
        ? _c("label", { domProps: { textContent: _vm._s(_vm.label) } })
        : _vm._e(),
      _vm.isRange
        ? _c("date-picker", {
            ref: "timepicker",
            attrs: {
              type: "time",
              "value-type": "format",
              range: true,
              shortcuts: [],
              disabled: _vm.disabled,
              editable: _vm.editable,
              clearable: _vm.clearable,
              placeholder: _vm.placeholder,
              minuteStep: _vm.innerMinuteStep,
              format: _vm.format,
              "append-to-body": _vm.appendToBody,
              width: _vm.width,
              "range-separator": _vm.rangeSeparator,
            },
            on: { change: _vm.onChange },
            model: {
              value: _vm.time,
              callback: function ($$v) {
                _vm.time = $$v
              },
              expression: "time",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }