export default {
    protocol : `
    <div style="line-height: 28px;padding: 20px 12px;text-align: left;">
    <h2 style="text-align: center">智慧社区全域服务平台物业项目入驻服务协议</h2>
    <br><br>

    &nbsp;&nbsp;&nbsp;&nbsp;<b>《智慧社区全域服务平台物业项目入驻服务协议》</b>（以下简称“本协议”）是由智慧社区全域服务平台（以下简称“平台”，包括PC端、移动端及应用程序）的客户（以下简称“客户”或“您”）与平台运营方，即浙江慧享信息科技有限公司（以下简称“慧享科技”或“本公司”）及其关联公司之间，就平台服务等相关事宜共同缔结。
    <b>
    本协议具有合同效力，您应当阅读并遵守本协议。请您务必审慎阅读、充分理解各条款内容，特别是以黑体加粗形式提示您注意的条款，另请您特别理解和注意如下规定：<br>
    &nbsp;&nbsp;&nbsp;&nbsp;★ 您应保证您填写的联系人和联系方式真实有效，如果由于您的原因造成未收到通知而导致服务中止或终止的，不利后果由您自行承担。<br>
    &nbsp;&nbsp;&nbsp;&nbsp;★ 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国的法律。如就本协议内容或其执行发生任何争议，则双方应首先通过友好协商方式解决；协商不成的，任何一方均应向杭州市余杭区人民法院提起诉讼。</b>
    <br><br>
    
    <h3>
    一、 协议确认
    </h3>
   
    &nbsp;&nbsp;&nbsp;&nbsp;1.1 您同意并点击确认本协议条款并完成项目入驻申请程序后，才能成为平台的正式企业用户，并享受相应服务。<br>
    &nbsp;&nbsp;&nbsp;&nbsp;1.2 根据国家法律法规变化及平台运营需要，<b>慧享科技有权对本协议条款不时进行修改，修改后的协议一经发布或经您确认同意后即刻生效，并代替原来的协议。</b>您可在平台网站随时查阅最新协议。您继续使用平台的行为将视为您接受了本公司不时更新的用户服务协议。<br>
    &nbsp;&nbsp;&nbsp;&nbsp;1.3 本协议包括协议正文及所有平台已经发布的，或未来可能发布或更新的各类规则，包括但不限于：《用户协议及隐私政策》、《软件许可协议》等。<br>
    &nbsp;&nbsp;&nbsp;&nbsp;<b>所有规则为本协议不可分割的组成部分，与本协议正文具有同等法律效力。</b>在您申请成为臻平台企业用户前，请仔细阅读本协议的正文内容及以上各类规则。<br>
    &nbsp;&nbsp;&nbsp;&nbsp;1.4 在您完成项目入驻申请程序后，即表示您自愿接受本协议，正式成为平台的企业用户，并认可本协议全部条款内容。若您不同意本协议，或对本协议中的条款存在任何疑问，请您立即停止平台项目入驻申请程序，并可以选择不使用本平台服务。<br>
    &nbsp;&nbsp;&nbsp;&nbsp;1.5 您申请成功后，即视为您确认自己具有享受平台无偿或有偿服务相应的权利能力和行为能力，能够独立承担法律责任。<br>
    &nbsp;&nbsp;&nbsp;&nbsp;1.6 平台保留在法律允许的范围内独自决定拒绝服务、关闭用户账户、清除用户数据等的权利。
    <h3>二、服务定义</h3>
    
    &nbsp;&nbsp;&nbsp;&nbsp;2.1 智慧社区应用：指基于本平台为客户提供的标准化智慧社区应用，或针对客户智慧社区需求进行定制开发后的应用。<br>
    &nbsp;&nbsp;&nbsp;&nbsp;2.2 手机端应用：指慧享科技为客户提供的标准版的邑生活APP软件（或支付宝小程序、微信小程序），以及邑管理APP软件、邑商家APP软件，或另行约定，为客户定制的专属手机端应用。<br>
    &nbsp;&nbsp;&nbsp;&nbsp;2.3 运营服务：主要指针对平台中的客户运营使用进行的服务，包括但不限于对客户账号及应用的关停、启用等服务。<br>
    &nbsp;&nbsp;&nbsp;&nbsp;2.4 客户用户：指客户开通平台后的直接使用人，包括客户的员工，所辖项目的业主、住户。<br>
    &nbsp;&nbsp;&nbsp;&nbsp;2.5 使用期限：指客户按约定使用平台智慧社区应用的期限，也即平台向客户提供平台服务的期限。<br>
    <h3>三、服务内容</h3>
    &nbsp;&nbsp;&nbsp;&nbsp;3.1 慧享科技按平台能力向客户提供平台使用授权，向客户所辖项目提供平台SaaS服务。若甲方需要乙方提供定制开发服务的，则乙方在完成定制开发服务后为甲方提供智慧社区应用平台定制模块业务服务。
    <h3> 四、服务启用、续期与终止</h3>
   
    &nbsp;&nbsp;&nbsp;&nbsp;4.1 客户完成项目入驻申请程序且经慧享科技审核通过后，平台将授予客户一个企业账号，客户可以通过该账号登录平台进行相关的业务管理。<br>
    &nbsp;&nbsp;&nbsp;&nbsp;<b>客户仅享有企业账号的使用权，</b>使用期限自平台提供客户平台账号日期起计算。<br>
    &nbsp;&nbsp;&nbsp;&nbsp;<b>客户不得将企业账号以任何有偿或无偿的形式进行转让、赠与、出租或者以其他方式允许他人使用。</b><br>
    &nbsp;&nbsp;&nbsp;&nbsp;<b>客户对其账号下的一切行为承担全部法律责任。</b><br>
    &nbsp;&nbsp;&nbsp;&nbsp;4.2 本协议约定服务期限为1年，即客户账号提交之日起。合同到期后，客户应继续向慧享科技确认续期（如有偿服务的，应履行缴费义务），合同自动顺延。<br>
    &nbsp;&nbsp;&nbsp;&nbsp;4.3 平台整合社会优质商家资源，为客户及其用户提供社区商业服务。<br>
    &nbsp;&nbsp;&nbsp;&nbsp;4.4 鉴于网络服务的特殊性，平台无义务审核是否是您本人使用该组用户名及密码，仅审核用户名及密码是否与数据库中保存的一致，任何人只要输入的用户名及密码与数据库中保存的一致，即可凭借该组用户名及密码登录并获得臻贝所提供的各类服务，所以即使您认为您的账户登录行为并非您本人所为，慧享科技将不承担因此而产生的任何责任。<br>
    &nbsp;&nbsp;&nbsp;&nbsp;<b>4.5 若您在平台成功申请企业账户后，连续6个月时间内未使用该账户(包括未创建项目、未产生业务)，则平台有权对您的账户进行清理。</b><br>
    &nbsp;&nbsp;&nbsp;&nbsp;4.6 企业用户向平台提出注销账户申请，经慧享科技审核同意后，由平台注销该用户账户，本协议即告终止。但在注销该用户账户后，平台仍有权：<br>
    &nbsp;&nbsp;&nbsp;&nbsp;4.6.1 保留该企业用户的注册信息及过往的全部业务行为记录；<br>
    &nbsp;&nbsp;&nbsp;&nbsp;4.6.2 如用户在账户注销前在平台上存在违法行为或违反本协议的行为，慧享科技仍可行使本协议所规定的权利。<br>
    &nbsp;&nbsp;&nbsp;&nbsp;4.7在下列情况下，平台可以通过注销用户账户的方式单方解除本协议：<br>
    &nbsp;&nbsp;&nbsp;&nbsp;4.7.1 在用户违反本协议相关规定时，平台有权暂停或终止向该用户提供服务。如该用户在平台暂停或终止提供服务后，再一次直接或间接或以他人名义注册为平台企业用户的，则平台有权再次暂停或终止向该用户提供服务；<br>
    &nbsp;&nbsp;&nbsp;&nbsp;4.7.2 一经发现用户注册信息中的内容是虚假的，平台即有权终止向该用户提供服务；<br>
    &nbsp;&nbsp;&nbsp;&nbsp;4.7.3 本协议修改或更新时，如用户表示不愿接受新的服务协议的，平台有权终止向该用户提供服务；<br>
    &nbsp;&nbsp;&nbsp;&nbsp;4.7.4 平台认为需终止提供服务的其他情况。<br>
    &nbsp;&nbsp;&nbsp;&nbsp;4.8 用户理解并同意，即便在本协议终止及用户的服务被终止后，平台仍有权：<br>
    &nbsp;&nbsp;&nbsp;&nbsp;4.8.1 继续保存并使用您的用户信息；<br>
    &nbsp;&nbsp;&nbsp;&nbsp;4.8.2继续向用户主张在其使用平台服务期间因违反法律法规、本协议及平台规则而应承担的责任。<br>
    <h3>五、数据安全</h3>
    
    &nbsp;&nbsp;&nbsp;&nbsp;<b>5.1 为保护您的权益，您在设定账户的密码时，请勿使用重复性或者连续数字的简单密码。请您对密码加以妥善保管，切勿将密码告知他人，因密码保管不善而造成的所有损失由您自行承担。</b><br>
    &nbsp;&nbsp;&nbsp;&nbsp;5.2 鉴于平台对用户使用终端（包括APP、小程序）进行鉴权，经用户授权可以收集必要的用户设备信息。慧享科技承诺不向任何第三方披露该数据，但在如下情形下，平台有权披露或向第三方提供，且不承担任何责任：<br>
    &nbsp;&nbsp;&nbsp;&nbsp;5.2.1事先获得了您的授权；<br>
    &nbsp;&nbsp;&nbsp;&nbsp;5.2.2根据法律法规或司法机关、行政机关的书面要求。<br>
    <h3>六、免责事由</h3>
    
    &nbsp;&nbsp;&nbsp;&nbsp6.1 本协议所称不可抗力，是指各方由于地震、台风、水灾、火灾、战争以及其它不能预见，并且对其发生和后果不能防止或不能避免且不可克服的客观情况。因不可抗力不能履行合同的，根据不可抗力的影响，部分或全部免除责任，但法律另有规定的除外。迟延履行合同后发生不可抗力的，不能免除责任。<br>
    &nbsp;&nbsp;&nbsp;&nbsp6.2 平台提供的使用服务可能受到客户及其用户的移动终端、网络设备、电信系统、第三方软硬件等的不利影响，平台对因上述问题造成的任何服务迟延或服务质量下降等不承担责任。<br>
    &nbsp;&nbsp;&nbsp;&nbsp6.3 平台对下列事项造成的使用服务问题不承担责任：<br>
    &nbsp;&nbsp;&nbsp;&nbsp6.3.1 因客户的设备所引起的；<br>
    &nbsp;&nbsp;&nbsp;&nbsp6.3.2因客户及其用户的安装等操作活动所引起的；<br>
    &nbsp;&nbsp;&nbsp;&nbsp6.3.3因客户及其用户的疏忽或不当操作所引起的。<br>
    &nbsp;&nbsp;&nbsp;&nbsp6.4因计算机及智慧平台的特殊性，慧享科技在已尽网络安全保障等勤勉义务的情况下，对因黑客破坏、计算机病毒程序入侵等给您造成损失的不承担任何责任。<br>
    &nbsp;&nbsp;&nbsp;&nbsp6.5平台对下述事项不承担责任：<br>
    &nbsp;&nbsp;&nbsp;&nbsp6.5.1 第三方对客户提出的索赔要求；<br>
    &nbsp;&nbsp;&nbsp;&nbsp6.5.2不因平台原因，客户的间接损失或利润的损失、生意的丢失、营业额的减少及任何其他后果性的经济损失。<br>
    <h3>七、其他</h3>
    
    &nbsp;&nbsp;&nbsp;&nbsp7.1 本合同生效后，无论您或慧享科技的名称、组织形式、企业性质、经营范围、注册资本、投资者等发生任何变更，另一方应继续或要求其权利义务的合法继承人恪守并履行其在本合同项下之相关义务。
    </div>
    `
}