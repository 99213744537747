<template>
  <div class="page">
    <form-panel :form="form" :hasHeader="false">
      <div class="main">
        <div class="box">
          <el-form-item
            label="项目名称"
            prop="projectName"
            :rules="[
              { required: true, message: '请填写项目名称', trigger: 'blur' }
            ]"
          >
            <v-input
              :maxlength="30"
              :width="width"
              placeholder="请按标准项目名称填写,不超过30字符"
              v-model="form.projectName"
            />
          </el-form-item>
          <el-form-item
            label="项目区划"
            :rules="[
              {
                type: 'array',
                required: true,
                message: '请选择项目区划',
                trigger: 'change'
              }
            ]"
          >
            <area-select
              :getChinaAreaTree1="getChinaAreaTree"
              :width="width"
              extraClass="projectArea"
              style="display: inline-block;"
              :codes.sync="selectedArea"
              @change="change"
              :detail-addr="false"
            >
            </area-select>
          </el-form-item>
          <el-form-item
            label="详细地址"
            prop="projectAddress"
            :rules="[
              { required: true, message: '请填写详细地址', trigger: 'blur' }
            ]"
          >
            <v-textarea
              :maxlength="50"
              :width="width"
              :rows="2"
              placeholder="请输入详细地址信息,如街道、门牌号、小区、楼栋号、单元等信息,不超过50字符"
              v-model="form.projectAddress"
            ></v-textarea>
          </el-form-item>
          <el-form-item
            label="项目邮编"
            prop="projectCode"
            :rules="[
              { required: true, message: '请填写项目邮编', trigger: 'blur' }
            ]"
          >
            <v-input
              :maxlength="6"
              :width="width"
              placeholder="请输入项目邮编"
              v-model="form.projectCode"
            />
          </el-form-item>
          <el-form-item
            label="项目类型"
            :rules="[
              {
                type: 'array',
                required: true,
                message: '请选择项目类型',
                trigger: 'change'
              }
            ]"
          >
            <v-select
              :width="width"
              v-model="form.projectType"
              :options="statusOps"
            ></v-select>
          </el-form-item>
          <el-form-item label="建筑面积">
            <v-input
              :width="width"
              placeholder="0.00(单位:平方米)"
              :maxlength="11"
              v-model="form.buildingArea"
            />
          </el-form-item>
          <el-form-item label="交付户数">
            <v-input
              :width="width"
              placeholder="0(单位:户)"
              :maxlength="10"
              v-model="form.liveNumber"
            />
          </el-form-item>
          <el-form-item label="入住户数">
            <v-input
              :width="width"
              placeholder="0(单位:户)"
              :maxlength="10"
              v-model="form.residentNumber"
            />
          </el-form-item>
        </div>
        <div class="box">
          <el-form-item
            label="所属企业"
            prop="affiliatedCompany"
            :rules="[
              { required: true, message: '请填写所属企业', trigger: 'blur' }
            ]"
          >
            <v-input
              :maxlength="30"
              :width="width"
              placeholder="请填写营业执照上企业名称,不超过30字符"
              v-model="form.affiliatedCompany"
            />
          </el-form-item>
          <el-form-item
            style="height: 118px;"
            label="营业执照"
            prop="text"
            :rules="[
              { required: true, message: '请上传营业执照', trigger: 'blur' }
            ]"
          >
            <v-uploader
              :fileHeight="112"
              :fileWidth="112"
              :fileSize="1"
              ref="myUpload"
              :extraData="uploadParams"
              :action="action"
              :multiple="false"
              :onlyImage="true"
              :limit="1"
              :auto-upload="true"
              :fileList.sync="fileList"
            >
            </v-uploader>
          </el-form-item>
          <el-form-item
            label="联系人"
            prop="contactPerson"
            :rules="[
              { required: true, message: '请填写联系人', trigger: 'blur' }
            ]"
          >
            <v-input
              :maxlength="20"
              :width="width"
              placeholder="请输入联系人"
              v-model="form.contactPerson"
            />
          </el-form-item>
          <el-form-item
            label="联系电话"
            prop="phoneNumber"
            :rules="[
              { required: true, message: '请填写联系电话', trigger: 'blur' }
            ]"
          >
            <v-input
              :maxlength="11"
              :width="width"
              placeholder="请输入联系电话"
              v-model="form.phoneNumber"
            />
          </el-form-item>
          <el-form-item
            label="验证码"
            prop="verificationCode"
            :rules="[
              { required: true, message: '请填写验证码', trigger: 'blur' }
            ]"
          >
            <v-input
              :maxlength="6"
              :width="width"
              placeholder="请输入验证码"
              v-model="form.verificationCode"
            />
            <span @click="onSendCode" class="code">{{ sendCode }}</span>
          </el-form-item>
          <el-form-item label="附言">
            <v-textarea
              :width="width"
              :rows="4"
              placeholder="可选,不超过200字符"
              v-model="form.postscript"
            ></v-textarea>
          </el-form-item>
        </div>
        <div class="protocol">
          <img
            @click="form.settlementAgreement = true"
            v-if="!form.settlementAgreement"
            src="./images/protocol.png"
            alt=""
          />
          <img
            @click="form.settlementAgreement = false"
            v-else
            src="./images/protocolSelect.png"
            alt=""
          />
          已阅读并同意<span @click="protocolDialog = true">平台入驻协议</span>
        </div>
        <div @click="submit" class="submit">申请入驻</div>
      </div>
    </form-panel>
    <el-dialog
      :width="dialogWidth"
      :visible.sync="protocolDialog"
      @closed="protocolDialog = false"
    >
      <div v-html="protocol.protocol"></div>
    </el-dialog>
  </div>
</template>

<script>
import { AreaSelect } from '@/components/bussiness'
import { vUploader } from 'components/control/index'
import protocol from './protocol.js'
let mobileType
let wOrigin = window.location.origin
if (wOrigin == 'https://shushuapitest.4001113900.com:10020') {
  mobileType = '666'
} else if (wOrigin == 'https://yshyanfa.lcfw.co') {
  mobileType = '666'
} else {
  mobileType = '666'
}
export default {
  components: {
    AreaSelect,
    vUploader
  },
  data () {
    return {
      getChinaAreaTree: `${API_CONFIG.origin}h5api/project/getProjectChinaAreaTree`,
      protocol,
      protocolDialog: false,
      action: API_CONFIG.uploadURL + '?module=order',
      width: 300,
      selectedArea: [],
      form: {
        projectName: '',
        affiliatedCompany: '',
        projectProvince: '',
        projectCity: '',
        projectArea: '',
        projectAddress: '',
        projectCode: '',
        contactPerson: '',
        projectType: '1',
        buildingArea: '',
        liveNumber: '',
        residentNumber: '',
        phoneNumber: '',
        verificationCode: '',
        postscript: '',
        settlementAgreement: false,
        businessLicense: ''
      },
      fileList: [],
      uploadParams: {
        type: 'order'
      },
      statusOps: [],
      sendCode: '发送验证码',
      isResing: false
    }
  },
  created () {
    this.getCommunityTypeList()
  },
  computed: {
    dialogWidth () {
      let dialogWidths = '980px'
      if (
        document.body.clientWidth < 1441 ||
        document.body.offsetWidth < 1441
      ) {
        dialogWidths = '880px'
      } else {
        dialogWidths = '980px'
      }
      return dialogWidths
    }
  },
  methods: {
    change (selectedArea) {
      // 取到省市区的对象
      if (selectedArea) {
        if (selectedArea.province) {
          this.form.projectProvince = selectedArea.province.name
        }
        if (selectedArea.city) {
          this.form.projectCity = selectedArea.city.name
        }
        if (selectedArea.area) {
          this.form.projectArea = selectedArea.area.name
        }
      }
    },
    initData () {
      this.form = {
        projectName: '',
        affiliatedCompany: '',
        projectProvince: '',
        projectCity: '',
        projectArea: '',
        projectAddress: '',
        projectCode: '',
        contactPerson: '',
        projectType: '1',
        buildingArea: '',
        liveNumber: '',
        residentNumber: '',
        phoneNumber: '',
        verificationCode: '',
        postscript: '',
        settlementAgreement: false,
        businessLicense: ''
      }
      this.selectedArea = []
      this.fileList = []
    },
    submit () {
      let numberReg = /^[0-9]*$/
      let decimalReg = /^\d{0,8}\.{0,1}(\d{1,2})?$/
      if (!decimalReg.test(this.form.buildingArea)) {
        this.$message('输入正确建筑面积，小数后最多2位')
        return
      } else if (
        this.form.liveNumber !== '' &&
        !numberReg.test(this.form.liveNumber)
      ) {
        this.$message('请输入正确交付户数')
        return
      } else if (
        this.form.residentNumber !== '' &&
        !numberReg.test(this.form.residentNumber)
      ) {
        this.$message('请输入正确入驻户数')
        return
      }
      if (this.isResing) {
        return
      }
      if (this.$refs.myUpload.fileList[0] === undefined) {
        this.$message('请上传营业执照')
        return
      } else if (this.form.projectName === '') {
        this.$message('请输入项目名称')
        return
      } else if (this.form.projectProvince === '') {
        this.$message('请选择项目区划')
        return
      } else if (this.form.projectAddress === '') {
        this.$message('请输入详细地址')
        return
      } else if (
        this.form.projectCode === '' ||
        !numberReg.test(this.form.projectCode)
      ) {
        this.$message('请输入正确项目邮编')
        return
      } else if (this.form.projectType === '') {
        this.$message('请选择项目类型')
        return
      } else if (this.form.affiliatedCompany === '') {
        this.$message('请输入所属企业')
        return
      } else if (this.form.contactPerson === '') {
        this.$message('请输入联系人')
        return
      } else if (
        this.form.phoneNumber === '' ||
        !numberReg.test(
          this.form.phoneNumber ||
            toString(this.form.phoneNumber).slice(0, 1) !== 1
        )
      ) {
        this.$message('请输入联系电话')
        return
      } else if (
        this.form.verificationCode === '' ||
        !numberReg.test(this.form.verificationCode)
      ) {
        this.$message('请输入正确验证码')
        return
      } else if (!this.form.settlementAgreement) {
        this.$message('请勾选项目入驻协议')
        return
      }
      this.form.businessLicense = this.$refs.myUpload.fileList[0].url
      let params = this.$qs.stringify(this.form)
      this.isResing = true
      this.$axios
        .put(
          `${API_CONFIG.origin}h5api/project/application?${params}`,
          {},
          { headers: { token: '', mobileType: mobileType } }
        )
        .then(res => {
          if (res.status === 100) {
            this.$message({
              message: '提交成功',
              type: 'success'
            })
            this.$emit('submit1')
            this.isResing = false
            this.initData()
          } else {
            this.$message(res.msg)
            this.isResing = false
          }
        })
    },
    onSendCode () {
      let that = this
      if (this.form.phoneNumber.length !== 11 || isNaN(this.form.phoneNumber)) {
        this.$message('请输入正确手机号')
        return
      }
      if (this.sendCode !== '发送验证码') {
        return
      }
      let time = 60
      let t = setInterval(() => {
        if (time > 0) {
          that.sendCode = `${time--}s后重新发送`
        } else {
          that.sendCode = '发送验证码'
          clearInterval(t)
        }
      }, 1000)
      this.$axios
        .post(
          `${API_CONFIG.origin}h5api/project/projectSendCode?phone=${this.form.phoneNumber}&type=7`,
          {},
          { headers: { mobileType: mobileType, token: '' } }
        )
        .then(res => {
          if (res.status === 100) {
            this.$message({
              message: '短信已发送',
              type: 'success'
            })
          } else {
            this.$message(res.msg)
          }
        })
    },
    getCommunityTypeList () {
      this.$axios
        .get(`${API_CONFIG.origin}h5api/project/getCommunityTypeList`, {
          headers: { token: '' }
        })
        .then(res => {
          if (res.status === 100) {
            res.data.forEach((item, index) => {
              let arr = { text: item.value, value: item.code, id: item.id }
              this.statusOps.push(arr)
            })
          }
        })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  padding-top: 60px;
  overflow-y: auto;
  height: 460px;
  .main {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .box {
      width: 50%;
      text-align: left;
      margin-top: 12px;
    }
  }
}
.code {
  position: absolute;
  right: 18%;
  // left: 77%;
  color: #0080ff;
  cursor: pointer;
}
@media screen and (max-width: 1440px) {
  .code {
    position: absolute;
    right: 5%;
    // left: 77%;
    color: #0080ff;
    cursor: pointer;
  }
}
.submit {
  margin: 0 auto;
  width: 240px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  background-image: linear-gradient(to right, #5cb0ff, #5bffce);
  border-radius: 4px;
}
.submit:hover {
  background-image: linear-gradient(to right, #2390f6, #36ffc3);
}
.protocol {
  width: 100%;
  padding: 20px;
  font-size: 12px;
  color: #5e5e67;
  text-align: center;
  img {
    width: 16px;
    height: 16px;
    vertical-align: bottom;
  }
  span {
    color: #0080ff;
    cursor: pointer;
  }
}
::v-deep .form-panel-container {
  background-color: transparent;
}
::v-deep .el-form-item__label {
  text-align: left;
  width: 106px !important;
}
::v-deep .el-form-item__content {
  margin-left: 0 !important;
}
::v-deep .el-input .el-input__inner {
  // width: 300px;
  height: 36px;
}
::v-deep .el-textarea__inner {
  width: 300px;
}
</style>
