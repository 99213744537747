import axios from '@/axios'
import { mapHelper } from 'common/utils'
const map = {
  namespaced: true,
  state: {
    payType: [], // 支付方式
    orderSource: [], // 数据来源
    visitorEnterType: [] // 进入方式
  },
  mutations: {
    setPayType (state, payType) {
      state.payType = payType
    },
    setOrderSource (state, orderSource) {
      state.orderSource = orderSource
    },
    setVisitorEnterType (state, visitorEnterType) {
      state.visitorEnterType = visitorEnterType
    }
  },
  actions: {
    query ({ commit }, { type, mutationName }) {
      const url = `${API_CONFIG.controlBaseURL}sysCodeword/geSysCodeWord`
      const params = {
        typeValue: type
      }
      return axios.get(url, { params }).then((res) => {
        const { status, data } = res
        if (status === 100) {
          let list = data || []
          list = list.map(item => {
            return {
              ...item,
              text: item.value,
              value: item.code
            }
          })
          commit(mutationName, list)
        }
      })
    },
    queryPayType ({ dispatch }) {
      return dispatch('query', {
        type: 'STD_PAY_TYPE',
        mutationName: 'setPayType'
      })
    },
    queryOrderSource ({ dispatch }) {
      return dispatch('query', {
        type: 'STD_ORDER_SOURCE',
        mutationName: 'setOrderSource'
      })
    },
    queryVisitorEnterType ({ dispatch }) {
      return dispatch('query', {
        type: 'STD_VISITOR_ENTER_TYPE',
        mutationName: 'setVisitorEnterType'
      })
    }
  },
  getters: {
    getPayType: (state) => () => {
      const {
        map: payTypeMap,
        setOps: setPayTypeOps
      } = mapHelper.setMap(state.payType)
      return {
        payTypeMap,
        setPayTypeOps
      }
    },
    getOrderSource: (state) => () => {
      const {
        map: orderSourceMap,
        setOps: setOrderSourceOps
      } = mapHelper.setMap(state.orderSource)
      return {
        orderSourceMap,
        setOrderSourceOps
      }
    },
    getVisitorEnterType: (state) => () => {
      const {
        map: visitorEnterTypeMap,
        setOps: setVisitorEnterTypeOps
      } = mapHelper.setMap(state.visitorEnterType)
      return {
        visitorEnterTypeMap,
        setVisitorEnterTypeOps
      }
    }
  }
}

export default map
