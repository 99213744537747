var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabs-panel-container" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "tabs",
          on: { change: _vm.change },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        _vm._l(_vm.tabs, function (item, index) {
          return _c("el-radio-button", {
            key: index,
            attrs: { label: item.label, disabled: item.disabled },
          })
        }),
        1
      ),
      _vm.showComponent && _vm.keepAlive
        ? _c(
            "div",
            { staticClass: "component-container" },
            [
              _c(
                "keep-alive",
                [
                  _c(
                    _vm.tabComponent,
                    _vm._b(
                      { tag: "component", on: { contact: _vm.contact } },
                      "component",
                      _vm.tabProps,
                      false
                    )
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showComponent && !_vm.keepAlive
        ? _c(
            "div",
            { staticClass: "component-container" },
            [
              _c(
                _vm.tabComponent,
                _vm._b(
                  { tag: "component", on: { contact: _vm.contact } },
                  "component",
                  _vm.tabProps,
                  false
                )
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }