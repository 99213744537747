<template>
  <div class="v-control v-select2-container">
    <label class='select2-text'
           v-if="label"
           v-text="label"></label>
    <div class="select2Parent"
         :style="{ width: width + 'px' }"
         v-if="!isText">
      <vSelect ref="select"
               class="vue-select2"
               v-model="defaultBackText"
               :options="selectOption"
               :placeholder="defaultPlaceholder"
               :onSearch="onSearch"
               @change="onChange"
               :disabled="disabled"
               :filterable="false"
               :inputId="randomId"
               @search:blur="onblur"
               @search:focus="onfocus"
               :resetOnOptionsChange="resetOnOptionsChange"
               >
        <span slot="no-options">{{noOptions}}</span>
      </vSelect>
    </div>
    <div class="selectSpan"
         v-if="isText">
      <span>{{defaultBackText}}</span>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import _ from 'lodash'
import qs from 'querystring'
import emitter from 'element-ui/src/mixins/emitter'

const startSearchLength = 2
const limitCnt = 20
// @group 基础组件  新湖缴费规则设置 新增修改远程搜索下拉选择框
// @title Select5 远程搜索选择框
export default {
  name: 'v-select5',
  mixins: [emitter],
  components: {
    vSelect
  },
  props: {
    // 搜索地址
    searchUrl: {
      type: String,
      required: true
    },
    // 回显搜索地址
    backSearchUrl: String,
    // 请求的媒体类型
    contentType: {
      type: String,
      // `'application/x-www-form-urlencoded'`
      default: 'application/x-www-form-urlencoded'
    },
    communityId: String,
    // 是否url后和body里两种方式一起传参
    allDataType: {
      type: Boolean,
      // `'false'`
      default: false
    },
    // 传参方式 get/post等....
    method: {
      type: String,
      // `'get'`
      default: 'get'
    },
    // 搜索是否以formData格式搜索
    formData: {
      type: Boolean,
      // `'get'`
      default: false
    },
    // 搜索请求参数 text:输入搜索 value:回显请求参数
    request: {
      type: Object,
      // `'{text: 'name',value: 'id'}'`
      default: () => {
        return {
          text: 'name',
          value: 'id'
        }
      }
    },
    // 选择下拉选择项返回参数 text:文本  value:保存或者使用
    response: {
      type: Object,
      // `'{text: 'name',value: 'id'}'`
      default: () => {
        return {
          text: 'name',
          value: 'id'
        }
      }
    },
    // 是否文本显示
    isText: {
      type: Boolean,
      // `'false'`
      default: false
    },
    // 左侧文本
    label: String,
    // 占位文本
    placeholder: String,
    // 是否禁用
    disabled: Boolean,
    // 绑定值
    value: [String, Number],
    // 搜索带上的其它的参数
    subjoin: Object,
    // 不使用接口查询的回显参数
    backText: [String, Number],
    // 是否清除选中项
    clearSelectValue: {
      type: Boolean,
      // `'true'`
      default: true
    },
    // 处理接口返回数据
    handleData: Function,
    // 回显处理数据
    echoRequestData: Function,
    // 输入框宽度
    width: {
      type: Number,
      // `144`
      default: 144
    },
    // 外部参数更改时清空
    subjoinChangeClear: {
      type: Boolean,
      default: true
    },
    // 初始设置选择项
    initOption: {
      type: Boolean,
      default: false
    },
    // 点击输入框，不调起下拉框，可结合函数'noPullDown',处理一些业务逻辑
    noPullDown: Boolean,
    // 是否双向监听
    isWatch: {
      type: Boolean,
      // `'true'`
      default: false
    },
    // 是否记录上一次列表
    isRecord: {
      type: Boolean,
      // `'true'`
      default: true
    },
    // 额外的选项
    extraOptions: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      // 显示String
      defaultBackText: '',
      // 下拉选项
      options: [],
      noOptions: '请再输入2个字符',
      // 生成随机id
      randomId: Math.random().toString(36).substr(2),
      // 回显value
      backValue: undefined,
      resetOnOptionsChange: true,
      toGetDetail: true,
      storageValue: undefined,
      // 是否外部改变
      isExtraChange: true,
      timer: null
    }
  },
  computed: {
    newbackText: function () {
      return this.backText
    },
    defaultPlaceholder () {
      if (this.placeholder) {
        return this.placeholder
      } else if (this.label) {
        return `查询${this.label}`
      } else {
        return ''
      }
    },
    selectOption () {
      return [...this.extraOptions, ...this.options]
    }
  },
  mounted () {
    this.$nextTick(() => {
      // 因为本身onSearch 删除到0个字符时候不执行回调 只能操作dom
      let monitorValueEl = document.getElementById(this.randomId)
      let _this = this
      monitorValueEl && (monitorValueEl.oninput = () => {
        if (_this.noPullDown) {
          return
        }
        if (monitorValueEl.value.length < startSearchLength) {
          // this.clearValue()
          _this.noOptions = `请再输入${startSearchLength - monitorValueEl.value.length}个字符`
        }
        _this.pullDownPosition()
      })
      // 外部元素overflow会影响层级，使用fixed定位 并计算位置
      monitorValueEl && (monitorValueEl.onfocus = () => {
        // 防止输入一字符之后 失去焦点再次获取焦点依旧提示请输入1字符
        if (_this.options.length === 0) {
          _this.noOptions = '请再输入2个字符'
        }
        if (!_this.noPullDown) {
          _this.pullDownPosition()
        } else {
          _this.$emit('noPullDown')
        }
      })
      if (this.value){
        if (this.backText) {
          this.showBackText()
          this.storageValue = this.backText
        } else {
          this.togetDetailInfo(this.value)
        }
      }
    })
    this.initOption && this.firstSearch()
  },
  methods: {
    onblur () {
      if(!this.isRecord){
        this.options=[]
      }
      setTimeout(() => {
        clearInterval(this.timer)
      }, 4000)
    },
    onfocus () {
      if (!this.noPullDown) {
        this.timer = setInterval(this.pullDownPosition, 200)
      } else {
        this.$emit('noPullDown')
      }
    },
    // 下拉框定位
    pullDownPosition () {
      let selectDom = this.$refs.select ? this.$refs.select.$el : null
      if (!selectDom) return
      this.$nextTick(() => {
        let dropdownMenu = selectDom.childNodes[2]
        if (!dropdownMenu.style) return
        let left = selectDom.getBoundingClientRect().left
        let top = selectDom.getBoundingClientRect().top + selectDom.clientHeight
        const bodyHeight = window.document.body.offsetHeight
        let width = selectDom.clientWidth
        let dropdownMenuHeight = dropdownMenu.offsetHeight
        let isDown = (bodyHeight - top) < dropdownMenuHeight
        if (isDown) {
          dropdownMenu.classList.add('dropdown-menuTop')
          dropdownMenu.style.top = `${top - dropdownMenuHeight - selectDom.clientHeight}px`
          dropdownMenu.style.left = `${left}px`
          dropdownMenu.style.width = `${width}px`
        } else {
          dropdownMenu.classList.remove('dropdown-menuTop')
          dropdownMenu.style.top = `${top}px`
          dropdownMenu.style.left = `${left}px`
          dropdownMenu.style.width = `${width}px`
        }
      }, 200)
    },
    // 不请求接口显示数据
    showBackText () {
      this.$nextTick(() => {
        this.backText && (this.defaultBackText = this.backText)
      })
    },
    // 父元素绑定的有值 请求这条准确数据 进行回显
    togetDetailInfo (echoValue) {
      let postData = {}
      if(this.communityId) {
        postData.communityId = this.communityId;
      }
      let headers = {
        'Content-Type': this.contentType
      }
      let postParamsData, getParamsData
      postData[this.request.value] = this.value

      this.resetOnOptionsChange = false
      this.echoRequestData && (postData = this.echoRequestData(postData))
      if (this.method === 'get') {
        postParamsData = undefined
        getParamsData = postData
      } else if (this.allDataType) {
        postParamsData = postData
        getParamsData = postData
      } else {
        postParamsData = postData
        getParamsData = undefined
      }
      let isPostType = this.method.toLocaleLowerCase() === 'post'
      let isDefaultContentType = this.contentType === 'application/x-www-form-urlencoded;charset=UTF-8'
      let isformData = this.formData === false
      if (isPostType && isDefaultContentType && isformData) {
        postParamsData = qs.stringify(postParamsData)
      }
      this.isExtraChange = false
      this.$nextTick(() => {
        postData = Object.assign(postData, this.subjoin)
        this.$axios({
          method: this.method,
          url: this.backSearchUrl || this.searchUrl,
          params: getParamsData,
          data: postParamsData,
          headers
        }).then(res => {
          let returnStatus = String(res.status)
          let returnDataStatus = res.data && res.data.length > 0
          if (returnStatus && returnDataStatus) {
            let data = res.data
            this.handleData && (data = this.handleData(data))
          

            let options = []
            let tempBackText
            let tempStorageValue
            let echoItem
            if (data && data.length) {
              data.forEach(item => {
                console.log('++++item', item)
                options.push({
                  label: item[this.response.text || 'name'],
                  value: item[this.response.value || 'id'],
                  obj: item
                })
                // eslint-disable-next-line eqeqeq
                if (echoValue !== undefined && item[this.response.value || 'id'] == echoValue) {
                  tempBackText = item[this.response.text || 'name']
                  tempStorageValue = item[this.response.value || 'id']
                  echoItem = item
                }
              })
            }
            this.options = options
            this.defaultBackText = tempBackText
            this.storageValue = tempStorageValue
            if (tempStorageValue === undefined) {
              // 若是没有查询到回显，更新绑定的值
              this.$emit('input', undefined)
            }
            this.$emit('onChange', echoItem, true)
          } else {
            this.storageValue = this.value
            this.isExtraChange = true
          }
        })
      }, 20)
    },
    // input输入来进行 搜索
    onSearch: _.throttle(function (search, loading) {
      if (this.needRelevanceMessage && this.needRelevanceMessage.length > 0) {
        return false
      }

      let _this = this
      if (search.length < startSearchLength) {
        return false
      }
      _this.resetOnOptionsChange = false
      _this.options = []
      let paramsData = null
      if(this.communityId) {
        paramsData = {
          communityId: this.communityId
        }
      }
      
      paramsData[_this.request.text] = search
      paramsData = Object.assign(paramsData, this.subjoin)
      let headers = {
        'Content-Type': _this.contentType
      }
      let postParamsData, getParamsData
      if (_this.method === 'get') {
        postParamsData = undefined
        getParamsData = paramsData
      } else if (_this.allDataType) {
        postParamsData = paramsData
        getParamsData = paramsData
      } else {
        postParamsData = paramsData
        getParamsData = undefined
      }
      // 如果是post方式且默认Content-Type 则数据转换为formData格式 可使用formData：false 不使用这种转换
      let isPostType = this.method.toLocaleLowerCase() === 'post'
      let isDefaultContentType = this.contentType === 'application/x-www-form-urlencoded;charset=UTF-8'
      let isformData = this.formData === false
      if (isPostType && isDefaultContentType && isformData) {
        postParamsData = qs.stringify(postParamsData)
      }
      _this.$axios({
        method: _this.method,
        url: _this.searchUrl,
        params: getParamsData,
        data: postParamsData,
        headers
      }).then(res => {
        let data = res.data
        this.handleData && (data = this.handleData(data))
        let returnStatus = String(res.status)
        let returnDataStatus = data && data.length > 0
        if (returnStatus && returnDataStatus) {
          let options = []
          data.forEach((item, index) => {
            options.push({
              label: item[_this.response.text || 'name'],
              value: item[_this.response.value || 'id'],
              obj: item
            })
          })
          _this.options = options
          _this.pullDownPosition()
        } else {
          _this.noOptions = '没有找到匹配项'
        }
      })
    }, 600),
    firstSearch () {
      let _this = this
      // _this.resetOnOptionsChange = false
      _this.options = []
      let paramsData = {
        limitCnt: limitCnt
      }
      paramsData[_this.request.text] = ''
      paramsData = Object.assign(paramsData, this.subjoin)
      let headers = {
        'Content-Type': _this.contentType
      }
      let postParamsData, getParamsData
      if (_this.method === 'get') {
        postParamsData = undefined
        getParamsData = paramsData
      } else if (_this.allDataType) {
        postParamsData = paramsData
        getParamsData = paramsData
      } else {
        postParamsData = paramsData
        getParamsData = undefined
      }
      // 如果是post方式且默认Content-Type 则数据转换为formData格式 可使用formData：false 不使用这种转换
      let isPostType = this.method.toLocaleLowerCase() === 'post'
      let isDefaultContentType = this.contentType === 'application/x-www-form-urlencoded;charset=UTF-8'
      let isformData = this.formData === false
      if (isPostType && isDefaultContentType && isformData) {
        postParamsData = qs.stringify(postParamsData)
      }
      _this.$axios({
        method: _this.method,
        url: _this.searchUrl,
        params: getParamsData,
        data: postParamsData,
        headers
      }).then(res => {
        let data = res.data
        this.handleData && (data = this.handleData(data))
        let returnStatus = String(res.status)
        let returnDataStatus = data && data.length > 0
        if (returnStatus && returnDataStatus) {
          let options = []
          data.forEach((item, index) => {
            console.log('aaaa-item-', item)
            options.push({
              label: item[_this.response.text || 'name'],
              value: item[_this.response.value || 'id'],
              obj: item
            })
          })
          _this.options = options
          // _this.pullDownPosition()
        } else {
          _this.noOptions = '没有找到匹配项'
        }
      })
    },
    // 选择选项之后
    onChange (val) {
      if (this.storageValue) {
        if (val && val.obj) {
          // @arg (val, isFirst)
          this.$emit('onChange', val.obj)
        } else if (!val) {
          this.$emit('onChange')
          if (val === null) {
            this.$emit('input', undefined)
          }
          this.storageValue = undefined
        }
        this.$nextTick(() => {
          this.dispatch('ElFormItem', 'el.form.change', [this.value])
        })
      }
    },
    // 清空选项以及下拉搜索list
    clearValue () {
      this.defaultBackText = undefined
      this.options = []
      this.noOptions = '请再输入2个字符'
      // this.$emit('input', undefined)
    },
    getBackTextValue () {
      if (this.defaultBackText) {
        if (this.defaultBackText !== null && typeof this.defaultBackText === 'object') {
          return this.defaultBackText.label
        } else if (this.defaultBackText !== null && typeof this.defaultBackText === 'string') {
          return this.defaultBackText
        }
      }
      return undefined
    }
  },
  watch: {
    subjoin: {
      handler (newVal, oldVal) {
        if(!this.isWatch){
          if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            if (!this.backText) {
              this.subjoinChangeClear && this.clearValue()
            }
          }
        }
      },
      deep: true
    },
    value (val) {
      console.log(123,'value')
      val && (this.storageValue = val)
      if (this.backText) {
        this.showBackText()
      } else if (val && this.toGetDetail) {
        this.togetDetailInfo(val)
      } else if (!val){
        this.defaultBackText = undefined
      }
      this.toGetDetail = true
      this.isExtraChange = true
    },
    defaultBackText (val) {
      if (!this.isExtraChange) {
        this.isExtraChange = true
        return
      }
      if (val && val.value !== undefined) { //通过onchange事件，先执行defaultBackText监听，再执行value监听，defaultBackText是对象
        this.toGetDetail = false
        this.$emit('input', val.value)
      }
      if (val === null) {
        this.toGetDetail = true
        this.$emit('input', undefined)
      }
    },
    disabled (val) {
      if (val && this.clearSelectValue) {
        // 无options 就会清空选项 所以只要清空这个就好 不用挨个清id和text
        this.clearValue()
      }
    },
    clearSelectValue (val) {
      if (val) {
        this.clearValue()
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>

<style scoped lang="scss">
.v-select2-container {
  .select2-text {
    margin-right: 5px;
  }
  .select2Parent {
    min-width: 144px;
    display: inline-block;
  }
}
</style>

<style scoped>
.v-select2-container .select2Parent >>> .dropdown-toggle {
  border: 1px solid #dcdfe6;
  height: 40px;
  padding: 0;
  min-width: 144px;
  /* max-width: 170px; */
  overflow: hidden;
}
.v-select2-container .select2Parent >>> .dropdown-toggle .selected-tag {
  margin: 0;
  height: 100%;
}
.select2Parent >>> .vs__selected-options {
  /* max-width:130px; */
  width: 60%;
  width: -moz-calc(100% - 70px);
  width: -webkit-calc(100% - 70px);
  width: calc(100% - 70px);
}
.select2Parent >>> .vs__selected-options span.selected-tag {
  display: block;
  /* max-width: 92px; */
  line-height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2Parent >>> input[type="search"] {
  height: 28px;
}
.select2Parent >>> .dropdown-menu {
  min-width: 144px;
  /* max-width: 170px; */
  position: fixed;
}
.select2Parent >>> .dropdown-menuTop {
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-bottom: none;
}
.select2Parent >>> .dropdown-menu li a {
  white-space: normal;
  word-break: break-all;
}
.select2Parent >>> input[type="search"]::-webkit-input-placeholder {
  color: #c0c4d6;
}
.select2Parent >>> .v-select .vs__actions {
  background-color: #f8f8f8;
  padding: 0 6px 0 0;
}
.select2Parent >>> .v-select .open-indicator {
  width: 32px;
  border-left: 1px solid #ccc;
}
.select2Parent >>> .v-select .clear {
  background: url("./images/clear.png") no-repeat #fff;
  background-size: 12px 12px;
  background-position: center;
  margin-right: 0px;
  width: 28px;
  height: 100%;
}
.select2Parent >>> .v-select .clear[disabled] {
  display: none;
}
.select2Parent >>> .v-select .clear span {
  display: none;
}
.select2Parent >>> .v-select .open-indicator:before {
  border: 0;
  background: url("./images/search.png") no-repeat;
  background-size: 17px 17px;
  background-position: center;
  width: 32px;
  height: 32px;
  transform: rotate(0deg);
}
.select2Parent >>> .v-select.open .open-indicator:before {
  /* transform: rotate(-90deg);
    border: 0; */
  background: url("./images/search_blue.png") no-repeat;
  background-size: 17px 17px;
  background-position: center;
}
</style>
<style lang="scss">
.is-error {
  .dropdown-toggle {
    border-color: #f56c6c !important;
  }
}
</style>
