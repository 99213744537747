<template>
  <div class="v-control v-tooltip-container">
    <el-tooltip
			v-model="model"
			:effect="effect"
			:content="content"
			:placement="placement"
			:offset="offset"
			:transition="transition"
			:visible-arrow="visibleArrow"
			:open-delay="openDelay"
			:manual="manual"
			:popper-class="popperClass"
			:enterable="enterable"
			:hide-after="hideAfter"
			:tabindex="tabindex"
			:disabled="disabled"
    >
			<span v-if="hasMessage">
				{{message}}
				<i v-if="hasIcon" :class="iconClassName"></i>
			</span>
    </el-tooltip>
  </div>
</template>
<script>
import Vue from 'vue'
import { Tooltip } from 'element-ui'

Vue.use(Tooltip)

// @group 基础组件
// @title tooltip 文字提示组件
export default {
	name: 'v-tooltip',
	data () {
		return {
			model: this.value
		}
	},
  props: {
    // 类型
    value: {
			type: Boolean,
			default: false
		},	
    // 默认提供的主题 dark/light
    effect: {
			type: String,
			default: 'dark'
		},
    // 显示的内容
    content: String,
    // Tooltip 的出现位置
    placement: String,
    // 出现位置的偏移量
    offset: Number,
    // 定义渐变动画
		transition: String,
		// 是否显示 Tooltip 箭头
		visibleArrow: Boolean,
    // 是否禁用
		disabled: Boolean,
		// 延迟出现，单位毫秒
		openDelay: Number,
    // 手动控制模式，设置为 true 后，mouseenter 和 mouseleave 事件将不会生效
    manual: {
      type: Boolean,
      default: false
    },
		// 为 Tooltip 的 popper 添加类名	
		popperClass: String,
		// 鼠标是否可进入到 tooltip 中
		enterable: Boolean,
		// Tooltip 出现后自动隐藏延时，单位毫秒，为 0 则不会自动隐藏
		hideAfter: Number,
		// Tooltip 组件的 tabindex
		tabindex: Number,
		iconClassName: String,
		hasIcon: Boolean,
		hasMessage: Boolean,
		// tooltip span 文案
		message: String
	}
}
</script>
<style scoped lang="scss">
  .v-tooltip-container {
    display: inline-block;
  }
</style>
