<template>
  <div class="nav-menu-panel-top top">
    <div class="left">
      <!-- 左侧二级菜单 -->
      <!-- level == -1 为一级菜单 后续菜单level递增1 -->

      <div class="menu-group-list">
        <div
          v-for="menu in menuListCache"
          class="menu-group-item"
          :class="menu.id == activeMenuId[1][0] && 'active-root'"
          :key="menu.id"
        >
          <div
            class="menu-group-item-name"
            @mouseenter="() => handleClickSec(menu)"
          >
            <span class="menu-group-item-name-inner">
              {{ menu.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <waterfall :options="{transitionDuration:1}">
        <template>
          <waterfall-item
            v-for="(item, index) in selectedMemuItem.childMenus"
            :key="item.id"
          >
            <div
              class="menu-group-list menu-group-list-child"
              style="padding-bottom:20px"
            >
              <!-- 三级菜单 -->
              <div
                class="menu-group-item menu-sec"
                :class="item.id == activeMenuId[1][0] && 'active-root'"
                @click="itemClick(item)"
                :style="{
                  cursor: item.urlJumpType >= 0 ? 'pointer' : 'auto'
                }"
              >
                <div class="menu-group-item-name">
                  {{ item.name }}
                </div>
                <div class="menu-group-item-name" v-if="item.urlJumpType >= 0">
                  &nbsp;&gt;
                </div>
              </div>

              <menu-group
                v-show="
                  status &&
                    item.menuType >= 1 &&
                    item.specialNo !== 'system_menuGroup'
                "
                :group="item"
                :status="status"
                :centerItem="centerItem"
                :firstItem="firstItem"
                :secondItem="secondItem"
                :thirdItem="thirdItem"
                :activeMenuId="activeMenuId"
                :level="item.level"
              />
            </div>
          </waterfall-item>
        </template>
      </waterfall>
    </div>
  </div>
</template>
<script>
import MenuGroup from "./menu-group-top";
import { Waterfall, WaterfallItem } from 'vue2-waterfall';
import { menuJump, internalMenuJump, findFirstMenu } from '@/common/menuHandler'

export default {
  name: "nav-menu-panel-top",
  components: {
    MenuGroup,
    Waterfall,
    WaterfallItem
  },
  inject:['menubar'],
  props: {
    menuListCache: {},
    group: {},
    activeMenuId: {},
    centerItem: {},
    firstItem: {},
    secondItem: {},
    thirdItem: {},
    status: {}
  },
  data() {
    return {
      menuList: {
        childMenus: []
      },
      selectedMemuItem: {},
      itemMap: {
        0: 'centerItem',
        1: 'firstItem',
        2: 'secondItem',
        3: 'thirdItem'
      }
    };
  },
  methods: {
    handleClickSec(item) {
      console.log(item, this.$route.query)
      if (!item) {
        this.selectedMemuItem = (this.activeMenuId[1].length > 0 ? this.group.childMenus.find(v => v.id == this.activeMenuId[1][0]) : this.group.childMenus[0]) || this.group.childMenus[0]
      } else {
        this.selectedMemuItem = item
      }
    },

    itemClick(item) {
      if(item.urlJumpType < 0){
        return
      }
      if (this.status) {
        this.navigate(item)
      } else {
        const menuItem = findFirstMenu(item)
        if (menuItem) {
          this.navigate(menuItem)
        }
      }
      this.menubar.closeDrawer()
    },
    navigate(item) {
      const { menuType, urlJumpType, name, id, specialNo, level, url, childMenus } = item
      if (menuType === 1) {
        const activeMenuId = this.activeMenuId[level + 1]
        if (level === 0) {
          if (specialNo === 'system_menuGroup') {
            if (childMenus && childMenus.length) {
              const firstChildMenuId = childMenus[0].id
              internalMenuJump(firstChildMenuId)
            }
            return
          }
          if (activeMenuId && activeMenuId.length) {
            if (activeMenuId.includes(id)) {
              activeMenuId.splice(0, activeMenuId.length)
            } else {
              activeMenuId.splice(0, activeMenuId.length, id)
            }
          } else {
            activeMenuId.push(id)
          }
        } else {
          const index = activeMenuId.indexOf(id)
          if (index !== -1) {
            activeMenuId.splice(index, 1)
          } else {
            activeMenuId.push(id)
          }
        }
      } else if (menuType === 2) {
        if ([1, 2].includes(urlJumpType)) {
          menuJump(item)
        } else {
          internalMenuJump(id)
        }
      }
    },
  },
}
</script>

<style lang="scss">
.nav-menu-panel-top {
  display: flex;

  justify-content: space-between;
  height: 100%;
  background: white;
  user-select: none;
  color: #323233;
  .Waterfall-item {
    box-sizing: border-box;
    width: 25%;
    padding-left: 5%;
  }
  // transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  // transition: all 200ms;
  &.top {
    background: white;
    text-align: left;
    padding-right: 15px;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    align-items: stretch;

    .left {
      display: flex;
      width: 240px;
      background: #f2f6fc;
      overflow-y: auto;
      padding:20px 0;

      flex-direction: column;
      .menu-group-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 40px;
        .menu-group-item-name {
          box-sizing: border-box;
          height: 100%;
          display: flex;
          align-items: center;
          .menu-group-item-name-inner{
            display:inline-block;
            padding:2px 0;
            border-top: 3px solid transparent;
            border-bottom: 3px solid transparent;

          }
        }
        &.active-root {
          .menu-group-item-name-inner {
            border-bottom: 3px solid #111214;
          }
        }
        &:not(.active-root){
          .menu-group-item-name:hover {
            .menu-group-item-name-inner{
             color:#5182ff
            }
          }
        }
      }
    }
    .right {
      box-sizing: border-box;
      padding-top: 16px;
      padding-left: 15px;
      height: 100%;
      padding-right: 20%;
      flex: 1;
      overflow-x: auto;
      .menu-group-item.menu-sec {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 40px;
        outline: none;
        cursor: pointer;
        color: #5182ff;
      }
      .menu-group-top {
        // padding-left: 15px;
        .menu-group-list {
          .menu-group-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 40px;
            outline: none;
            cursor: pointer;
            .menu-group-item-icon {
              min-width: 16px;
              max-width: 16px;
              height: 16px;
              padding: 0 16px;
            }
            .menu-group-item-name {
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-break: break-all;
              
            }
          }
        }
        .menu-group-list-root {
          margin-bottom: 10px;
          .menu-group-item-root {
            border-radius: 10px;
            &:not(.active-root) {
              z-index: 1;
              position: relative;
              overflow: hidden;
              transition: box-shadow 0.5s ease-in-out;
              &::after {
                content: "";
                z-index: -1;
                position: absolute;
                background: #fff;
                opacity: 0.8;
                top: -50%;
                left: 0%;
                bottom: -50%;
                width: 20px;
                transform: translate3d(-50px, 0, 0) rotate(35deg);
              }
              &:hover {
                background: #f1f5fa;
                box-shadow: 0px 10px 15px 0px rgba(108, 145, 185, 0.1),
                  0px -10px 10px 0px rgba(255, 255, 255, 0.7),
                  0px 5px 10px 0px rgba(121, 183, 255, 0.03);
                border-radius: 10px;
                &::after {
                  transition: transform 0.5s ease-in-out;
                  transform: translate3d(300px, 0, 0) rotate(35deg);
                }
              }
            }
            &.active-root {
              background: linear-gradient(
                90deg,
                rgba(67, 168, 255, 1) 0%,
                rgba(82, 159, 255, 1) 99%
              );
              box-shadow: 0px 5px 10px 0px rgba(115, 171, 224, 0.5),
                0px -10px 10px 0px rgba(255, 255, 255, 0.7);
              border-radius: 10px;
              .menu-group-item-name {
                color: #fff;
              }
            }
          }
        }
        .menu-group-list-child {
          .menu-group-item-child {
            .menu-group-item-name.is-sub-menu {
                color: #909399;
                cursor: auto;
            }
            &:hover {
              .menu-group-item-name:not(.is-sub-menu) {
                color: #1b8cf2;
              }
            }
            &.active-child {
              .menu-group-item-name {
                color: #1b8cf2;
              }
            }
          }
        }
      }
    }
  }

  &.open {
    // width: 290px;
    width: 240px;
    .bottom {
      .control {
        .icon-status {
          transform: rotate(90deg);
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;
  // background: rgba(101, 155, 214, 0.1);
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  // background: #e4ecf3;
  background-color: transparent;
  box-shadow: none;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  // background: #f0f3f7;
  background-color: transparent;
  box-shadow: none;
}
</style>
