import { basicHelper } from './utils'
import { uploadURL } from 'common/api'

export function isVNode (node) {
  return node !== null && typeof node === 'object' && basicHelper.hasOwn(node, 'componentOptions')
}

/**
 *
 * 生成v-input组件的VNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
export function createInputVNode ($vue, row, prop, opts = {}) {
  let VNode = $vue.$createElement('v-input', {
    props: {
      ...(opts.attrs || {}),
      value: row[prop]
    },
    on: {
      input (newValue) {
        row[prop] = newValue
      },
      ...(opts.methods || {})
    }
  })
  return VNode
}

/**
 *
 * 生成包含 v-select v-input 组件的VNode, 专为 自定义积分规则 - (积分有效期 / 上限) 生成的 vNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
export function createInputSpanVNode ($vue, row, prop, opts) {
  let VNode = $vue.$createElement('div', null, [
    $vue.$createElement('v-select', {
      props: {
        options: opts.options,
        value: row[prop],
        width: 120
      },
      on: {
        input (newValue) {
          row[prop] = newValue
        },
        change ($event) {
          if (opts.cb) {
            opts.cb($event)
          }
        }
      }
    }),
    $vue.$createElement('span', {
      domProps: {
        innerText: opts.spanInnerTextOne
      },
      style: {
        display: opts.spanDisplayOne
      }
    }),
    $vue.$createElement('v-input', {
      props: {
        value: row[opts.inputNumberValue],
        width: opts.inputNumberWidth,
        ...(opts.attrs || {}),
      },
      style: {
        display: opts.inputDisplay
      },
      on: {
        input (newValue) {
          row[opts.inputNumberValue] = newValue
        },
        ...(opts.methods || {})
      }
    }),
    $vue.$createElement('span', {
      domProps: {
        innerText: opts.spanInnerTextTwo
      },
      style: {
        display: opts.spanDisplayTwo
      }
    })
  ])
  return VNode
}

/**
 *
 * 生成包含 v-select v-input 组件的VNode, 专为 自定义积分规则 - 规则 生成的 vNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
 export function createRulesVNode ($vue, row, prop, opts) {
  let VNode = $vue.$createElement('div', null, [
    $vue.$createElement('span', {
      domProps: {
        innerText: opts.spanInnerTextOne
      },
      style: {
        display: opts.spanDisplayOne
      }
    }),
    $vue.$createElement('v-input', {
      props: {
        value: JSON.parse(row[prop]).value,
        width: opts.inputNumberWidth,
        ...(opts.attrs || {}),
      },
      style: {
        display: opts.inputDisplay
      },
      on: {
        ...(opts.methods || {})
      }
    }),
    $vue.$createElement('v-select', {
      props: {
        options: opts.options,
        value: JSON.parse(row[prop]).type,
        width: opts.selectWidth
      },
      on: {
        input (newValue) {
          const newJson = JSON.parse(row[prop])
          newJson.type = newValue
          row[prop] = JSON.stringify(newJson)
        },
        change ($event) {
          if (opts.cb) {
            opts.cb($event)
          }
        }
      }
    }),
    $vue.$createElement('span', {
      domProps: {
        innerText: opts.spanInnerTextTwo
      },
      style: {
        display: opts.spanDisplayTwo
      }
    })
  ])
  return VNode
}

/**
 *
 * 生成v-input-number组件的VNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
export function createNumberVNode ($vue, row, prop, opts = {}) {
  let VNode = $vue.$createElement('v-input-number', {
    props: {
      ...(opts.attrs || {}),
      value: row[prop]
    },
    on: {
      input (newValue) {
        row[prop] = newValue
      },
      ...(opts.methods || {}),
    }
  })
  return VNode
}

/**
 *
 * 生成v-select组件的VNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
export function createSelectVNode ($vue, row, prop, opts = {}) {
  let VNode = $vue.$createElement('v-select', {
    props: {
      disabled:row.disabled,
      ...(opts.attrs || {}),     
      options: opts.options,
      value: row[prop]
    },
    on: {
      input (newValue) {
        row[prop] = newValue
      },
      // 新增select change 事件的监听
      change ($event) {
        if (opts.cb) {
          opts.cb($event)
        }
      },
      ...(opts.methods || {}),
    }
  })
  return VNode
}

/**
 *
 * 生成原生img的VNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
export function createImgVNode ($vue, row, prop, opts) {
  if (!opts) {
    opts = {}
  }
  let VNode = $vue.$createElement('img', {
    attrs: {
      src: opts.imgUrl || row[prop]
    },
    style: {
      width: opts.width || '48px',
      height: opts.height || '48px',
      objectFit: 'cover',
      ...opts.style
    },
    on: {
      click: () => {
        if (opts.cb) {
          opts.cb(row, row[prop])
        } else {
          let imgUrl = opts.imgUrl || row[prop]
          if (imgUrl) {
            $vue.$previewBox([imgUrl])
          }
        }
      }
    }
  })
  return VNode
}

/**
 *
 * 生成v-uploader组件的VNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
export function createUploaderVNode ($vue, row, prop, opts) {
  let VNode = $vue.$createElement('v-uploader', {
    props: {
      imgUrls: row[prop],
      fileWidth: 96,
      action: `${uploadURL}?module=${opts.module}`
    }
  })
  return VNode
}

/**
 *
 * 生成v-tooltip组件的VNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
export function createTooltipVNode ($vue, row, prop, opts) {
  let optProps = {}
  for (let key in opts) {
    optProps[key] = opts[key]
  }
  let VNode = $vue.$createElement('v-tooltip', {
    props: optProps
  })
  return VNode
}

/**
 *
 * 生成v-switch组件的VNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
export function createSwitchVNode ($vue, row, prop, opts) {
  let optProps = {}
  for (let key in opts) {
    optProps[key] = opts[key]
  }
  let VNode = $vue.$createElement('v-switch', {
    props: optProps,
    on: {
      change: ($event) => {
        opts.cb($event)
      }
    }
  })
  return VNode
}



/**
 *
 * 生成html片段的VNode
 * @export
 * @param {Object} $vue vue实例
 * @param {String} innerHTML html片段
 * @returns 返回生成的VNode对象
 */
export function createHTMLVNode ($vue, innerHTML, row, prop, opts) {
  if (!opts) {
    opts = {}
  }
  let VNode = $vue.$createElement('span', {
    domProps: {
      innerHTML: innerHTML
    },
    on: {
      click: () => {
        opts.cb && opts.cb(row, row[prop])
      }
    }
  })
  return VNode
}

/**
 *
 * 生成alink样式的VNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row 当前行数据
 * @param {Object} opts 配置项(目前主要包括颜色及回调配置)
 * @returns 返回生成的VNode对象
 */
export function createAlinkVNode ($vue, row, prop, opts) {
  if (!opts) {
    opts = {}
  }
  // 调用方传值则用调用方opts.text否则取默认的row[prop]
  // 最终处理undefined及空字符串直接返回不带标签的空字符串 （性能比VNode好）
  let innerText = opts.text !== undefined ? opts.text : row[prop]
  if (innerText === '' || innerText === undefined) {
    return ''
  }
  let VNode = $vue.$createElement('span', {
    domProps: {
      innerText
    },
    style: {
      color: opts.color || '#1B8CF2',
      cursor: 'pointer'
    },
    on: {
      click: () => {
        opts.cb && opts.cb(row, row[prop])
      }
    }
  })
  return VNode
}

/**
 *
 * 生成v-checkbox组件的VNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
 export function createCheckboxVNode ($vue, row, prop, opts = {}) {
  let VNode = $vue.$createElement('v-checkbox', {
    props: {
      value: row[prop],
      label: opts.label,
      disabled: opts.disabled,
      ...(opts.attrs || {}),
    },
    // style: {
    //   display: opts.inputDisplay
    // },
    on: {
      input (newValue) {
        row[prop] = newValue
      },
      ...(opts.methods || {})
    }
  })
  return VNode
}


export function createCheckboxGroupVNode($vue, row, prop, children = []) {
 
  const h = $vue.$createElement
  let changeGroup =(e)=>console.log(e)
  let changeBox = (e,child)=>{
    console.log('createElement',e)
    if(row[prop].includes(child.value)){
      row[prop] = row[prop].filter(v=>{
        return v != child.value
      })
    }else{
      row[prop].push(child.value)
    }
    child.checked = row[prop].includes(child.value)
  }
  const childrenList = children.map((child,i)=>{
    return  <el-checkbox 
              disabled={row.disabled}
              style="display:block"
              checked={child.checked}
              label={child.value}
              value={child.checked}
              onChange={e => changeBox(e,child)}
              key={`${row.id}-${prop}-${child.value}`}
            >
                {child.label}
            </el-checkbox>
  })
  let VNode = <el-checkbox-group 
                value={row[prop]} 
                onChange={changeGroup}
              >
                {childrenList}
              </el-checkbox-group>
  return VNode
  // let VNode = $vue.$createElement('div', {
  //   // style: {
  //   //   display: opts.inputDisplay
  //   // },
  
  // },
  //   children.map((child,i) => {
  //     let config =  {
  //       props:{
  //         disabled: row.disabled,
  //         value:child.checked,
  //         label:child.value,
  //         checked: false,
  //         ...(child.attrs || {}),
  //       },
  //       ref:`${row.id}-${prop}-${child.val}`,

  //       style: {
  //         display: 'block'
  //       },
  //       on: {
  //         change:(newValue)=>{
            
  //           // $vue.$emit('update:selfModel',{row,prop,child,newValue}) 
          
  //           // children[i].checked =!children[i].checked
  //           if(row[prop].includes(child.value)){
  //             row[prop] = row[prop].filter(v=>{
  //               return v != child.value
  //             })
  //           }else{
  //             row[prop].push(child.value)
  //           }
  //         },
  //         ...(child.methods || {})
  //       }
  //     }
  //     return $vue.$createElement('el-checkbox',config,[child.label])
  //   })
  // )
  // return VNode
}

export function createMultiSelectVNode($vue, row, prop, children,opt) {
 
  const h = $vue.$createElement
  let changeSelect = (val) =>{
      row[prop] = val
    }
  let isObject = function(prop){
    return Object.prototype.toString.call(prop).indexOf('Object')
  }
  let optionsList = children.map((child,i)=>{
    return  <el-option
              label={child.categoryName}
              key={child[opt.valueKey]}
              value={child}>
            </el-option>
  })

 let VNode = <el-select 
              filterable
              valueKey={opt.valueKey} 
              style={opt.style} 
              disabled={row.disabled} 
              value={row[prop]} 
              onChange={changeSelect} 
              scopedSlots={{
                default:()=>optionsList
              }} 
              multiple 
              collapseTags 
              placeholder="请选择"
            ></el-select>
  return VNode
}
