<template>
  <div class="v-control v-timepicker-contianer" :class="isRange ? 'range-wrapper' : 'single-wrapper'">
    <label v-if="label" v-text="label"></label>
    <date-picker v-if="isRange"
      ref="timepicker"
      v-model="time"
      type="time"
      value-type="format"
      :range="true"
      :shortcuts="[]" 
      :disabled="disabled"
      :editable="editable"
      :clearable="clearable"
      :placeholder="placeholder"
      :minuteStep="innerMinuteStep"
      :format="format"
      :append-to-body="appendToBody"
      :width="width"
      :range-separator="rangeSeparator"
      @change="onChange"
    >
    </date-picker>
  </div>
</template>

<script>
import Vue from 'vue'
import DatePicker from 'vue2-datepicker'

Vue.use(DatePicker)

// @group 基础组件
// @title TimePicker 时间选择器

export default {
  name: 'v-timepicker2',
  props: {
    // 标签名称
    label: String,
    // 范围类型的开始时间 sync
    startTime: String,
    // 范围类型的结束时间 sync
    endTime: String,
    // 禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 文本框可输入
    editable: {
      type: Boolean,
      default: true
    },
    // 是否显示清除按钮
    clearable: {
      type: Boolean,
      default: true
    },
    // 非范围选择时的占位内容
    placeholder: {
      type: String,
      default: '选择时间'
    },
    // 范围选择时开始日期的占位内容
    startPlaceholder: {
      type: String,
      default: '选择开始时间'
    },
    // 范围选择时开始日期的占位内容
    endPlaceholder: {
      type: String,
      default: '选择结束时间'
    },
    // 是否为时间范围选择
    isRange: {
      type: Boolean,
      default: false
    },
    // 选择范围时的分隔符
    rangeSeparator: {
      type: String,
      default: '-'
    },
    // 时间显示格式 'HH:mm:ss'
    format: {
      type: String,
      default: 'HH:mm'
    },
    // 是否在body下添加填出框
    appendToBody: {
      type: Boolean,
      default: true
    },
    // 快捷选择时间数组
    quickTimes: {
      type: Array
    },
    // 范围选择时的开始时间的快捷选择数组
    sQuickTimes: Array,
    // 范围选择时的结束时间的快捷选择数组
    eQuickTimes: Array,
    width: {
      type: Number,
      default: 144
    },
  },
  data () {
    return {
      time: this.value,
    }
  },
  computed: {
    innerMinuteStep () {
      if (this.format === 'HH:mm') {
        return 1
      } else {
        return 0
      }
    },
    value () {
      this.time = [this.startTime, this.endTime]
      return [this.startTime, this.endTime]
    }
  },
  watch: {
    value (newValue) {
      this.time = newValue
    },
  },
  methods: {
    onChange (value) {
      const [ startTime, endTime ] = value.splice(this.rangeSeparator);
      this.$emit('update:startTime', startTime)
      this.$emit('update:endTime', endTime)
      this.$emit('change', [startTime, endTime])
    },
  }
}
</script>

<style lang="scss" scoped>
  .quick-times-wrapper {
    font-size: 14px;
    margin: 0 10px;
    .times-group {
      margin: 5px 0;
      a {
        cursor: pointer;
        margin-right: 10px;
        color: #409EFF
      }
    }
  }
  label {
    margin-right: 5px;
    color: #333333;
  }
  .multip-container {
    width: 424px;
    display: flex;
    align-items: center;
  }
  .range-wrapper {
    width: 540px;
  }
  .single-wrapper {
    width: 261px;
  }
  .range-separator {
    margin: 0 10px;
  }
</style>
<style lang="scss">
  .is-error {
    .mx-input {
      border-color: #F56C6C
    }
  }
</style>
