<template>
    <div class='v-control v-ueditor-container'>
        <vue-ueditor-wrap ref="ueditorWrap" v-model="model" :config='mixedConfig' @ready='ready' id="ueditor-container"></vue-ueditor-wrap>
    </div>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
import emitter from 'element-ui/src/mixins/emitter'
// 引入了Ueditor 源码 和 vue-ueditor-wrap组件（源码里的all.js里修改了图片上传的方法，config里需要配置图片的配置项，dialogs/image/image.js修改了批量上传返回的路径参数） 如果要调整编辑上的菜单：修改conifg文件里的toolbars参数
// @group 基础组件
// @title Ueditor 富文本编辑器
export default {
  name: 'v-ueditor',
  mixins: [emitter],
  components: {
    VueUeditorWrap
  },
  props: {
    // 编辑器的内容,双向绑定
    value: {
      type: String,
      default: ''
    },
    // 沿用官方的config，具体参考官方链接http://fex.baidu.com/ueditor/#start-config
    config: {
      type: Object,
      default: function () {
        return {}
      }
    },
    // 上传图片的文件夹，上传参数dirName的值（默认focus）
    uploadFolder: {
      type: String,
      default: 'focus'
    },
    // 允许的最大字符数，默认10000（也可以直接在config里设置maximumWords参数）
    maxLength: {
      type: Number,
      default: 10000
    }
  },
  data () {
    return {
      model: this.value,
      defaultConfig: {
        autoFloatEnabled: true,
        autoHeightEnabled: false,
        initialFrameWidth: '100%',
        initialFrameHeight: 550,
        // serverUrl: `${API_CONFIG.baseURL}uploadFileAction!uploadBackPicture.action`,
        serverUrl: `${API_CONFIG.ueditImgUploadURL}`,
        maximumWords: this.maxLength,
        UEDITOR_HOME_URL: `${location.origin}${location.pathname.substr(0, location.pathname.lastIndexOf('/'))}/static/ueditor/`
      }
    }
  },
  computed: {
    mixedConfig () {
      return Object.assign({}, this.defaultConfig, this.config)
    }
  },
  mounted () {

  },
  destoryed () {
    // this.editor.destory();
  },
  methods: {
    ready (editorInstance) {
      let _this = this
      editorInstance.execCommand('serverparam', {
        // 将图片上传服务器指定的文件夹
        'dirName': _this.uploadFolder
      })
      this.$emit('ready', editorInstance)
    }
  },
  watch: {
    value (newValue) {
      this.model = newValue
      this.dispatch('ElFormItem', 'el.form.change', [newValue])
    },
    model (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
<style lang="scss" scoped>
.v-ueditor-container {
    line-height: 24px;
    width:750px;
}
</style>
