/*
 * @Author: jianying.wan
 * @Date: 2020-02-10 16:14:10
 * @LastEditors: zhanglei zhanglei@lvchengfuwu.com
 * @LastEditTime: 2022-07-18 10:34:13
 * @Description: file content
 */
import List from './list/list'
import ListOther from './list-other/ListOther'
import FormPanel from './form-panel/form-panel'
import TablePanel from './table-panel/table-panel.vue'
import TablePanelDraggable from './table-panel-draggable/table-panel-draggable.vue'
import TablePanel2 from './table-panel2/table-panel2.vue'
import CheckboxPlus from './checkbox-plus/checkbox-plus'
import ImportTemplate from './import-template/import-template'
import ImportTemplate2 from './import-template/import-template2'
import MultiSelect from './multi-select/multi-select'
import MultiSelect2 from './multi-select/multi-select2'
// 区域选择器
import AreaSelect from './area-select/area-select'
import AreaSelect2 from './area-select2/area-select2'
import ChosenListPanel from './chosen-list-panel/chosen-list-panel'
import Col2Block from './col2-detail/block'
import Col2Detail from './col2-detail/detail'
import Col2Progress from './col2-detail/progress'
import Col2Item from './col2-detail/item'
// 二维码网格页面
import QrcodeCollection from './qrcode-collection/qrcode-collection'
import MobilePreview from './mobile-preview/mobile-preview'
import TabsPanel from './tabs-panel/tabs-panel'
import ProvincesMenu from './provinces-menu/provinces-menu'
// 三级类目选择
import CategorySelect from './category-select/category-select'
// 跳转组件
import JumpGo from './jump-go/index'
// 投票组件
import voteQuestion from './vote-question/index'
import RolePermissions from './role-permissions/role-permissions'
// 自定义视图
import CustomView from './custom-view/custom-view'
// 粒子组件
import ParticlesView from './particles-view/particles-view'
// 字段解释说明组件（tips）
import FieldTips from './field-tips/field-tips.vue'
// 滑动加载组件
import ScrollList from './scroll-list/scroll-list.vue'
// 下拉多选组件
import SelectMultiple from './select-multiple/select-multiple.vue'
// 包含弹框按钮的树控件
import ButtonTree from './button-tree/button-tree.vue'
// 选择-包含按钮弹框的树控件
import SelectTree from './select-tree/select-tree.vue'

export {
  List,
  ListOther,
  TablePanel,
  TablePanel2,
  TablePanelDraggable,
  FormPanel,
  CheckboxPlus,
  ImportTemplate,
  ImportTemplate2,
  MultiSelect,
  MultiSelect2,
  AreaSelect,
  AreaSelect2,
  ChosenListPanel,
  Col2Block,
  Col2Detail,
  Col2Progress,
  Col2Item,
  QrcodeCollection,
  MobilePreview,
  TabsPanel,
  ProvincesMenu,
  CategorySelect,
  JumpGo,
  voteQuestion,
  RolePermissions,
  CustomView,
  ParticlesView,
  FieldTips,
  ScrollList,
  SelectMultiple,
  ButtonTree,
  SelectTree
}
