<template lang="html">
<section class="col2-container">
  <el-row>
    <el-col :span="span">
      <slot></slot>
    </el-col>
  </el-row>
</section>
</template>

<script>
import Vue from 'vue'
import { Col, Row } from 'element-ui'
Vue.use(Col)
Vue.use(Row)

// 表单样式组件，用于控制表单内宽度占比，与其他Co2组件一起使用
// @group 业务组件
// @title Col2Detail Col2组件基础容器
export default {
  name: 'col2-detail',
  props: {
    // 24栅格数
    span: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
