var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "title-panel-container" }, [
    _vm.breadcrumb && _vm.breadcrumb.length
      ? _c("div", { staticClass: "left" }, [
          _c("i", { staticClass: "icon" }),
          _c("span", {
            staticClass: "breadcrumb",
            domProps: {
              textContent: _vm._s(_vm.handleBreadcrumb(_vm.breadcrumb)),
            },
          }),
          _vm.pageItem.helpUrl
            ? _c("span", {
                staticClass: "help-icon",
                attrs: { title: "点击此处进入帮助中心" },
                on: { click: _vm.openHelpCenter },
              })
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }