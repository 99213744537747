<!--
 * @Description: 商品的三级类目选择
 * @Author: 小广
 * @Date: 2019-05-29 09:42:27
 * @LastEditors: 小广
 * @LastEditTime: 2019-05-31 09:08:31
 -->
<template>
  <div class="category-select-wrapper">
    <el-dialog width="940px" :title="title" :visible.sync="dialogVisible" @open="open" @closed="closed">
      <div v-if="businessSearch" class="business-wrapper">
        <v-select2 label="所属商家：" v-model="shopId" v-bind="shopParams" @onChange="onChange"></v-select2>
      </div>
      <div class="content-wrapper">
        <div class="category-list-wrapper">
          <category-select-item
            v-if="firstList.length"
            title="一级类目"
            :category-list="firstList"
            @selected-data="firstCategorySelect"
          />
          <category-select-item
            v-if="secondList.length"
            title="二级类目"
            :category-list="secondList"
            @selected-data="secondCategorySelect"
          />
          <category-select-item
            v-if="thirdList.length"
            title="三级类目"
            :category-list="thirdList"
            :show-arrow="false"
            :show-all="showAll"
            @selected-data="thirdCategorySelect"
          />
        </div>
        <div class="select-number-wrapper">
          <span>已选：{{ `${thirdCategory.length}` }}</span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer bottom-button">
        <v-button
          :disabled="thirdCategory.length === 0"
          text="下一步"
          @click="bottomClick"
        ></v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CategorySelectItem from './category-select-item'

// @group 业务组件
// @title CategorySelect 商品的三级类目选择
export default {
  name: 'category-select',
  components: {
    CategorySelectItem
  },
  props: {
    // 是否显示 category-select，`.sync`修饰
    visible: {
      type: Boolean,
      // `false`
      default: false
    },
    // 标题
    title: {
      type: String,
      // `类目选择`
      default: '类目选择'
    },
    // 最后一级类目是否显示全选按钮
    showAll: {
      type: Boolean,
      // `false`
      default: false
    },
    // 是否可以搜索商家
    businessSearch: {
      type: Boolean,
      // `false`
      default: false
    },

    // 额外请求参数（除了`categoryLevel`和`parentId`，以外的请求参数）
    extraParams: {
      type: Object,
      // `{}`
      default: () => {
        return {}
      }
    }
  },

  watch: {
    visible (newValue) {
      this.dialogVisible = newValue
    },

    dialogVisible (newValue) {
      // 双向绑定，是否显示组件
      this.$emit('update:visible', newValue)
    }
  },

  data () {
    return {
      // 请求地址
      getCategoryLevelListUrl: `${API_CONFIG.shopBaseURL}goodsCategory/getCategoryLevelList`,
      shopParams: {
        searchUrl: `${API_CONFIG.baseURL}serverCodewordAction!getShops.action`,
        request: {
          text: 'busName',
          value: 'id'
        }
      },
      dialogVisible: this.visible,
      // 类目数组
      firstList: [],
      secondList: [],
      thirdList: [],
      // 选中的数据
      firstCategory: {},
      secondCategory: {},
      thirdCategory: [],
      contentShow: false,
      // 有商家搜索会使用到
      shopId: '',
      // 选中的商家
      selectShopItem: undefined
    }
  },

  methods: {

    open () {
      // 无商家搜索，就要重新请求数据
      if (!this.businessSearch) {
        // 获取一级类目
        this.requestForFirstCategory()
      }
    },

    closed () {
      if (!this.businessSearch) {
        // 清除之前的数据，防止新增了数据不能及时刷新
        this.firstList = []
        this.secondList = []
        this.thirdList = []
        this.thirdCategory = []
      }
    },

    onChange (item) {
      // 清空一些数据
      this.firstList = []
      this.secondList = []
      this.thirdList = []
      this.thirdCategory = []
      // 有item 代表选了新的商家
      if (item) {
        this.selectShopItem = item
        // 获取一级类目
        this.requestForFirstCategory()
      }
    },
    // 一级类目
    firstCategorySelect (selectData) {
      if (selectData.length) {
        let item = selectData[0]
        this.firstCategory = item
        // 清空所选
        this.thirdCategory = []
        this.requestForSecondCategory()
      } else {
        this.secondList = []
        this.thirdList = []
        this.thirdCategory = []
      }
    },

    // 二级类目
    secondCategorySelect (selectData) {
      if (selectData.length) {
        let item = selectData[0]
        this.secondCategory = item
        // 清空所选
        this.thirdCategory = []
        this.requestForThirdCategory()
      } else {
        this.thirdList = []
        this.thirdCategory = []
      }
    },

    // 三级类目
    thirdCategorySelect (selectData) {
      this.thirdCategory = selectData
    },

    // 获取一级类目
    requestForFirstCategory () {
      let params = { categoryLevel: 1 }
      if (this.extraParams) {
        params = Object.assign({}, this.extraParams, params)
      }
      if (this.selectShopItem) {
        params = Object.assign(params, { shopId: this.selectShopItem.id })
      }

      let getParams = { params }

      let _this = this
      this.$axios.get(this.getCategoryLevelListUrl, getParams).then(res => {
        if (res.status === 100) {
          /// 处理数据
          _this.firstList = res.data || []
        }
      })
    },

    // 获取二级类目
    requestForSecondCategory () {
      let params = {
        categoryLevel: 2,
        parentId: this.firstCategory.id
      }
      if (this.extraParams) {
        params = Object.assign({}, this.extraParams, params)
      }
      if (this.selectShopItem) {
        params = Object.assign(params, { shopId: this.selectShopItem.id })
      }
      let getParams = { params }
      let _this = this
      this.$axios.get(this.getCategoryLevelListUrl, getParams).then(res => {
        if (res.status === 100) {
          /// 处理数据
          _this.secondList = res.data || []
          _this.thirdList = []
        }
      })
    },

    // 获取三级类目
    requestForThirdCategory () {
      let params = {
        categoryLevel: 3,
        parentId: this.secondCategory.id
      }
      if (this.extraParams) {
        params = Object.assign({}, this.extraParams, params)
      }
      if (this.selectShopItem) {
        params = Object.assign(params, { shopId: this.selectShopItem.id })
      }
      let getParams = { params }
      let _this = this
      this.$axios.get(this.getCategoryLevelListUrl, getParams).then(res => {
        if (res.status === 100) {
          /// 处理数据
          _this.thirdList = res.data || []
        }
      })
    },

    // 下一步
    bottomClick () {
      // 在这可以把选中的值传出去
      let selectData = {
        first: this.firstCategory,
        second: this.secondCategory,
        third: this.thirdCategory
      }
      if (this.selectShopItem) {
        selectData.shop = this.selectShopItem
      }
      // 选择结束的最终数据
      this.$emit('select-finish', selectData)
      this.dialogVisible = false
    }
  }
}
</script>

<style>
</style>

<style scoped lang="scss">
.category-select-wrapper {
  display: flex;

  .business-wrapper {
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
  }

  .content-wrapper {
    display: block;
    margin-top: 20px;

    .category-list-wrapper {
      display: flex;
      min-height: 350px;
    }

    .select-number-wrapper {
      display: flex;
      margin-left: 5px;
      margin-top: 20px;
      color: #333;
    }
  }

  .bottom-button {
    text-align: center;
  }
}
</style>
