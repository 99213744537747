var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "data-brain-list-container" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            trigger: "hover",
            "popper-class": "data-brain-list-popper",
            "popper-options": _vm.popperOptions,
          },
          on: { show: _vm.show, hide: _vm.hide },
          scopedSlots: _vm._u(
            [
              {
                key: "reference",
                fn: function () {
                  return [_vm._t("dataBrain")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "ul",
            { staticClass: "data-brain-list" },
            [
              _vm._l(_vm.dataBrainMenu, function (item) {
                return [
                  _c(
                    "li",
                    {
                      key: item.id,
                      staticClass: "data-brain-item",
                      attrs: { slot: "reference" },
                      on: {
                        click: function ($event) {
                          return _vm.itemClick(item)
                        },
                      },
                      slot: "reference",
                    },
                    [
                      _c("div", {
                        staticClass: "name",
                        domProps: { textContent: _vm._s(item.name) },
                      }),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }