/*
 * @Author: jianying.wan
 * @Date: 2019-07-03 16:05:08
 * @LastEditors: 小广
 * @LastEditTime: 2020-05-11 11:33:01
 * @Description: 公共正则
 */
// url
const urlRegular = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\\.,@?^=%&:/~\\+#]*[\w\-\\@?^=%&/~\\+#])?/

// 快递单号
const expressNumberRegular = /^[a-zA-Z0-9]{1,32}$/

// 名字 10字
const nameRegular = /^[\u4e00-\u9fa5a-zA-Z]{2,10}$/

// 名字 20字
const nameRegular20 = /^[\u4e00-\u9fa5a-zA-Z]{2,20}$/

// 手机号码
const mobileRegular = /^(1)\d{10}$/

// 固定电话号码 新需求RONG-3533 和杨阔商定，允许输入 数字 和 -，- 位置和个数不做限制。
const telephoneRegular = /^[\d-]{5,20}$/

// 身份证号码
const idNumberRegular = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/

// 邮件地址
const emailAddressRegular = /^([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/

// 车牌号, 共7-8位, 第1位为汉字, 第2位为字母(需要自动把小写字母弄成大写), 5至6位车牌号


// const carPlateRegular = /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([A-HJ-NP-Z0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[A-HJ-NP-Z0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/
const carPlateRegular = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/

// 验证码
const verifyCodeRegular = /^[0-9]{6}$/

// 匹配特殊字符
const specialCharRegular = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？%+_]")

export {
  urlRegular,
  expressNumberRegular,
  nameRegular,
  nameRegular20,
  mobileRegular,
  telephoneRegular,
  idNumberRegular,
  emailAddressRegular,
  carPlateRegular,
  verifyCodeRegular,
  specialCharRegular
}
