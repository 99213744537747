<!--
 * @Author: jianying.wan
 * @Date: 2020-03-07 10:38:41
 * @LastEditors: jianying.wan
 * @LastEditTime: 2020-03-09 11:14:47
 * @Description: 滑动加载更多的列表
 -->
<template>
  <div class="list-container"
       ref="contentWrapper"
       @scroll="listScroll">
    <div class="header">
      <!-- 配置右上角操作按钮 -->
      <slot name="headerSlot"></slot>
    </div>
    <div class="custom-content">
      <!-- 自定义内容, 位于搜索项上方 -->
      <slot></slot>
    </div>
    <search-panel ref="searchPanel"
                  :searchVisible="searchVisible"
                  :searchName="searchName"
                  :exportName="exportName"
                  :exportPermission="exportPermission"
                  :multiExport="multiExport"
                  @searchData="searchPanelSearchData"
                  @exportData="exportData">
      <template #searchSlot>
        <!-- 配置搜索项中的控件 -->
        <slot name="searchSlot"></slot>
      </template>
      <template #btnSlot="scope">
        <!-- 配置搜索项右侧的操作按钮 -->
        <slot name="btnSlot"
              :searchDisabled="scope.searchDisabled"></slot>
      </template>
    </search-panel>
    <div class="empty-wrapper"
         v-if="noData">
      <img :src="emptyImgSrc" />
      <span v-text="emptyText"></span>
    </div>
    <div :class="['loading-wrapper', {'active': isSearching}]"
         v-loading="isSearching"></div>
    <div class="list-content"
         ref="listWrapper">
      <slot name="listSlot"></slot>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import searchPanel from 'components/search-panel/search-panel'
import { downloadHelper } from 'common/utils'
import { mapMutations, mapGetters } from 'vuex'
import { Notification, Progress } from 'element-ui'
import emptyImgSrc from './images/empty.png'

Vue.use(Progress)

const exportProgress = {
  data () {
    return {
      percentage: 0
    }
  },
  methods: {
    percentageChange (percentage) {
      this.percentage = percentage
    }
  },
  render (createElement) {
    return createElement('div', null, [
      createElement('el-progress', {
        props: {
          percentage: this.percentage
        }
      })
    ])
  }
}

// @group 业务组件
// @title List 列表
export default {
  name: 'scroll-list',
  components: {
    searchPanel,
    exportProgress // eslint-disable-line
  },
  props: {
    // 是否显示搜索按钮
    searchVisible: {
      type: Boolean,
      default: true
    },
    // 搜索按钮名称
    searchName: String,
    // 单个导出按钮名称
    exportName: String,
    // 列表数据查询接口
    searchUrl: {
      type: String,
      required: true
    },
    // 搜索项中的列表数据查询参数
    searchParams: {
      type: Object,
      // `{}`
      default () {
        return {}
      }
    },
    // 列表数据查询附加参数
    extraParams: {
      type: Object,
      // `{}`
      default () {
        return {}
      }
    },
    // 列表数据导出接口
    exportUrl: String,
    // 列表数据导出权限
    exportPermission: [Boolean, String],
    // 列表数据导出方式，默认根据exportUrl来判断（老接口直接下载文件 `old`，新接口从导出中心下载 `delay`），传递此参数可以直接指定导出方式
    exportMethod: {
      // `'old'` / `'new'` / `'delay'`
      type: String
    },
    // 列表数据导出前回调函数，返回的布尔值决定是否继续执行导出操作
    exportBefore: Function,
    // 列表数据导出参数，默认使用查询接口参数，可通过此选项进行扩展
    exportParams: {
      type: Object,
      // `{}`
      default () {
        return {}
      }
    },
    // 配置导出按钮
    multiExport: Array,
    // 接口请求之前的回调函数，可以对搜索条件做一些预处理
    searchBefore: Function,
    // 物联网列表初始化的时候是否需要默认的园区参数
    needDefaultIotCommunity: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      name: '',
      echo: true,
      isSearching: false,
      pageInfo: {
        curPage: 1,
        pageSize: 20
      },
      noData: false,
      hasMore: false,
      listData: [],
      emptyImgSrc,
      emptyText: '对不起，没有找到符合条件的记录'
    }
  },
  created () {
    this.echo = this.extraParams === null || Object.keys(this.extraParams).length === 0
    if (this.echo) {
      this.name = this.$route.name
      let searchParams = this.getListSearch(this.name)
      if (this.needModifyIotCommunity() && this.needDefaultIotCommunity) {
        // 添加物联网园区默认搜索参数
        if (searchParams) {
          searchParams.communityId = this.iotCommunityInfo.id
        } else {
          searchParams = {
            communityId: this.iotCommunityInfo.id
          }
        }
      }
      if (searchParams) {
        this.$emit('update:searchParams', searchParams)
      }
    } else {
      let searchParams = {
        ...this.searchParams,
        ...this.extraParams
      }
      if (this.needModifyIotCommunity() && this.needDefaultIotCommunity) {
        // 添加物联网园区默认搜索参数
        searchParams.communityId = this.iotCommunityInfo.id
      }
      this.$emit('update:searchParams', searchParams)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.searchData()
    }, 20)
  },
  computed: {
    iotCommunityInfo () {
      // 物联网当前默认园区
      return this.$store.state.iotCommunityStore.currentIotCommunity
    },
    ...mapGetters('searchStore', [
      'getListSearch'
    ])
  },
  watch: {
    iotCommunityInfo () {
      if (this.needModifyIotCommunity()) {
        this.searchParams.communityId = this.iotCommunityInfo.id
      }
      this.searchData()
    }
  },
  beforeDestroy () {
    if (this.echo) {
      this.setListSearch({
        name: this.name,
        params: this.searchParams
      })
    }
  },
  methods: {
    listScroll (event) {
      let scrollTop = this.$refs.contentWrapper.scrollTop
      let height = this.$refs.contentWrapper.clientHeight
      let totalHeight = this.$refs.listWrapper.clientHeight
      if (scrollTop + height > totalHeight - 10) {
        // 距离底部10加载更多页面
        if (this.hasMore && !this.isSearching) {
          let pageInfo = this.pageInfo
          let curPage = pageInfo.curPage
          pageInfo.curPage = curPage + 1
          this.searchData({}, pageInfo)
        }
      }
    },
    needModifyIotCommunity () {
      // 是否需要修改物联网默认的园区搜索
      let need = this.searchParams && 'communityId' in this.searchParams && this.iotCommunityInfo.id && this.$route.meta.iotMenu
      return need
    },
    handleSearchParams (postData) {
      for (let key in postData.params) {
        if (typeof postData.params[key] === 'string') {
          postData.params[key] = postData.params[key].trim()
        }
        if (postData.params[key] === null || postData.params[key] === '') {
          postData.params[key] = undefined
        }
      }
    },
    // @vuese
    // 搜索列表数据
    searchPanelSearchData () {
      this.searchData()
    },
    // @vuese
    // 搜索列表数据
    async searchData (searchParams = {}, paginationParams = {
      curPage: 1,
      pageSize: 20
    }) {
      let _this = this
      let postData = {
        params: {
          ..._this.searchParams,
          ..._this.sortParams,
          ...paginationParams,
          ...searchParams
        }
      }
      _this.handleSearchParams(postData)
      let searchBeforeResult = _this.searchBefore ? await _this.searchBefore(postData) : true
      if (!searchBeforeResult) {
        return
      }
      _this.isSearching = true
      _this.$refs.searchPanel.setSearchDisabled(true)
      // _this.$refs.paginationPanel.setDisabled(true)
      _this.$axios.get(_this.searchUrl, postData).then(res => {
        if (res.status == 100) { // eslint-disable-line
          let data = res.data || {}
          _this.hasMore = data.maxPage > data.curPage
          _this.pageInfo.curPage = data.curPage
          _this.pageInfo.maxPage = data.maxPage
          _this.pageInfo.maxRow = data.maxRow
          _this.pageInfo.row = data.row
          let dl = []
          if (data.curPage > 1) {
            dl = _this.listData
          }
          let resultList = data.resultList || []
          dl = dl.concat(resultList)
          _this.noData = dl.length === 0
          _this.listData = dl
          // 搜索成功回调
          // @arg 接口响应参数
          _this.$emit('searchSuccess', _this.listData)
        } else {
          _this.tableData = []
          // 搜索失败回调
          // @arg 接口响应参数
          _this.$emit('searchError', res)
        }
        _this.isSearching = false
        _this.$refs.searchPanel.setSearchDisabled(false)
      })
    },
    // @vuese
    // 导出列表数据
    getSearchParams (searchParams = {}) {
      let _this = this
      return {
        params: {
          ..._this.searchParams,
          ..._this.sortParams,
          ..._this.pageInfo,
          ...searchParams
        }
      }
    },
    // @vuese
    // 导出列表数据
    async exportData (index) {
      let _this = this
      let exportUrl, exportMethod, exportBefore
      if (index !== -1) {
        let { url, method, before } = _this.multiExport[index]
        exportUrl = url
        exportMethod = method
        exportBefore = before
      } else {
        exportUrl = _this.exportUrl
        exportMethod = _this.exportMethod
        exportBefore = _this.exportBefore
      }
      if (!exportUrl) {
        return
      }
      let postData = {
        params: {
          ..._this.searchParams,
          ..._this.exportParams
        }
      }
      let result = exportBefore ? await exportBefore(postData) : true
      if (!result) {
        return
      }
      let exportOptions = {
        exportUrl,
        postData
      }
      _this.handleSearchParams(postData)
      _this.$refs.searchPanel.setExportDisabled(true)
      if (exportMethod && exportMethod === 'old') {
        _this.oldExport(exportOptions)
      } else if (exportMethod && exportMethod === 'new') {
        _this.newExport(exportOptions)
      } else if (exportMethod && exportMethod === 'delay') {
        _this.delayExport(exportOptions)
      } else if (exportUrl.indexOf('!') !== -1) {
        _this.oldExport(exportOptions)
      } else {
        _this.delayExport(exportOptions)
      }
    },
    oldExport (ops) {
      let { exportUrl, postData } = ops
      let config = {
        action: exportUrl,
        formData: postData.params
      }
      downloadHelper.downloadByAction(config)
      this.$refs.searchPanel.setExportDisabled(false)
    },
    async newExport (ops) {
      let _this = this
      let breadcrumb = _this.$store.getters.getBreadcrumb
      let title = `${breadcrumb.join(' > ')} - 导出进度`
      let exportProgressVNode = _this.$createElement('exportProgress', null)
      let notify = Notification({
        title: title,
        dangerouslyUseHTMLString: true,
        duration: 0,
        message: exportProgressVNode
      })
      downloadHelper.downloadByApi(ops, (res) => {
        if (res.status == 100) { // eslint-disable-line
          let data = res.data
          data.percent && exportProgressVNode.componentInstance.percentageChange(parseInt(data.percent))
          if (data.percent === '100') {
            _this.$refs.searchPanel && _this.$refs.searchPanel.setExportDisabled(false)
            downloadHelper.downloadByLocation(data.filePath)
            setTimeout(() => {
              notify.close()
            }, 1000)
          }
        } else {
          _this.$refs.searchPanel && _this.$refs.searchPanel.setExportDisabled(false)
          notify.close()
        }
      })
    },
    delayExport (ops) {
      let _this = this
      let { exportUrl, postData } = ops
      _this.$axios.get(exportUrl, postData).then(res => {
        if (res.status == 100) { // eslint-disable-line
          _this.$message({
            type: 'success',
            message: '导出成功，请稍候前往导出中心查看进度',
            center: true
          })
        }
        _this.$refs.searchPanel.setExportDisabled(false)
      })
    },
    ...mapMutations('searchStore', [
      'setListSearch'
    ])
  }
}
</script>

<style lang="scss">
.list-container {
  .table-panel {
    .operate {
      .el-dropdown {
        color: #409eff;
        cursor: pointer;
        font-size: inherit;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.list-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px;
  background-color: #fff;
  overflow-y: scroll;
  -ms-overflow-style: none;
  margin-bottom: 24px;
  &::-webkit-scrollbar {
    display: none;
  }
  .empty-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    span {
      flex: 0 0 100%;
    }
  }
  .loading-wrapper {
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: calc(100% - 120px);
    z-index: -20;
    &.active {
      z-index: 20;
    }
  }
  .header {
    position: absolute;
    top: 20px;
    right: 20px;
    :not(:last-child) {
      margin-right: 8px;
    }
  }
}
</style>
