var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tree-button-wrapper", attrs: { id: "treeButtonBox" } },
    [
      _c(
        "div",
        { staticClass: "tree-button-box", attrs: { id: "treeButton" } },
        [
          _c("el-tree", {
            ref: "elTree",
            attrs: {
              "node-key": _vm.nodeKey,
              "default-checked-keys": _vm.defaultCheckedKeys,
              data: _vm.data,
              props: _vm.defaultProps,
              "filter-node-method": _vm.filterNodeMethod,
              "empty-text": _vm.emptyText,
              lazy: _vm.lazy,
              load: _vm.loadData,
              "default-expanded-keys": _vm.defaultExpandedKeys,
              "default-expand-all": _vm.expandAll,
              "check-strictly": _vm.checkStrictly,
              "expand-on-click-node": _vm.expandOnClickNode,
              accordion: _vm.accordion,
              "highlight-current": _vm.highlightCurrent,
              "current-node-key": _vm.currentNodeKey,
              "show-checkbox": _vm.showCheckbox,
              "check-on-click-node": _vm.checkOnClickNode,
              draggable: _vm.draggable,
              "allow-drag": _vm.allowDrag,
              indent: _vm.indent,
              "render-after-expand": _vm.renderAfterExpand,
            },
            on: {
              "node-click": _vm.nodeClick,
              "node-contextmenu": _vm.nodeContextMenu,
              "check-change": _vm.checkChange,
              check: _vm.check,
              "current-change": _vm.currentChange,
              "node-expand": _vm.nodeExpand,
              "node-collapse": _vm.nodeCollapse,
              "node-drag-start": _vm.nodeDragStart,
              "node-drag-enter": _vm.nodeDragEnter,
              "node-drag-leave": _vm.nodeDragLeave,
              "node-drag-over": _vm.nodeDragOver,
              "node-drag-end": _vm.nodeDragEnd,
              "node-drop": _vm.nodeDrop,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ node, data }) {
                  return [
                    _c("div", { staticClass: "custom-tree-node" }, [
                      data.icon
                        ? _c("span", {
                            staticClass: "icon",
                            style: _vm.handleBG(data, node),
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          ref: "nodeContent",
                          staticClass: "node-content",
                          on: {
                            contextmenu: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.contentContextMenu(data, node, $event)
                            },
                          },
                        },
                        [
                          data && data.html
                            ? _c("div", {
                                staticStyle: { display: "inline-block" },
                                domProps: { innerHTML: _vm._s(data.value) },
                              })
                            : data && data.component
                            ? _c(data.componentName, {
                                tag: "component",
                                attrs: { row: _vm.scope.row },
                              })
                            : _c("div", {
                                staticStyle: { display: "inline-block" },
                                domProps: {
                                  textContent: _vm._s(
                                    data[_vm.defaultProps.label]
                                  ),
                                },
                              }),
                        ],
                        1
                      ),
                      _vm.showBtnAll(data)
                        ? _c("div", { staticClass: "node-button" }, [
                            _c(
                              "div",
                              {
                                staticClass: "node-button-icon",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.buttonIconClick(data, $event)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-more" })]
                            ),
                            _c(
                              "ul",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showList(data),
                                    expression: "showList(data)",
                                  },
                                ],
                                staticClass: "node-button-list",
                              },
                              _vm._l(_vm.btnFilterOps, function (item) {
                                return _c(
                                  "li",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showBtn(data, item),
                                        expression: "showBtn(data, item)",
                                      },
                                    ],
                                    key: item.value,
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.buttonClick(data, item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.text) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }