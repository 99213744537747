var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "v-uploader-wrapper" }, [
    _c(
      "div",
      { staticClass: "v-controls" },
      [
        _c(
          "el-upload",
          {
            ref: "upload",
            staticClass: "upload-demo",
            style: _vm.fileStyle,
            attrs: {
              disabled: _vm.disabled,
              data: _vm.extraData,
              action: _vm.action,
              multiple: _vm.multiple,
              "show-file-list": false,
              "before-upload": _vm.beforeAvatarUpload,
              drag: false,
              "auto-upload": true,
              "on-success": _vm.successUpload,
              "on-error": _vm.errorUpload,
              "list-type": _vm.listType,
              "on-change": _vm.onChange,
              headers: _vm.importHeaders,
              "http-request": _vm.httpRequest,
              name: _vm.name,
              limit: _vm.limitValue,
              accept: _vm.acceptFileType,
            },
          },
          [
            _c("div", { staticClass: "box", on: { click: _vm.btnClick } }, [
              !_vm.disabled
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.limit -
                              _vm.value.length -
                              _vm.uploadListShow.length >
                            0,
                          expression:
                            "limit - value.length - uploadListShow.length > 0",
                        },
                      ],
                      staticClass: "box-top",
                    },
                    [
                      _c(
                        "v-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          attrs: { type: "primary" },
                        },
                        [_vm._v("点击上传\r\n          ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "fill-empty",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " 剩余可上传数为 " +
                              _vm._s(
                                /* 禁止这片空白区域被点击 */ _vm.limitValue
                              ) +
                              "，超出将取消本次上传!"
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "box-content",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _vm._l(_vm.uploadListShow, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: `upload_${index}`,
                        staticClass: "file-list",
                        style: { width: _vm.width },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "pre-icon" }, [
                          _c("i", { staticClass: "el-icon-loading" }),
                        ]),
                        item !== "" && typeof item === "object"
                          ? _c("div", { staticClass: "file-name" }, [
                              _vm._v("文件正在上传中..."),
                            ])
                          : typeof item === "string" && item !== ""
                          ? _c("div", { staticClass: "file-name" }, [
                              _vm._v(
                                "\r\n              文件上传成功,等待其他文件上传完成中...\r\n            "
                              ),
                            ])
                          : _c("div", { staticClass: "file-name" }, [
                              _vm._v("文件上传失败,等待其他文件上传完成中..."),
                            ]),
                      ]
                    )
                  }),
                  _vm._l(_vm.fileListShow, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "file-list",
                        style: { width: _vm.width },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.previewUpload.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "pre-icon" }, [
                          _c("i", { staticClass: "el-icon-document" }),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "file-name",
                            attrs: { title: item.name },
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "down file-icon",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.downFile(item)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-download" })]
                        ),
                        !_vm.disabled
                          ? _c(
                              "div",
                              {
                                staticClass: "delete file-icon",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteFile(index, item)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }