<template>
  <div class="qrcode-list-container">
    <el-popover popper-class="qrcode-list-popper" placement="bottom-end" :popper-options="popperOptions" :offset="-16">
      <div class="header" v-text="title"></div>
      <ul class="qrcode-list">
        <li class="qrcode-item" v-for="(item, index) in qrcodeList" :key="index">
          <div :id="'download_qrcode_img_' + index" class="img"></div>
          <div class="name" v-text="item.name"></div>
        </li>
      </ul>
      <template v-slot:reference>
        <slot name="qrcode"></slot>
      </template>
    </el-popover>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2'
export default {
  name: 'qrcode-list',
  props: {
    qrcodeList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      title: '下载二维码',
      popperOptions: {
        boundariesElement: '.qrcode-list-container'
      }
    }
  },
  mounted () {
    const qrcodeList = this.qrcodeList
    if (qrcodeList && qrcodeList.length > 0) {
      qrcodeList.forEach((item, index) => {
        const id = `download_qrcode_img_${index}`
        this.$nextTick(() => {
          new QRCode(id, { // eslint-disable-line
            width: 100,
            height: 100,
            text: item.src
          })
        }, 20)
      })
    }
  }
}
</script>
<style lang="scss">
  .qrcode-list-popper {
    padding: 1px 0 0 0 !important;
    border: none !important;
  }
</style>
<style scoped lang="scss">
  .qrcode-list-popper {
    .header {
      height: 44px;
      padding: 0 24px;
      line-height: 44px;
      color: #AAA;
      background-color: #e3f0ff;
    }
    .qrcode-list {
      max-width: 430px;
      max-height: 600px;
      overflow: auto;
      .qrcode-item {
        display: inline-block;
        text-align: center;
        margin: 10px 20px;
        .img {
          width: 100px;
          height: 100px;
          margin-bottom: 10px;
        }
        .name {
          font-size: 14px;
          height: 14px;
          line-height: 14px;
        }
      }
    }
  }
</style>
