<template>
  <div class="menu-group-top">
    <!-- 三级菜单已经在外层循环这里遇到三级菜单（level == 1）不展示 -->
    <div
      class="menu-group-item"
      :class="setNameClass(group)"
      @click="itemClick(group)"
      v-if="level > 1"
    >
      
      <div
        class="menu-group-item-name"
        :class="group.urlJumpType < 0 && 'is-sub-menu'"
 
      >
       {{ group.name }}
      </div>
    </div>

    <div
      class="menu-group-list"
      :class="
        item.level == 0 ? 'menu-group-list-root' : 'menu-group-list-child'
      "
      v-for="item in group.childMenus"
      :key="item.id"
    >
      <menu-group-top
        v-show="
          status &&
            item.menuType >= 1 &&
            item.menuType <= 2 &&
            item.specialNo !== 'system_menuGroup'
        "
        :group="item"
        :status="status"
        :centerItem="centerItem"
        :firstItem="firstItem"
        :secondItem="secondItem"
        :thirdItem="thirdItem"
        :activeMenuId="activeMenuId"
        :level="item.level"
      />
    </div>
  </div>
</template>

<script>
import arrowdownSrc from './images/arrowdown.png'
import arrowupSrc from './images/arrowup.png'
import { menuJump, internalMenuJump, findFirstMenu } from '@/common/menuHandler'

export default {
  name: 'menu-group-top',
  props: {
    group: Object,
    status: Boolean,
    centerItem: Object,
    firstItem: Object,
    secondItem: Object,
    thirdItem: Object,
    activeMenuId: Object,
    level: Number,
  },
  inject:['menubar'],
  data() {
    return {
      arrowdownSrc,
      arrowupSrc,
      itemMap: {
        0: 'centerItem',
        1: 'firstItem',
        2: 'secondItem',
        3: 'thirdItem'
      }
    }
  },
  methods: {
    setNameClass(item) {
      const { menuType, level, name, id, specialNo } = item
      let classList = ['menu-group-item_' + id]
      if (level === 0) {
        classList.push('menu-group-item-root')
      } else {
        classList.push('menu-group-item-child')
      }
      const key = this.itemMap[level]
      if (key && this[key] && this[key].id === id) {
        if (level === 0) {
          classList.push('active-root')
        }
        //  else {
        //   classList.push('active-child')
        // }
      }
      return classList
    },
    navigate(item) {
      const { menuType, urlJumpType, name, id, specialNo, level, url, childMenus } = item
      if (menuType === 1) {
        const activeMenuId = this.activeMenuId[level + 1]
        if (level === 0) {
          if (specialNo === 'system_menuGroup') {
            if (childMenus && childMenus.length) {
              const firstChildMenuId = childMenus[0].id
              internalMenuJump(firstChildMenuId)
            }
            return
          }
          if (activeMenuId && activeMenuId.length) {
            if (activeMenuId.includes(id)) {
              activeMenuId.splice(0, activeMenuId.length)
            } else {
              activeMenuId.splice(0, activeMenuId.length, id)
            }
          } else {
            activeMenuId.push(id)
          }
        } else {
          const index = activeMenuId.indexOf(id)
          console.log(index)
          if (index !== -1) {
            activeMenuId.splice(index, 1)
          } else {
            activeMenuId.push(id)
          }
        }
      } else if (menuType === 2) {
        if ([1, 2].includes(urlJumpType)) {
          menuJump(item)
        } else {
          internalMenuJump(id)
        }
      }
    },
    itemClick(item) {
      if(item.urlJumpType < 0){
        return
      }
      if (this.status) {
        this.navigate(item)
      } else {
        const menuItem = findFirstMenu(item)
        if (menuItem) {
          this.navigate(menuItem)
        }
      }
      this.menubar.closeDrawer()
    }
  }
}
</script>
