<template>
  <div class="v-control v-cascader-wrapper">
    <el-cascader
      v-model="model"
      :style="{ width: width + 'px' }"
      :clearable="clearable"
      :props="staProps"
      :placeholder="placeholder"
      :disabled="disabled"
      :filterable="filterable"
      :separator="separator"
      @change="change"
      ref="cascader"
    >
    </el-cascader>
  </div>
</template>

<script>
import Vue from 'vue'
import { Cascader } from 'element-ui'

Vue.use(Cascader)

// @group 基础组件
// @title Cascader 级联选择器
export default {
  name: 'area-select2',
  props: {
    // 选中项的value数组，支持`.sync`
    value: Array,
    // 额外class类名
    extraClass: {
      type: String,
      default: ''
    },
    // 选项分隔符
    separator: {
      type: String,
      // `横杠'-'`
      default: '-'
    },
    // 输入框占位文本
    placeholder: String,
    // 是否禁用
    disabled: {
      type: Boolean,
      // `false`
      default: false
    },
    // 是否显示清除按钮
    clearable: {
      type: Boolean,
      // `true`
      default: true
    },
    // 显示宽度
    width: {
      type: Number,
      // `160`
      default: 160
    },
    // 是否可搜索选项
    filterable: {
      type: Boolean,
      // `false`
      default: false
    }
  },
  data () {
    return {
      model: this.value,
      staProps: {
        label: 'name',
        value: 'code',
        lazy: true,//设置lazyLoad，必须配合lazy：true
        lazyLoad: this.getSta
      },
      getAreaListUrl: `${API_CONFIG.butlerBaseURL}common/getAreaList`
    }
  },

  watch: {
    model (newValue) {
      // 返回当前所选值的value数组
      this.$emit('update:value', newValue)
    },

    value (newValue, oldValue) {
      this.model = newValue
      if (this.model && this.model.length) {
        // 回显触发change
        this.change(this.value)
      }
    }
  },

  methods: {
    getSta(node, resolve) {
      if (node && node.level ==5 ) {
        return resolve([]);
      }
      this.$axios.get(this.getAreaListUrl+`?areaCode=${node.value?node.value: undefined}`).then((req) => {
        resolve(
          req.data.map(v=>({
            ...v,
            leaf: node.level >= 4
          }))
        )
      });
    },
    change (value) {
      var checkedNodes = this.$refs["cascader"].getCheckedNodes().length?this.$refs["cascader"].getCheckedNodes()[0].pathNodes: {}
      let val = {}
      if (checkedNodes && checkedNodes.length) {
        val['province'] = checkedNodes[0]
        val['city'] = checkedNodes[1]
        val['area'] = checkedNodes[2]
        val['street'] = checkedNodes[3]
        val['shequ'] = checkedNodes[4]
      }
      this.$emit('change', val, value)
    }
  }
}
</script>

<style scoped lang="scss">
.v-cascader-wrapper {
  display: inline-block;
}
</style>
