<template lang="html">
  <el-col :span="span">
    <div class="col2-block">
      <div class="title" v-if="title">
        <span class="pre-point"></span>
        <span v-text="title"></span>
        <slot name="headerOperate"></slot>
      </div>
      <div class="block-content">
        <slot>
          <div class="nodata-content">
            暂无数据
          </div>
        </slot>
      </div>
    </div>
  </el-col>
</template>
<script>
// @group 业务组件
// @title Col2Block Col2组件块级容器
export default {
  name: 'col2-block',
  props: {
    // 块级标题
    title: String,
    // 栅格数
    span: {
      type: Number,
      default: 24
    }
  }
}
</script>

<style lang="scss" scoped>
.pre-point {
  border-radius: 50%;
  background-color: #dce2e7;
  height: 5px;
  width: 5px;
  margin-right: 5px;
}
.title {
  display: flex;
  align-items: center;
}
.block-content {
  margin-top: 8px;
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
}
.nodata-content{
  display: flex;
  text-align: left;
  min-height: 80px;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ddd;
}
</style>
<style lang="scss">
.col2-block {
  .el-form-item__label {
    font-weight: bold;
    color: #333;
  }
  .block-content>.el-form-item {
    clear: both;
  }
}
</style>
