<template>
    <div :class="['checkbox-plus-wrapper', {'check-item-vertical' : align === 'vertical'}, checkClass]" :style="checkStyle">
      <div class="check-item" v-for="(item, index) in myOptions" :key="index">
        <v-checkbox v-if="type === 'radio'" :type="type" :ref="`checkbox${index}`" :index="index" :label="item.label" v-model="model" :labelValue="item.value" :disabled="item.disabled" :canInvert="canInvert" @pick="onChange"></v-checkbox>
        <v-checkbox v-else :type="type" :ref="`checkbox${index}`" :index="index" :label="item.label" v-model="checkboxValue[index]" :disabled="item.disabled" :canInvert="canInvert" @pick="onChange"></v-checkbox>
      </div>
    </div>
</template>

<script>
import { vCheckbox } from '../../control/index'
import emitter from 'element-ui/src/mixins/emitter'
// @group 业务组件
// @title CheckboxPlus 加强版复选框
export default {
  name: 'checkbox-plus',
  mixins: [emitter],
  props: {
    // 样式类型: border:按钮样式  default: 普通checkbox样式 radio: radio单选样式
    type: {
      type: String,
      // `border`
      default: 'border'
    },
    // 选择模式: radio: 单选 checkbox-plus: 复选 cascade: 包含&互斥(级联)
    mode: {
      type: String,
      // `radio`
      default: 'radio'
    },
    // 对齐方式:horizontal: 水平排列  vertical: 垂直排列
    align: {
      type: String,
      // `horizontal`
      default: 'horizontal'
    },
    // 选项数组，数组子对象为{label: '共享停车', value: '1'}
    options: {
      type: Array,
      // `[]`
      default: () => {
        return []
      }
    },
    // 双向绑定的值，如果model为radio，则为单个值，否则为[]
    value: {
      type: [Array, String, Boolean, Number]
    },
    // 是否整体禁用
    disabled: {
      type: Boolean
    },
    // 额外style样式
    checkStyle: {
      type: Object,
      // `{}`
      default: () => {
        return {}
      }
    },
    // 额外class类
    checkClass: {
      type: Array,
      // `[]`
      default: () => {
        return []
      }
    },
    // 内置禁用的参数名
    disabledName: {
      type: String,
      // `disabled`
      default: 'disabled'
    }
  },
  components: {
    'v-checkbox': vCheckbox
  },
  created: function () {
    this.initData()
  },
  data () {
    return {
      model: this.value,
      checkboxValue: [],
      radioValue: undefined
    }
  },
  watch: {
    value (newValue) {
      this.model = newValue
      this.initData()
    },
    model (newValue) {
      this.$emit('update:value', newValue)
    }
  },
  computed: {
    myOptions () {
      let disabled = this.disabled
      let options = this.options
      let myOptions = []
      Array.isArray(options) && options.forEach((item) => {
        item.disabled = disabled || item[this.disabledName]
        myOptions.push(item)
      })
      return myOptions
    },
    canInvert () {
      let mode = this.mode
      if (mode === 'radio') {
        // 单选
        return false
      } else if (mode === 'checkbox') {
        // 复选
        return true
      } else {
        return true
      }
    }
  },
  methods: {
    initData () {
      let type = this.type
      let mode = this.mode
      let checkboxValue = this.checkboxValue = []
      if (type !== 'radio') {
        let options = this.myOptions
        let model = this.model
        Array.isArray(options) && options.forEach((item) => {
          if (mode === 'radio') {
            if (model === item.value) {
              checkboxValue.push(true)
            } else {
              checkboxValue.push(false)
            }
          } else {
            if (model.includes(item.value)) {
              checkboxValue.push(true)
            } else {
              checkboxValue.push(false)
            }
          }
        })
        this.checkboxValue = checkboxValue
      }
    },
    // @vuese
    // 获取所有已选中项
    getCheckedOptions () {
      let options = this.myOptions
      let model = this.model
      let checkedOptions = []
      let mode = this.mode
      if (mode === 'radio') {
        let res = options.find((item) => {
          return item.value === model
        }) || {}
        return res
      } else {
        Array.isArray(options) && options.forEach((item) => {
          if (model.includes(item.value)) {
            checkedOptions.push(item)
          }
        })
        return checkedOptions
      }
    },
    onChange (val, index) {
      let mode = this.mode
      let type = this.type
      let options = this.myOptions
      // 增加回调
      if (mode === 'radio') {
        if (val) {
          this.model = options[index].value
        } else {
          this.model = undefined
        }
      }
      if (mode === 'radio' && type !== 'radio') {
        this.cancelOther(index)
      }
      if (mode === 'cascade') {
        // 级联模式
        // 这种模式下,必须保证数组的顺序和级联顺序一一对应
        this.cascadeChange(val, index)
      }
      // 增加回调事件
      this.$nextTick(() => {
        let _this = this
        if (type !== 'radio' && mode !== 'radio') {
          _this.model = []
          let checkboxValue = this.checkboxValue
          Array.isArray(checkboxValue) && checkboxValue.forEach((item, index) => {
            let val = options[index].value
            if (item) {
              if (!_this.model.includes(val)) {
                _this.model.push(val)
              }
            }
          })
        }
        let checkedOption = this.getCheckedOptions()
        // 单选不用返回数组
        if (mode === 'radio') {
          val = true
        }
        // 选择框value改变时的回调
        // @arg (index, pureCheckedOptions, checkedOptions)接收四个参数 1.index 当前选中checkbox的索引
        // @arg 2.pureCheckedOptions 只包含选中项的value的数组
        // @arg 3.checkedOptions 包含选中项的所有键值对
        // @arg 4.extraData 其他额外参数,目前包含status(标识当前是选中还是取消选择)
        this.$emit('onChange', index, this.model, checkedOption, {
          // 标识当前是选中还是取消选择
          status: val
        })
        this.$emit('update:value', this.model)
        this.dispatch('ElFormItem', 'el.form.change', this.model)
      }, 20)
    },
    // 取消其他已选项
    cancelOther (index) {
      let checkboxValue = this.checkboxValue
      let options = this.myOptions
      if (options && options.length) {
        for (let i = 0; i < options.length; i++) {
          let item = options[i]
          if (i !== index) {
            if (!item.disabled && checkboxValue[i]) {
              checkboxValue[i] = false
            }
          } else {
            checkboxValue[i] = true
          }
        }
      }
    },
    // 级联变动
    cascadeChange (val, index) {
      let checkboxValue = this.checkboxValue
      let options = this.myOptions || []
      let len = options.length
      if (val) {
        // 选中
        index += 1
        if (index < len) {
          for (let i = index; i < len; i++) {
            let item = options[i]
            // 跳过已经选中的项
            if (!item.disabled && !checkboxValue[i]) {
              // 将当前项往右的选项置为选中状态
              this.$set(checkboxValue, i, true)
              this.cascadeChange(true, i)
              break
            }
          }
        }
      } else {
        // 取消选择
        index -= 1
        if (index > -1) {
          for (let i = index; i > -1; i--) {
            let item = options[i]
            // 跳过未选中的项
            if (!item.disabled && checkboxValue[i]) {
              // 将当前项往左的选项置为未选中状态
              this.$set(checkboxValue, i, false)
              this.cascadeChange(false, i)
              break
            }
          }
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.checkbox-plus-wrapper {
  display: flex;
  flex-wrap: wrap;
  .check-item {
    display: flex;
    align-items: center;
    min-height: 36px;
    padding-bottom: 0;
    padding-right: 16px;
    margin-top: 10px;
  }
  div:last-child {
    padding-right: 0;
    padding-bottom: 0;
  }
}
.check-item-vertical {
  flex-direction: column;
  .check-item {
    padding-right: 0;
    padding-bottom: 10px;
  }
}
</style>
<style lang="scss">
  .checkbox-plus-wrapper {
    .my-checkbox {
      display: flex !important;
      // .el-checkbox__input {
      //   line-height: 28px;
      // }
      .el-checkbox__label {
        text-align: left;
        white-space: normal;
      }
    }
    .my-radio {
      display: flex !important;
      // .el-radio__input {
      //   line-height: 20px;
      // }
      .el-radio__label {
        text-align: left;
        white-space: normal;
      }
    }
  }
</style>
