<template>
  <div class="pagination-panel-container" v-if="params.maxPage > 0">
    <div class="info" v-text="'第' + params.curPage + '页/共' + params.maxPage + '页 共' + params.maxRow + '条'"></div>
    <div class="ops">
      <v-button class="button-container" text="首页" v-show="params.curPage !== 1" :disabled="disabled" @click="firstClick"></v-button>
      <v-button class="button-container" text="上一页" v-show="params.curPage !== 1" :disabled="disabled" @click="previousClick"></v-button>
      <v-button class="button-container" text="下一页" v-show="params.curPage !== params.maxPage" :disabled="disabled" @click="nextClick"></v-button>
      <v-button class="button-container" text="末页" v-show="params.curPage !== params.maxPage" :disabled="disabled" @click="lastClick"></v-button>
      <div class="jump" v-show="params.maxPage !== 1">
        <span>跳转到</span>
        <v-input-number class="input-page" v-model="inputPage" :min="1" :width="53" />
        <span>页</span>
        <v-button class="button-container button-jump" text="GO" :disabled="disabled || jumpDisabled" @click="jumpClick"></v-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'pagination-panel',
  data () {
    return {
      params: {},
      disabled: false,
      inputPage: 1
    }
  },
  props: {
    name: {}
  },
  mounted () {
    this.resetParams()
  },
  computed: {
    jumpDisabled () {
      return this.inputPage === undefined
    },
    ...mapGetters('searchStore', [
      'getListCurPage'
    ])
  },
  methods: {
    setDisabled (status) {
      this.disabled = status
    },
    firstClick () {
      this.params.curPage = 1
      this.$emit('searchData')
    },
    previousClick () {
      this.params.curPage = this.params.curPage - 1
      this.$emit('searchData')
    },
    nextClick () {
      this.params.curPage = this.params.curPage + 1
      this.$emit('searchData')
    },
    lastClick () {
      this.params.curPage = this.params.maxPage
      this.$emit('searchData')
    },
    jumpClick () {
      if (this.inputPage < 1) {
        this.inputPage = 1
      } else if (this.inputPage > this.params.maxPage) {
        this.inputPage = this.params.maxPage
      }
      this.params.curPage = this.inputPage
      this.$emit('searchData')
    },
    resetParams () {
      let data = this.getListCurPage(this.name)
      this.params = {
        executeSearch: 1,
        curPage: data && data.curPage >= 1 ? data.curPage : 1,
        pageSize: 20,
        maxPage: 0,
        maxRow: 0,
        row: 0
      }
    },
    setParams (params) {
      for (let key in params) {
        this.params[key] = params[key]
      }
    },
    getParams () {
      return this.params
    }
  }
}
</script>
<style scoped lang="scss">
  .pagination-panel-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    .info {
      font-size: 14px;
    }
    .ops {
      margin-left: 10px;
      .button-container {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
      .jump {
        display: inline-block;
        .input-page {
          margin: 0 5px;
        }
        .button-jump {
          margin-left: 10px;
        }
      }
    }
  }
</style>
