const routes = [

  // 党组织管理
  {
    path: '/home/partyMgrList', // 列表
    name: 'partyOrganizationManagement',
    component: resolve => require(['@/views/organize/partyMgr/PartyMgrList'], resolve)
  },
  {
    path: '/home/partyMgrForm', // 表单
    name: 'partyMgrForm',
    component: resolve => require(['@/views/organize/partyMgr/PartyMgrForm'], resolve)
  },

  // 导出中心
  {
    path: '/organize/exportCenter',
    name: 'orgexportcentre0',
    component: resolve => require(['@/views/data/dataExportCenter/DataExportCenterList'], resolve)
  },

  // 品质管理
  {
    path: '/home/organize/qualityCategoryForm', // 品质标准-配置类目-表单页
    name: 'qualityCategoryForm',
    component: resolve => require(['@/views/organize/qualityManage/qualityStandard/QualityCategoryForm'], resolve)
  },
  {
    path: '/home/organize/qualityConfigCategoryList', // 品质标准-配置类目-列表页
    name: 'qualityConfigCategoryList',
    component: resolve => require(['@/views/organize/qualityManage/qualityStandard/QualityConfigCategoryList'], resolve)
  },
  {
    path: '/home/organize/qualityChecklistTemplateForm', // 品质标准-检查表模板-表单页
    name: 'qualityChecklistTemplateForm',
    component: resolve => require(['@/views/organize/qualityManage/qualityStandard/QualityChecklistTemplateForm'], resolve)
  },
  {
    path: '/home/organize/qualityChecklistTemplateList', // 品质标准-检查表模板-列表页
    name: 'qualityChecklistTemplateList',
    component: resolve => require(['@/views/organize/qualityManage/qualityStandard/QualityChecklistTemplateList'], resolve)
  },
  {
    path: '/home/organize/qualityInspectionCategoryList', // 品质标准-检查类目-列表页
    name: 'qualityInspectionCategoryList',
    component: resolve => require(['@/views/organize/qualityManage/qualityStandard/QualityInspectionCategoryList'], resolve)
  },
  {
    path: '/home/organize/qualityStandardLibraryForm', // 品质标准-检查标准库-表单页
    name: 'qualityStandardLibraryForm',
    component: resolve => require(['@/views/organize/qualityManage/qualityStandard/QualityStandardLibraryForm'], resolve)
  },
  {
    path: '/home/organize/qualityStandardLibraryList', // 品质标准-检查标准库-列表页
    name: 'qualityStandardLibraryList',
    component: resolve => require(['@/views/organize/qualityManage/qualityStandard/QualityStandardLibraryList'], resolve)
  },
  {
    path: '/home/organize/qualityStandardLibraryImport', // 品质标准-检查标准库-导入页
    name: 'qualityStandardLibraryImport',
    component: resolve => require(['@/views/organize/qualityManage/qualityStandard/QualityStandardLibraryImport'], resolve)
  },
  {
    path: '/home/organize/qualityInspectionPlanList', // 检查计划-列表页
    name: 'qualityInspectionPlanList',
    component: resolve => require(['@/views/organize/qualityManage/qualityInspectionPlan/QualityInspectionPlanList'], resolve)
  },
  {
    path: '/home/organize/qualityInspectionPlanForm', // 检查计划-表单页
    name: 'qualityInspectionPlanForm',
    component: resolve => require(['@/views/organize/qualityManage/qualityInspectionPlan/QualityInspectionPlanForm'], resolve)
  },
  {
    path: '/home/organize/qualityInspectionTaskList', // 检查任务-列表页
    name: 'qualityInspectionTaskList',
    component: resolve => require(['@/views/organize/qualityManage/qualityInspectionTask/QualityInspectionTaskList'], resolve)
  },
  {
    path: '/home/organize/qualityInspectionTaskForm', // 检查任务-表单页
    name: 'qualityInspectionTaskForm',
    component: resolve => require(['@/views/organize/qualityManage/qualityInspectionTask/QualityInspectionTaskForm'], resolve)
  },
  {
    path: '/home/organize/qualityInspectionView', // 检查任务-查看检查项-列表页
    name: 'qualityInspectionView',
    component: resolve => require(['@/views/organize/qualityManage/qualityInspectionTask/QualityInspectionView'], resolve)
  },
  {
    path: '/home/organize/qualityPlanReportList', // 计划报告-列表页
    name: 'qualityPlanReportList',
    component: resolve => require(['@/views/organize/qualityManage/qualityPlanReport/QualityPlanReportList'], resolve)
  },
  {
    path: '/home/organize/qualityTaskReportList', // 计划报告-任务报告-列表页
    name: 'qualityTaskReportList',
    component: resolve => require(['@/views/organize/qualityManage/qualityPlanReport/QualityTaskReportList'], resolve)
  },
  // TODO 品质报告-未发布
  {
    path: '/home/organize/qualityReportUnpublishList', // 列表页
    name: 'qualityReportUnpublishList',
    component: resolve => require(['@/views/organize/qualityReportUnpublish/QualityReportUnpublishList'], resolve)
  },
  {
    path: '/home/organize/qualityReportUnpublishForm', // 表单页
    name: 'qualityReportUnpublishForm',
    component: resolve => require(['@/views/organize/qualityReportUnpublish/QualityReportUnpublishForm'], resolve)
  },
  // TODO 品质报告-已发布
  {
    path: '/home/organize/qualityReportPublishList', // 列表页
    name: 'qualityReportPublish',
    component: resolve => require(['@/views/organize/qualityReportPublish/QualityReportPublishList'], resolve)
  },

]

export default routes
