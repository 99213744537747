const routes = [

  // TODO 菜单名称
  {
    path: '/home/storeRoomList', // 用户库房管理列表页
    name: 'storeRoom1',
    component: resolve => require(['@/views/user/storeRoom/storeRoomList'], resolve)
  },
  {
    path: '/home/storeRoomImport', // 用户库房管理导入页
    name: 'storeRoomImport',
    component: resolve => require(['@/views/user/storeRoom/import'], resolve)
  },
  {
    path: '/home/storeRoomForm', // 用户库房管理新增、编辑页
    name: 'storeRoomForm',
    component: resolve => require(['@/views/user/storeRoom/storeRoomForm'], resolve)
  },
  // 用户资料管理
  {
    path: '/home/registerOwnerMgr', // 注册用户资料
    name: 'serverOwnerAction!getAllOwnerList.action1',
    component: resolve => require(['@/views/user/ownerMgr/registerOwnerMgr/RegisterOwnerMgr'], resolve)
  },
  {
    path: '/home/unRegisterOwnerMgr', // 未注册用户资料
    name: 'serverOwnerAction!getAllOwnerList.action2',
    component: resolve => require(['@/views/user/ownerMgr/unRegisterOwnerMgr/UnRegisterOwnerMgr'], resolve)
  },
  {
    path: '/home/ownerMgrForm', // 用户资料form
    name: 'ownerMgrForm',
    component: resolve => require(['@/views/user/ownerMgr/ownerMgrForm/OwnerMgrForm'], resolve)
  },
  {
    path: '/home/newOwnerMgrForm', // 20220630版本人员档案修改-注册业主管理修改
    name: 'newOwnerMgrForm',
    component: resolve => require(['@/views/user/ownerMgr/ownerMgrForm/newOwnerMgrForm'], resolve)
  },
  {
    path: '/home/addHouseForm', // 用户资料增加房产
    name: 'addHouseForm',
    component: resolve => require(['@/views/user/ownerMgr/ownerMgrForm/AddHouseForm'], resolve)
  },
  {
    path: '/home/UnRegisterOwnerMgrImport', // 未注册用户导入
    name: 'UnRegisterOwnerMgrImport',
    component: resolve => require(['@/views/user/ownerMgr/unRegisterOwnerMgr/import'], resolve)
  },
  {
    path: '/home/orgTipOffList', // 组织举报管理列表页
    name: 'orgTipOff0',
    component: resolve => require(['@/views/user/orgTipOff/OrgTipOffList'], resolve)
  },
  {
    path: '/home/OrgTipOffDetail', // 组织举报管理详情页
    name: 'orgTipOffDetail',
    component: resolve => require(['@/views/user/orgTipOff/OrgTipOffDetail'], resolve)
  },
  // TODO 菜单名称
  // 用户管理
  {
    path: '/home/registerUserMgrList', // 注册用户管理
    name: 'registerUser1',
    component: resolve => require(['@/views/user/registerUserMgr/RegisterUserMgrList'], resolve)
  },
  {
    path: '/home/registerUserMgrForm', //  注册用户编辑
    name: 'registerUserMgrForm',
    component: resolve => require(['@/views/user/registerUserMgr/RegisterUserMgrForm'], resolve)
  },
  {
    path: '/home/registerUserMgrView', //  注册用户查看
    name: 'registerUserMgrView',
    component: resolve => require(['@/views/user/registerUserMgr/RegisterUserMgrView'], resolve)
  },
  {
    path: '/home/ownerCarList', // 用户车辆管理列表页
    name: 'serverOwnerAction!queryOwnerCarList.action1',
    component: resolve => require(['@/views/user/ownerCar/OwnerCarList'], resolve)
  },
  {
    path: '/home/ownerCarForm', // 用户车辆管理表单页
    name: 'ownerCarForm',
    component: resolve => require(['@/views/user/ownerCar/OwnerCarForm'], resolve)
  },
  {
    path: '/home/ownerCarImport', // 用户车辆管理导入页
    name: 'ownerCarImport',
    component: resolve => require(['@/views/user/ownerCar/import'], resolve)

  },
  // 用户审核
  {
    path: '/home/realNameAuditList', // 用户实名举报待审核
    name: 'userRealNameAudit1',
    component: resolve => require(['@/views/user/realNameAudit/RealNameAuditList'], resolve)
  },
  {
    path: '/home/realNameAuditListNoPass', // 用户实名举报不通过
    name: 'userRealNameAudit2',
    component: resolve => require(['@/views/user/realNameAudit/RealNameAuditListNoPass'], resolve)
  },
  {
    path: '/home/realNameAuditDetail', // 用户实名举报详情
    name: 'realNameAuditDetail',
    component: resolve => require(['@/views/user/realNameAudit/RealNameAuditDetail'], resolve)
  },
  // TODO 菜单名称
  {
    path: '/home/carPlaceList', // 车位管理列表页
    name: 'carPlace1',
    component: resolve => require(['@/views/user/carPlace/CarPlaceList'], resolve)
  },
  {
    path: '/home/carPlaceForm', // 车位管理表单页
    name: 'carPlaceForm',
    component: resolve => require(['@/views/user/carPlace/CarPlaceForm'], resolve)
  },
  {
    path: '/home/carPlaceImport', // 车位管理导入页
    name: 'carPlaceImport',
    component: resolve => require(['@/views/user/carPlace/import'], resolve)
  },
  // 土地管理
  {
    path: '/home/landManageList', // 土地管理列表页
    name: 'landManageList',
    component: resolve => require(['@/views/user/landManage/landManageList'], resolve)
  },
  {
    path: '/home/landManageForm', // 土地管理表单页
    name: 'landManageForm',
    component: resolve => require(['@/views/user/landManage/landManageForm'], resolve)
  },
  {
    path: '/home/landManageImport', // 土地管理导入页
    name: 'landManageImport',
    component: resolve => require(['@/views/user/landManage/landManageImport'], resolve)
  },
  // 确权管理
  {
    path: '/home/rightConfirManageList', // 确权管理列表页
    name: 'rightConfirManageList',
    component: resolve => require(['@/views/user/rightConfirManage/rightConfirManageList'], resolve)
  },
  {
    path: '/home/rightConfirManageForm', // 确权管理表单页
    name: 'rightConfirManageForm',
    component: resolve => require(['@/views/user/rightConfirManage/rightConfirManageForm'], resolve)
  },

  // TODO 菜单名称
  {
    path: '/home/orgLabelList', // 组织标签列表页
    name: 'orgLabel1',
    component: resolve => require(['@/views/user/orgLabel/OrgLabelList'], resolve)
  },
  {
    path: '/home/orgLabelForm', // 组织审核列表页
    name: 'orgLabelForm',
    component: resolve => require(['@/views/user/orgLabel/OrgLabelForm'], resolve)
  },
  {
    path: '/home/orgLabelAttrForm', // 组织审核列表页
    name: 'orgLabelAttrForm',
    component: resolve => require(['@/views/user/orgLabel/OrgLabelAttrForm'], resolve)
  },

  {
    path: '/home/relevanceOrgAuditList', // 用户审核-关联管理组织审核 待审核列表页
    name: 'userCollection1',
    component: resolve => require(['@/views/user/relevanceOrgAudit/RelevanceOrgAuditList'], resolve)
  },
  {
    path: '/home/relevanceOrgAuditListNoPass', // 用户审核-关联管理组织审核 未通过列表页
    name: 'userCollection2',
    component: resolve => require(['@/views/user/relevanceOrgAudit/RelevanceOrgAuditListNoPass'], resolve)
  },
  {
    path: '/home/relevanceOrgAuditForm', // 用户审核-关联管理组织审核 编辑页面
    name: 'relevanceOrgAuditForm',
    component: resolve => require(['@/views/user/relevanceOrgAudit/RelevanceOrgAuditForm'], resolve)
  },
  {
    path: '/home/userInfoAuditList', // 用户信息审核列表
    name: 'userAction!examineList.action1',
    component: resolve => require(['@/views/user/userInfoAudit/UserInfoAuditList'], resolve)
  },
  {
    path: '/home/userInfoAuditEdit', // 用户信息审核编辑
    name: 'userInfoAuditEdit',
    component: resolve => require(['@/views/user/userInfoAudit/UserInfoAuditForm'], resolve)
  },
  {
    path: '/home/userInfoRefuseList', // 用户信息不通过列表
    name: 'userAction!examineList.action2',
    component: resolve => require(['@/views/user/userInfoAudit/UserInfoRefuseList'], resolve)
  },

  {
    path: '/home/resumptionWorkUser', // 复工人员管理
    name: 'resumptionWorkUser',
    component: resolve => require(['@/views/user/reworkersManage/ReworkersManage'], resolve)
  },
  {
    path: '/home/reworkersImport', // 导入复工人员
    name: 'reworkersImport',
    component: resolve => require(['@/views/user/reworkersManage/Import'], resolve)
  },
   // 合同模板
  {
    path: '/home/contractTemplateList', // 列表页
    name: 'contractTemplate',
    component: resolve => require(['@/views/user/contractTemplate/ContractTemplateList'], resolve)
  },
  {
    path: '/home/contractTemplateForm', // 表单页
    name: 'contractTemplateForm',
    component: resolve => require(['@/views/user/contractTemplate/ContractTemplateForm'], resolve)
  },
  // 合同审批
  {
    path: '/home/contractApproveList', // 列表页
    name: 'contractApprove',
    component: resolve => require(['@/views/user/contractApprove/ContractApproveList'], resolve)
  },
  {
    path: '/home/contractApproveForm', // 表单页
    name: 'contractApproveForm',
    component: resolve => require(['@/views/user/contractApprove/ContractApproveForm'], resolve)
  }
]

export default routes
