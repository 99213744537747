var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "home-container",
      class: _vm.show ? "" : "home-full-screen",
    },
    [
      _vm.show
        ? _c(
            "div",
            { staticClass: "top", class: _vm.showCockpit ? "top-cockpit" : "" },
            [_c("top", { attrs: { logoSrc: _vm.logoSrc } })],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          class: ["bottom", { workTop: _vm.isWorkTop }],
          style: {
            background: _vm.isWorkTop
              ? _vm.theme.worktop_background || "#fff"
              : "",
          },
        },
        [
          _vm.menuGroupType == "left" ? _c("nav-menu-panel") : _vm._e(),
          _c(
            "div",
            { staticClass: "views", class: { "views-JSC": _vm.isJSC } },
            [
              _c(
                "div",
                {
                  ref: "content",
                  staticClass: "content",
                  style: {
                    paddingTop: _vm.isWorkTop ? 0 : _vm.isJSC ? 0 : "72px",
                  },
                  on: { click: _vm.iotCommunityViewControl },
                },
                [
                  _c("room-tree", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showRoomTreeView && _vm.shouldGetTree,
                        expression: "showRoomTreeView&&shouldGetTree",
                      },
                    ],
                    ref: "room-tree",
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isWorkTop && !_vm.isJSC,
                          expression: "!isWorkTop && !isJSC",
                        },
                      ],
                      staticClass: "title-panel",
                    },
                    [_c("title-panel")],
                    1
                  ),
                  _vm.isLoaed
                    ? _c("router-view", { staticClass: "router-view" })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm.showIotCommunityView
            ? _c("iot-community-tree", { ref: "iotTree" })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "updatePwd-dialog",
            title: _vm.updatePwdTitle,
            width: "500px",
            visible: _vm.updatePwdVisible,
            "show-close": false,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.updatePwdVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "updatePwd-dialog-content" }, [
            _vm.dialogStatus === 0
              ? _c("div", [
                  _vm._v(
                    "您的账号当前有安全风险，请修改密码（首次登录系统/初始密码/账号三个月未修改密码）"
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "form" },
              [
                _vm.dialogStatus === 1
                  ? _c(
                      "el-form",
                      {
                        ref: "password",
                        attrs: {
                          model: _vm.password,
                          "hide-required-asterisk": true,
                          "label-width": "100px",
                          rules: _vm.passwordRules,
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "新密码：", prop: "newPassword" } },
                          [
                            _c("v-input", {
                              attrs: {
                                type: "password",
                                width: 160,
                                autocomplete: "new-password",
                              },
                              model: {
                                value: _vm.password.newPassword,
                                callback: function ($$v) {
                                  _vm.$set(_vm.password, "newPassword", $$v)
                                },
                                expression: "password.newPassword",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "确认密码：",
                              prop: "newPasswordRepeat",
                            },
                          },
                          [
                            _c("v-input", {
                              attrs: {
                                type: "password",
                                width: 160,
                                autocomplete: "new-password",
                              },
                              model: {
                                value: _vm.password.newPasswordRepeat,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.password,
                                    "newPasswordRepeat",
                                    $$v
                                  )
                                },
                                expression: "password.newPasswordRepeat",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ops" },
              [
                _c("v-button", {
                  staticClass: "logout",
                  attrs: { text: "登出", type: "default" },
                  on: { click: _vm.logout },
                }),
                _c("v-button", {
                  staticClass: "update",
                  attrs: { text: "修改" },
                  on: { click: _vm.updatePassword },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }