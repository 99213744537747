import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios'
import qs from 'querystring'
import { _localStorage, _ueditorFileClick } from './common/utils'
import i18n from './i18n'

// import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { Table, TableColumn, Form, FormItem, Dropdown, DropdownMenu, DropdownItem, Popover, Dialog, Loading, Message, Radio,MessageBox, infiniteScroll ,TimePicker, Checkbox, CheckboxGroup, Notification,Tree } from 'element-ui'
import { vButton, vInput, vInputNumber, vSelect, vSelect2,vSelect3, vDatepicker, vDatepicker2, vTextarea, vTimepicker, vSwitch, vTooltip, vCheckbox } from 'components/control/index'

import { List, ListOther, FormPanel, CustomView, FieldTips, ScrollList,  } from 'components/bussiness/index'
import PreviewBox from 'components/control/v-preview/preview'
//
import wishareUI from 'wishare-ui'
import 'wishare-ui/lib/theme-chalk/index.css'
// Vue.use(ElementUI)s
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/index.css'

Vue.use(VXETable)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Popover)
Vue.use(Dialog)
Vue.use(Radio)
Vue.use(infiniteScroll)
Vue.use(TimePicker)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Tree)
Vue.prototype.$notify = Notification

Vue.use(wishareUI)

Vue.component('list', List)
Vue.component('list-other', ListOther)
Vue.component('form-panel', FormPanel)
Vue.component('custom-view', CustomView)
Vue.component('field-tips', FieldTips)
Vue.component('scroll-list', ScrollList)

Vue.component('v-button', vButton)
Vue.component('v-input', vInput)
Vue.component('v-input-number', vInputNumber)
Vue.component('v-select', vSelect)
Vue.component('v-select2', vSelect2)
Vue.component('v-select3', vSelect3)
Vue.component('v-datepicker', vDatepicker)
Vue.component('v-datepicker2', vDatepicker2)
Vue.component('v-textarea', vTextarea)
Vue.component('v-timepicker', vTimepicker)
Vue.component('v-switch', vSwitch)
Vue.component('v-tooltip', vTooltip)
Vue.component('v-checkbox', vCheckbox)
Vue.use(Loading.directive)

Vue.prototype.$previewBox = PreviewBox
Vue.prototype.$loading = Loading.service
window._ueditorFileClick = _ueditorFileClick
let extendOptions = (targetOptions, options) => {
  if (options.length === 2) {
    targetOptions[1] = {
      ...targetOptions[1],
      ...options[1]
    }
  }
  targetOptions[0] = options[0]
}
Vue.prototype.$alert = (...options) => {
  let _options = ['', {
    confirmButtonText: '确定',
    showClose: false
  }]
  extendOptions(_options, options)
  return MessageBox.alert(..._options).then(() => {
    return true
  }).catch(() => {
    return false
  })
}
Vue.prototype.$confirm = (...options) => {
  let _options = ['', {
    type: 'warning',
    cancelButtonText: '取消',
    confirmButtonText: '确定',
    showClose: false,
    lockScroll: true
  }]
  extendOptions(_options, options)
  return MessageBox.confirm(..._options).then(() => {
    return true
  }).catch(() => {
    return false
  })
}

const message = (options) => {
  if (typeof options === 'string') {
    options = {
      message: options
    }
  }
  let _options = {
    type: 'error',
    center: true,
    ...options
  }
  Message(_options)
}
['success', 'warning', 'info', 'error'].forEach((type) => {
  message[type] = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    options.type = type
    message(options)
  }
})
Vue.prototype.$message = message

Vue.prototype.$setBreadcrumb = (options) => {
  Vue.prototype.$nextTick(() => {
    store.commit('setBreadcrumb', options)
  }, 20)
}

Vue.prototype.$qs = qs
Vue.prototype.$axios = axios

Vue.prototype.$controlSize = {
  input: {
    small: 200,
    medium: 500,
    large: 800
  }
}

Vue.config.productionTip = false

const getGeneralInfoURL = `${API_CONFIG.controlBaseURL}systemConfig/getGeneralInfo`
axios.get(getGeneralInfoURL).then(res => {
  const { status, data } = res
  if (status === 100) {
    if (data) {
      const generalInfo = {
        version: data.platform_version,
        backgroundImageSrc: data.platform_bg_img,
        copyright: data.platform_copyright,
        icpNO: data.platform_icp,
        mainlogoSrc: data.platform_logo,
        miniLogoSrc: data.sub_platform_default_logo
      }
      store.commit('setBaseConfig', generalInfo)

      const version = data.platform_version
      const localVersion = _localStorage.getItem('version')
      if (version !== localVersion) {
        store.commit('setVersionUpgrade', true)
        // 清空存储的列表选项数据
        _localStorage.removeItem('menu_list_option')
        _localStorage.setItem('version', version)
      } else {
        store.commit('setVersionUpgrade', false)
      }
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
