<template>
  <div class="tabs-panel-container">
    <el-radio-group class="tabs" v-model="active" @change="change">
      <el-radio-button
        v-for="(item, index) in tabs"
        :key="index"
        :label="item.label"
        :disabled="item.disabled"
      ></el-radio-button>
    </el-radio-group>
    <div class="component-container" v-if="showComponent && keepAlive">
      <keep-alive>
        <component
          :is="tabComponent"
          v-bind="tabProps"
          @contact="contact"
        ></component>
      </keep-alive>
    </div>
    <div class="component-container" v-if="showComponent && !keepAlive">
      <component
        :is="tabComponent"
        v-bind="tabProps"
        @contact="contact"
      ></component>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { RadioGroup, RadioButton } from 'element-ui'

Vue.use(RadioGroup)
Vue.use(RadioButton)

// @group 业务组件
// @title Tabs 标签页
export default {
  name: 'tabs-panel',
  props: {
    tabs: {
      type: Array,
      required: true,
      validator(value) {
        return value.length > 0
      }
    },
    activeLabel: {
      type: String,
      default: ''
    },
    keepAlive: {
      type: Boolean,
      default: true
    },
    tabRouter: {
      type: Boolean,
      default: true
    },
    before: Function
  },
  data() {
    return {
      showComponent: false,
      active: '',
      tabComponent: '',
      tabProps: {},
      asyncQueue: [],
      currentIndex:undefined
    }
  },
  watch: {
    tabComponent: {
      handler() {
        // 动态修改tabProps
        if (this.currentIndex) {
          this.tabProps = this.tabs[this.currentIndex].props
        }
      }
    }
  },
  created() {
    if (this.tabRouter) {
      this.$watch('$route', newValue => {
        const newTab = newValue.query._tab
        const label = this.tabs[newTab].label
        if (this.active !== label) {
          this.active = label
        }
        this.changeComponent(newTab)
      })
      const tab = this.$route.query._tab
      if (tab === undefined) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            _tab: 0
          }
        })
      } else {
        const label = this.tabs[tab].label
        this.active = label
        this.changeComponent(tab)
      }
    } else {
      this.$watch('activeLabel', newValue => {
        if (this.active !== newValue) {
          this.active = newValue
          this.change(newValue)
        }
      })
      this.$watch('active', newValue => {
        // v-model event
        this.$emit('update:activeLabel', newValue)
      })
      const label = this.activeLabel || this.tabs[0].label
      this.active = label
      this.change(label)
    }
  },
  beforeMount() {
    if (this.before) {
      this.before(() => {
        console.log(this.before)
        this.showComponent = true
      })
    } else {
      this.showComponent = true
    }
  },
  methods: {
    contact(ops) {
      const { index, method, data, cb } = ops
      if (index === undefined) {
        this.$parent[method](data)
        return
      }
      const _index = index + 1
      if (this.$children.length >= _index) {
        if (this.$children[_index]) {
          let d = this.$children[_index][method](data)
          cb && cb(d)
        } else {
          if (!this.asyncQueue[index]) {
            this.asyncQueue[index] = []
          }
          const _this = this
          this.asyncQueue[index].push(() => {
            const d = _this.$children[_index][method](data)
            cb && cb(d)
          })
        }
      }
    },
    getTabIndex(label) {
      let tabIndex = this.tabs.findIndex(tab => {
        return tab.label === label
      })
      return tabIndex
    },
    changeComponent(index) {
      const tab = this.tabs[index]
      this.currentIndex = index
      this.tabComponent = tab.component
      this.tabProps = tab.props
      const asyncQueue = this.asyncQueue[index]
      if (asyncQueue) {
        this.$nextTick(() => {
          asyncQueue.forEach(func => {
            func()
          })
          this.asyncQueue[index] = []
        })
      }
      this.$emit('change', index, tab)
    },
    change(label) {
      const tabIndex = this.getTabIndex(label)
      if (this.tabRouter) {
        this.$router.push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            _tab: tabIndex
          }
        })
      } else {
        this.changeComponent(tabIndex)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.tabs-panel-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
  text-align: left;
  .tabs {
    height: 40px;
    padding: 0 20px 20px;
    margin-top: -60px;
    background-color: #fff;
  }
  .component-container {
    height: 100%;
    > :nth-child(n) {
      height: 100%;
    }
  }
}
</style>
