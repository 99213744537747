const houseProfileStore = {
  namespaced: true,
  state: {
    treeExpandedKeys: [],
    selectedNode: {}
  },
  mutations: {
    setTreeExpanedKeys (state, keys) {
      state.treeExpandedKeys = keys
    },
    setSelectedNode (state, Node) {
      state.selectedNode = Node
    }
  }
}

export default houseProfileStore
