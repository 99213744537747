<template>
  <div class="select-tree" :class="{ 'min-w': label }">
    <!-- <div class="label" v-if="label">{{label}}</div> -->
    <div class="content">
      <v-input
        :label="label"
        ref="input"
        class="search"
        v-model="filterText"
        :placeholder="placeholder"
        :width="width"
        :suffix-icon="showTree ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
        @focus="onFocus"
        @blur="onBlur"
        @clear="clear"
        :disabled="disabled"
      />
      <div class="hx-tree-group" v-show="showTree" ref="treeGroup">
        <div
          class="tree-group__box"
          v-show="treeData.length !== 0"
          :style="{ width: `${width}px` }"
        >
          <button-tree
            ref="buttonTree"
            class="button-tree"
            :key="key"
            :data="treeData"
            :defaultProps="treeProps"
            :nodeKey="nodeKey"
            :showCheckbox="showCheckbox"
            :defaultCheckedKeys="defaultCheckedKeys"
            :filterText="filterText"
            :highlightCurrent="highlightCurrent"
            :filterNodeMethod="filterNodeMethod"
            :expand-on-click-node="false"
            @nodeClick="nodeClick"
          />
        </div>
        <div
          v-show="treeData.length === 0"
          class="tree-group__box is-empty"
          :style="{ width: `${width}px` }"
        >暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ButtonTree } from 'components/bussiness'
import { createPopper } from '@popperjs/core';
export default {
  name: 'selectTree',
  components: {
    ButtonTree,
  },
  props: {
    // 选中项的值，支持`.sync`
    value: {
      type: [String, Number],
      required: true,
    },
    data: {
      // 对应value的节点数据
      type: Object,
      default() {
        return {}
      },
    },
    label: String, // 用于该组件作为查询条件时
    placeholder: {
      type: String,
      default: '查询所属部门',
    },
    width: {
      type: Number,
      default: 182,
    },
    treeData: {
      type: Array,
      default: () => {
        return []
      },
    },
    treeProps: {
      type: Object,
      default: () => {
        return {}
      },
    },
    expandOnClickNode: {
      type: Boolean,
      default: true,
    },
    highlightCurrent: {
      type: Boolean,
      default: true,
    },
    filterNodeMethod: {
      type: Function,
    },
    // 节点是否可被选择
    showCheckbox: {
      type: Boolean,
      default: false
    },
    // 节点唯一字段，做选中区分
    nodeKey: {
      type: String,
      default: 'id'
    },
    // 默认选中的key
    defaultCheckedKeys: {
      type: Array,
    },
    // 是否禁用全部表单控件
    disabled: {
      type: Boolean,
      // `false`
      default: false
    },
  },
  data() {
    return {
      showTree: false,
      filterText: '',
      result: null,
      // model: this.value,
      key: 0,
    }
  },
  computed: {
    model: {
      get() {
        return this.value ? String(this.value) : ''
      },
      set(val) {
        this.$emit('update:value', val)
      },
    },
  },
  watch: {
    model: {
      handler(newValue) {
        this.watcherMethods()
      },
      deep: true,
      immediate: true,
    },
    treeData: {
      handler() {
        this.watcherMethods()
      },
      immediate: true,
      deep: true,
    },
    showTree(val) {
      if (val) {
        let input = this.$refs.input.$refs.input.$el;
        let treeGroup = this.$refs.treeGroup;
        let pTreeGroupName = treeGroup.parentNode ? treeGroup.parentNode.tagName : "";
        if (pTreeGroupName !== "body") {
          const body = document.querySelector("body");
          // 未加入，body，选择加入
          if (body.append) {
            body.append(treeGroup);
          } else {
            body.appendChild(treeGroup);
          }
        }
        createPopper(input, treeGroup, {
          placement: 'bottom'
        })
        return
      }
      // 树弹框隐藏时触发
      this.watcherMethods()
    },
  },

  mounted() {
    // 为阻止input框失去焦点
    this.$refs.treeGroup.onmousedown = function (e) {
      if (e && e.preventDefault) {
        // 现代浏览器阻止默认事件
        e.preventDefault()
      } else {
        // IE阻止默认事件
        window.event.returnValue = false
      }
      return false
    }
  },
  methods: {
    onFocus() {
      this.filterText = ''
      setTimeout(() => {
        this.showTree = true
      }, 100)
      this.$emit('focus', event)
    },
    onBlur() {
      const body = document.querySelector("body");
      body.removeChild(body.lastChild)
      this.showTree = false
      this.$emit('blur', event)
    },
    clear() {
      // this.form.orgId = ''
      this.model = ''
      this.key += 1
      this.$emit('clear')
    },
    nodeClick(data, node, nodeVue) {
      this.filterText = data[this.treeProps.label]
      this.model = data[this.treeProps.id]
      this.$emit('nodeClick', data, node, nodeVue)
      // this.showTree = false
      this.$refs.input.blur()
    },

    /* 计算 */
    watcherMethods() {
      if (this.model === '') {
        this.filterText = ''
        this.$emit('update:data', {})
      } else {
        this.recursGetData(this.model, this.treeData)
        this.$emit('update:data', this.result)
        this.filterText = this.result ? this.result[this.treeProps.label] : ''
        this.result = null
      }
    },
    // 通过值递归获取数据
    recursGetData(id, arr) {
      arr.map((d, i) => {
        if (id === d[this.treeProps.id]) {
          this.result = d
        } else {
          if (d[this.treeProps.children].length !== 0) {
            this.recursGetData(id, d[this.treeProps.children])
          }
        }
      })
    },
    // 设置选中节点
    setCheckedNodes (nodes) {
      this.$refs.buttonTree.setCheckedNodes(nodes)
    },
  },
}
</script>

<style lang="scss" scoped>
.min-w {
  // min-width: 299px;
}
.select-tree {
  position: relative;
  display: flex;
  .label {
    color: #333333;
    flex: 1;
    text-align: right;
    line-height: 40px;
    display: inline-block;
    margin-right: 5px;
  }
  .content {
    position: relative;
    .search {
      // ::v-deep .el-input__inner {
      //   border-radius:18px;
      //   background: rgba(144,166,186, 0.1);
      //   box-shadow: inset 4px 1px 8px 0px rgba(95,122,155,0.1);
      //   border: none;
      // }
      ::v-deep .el-input__suffix {
        line-height: 32px;
      }
    }
  }
}
</style>
<style lang="scss">
.hx-tree-group {
  &::before {
    content: "";
    border: 1px solid;
    border-color: #e4e7ed transparent transparent #e4e7ed;
    background-color: #fff;
    width: 6px;
    height: 6px;
    position: absolute;
    top: 2px;
    left: 36px;
    z-index: 2004;
    transform: rotateZ(45deg);
  }
  position: absolute;
  left: 0;
  top: 110%;
  z-index: 3000;
  color: #606266;
  .tree-group__box {
    &.is-empty {
      height: auto;
      color: #909399;
      text-align: center;
      padding: 4px 0;
      font-size: 14px;
    }
    /** 不可使用定位,会破坏button-tree组件结构 */
    // width: 182px;
    height: 204px;
    border: 1px solid #e4e7ed;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin: 5px 0;
    padding: 5px 0 0;
  }
}
</style>
