<template>
  <div class="v-select-container">
    <label v-if="label" v-text="label"></label>
    <el-select
      ref="select"
      v-model="model"
      :style="{ width: width + 'px' }"
      :placeholder="placeholder"
      :disabled="disabled"
      :filterable="filterable"
      :multiple="multiple"
      :clearable="clearable"
      :multiple-limit="multipleLimit"
    >
      <span v-if="!optionGroup">
        <el-option v-for="option in options" :key="option.value"
          :value="option.value" :label="option.text" :disabled="option.disabled">
        </el-option>
      </span>
      <span v-if="optionGroup">
        <span v-for="group in options" :key="group.value">
          <el-option-group v-if="group.options && group.options.length" :label="group.label">
            <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-option-group>
        </span>
      </span>
    </el-select>
  </div>
</template>
<script>
import Vue from 'vue'
import _ from 'lodash'
import { Select, Option, OptionGroup } from 'element-ui'

Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)

// @group 基础组件
// @title Select 下拉选择框
export default {
  name: 'select-multiple',
  props: {
    // 绑定值
    value: Array,
    // 下拉选择框左侧label
    label: String,
    // 下拉选项分组
    optionGroup: {
      type: Boolean,
      // `false`
      default: false
    },
    multiple: {
      type: Boolean,
      // `true`
      default: true
    },
    // 下拉选项， 对象数组，value：选中的值
    options: {
      type: Array,
      // `[]`
      default () {
        return []
      }
    },
    // 是否禁用
    disabled: Boolean,
    // 下拉选择框宽度
    width: {
      type: Number,
      // `144`
      default: 144
    },
    // 下拉选择框占位文本
    placeholder: String,
    // 异步获取下拉选项
    asyncOptions: {
      type: Boolean,
      // `false`
      default: false
    },
    // 是否可搜索
    filterable: Boolean,
    // 是否可以清空选项
    clearable: Boolean,
    multipleLimit: {
      type: Number,
      default: 0
    },
  },
  data () {
    return {
      tempModel: null,
      model: this.value
    }
  },
  created () {
    if (this.asyncOptions && this.findIndex() === -1) {
      this.exchange()
      const unwatch = this.$watch('options', (newArr) => {
        this.exchange()
        unwatch()
      })
    }
  },
  watch: {
    value (newArr, oldArr) {
      if (this.tempModel === null) {
        this.model = newArr
        let option
        if (!this.optionGroup) {
          option = this.options.find(o => {
            if (newArr.length > oldArr.length) {
              return o.value === _.difference(newArr, oldArr)[0]
            }
            return o.value === _.difference(oldArr, newArr)[0]
          })
        }
        // 选中值发生变化时触发
        // @arg 当前选中的值，上一次的选中值, 用户操作值的下拉选项
        this.$emit('change', newArr, oldArr, option)
      }
    },
    model (newArr) {
      if (this.tempModel === null) {
        // v-model event
        this.$emit('input', newArr)
      }
    }
  },
  methods: {
    // @vuese
    // 使 select 获取焦点
    focus () {
      this.$refs.select.focus()
    },
    // @vuese
    // 使 select 失去焦点
    blur () {
      this.$refs.select.blur()
    },
    exchange () {
      [this.tempModel, this.model] = [this.model, this.tempModel]
    },
    findIndex () {
      const index = this.options.findIndex((option) => {
        return option.value === this.value[0]
      })
      return index
    }
  }
}
</script>
<style scoped lang="scss">
  .v-select-container {
    display: inline-block;
    label {
      margin-right: 5px;
      color: #333333;
    }
    .el-select {
      ::v-deep .el-select__tags {
        white-space: nowrap !important;
        text-overflow: ellipsis;
        text-align: left;
        > span {
          display: block !important;
          width: 100%;
          overflow: hidden;
          // 样式一
          // > span {
          //   max-width: 100%;
          //   position: relative;
          //   > span {
          //     max-width: 80%;
          //     overflow: hidden;
          //     text-overflow: ellipsis;
          //     display: inline-block;
          //   }
          //   i {
          //     position: absolute!important;
          //     right: 0px;
          //     top: 5px;
          //   }
          // }
          // 样式二
          > span {
            display: inline-block;
            width: 80%;
            position: relative;
            > span {
              width: 90%;
              overflow: hidden;
              text-overflow: ellipsis;
              display: inline-block;
            }
            i {
              position: absolute!important;
              right: 0px;
              top: 5px;
            }
          }
        }
      }
    }
  }
</style>
