var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-group" },
    _vm._l(_vm.group, function (item, index) {
      return _c(
        "div",
        {
          key: item.id,
          staticClass: "menu-group-list",
          class:
            item.level == 0 ? "menu-group-list-root" : "menu-group-list-child",
        },
        [
          item.level == 0
            ? _c(
                "el-popover",
                {
                  attrs: {
                    placement: "right",
                    trigger: "hover",
                    disabled: _vm.status,
                    "visible-arrow": false,
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { color: "#43628A", "font-size": "14px" } },
                    [_vm._v(_vm._s(item.name))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "menu-group-item",
                      class: _vm.setNameClass(item),
                      attrs: { slot: "reference" },
                      on: {
                        click: function ($event) {
                          return _vm.itemClick(item, index)
                        },
                      },
                      slot: "reference",
                    },
                    [
                      item.level == 0
                        ? _c("img", {
                            staticClass: "menu-group-item-icon",
                            attrs: {
                              src: item.icon,
                              referrerPolicy: "no-referrer",
                            },
                          })
                        : item.menuType == 1
                        ? _c("img", {
                            staticClass: "menu-group-item-icon",
                            attrs: {
                              src:
                                _vm.activeMenuId[item.level + 1] &&
                                _vm.activeMenuId[item.level + 1].includes(
                                  item.id
                                )
                                  ? _vm.arrowupSrc
                                  : _vm.arrowdownSrc,
                            },
                          })
                        : _c("div", { staticClass: "menu-group-item-icon" }),
                      _c(
                        "div",
                        {
                          staticClass: "menu-group-item-name",
                          attrs: { title: item.name },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item.name) + "\n        "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            : _c(
                "div",
                {
                  staticClass: "menu-group-item",
                  class: _vm.setNameClass(item),
                  attrs: { slot: "reference" },
                  on: {
                    click: function ($event) {
                      return _vm.itemClick(item, index)
                    },
                  },
                  slot: "reference",
                },
                [
                  item.level == 0
                    ? _c("img", {
                        staticClass: "menu-group-item-icon",
                        attrs: {
                          src: item.icon,
                          referrerPolicy: "no-referrer",
                        },
                      })
                    : item.menuType == 1
                    ? _c("img", {
                        staticClass: "menu-group-item-icon",
                        attrs: {
                          src:
                            _vm.activeMenuId[item.level + 1] &&
                            _vm.activeMenuId[item.level + 1].includes(item.id)
                              ? _vm.arrowupSrc
                              : _vm.arrowdownSrc,
                        },
                      })
                    : _c("div", { staticClass: "menu-group-item-icon" }),
                  _c(
                    "div",
                    {
                      staticClass: "menu-group-item-name",
                      attrs: { title: item.name },
                    },
                    [_vm._v("\n          " + _vm._s(item.name) + "\n        ")]
                  ),
                ]
              ),
          _c("menu-group", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.status &&
                  item.menuType == 1 &&
                  _vm.activeMenuId[item.level + 1] &&
                  _vm.activeMenuId[item.level + 1].includes(item.id) &&
                  item.specialNo !== "system_menuGroup",
                expression:
                  "status && item.menuType == 1 &&  activeMenuId[item.level + 1] && activeMenuId[item.level + 1].includes(item.id) && item.specialNo !== 'system_menuGroup'",
              },
            ],
            attrs: {
              group: item.childMenus,
              status: _vm.status,
              centerItem: _vm.centerItem,
              firstItem: _vm.firstItem,
              secondItem: _vm.secondItem,
              thirdItem: _vm.thirdItem,
              activeMenuId: _vm.activeMenuId,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }