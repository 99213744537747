var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.handlePermission(_vm.permission)
    ? _c(
        "div",
        {
          staticClass: "v-control v-button-container",
          style: { width: _vm.width + "px" },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: _vm.type,
                icon: _vm.icon,
                plain: _vm.plain,
                round: _vm.round,
                circle: _vm.circle,
                disabled: _vm.disabled,
              },
              on: { click: _vm.click },
            },
            [
              [
                _vm.text
                  ? _vm._t("default", function () {
                      return [_vm._v(_vm._s(_vm.text))]
                    })
                  : _vm._t("default"),
              ],
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }