/*
 * @Description:
 * @Author: 露露
 * @Date: 2019-08-21 08:47:38
 * @LastEditors: 蒙川
 * @LastEditTime: 2019-12-06 14:59:53
 */
import { generateMapByOpts } from '@/common/utils'
import { mapHelper } from 'common/utils'

const houseTypeOps = [
  {
    text: '未知',
    value: undefined
  },
  {
    text: '公寓',
    value: 1
  },
  {
    text: '住宅',
    value: 7
  },
  {
    text: '排屋',
    value: 2
  },
  {
    text: '别墅',
    value: 3
  },
  {
    text: '商铺',
    value: 4
  },
  {
    text: '办公用房',
    value: 5
  },
  {
    text: '经营用房',
    value: 6
  },
  {
    text: '保姆房',
    value: 21
  },
  {
    text: '储藏室',
    value: 22
  },
  {
    text: '自行车库',
    value: 23
  },
  {
    text: '车库',
    value: '24'
  },
  {
    text: '车位',
    value: 25
  }
]
const userTypeOps = [
  {
    text: '业主',
    value: 1
  },
  {
    text: '家属',
    value: 2
  },
  {
    text: '租客',
    value: 3
  },
  {
    text: '伙伴',
    value: 4
  },
  {
    text: '嘉宾',
    value: 5
  }
]
const {
  map: userTypeMap,
  setOps: setUserTypeOps
} = mapHelper.setMap(userTypeOps)
const userRelationOps = [
  {
    text: '请选择',
    value: undefined
  },
  {
    text: '本人',
    value: 0
  },
  {
    text: '夫妻',
    value: 1
  },
  {
    text: '子女',
    value: 2
  },
  {
    text: '父母',
    value: 3
  },
  {
    text: '朋友',
    value: 4
  },
  {
    text: '祖父母',
    value: 5
  },
  {
    text: '孙辈',
    value: 6
  }
]
// 是否付款联系人
const isPaymentOps = [
  {
    text: '不是',
    value: 0
  },
  {
    text: '是',
    value: 1
  }
]
// 是否短信联系人
const isMessageOps = [
  {
    text: '不是',
    value: 0
  },
  {
    text: '是',
    value: 1
  }
]
// 智能开门
const isOpenDoorOps = [
  {
    text: '关闭',
    value: 0
  },
  {
    text: '开启',
    value: 1
  }
]
// 企业标签
const businessUserTypeOps = [
  {
    text: '法人',
    value: 1
  },
  {
    text: '员工',
    value: 2
  }
]
// 基础信息
// 状态
const _status = {
  0: '关闭',
  1: '正常'
}
// 性别
const sexOps = [
  {
    text: '请选择',
    value: undefined
  },
  {
    text: '男',
    value: 1
  },
  {
    text: '女',
    value: 2
  }
]
// 政治面貌
const politicsStatusOps = [
  {
    text: '请选择',
    value: undefined
  },
  {
    text: '中共党员',
    value: '中共党员'
  },
  {
    text: '共青团员',
    value: '共青团员'
  },
  {
    text: '群众',
    value: '群众'
  }
]
// 婚姻状况
const isMarryOps = [
  {
    text: '请选择',
    value: undefined
  },
  {
    text: '单身',
    value: 0
  },
  {
    text: '已婚',
    value: 1
  },
  {
    text: '离异',
    value: 2
  },
  {
    text: '丧偶',
    value: 3
  }
]
// 证件类型
const cardTypeOps = [
  {
    text: '请选择',
    value: 0
  },
  {
    text: '身份证',
    value: 1
  },
  {
    text: '军官证',
    value: 2
  },
  {
    text: '护照',
    value: 3
  },
  {
    text: '其他',
    value: 4
  }
]
// 数据来源
const userSourceOps = [
  {
    text: '注册用户',
    value: 1
  },
  {
    text: '快递用户',
    value: 2
  },
  {
    text: '活动用户',
    value: 3
  },
  {
    text: '导入用户',
    value: 4
  },
  {
    text: '寄存用户',
    value: 7
  }
]
const {
  setOps: setUserSourceOps
} = mapHelper.setMap(userSourceOps)

const userSourceMap = {
  1: '注册用户',
  2: '快递用户',
  3: '活动用户',
  4: '导入用户',
  7: '寄存用户'
}

// 工单处理状态
const orderStatusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '待接收',
    value: 1
  },
  {
    text: '待处理',
    value: 2
  },
  {
    text: '超时未接收',
    value: 3
  },
  {
    text: '超时未处理',
    value: 4
  },
  {
    text: '处理中',
    value: 5
  },
  {
    text: '已处理',
    value: 6
  },
  {
    text: '已完成',
    value: 7
  },
  {
    text: '已关闭',
    value: 8
  },
  {
    text: '超时处理中',
    value: 9
  }
]
const orderStatusMap = generateMapByOpts(orderStatusOps)

// 出入记录 出入方式
const inOutWayOps = [
  {
    text: '扫闸道二维码',
    value: 0
  },
  {
    text: '蓝牙开门',
    value: 1
  },
  {
    text: '人脸识别',
    value: 5
  }
]
const inOutWayMap = generateMapByOpts(inOutWayOps)

// 出入记录 数据来源
const inOutuserSourceOps = [
  {
    text: '业主app',
    value: 1
  },
  {
    text: '物管app',
    value: 2
  },
  {
    text: '支付宝小程序',
    value: 3
  },
  {
    text: '微信小程序',
    value: 4
  }
]
const inOutuserSourceMap = generateMapByOpts(inOutuserSourceOps)

// 一级标签
const labelTypeOps = [
  {
    text: '用户',
    value: 4
  }
]
// 状态
const statusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '正常',
    value: 1
  },
  {
    text: '关闭',
    value: 0
  }
]
// 房产信息
// 是否户主
const isHouseHolderOps = [
  {
    checked: false,
    label: '是',
    value: '1'
  },
  {
    checked: true,
    label: '否',
    value: '0'
  }
]
// 是否当前住址
const isDefaultOps = [
  {
    text: '不是',
    value: 0
  },
  {
    text: '是',
    value: 1
  }
]
// 审核状态
const isExamineOps = [
  {
    text: '待确认',
    value: 0
  },
  {
    text: '已确认',
    value: 1
  },
  {
    text: '未通过',
    value: 2
  }
]
// 车位信息
const parkingCertificateStatus = {
  0: '待上传',
  1: '待审核',
  2: '已通过',
  3: '不通过',
  4: '关闭'
}
const parkingSharedStatus = {
  0: '待开启',
  1: '已开启',
  2: '已关闭'
}
const parkingStatus = {
  0: '关闭',
  1: '开启'
}
// 注册用户列表
const _sex = {
  1: '男',
  2: '女'
}
const _realnameExamine = {
  0: '待认证',
  1: '已认证'
}
const searchSexOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '男',
    value: 1
  },
  {
    text: '女',
    value: 2
  }
]
const searchUserTypeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '业主/法人',
    value: 1
  },
  {
    text: '家属/员工',
    value: 2
  },
  {
    text: '租客',
    value: 3
  },
  {
    text: '伙伴',
    value: 4
  },
  {
    text: '嘉宾',
    value: 5
  }
]
const realnameExamineOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '待认证',
    value: 0
  },
  {
    text: '已认证',
    value: 1
  }
]
// 未注册用户资料列表
const _userSource = {
  1: '注册用户',
  2: '快递用户',
  3: '活动用户',
  4: '导入用户',
  5: '预约用户',
  7: '寄存用户'
}
// 注销状态
const adminStatusMap = {
  1: '正常',
  2: '停用',
  3: '注销'
}
// 婚姻状态
const isMarryMap = generateMapByOpts(isMarryOps)
// 证件类型
const cardTypeMap = generateMapByOpts(cardTypeOps)

const isYesOps = [
  {
    label: '是',
    value: '1'
  },
  {
    label: '否',
    value: '0'
  }
]
const educationOps = [
  {
    text: '请选择',
    value: undefined
  },
  {
    text: '博士',
    value: 1
  },
  {
    text: '硕士',
    value: 2
  },
  {
    text: '本科',
    value: 3
  },
  {
    text: '大专',
    value: 4
  },
  {
    text: '高中',
    value: 5
  },
  {
    text: '初中',
    value: 6
  }
]
const bloodTypeOps = [
  {
    text: '请选择',
    value: undefined
  },
  {
    text: 'A',
    value: 1
  },
  {
    text: 'B',
    value: 2
  },
  {
    text: 'AB',
    value: 5
  },
  {
    text: '0',
    value: 3
  },
  {
    text: 'Rh',
    value: 4
  }
]
const registeredResidenceOps = [
  {
    text: '请选择',
    value: undefined
  },
  {
    text: '农业户口',
    value: 1
  },
  {
    text: '非农业户口',
    value: 2
  }
]
export {
  houseTypeOps,
  userTypeOps,
  userRelationOps,
  isPaymentOps,
  isMessageOps,
  isOpenDoorOps,
  businessUserTypeOps,
  _status,
  sexOps,
  politicsStatusOps,
  isMarryOps,
  cardTypeOps,
  labelTypeOps,
  statusOps,
  isHouseHolderOps,
  isDefaultOps,
  isExamineOps,
  parkingCertificateStatus,
  parkingSharedStatus,
  parkingStatus,
  _sex,
  _realnameExamine,
  searchSexOps,
  searchUserTypeOps,
  realnameExamineOps,
  _userSource,
  isMarryMap,
  cardTypeMap,
  userTypeMap,
  setUserTypeOps,
  userSourceMap,
  userSourceOps,
  setUserSourceOps,
  adminStatusMap,
  isYesOps,
  educationOps,
  bloodTypeOps,
  registeredResidenceOps,
  orderStatusOps,
  orderStatusMap,
  inOutWayMap,
  inOutuserSourceMap,
}
