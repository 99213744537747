var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.previewMode == 0
        ? _c(
            "div",
            {
              staticClass: "my-gallery",
              attrs: {
                itemscope: "",
                itemtype: "http://schema.org/ImageGallery",
              },
            },
            _vm._l(_vm.slides, function (item, index) {
              return _c(
                "a",
                {
                  key: index,
                  staticClass: "figure-wrapper",
                  attrs: { href: item.src, itemprop: "contentUrl" },
                },
                [
                  _c("img", {
                    staticClass: "preview-image",
                    style: _vm.imgStyle,
                    attrs: { src: item.msrc, alt: item.alt },
                    on: {
                      click: function ($event) {
                        return _vm.actionPreview(index, $event)
                      },
                    },
                  }),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm.needAddTemplate ? _c("preview-template") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }