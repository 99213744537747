<template>
  <div class="v-control v-input-container">
    <label v-if="label" v-text="label"></label>
    <el-input
      ref="input"
      v-model="model"
      v-bind="{ ...bind, ...nativeProps }"
      :disabled="disabled"
      :clearable="clearable"
      :placeholder="defaultPlaceholder"
      :maxlength="maxlength == -1 ? undefined : maxlength"
      :minlength="minlength"
      :size="size"
      :autocomplete="autocomplete"
      :prefix-icon="prefixIcon"
      :suffix-icon="suffixIconName"
      @blur="onBlur"
      @focus="onFocus"
      @clear="clear"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import { Input } from 'element-ui'
import { throttle } from 'lodash'

Vue.use(Input)

// @group 基础组件
// @title Input 输入框
export default {
  name: 'v-input',
  props: {
    // 类型
    type: String,
    // 是否禁用
    disabled: Boolean,
    // 输入框宽度
    width: {
      type: Number,
      // `144`
      default: 144
    },
    // 绑定值
    value: [String, Number],
    // 被el-input $attrs 接收的属性
    nativeProps: {},
    // 输入框左侧label
    label: String,
    // 输入框占位文本
    placeholder: String,
    // 原生属性，最大输入长度
    maxlength: {
      type: Number,
      // `30`
      default: 30
    },
    // 原生属性，最小输入长度
    minlength: Number,
    // 输入框尺寸
    size: String,
    // 原生属性，自动补全
    autocomplete: String,
    // 输入框头部图标
    prefixIcon: String,
    // 输入框尾部图标
    suffixIcon: String,
    // 是否可清空
    clearable: {
      type: Boolean,
      // `true`
      default: true
    }
  },
  data () {
    return {
      model: this.value,
      bind: {},
      textareaObserver: undefined,
      inputWidth: this.width,
      defaultPlaceholder: this.placeholder,
      suffixIconName: this.suffixIcon
    }
  },
  created () {
    if (this.type === 'textareaHor') {
      this.bind = {
        type: 'textarea',
        resize: 'horizontal',
        rows: 1,
        autosize: {
          minRows: 1
        }
      }
    } else {
      this.bind = {
        type: this.type,
        style: {
          width: this.inputWidth + 'px'
        }
      }
    }
    if (this.placeholder === undefined && this.label) {
      this.defaultPlaceholder = `请输入${this.label}`
    }
    this.handleSuffixIcon(this.value)
  },
  mounted () {
    if (this.type === 'textareaHor') {
      const textarea = this.$refs.input.$refs.textarea
      textarea.style.width = this.inputWidth + 'px'
      const resizeTextarea = this.$refs.input.resizeTextarea
      const textareaObserver = new ResizeObserver(throttle(entries => {
        resizeTextarea()
      }), 500)
      textareaObserver.observe(textarea)
      this.textareaObserver = textareaObserver
    }
  },
  beforeDestroy () {
    if (this.type === 'textareaHor') {
      if (this.textareaObserver) {
        const textarea = this.$refs.input.$refs.textarea
        this.textareaObserver.unobserve(textarea)
      }
    }
  },
  watch: {
    value (newValue) {
      // this.handleSuffixIcon(newValue)
      // this.model = newValue
      // // 输入框的值改变时触发
      // // @arg 输入框的值
      // this.$emit('change', newValue)
      this.changeModel(newValue)
    },
    model (newValue) {
      // v-model event
      this.$emit('input', newValue, this)
    },
    placeholder (newValue) {
      this.defaultPlaceholder = newValue
    }
  },
  methods: {
    /* 适配虚拟dom */
    changeModel (newValue) {
      this.handleSuffixIcon(newValue)
      this.model = newValue
      // 输入框的值改变时触发
      // @arg 输入框的值
      this.$emit('change', newValue)
    },
    /** end */
    handleSuffixIcon (val) {
      if (this.clearable && this.suffixIcon) {
        if (val === '') {
          this.suffixIconName = this.suffixIcon
        } else {
          this.suffixIconName = ''
        }
      }
    },
    onBlur (event) {
      // 输入框失去焦点时触发
      // @arg event
      this.$emit('blur', event, this)
    },
    onFocus (event) {
      // 输入框获得焦点时触发
      // @arg event
      this.$emit('focus', event)
    },
    // @vuese
    // 使 input 获取焦点
    focus () {
      this.$refs.input.focus()
    },
    // @vuese
    // 使 input 失去焦点
    blur () {
      this.$refs.input.blur()
    },
    clear () {
      // 点击由 clearable 属性生成的清空按钮时触发
      this.$emit('clear')
    }
  }
}
</script>
<style lang="scss">
  .v-input-container {
    .el-input {
      .el-input__inner {
        padding-left: 13px;
      }
    }
    .el-input--prefix {
      .el-input__inner {
        padding-left: 30px;
      }
    }
    .el-input--suffix {
      .el-input__suffix {
        line-height: 40px;
      }
    }
  }
</style>
<style scoped lang="scss">
  .v-input-container {
    display: inline-block;
    label {
      margin-right: 5px;
      color: #333333;
    }
  }
</style>
