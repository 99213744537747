<!--
 * @Description: 主要功能
 * @Author: FCC
 * @Date: 2019-08-09 15:26:50
 * @LastEditors: 小广
 * @LastEditTime: 2020-04-02 14:22:03
 -->
<template>
  <div class="multiSelect-bg">
    <el-dialog
      title="项目列表"
      :visible.sync="dialogShow"
      :append-to-body="appendToBody"
      :show-close="false"
      :close-on-click-modal="true"
      width="1050px"
      top="40px"
      @closed="closeDialog"
    >
      <div slot="title" class="topStyle">
        <span>{{title}}</span>
        <i class="el-icon-close pointer" @click="closeDialog"></i>
      </div>
      <div class="multiSelect-content">
        <div class="left">
          <search-panel @searchData="searchData">
            <template #searchSlot>
              <slot name="searchSlot"></slot>
            </template>
          </search-panel>
          <div class="table-panel-container">
            <table-panel
              ref="tablePanel"
              v-if="headers.length"
              :headers="headers"
              :tableData="tableData"
              :isSearching="isSearching"
              :isMultiSelect="true"
              :selectable="selectable"
              :hasOperateColumn="false"
              :autoHeight="false"
              @select="select"
              @selectAll="selectAll"
            >
              <template #operateSlot="scope">
                <div class="operate">
                  <slot name="operateSlot" :row="scope.row"></slot>
                </div>
              </template>
            </table-panel>
          </div>
          <div class="footer">
            <div class="f-left">
              <v-button
                v-if="showAllSelectBtn"
                style="margin-right: 5px;"
                text="全选"
                @click="searchData(1, 1000, true)"
              ></v-button>共
              <span>{{params.maxRow}}</span>条 共
              <span>{{params.maxPage}}</span>页
            </div>
            <div class="f-middle" v-if="showAllSelectBtn">注：全选操作上限1000条数据</div>
            <div class="f-middle" v-if="!isEnd">
              <a @click="searchData(params.nextPage)">加载至第{{params.nextPage}}页>></a>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="r-heard">
            <v-button text="清空" type="default" @click="clearAll"></v-button>
          </div>
          <div class="r-middle">
            <li
              class="list-box"
              v-for="(item, index) in selectedData"
              :key="index"
              @mouseenter="scrollTable(item[responseKey.id])"
            >
              <span
                :class="{'danger' : warningData.indexOf(item[responseKey.id]) > -1}"
              >{{item[responseKey.name]}}</span>
              <i
                class="el-icon-close pointer i-lineHeight"
                @click="cancelSelected(item[responseKey.id])"
              ></i>
            </li>
          </div>
          <div class="r-footer">
            <div>
              已选择：
              <span>{{selectedData.length}}</span>条
            </div>
            <v-button text="确定" @click="submit"></v-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { vButton } from 'components/control/index'
import searchPanel from 'components/search-panel/search-panel'
// import { TablePanel } from 'components/bussiness'
import TablePanel from '../table-panel/table-panel'
import _ from 'lodash'
const PAGESIZE = 20
// @group 业务组件
// @title MultiSelect 多选
export default {
  name: 'multi-select',
  components: {
    searchPanel,
    TablePanel,
    vButton,
  },
  data() {
    return {
      params: {
        executeSearch: 1,
        curPage: 1,
        pageSize: 20,
        maxPage: 0,
        maxRow: 0,
        row: 0,
      },
      tableData: [],
      isSearching: false,
      selectedData: [],
      isEnd: false,
      // multiName: false,
      dialogShow: false,
      countStorage: 0,
    }
  },
  props: {
    // 是否显示控件
    isShow: Boolean,
    // 搜索Url
    searchUrl: {
      type: String,
      required: true,
    },
    // 搜索请求方式
    searchMethod: {
      type: String,
      default: 'GET',
    },
    // 组件外拓展了自己的数据  得到的总的商品列表
    totalCompontDataList: {
      type: Array,
      default: () => {
        return []
      },
    },
    // 组件标题
    title: {
      type: String,
      // `''`
      default: '',
    },
    // 搜索参数 参照search-panel
    searchParams: {
      type: Object,
      // `{}`
      default: () => {
        return {}
      },
    },
    // 固定不变的列表数据查询参数
    extraParams: {
      type: Object,
      // `{}`
      default() {
        return {}
      },
    },
    // 反填选项列表
    backFill: {
      type: Array,
      // `[]`
      default: () => {
        return []
      },
    },
    // 列表参数  id {String}列唯一标识 name{String}选中显示抬头 or name{Array}选中显示抬头数列
    responseParams: {
      type: Object,
      // `{id: 'id', name: 'communityName'}`
      default: () => {
        return {
          id: 'id',
          name: 'communityName',
        }
      },
    },
    //  列表参数  id {String}返回数据唯一key name{String}返回数据显示内容
    responseKey: {
      type: Object,
      // `{id: 'id', name: 'name'}`
      default: () => {
        return {
          id: 'id',
          name: 'name',
        }
      },
    },
    // 返回额外参数
    responseOtherKey: Array,
    // table 参数 参照table-panel
    headers: {
      type: Array,
      // `[]`
      default: () => {
        return []
      },
    },
    handleData: Function,
    // 是否显示全选按钮
    showAllSelectBtn: {
      type: Boolean,
      // `true`
      default: true,
    },
    // table是否可选配置
    selectable: Function,
    // 保存前钩子函数
    beforeSave: Function,
    // 选中标红数据
    warningData: {
      type: Array,
      // `[]`
      default: () => {
        return []
      },
    },
    // 分隔符 responseParams.name 为Array时
    delimiter: {
      type: String,
      // `-`
      default: '-',
    },
    // Dialog 自身是否插入至 body 元素上。嵌套的 Dialog 必须指定该属性并赋值为 true
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    multiName() {
      return this.responseParams.name instanceof Array
    },
  },
  mounted() {
    console.log('11111')
    // this.multiName = this.responseParams.name instanceof Array
  },
  methods: {
    closeDialog() {
      this.$emit('update:isShow', false)
    },
    async submit() {
      this.countStorage = this.selectedData.length
      let ids = this.getIds(this.selectedData)
      let isContinue = this.beforeSave
        ? await this.beforeSave(this.selectedData, ids)
        : true
      if (!isContinue) {
        return
      }
      this.$emit('update:isShow', false)
      this.$emit('update:backFill', this.selectedData)
      // 确定选择callback
      // @arg function(list, ids)
      this.$emit('callback', this.selectedData, ids)
    },
    getIds(arr) {
      let ids = []
      arr.forEach((value) => {
        ids.push(value[this.responseKey.id])
      })
      return ids
    },
    // 搜索
    searchData(pageNum, pageSize, isAllSelect) {
      if (this.isEnd && pageSize) {
        this.$refs.tablePanel.toggleSelection()
        this.$refs.tablePanel.toggleAllSelection()
        return
      }
      this.params.curPage = pageNum || 1
      this.params.row = pageSize || PAGESIZE
      this.params.pageSize = pageSize || PAGESIZE
      let postData = {
        params: {
          ...this.searchParams,
          ...this.extraParams,
          ...this.params,
        },
      }
      this.handleSearchParams(postData)
      this.isSearching = true
      let config = {
        method: this.searchMethod,
        url: this.searchUrl,
      }
      if (this.searchMethod.toUpperCase() === 'POST') {
        config.data = postData.params
      }
      if (this.searchMethod.toUpperCase() === 'GET') {
        config.params = postData.params
      }
      this.$axios(config).then((res) => {
        if (res.status == 100) {
          // eslint-disable-line
          let { data } = res
          let Ids = this.totalCompontDataList.map((item) => item.id)
          let tableData = data.resultList.filter(
            (item) => !Ids.includes(item.id)
          )
          const { maxRow, maxPage, nextPage, curPage } = data
          this.params.maxRow = maxRow
          this.params.maxPage = maxPage
          if (isAllSelect && maxRow > 1000) {
            this.params.nextPage = 51
          } else {
            this.params.nextPage = nextPage
          }
          this.isEnd = maxPage === curPage
          this.handleData &&
            tableData.forEach((item, index) => {
              this.handleData(item, index)
            })
          this.tableData =
            pageNum === 1 || !pageNum
              ? tableData
              : [...this.tableData, ...tableData]
          if (isAllSelect) {
            this.$refs.tablePanel.toggleAllSelection()
          } else {
            this.selectBack()
          }
        }
        this.isSearching = false
      })
    },
    handleSearchParams(postData) {
      for (let key in postData.params) {
        if (typeof postData.params[key] === 'string') {
          postData.params[key] = postData.params[key].trim()
        }
        if (postData.params[key] === null || postData.params[key] === '') {
          postData.params[key] = undefined
        }
      }
    },
    // table点击选择框事件
    select(selection, row) {
      if (selection.indexOf(row) > -1) {
        // 选中
        // 定义空对象，将选中的对象给刚定义的空对象进行赋值
        let secRow = {}
        // multiName为true表示显示text是多个属性的组合
        if (this.multiName) {
          // responseKey.id  输出给业务方的key  responseParams.id业务方带进来去tableDatade key
          secRow[this.responseKey.id] = row[this.responseParams.id]
          let nameList = []
          // 遍历显示text的参数
          _.forEach(this.responseParams.name, (nameChild) => {
            nameList.push(row[nameChild])
          })
          secRow[this.responseKey.name] = nameList.join(this.delimiter)
        } else {
          secRow[this.responseKey.id] = row[this.responseParams.id]
          secRow[this.responseKey.name] = row[this.responseParams.name]
        }
        if (this.responseOtherKey && this.responseOtherKey.length > 0) {
          this.responseOtherKey.forEach((value) => {
            secRow[value] = row[value]
          })
        }
        secRow.obj = row
        this.selectedData = [...this.selectedData, secRow]
        this.selectedData = _.uniqBy(this.selectedData, this.responseKey.id)
      } else {
        // 取消选中
        // this.selectedData = _.remove(this.selectedData, value => {
        //   return value[this.responseKey.id] !== row[this.responseParams.id]
        // })
        this.selectedData = this.selectedData.filter(
          (value) => value[this.responseKey.id] !== row[this.responseParams.id]
        )
      }
    },
    selectAll(selections) {
      let selection = selections.filter((value) => value)
      if (selection.length > 0) {
        // 全选
        let secArr = []
        selection.forEach((value) => {
          if (value) {
            let secRow = {}
            if (this.multiName) {
              secRow[this.responseKey.id] = value[this.responseParams.id]
              let nameList = []
              this.responseParams.name.forEach((nameChild) => {
                nameList.push(value[nameChild])
              })
              secRow[this.responseKey.name] = nameList.join(this.delimiter)
            } else {
              secRow[this.responseKey.id] = value[this.responseParams.id]
              secRow[this.responseKey.name] = value[this.responseParams.name]
            }
            if (this.responseOtherKey && this.responseOtherKey.length > 0) {
              this.responseOtherKey.forEach((values) => {
                secRow[values] = value[values]
              })
            }
            secRow.obj = value
            secArr.push(secRow)
          }
        })
        this.selectedData = [...this.selectedData, ...secArr]
        this.selectedData = _.uniqBy(this.selectedData, this.responseKey.id)
      } else {
        // 全取消
        let ids = this.tableData.map((item) => item[this.responseParams.id])
        // this.selectedData = []
        this.selectedData = this.selectedData.filter(
          (value) => ids.indexOf(value[this.responseKey.id]) < 0
        )
      }
    },
    // 取消选中的值
    cancelSelected(id) {
      let _index = _.findIndex(this.tableData, (value) => {
        return value[this.responseParams.id] === id
      })
      this.$refs.tablePanel.toggleSelection([this.tableData[_index]], false)
      // this.selectedData = _.remove(this.selectedData, value => {
      //   return value[this.responseKey.id] !== id
      // })
      this.selectedData = this.selectedData.filter(
        (value) => value[this.responseKey.id] !== id
      )
    },
    // 反选
    selectBack() {
      let indexArr = []
      this.$refs.tablePanel.toggleSelection()
      this.selectedData.forEach((value) => {
        let _index = _.findIndex(this.tableData, (valueChild) => {
          return (
            value[this.responseKey.id] === valueChild[this.responseParams.id]
          )
        })
        this.tableData[_index] && indexArr.push(this.tableData[_index])
      })
      this.$nextTick(() => {
        this.$refs.tablePanel.toggleSelection(indexArr, true)
      }, 30)
    },
    // 清空
    clearAll() {
      this.selectedData = []
      this.$refs.tablePanel.toggleSelection()
    },
    scrollTable(id) {
      let _index = _.findIndex(this.tableData, (value) => {
        return value[this.responseParams.id] === id
      })
      this.$refs.tablePanel.setCurrentRow(_index)
    },
  },
  watch: {
    isShow(val) {
      this.dialogShow = val
      if (val) {
        this.countStorage = this.backFill.length
        console.info(this.backFill, 'this.backFill')
        this.selectedData = _.cloneDeep(this.backFill)
        this.tableData.length > 0 && this.selectBack()
        // if (this.backFill.length !== this.countStorage) {
        //   this.countStorage = this.backFill.length
        //   this.selectedData = _.cloneDeep(this.backFill)
        //   this.tableData.length > 0 && this.selectBack()
        //   // this.selectBack()
        // }
        this.$nextTick(() => {
          if (this.tableData.length > 0) {
          } else {
            this.searchData()
          }
        }, 30)
      }
    },
    extraParams: {
      handler() {
        this.tableData = []
      },
      deep: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.multiSelect-bg {
  text-align: left;
}
.topStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    font-size: 18px;
  }
}
.multiSelect-content {
  display: flex;
  flex-direction: row;
  height: 750px;
  .left {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: 10px;
    max-width: 700px;
    .table-panel-container {
      flex: 1;
      overflow: auto;
      border-top: 1px solid #e0e0e0;
      margin-bottom: 20px;
      .operate {
        :not(:last-child) {
          margin-right: 8px;
        }
      }
    }
    .footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .f-left {
        span {
          margin: 0 3px;
          font-weight: 700;
        }
      }
      .f-middle {
        display: flex;
        align-items: center;

        a {
          color: #0099ff;
          cursor: pointer;
        }
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    width: 300px;
    border-left: 1px solid #dedede;
    .r-heard {
      padding: 0 10px 10px;
      border-bottom: 1px solid #dedede;
      text-align: right;
    }
    .r-middle {
      flex: 1;
      // max-height: 729px;
      overflow: auto;
      .list-box {
        padding: 15px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        span {
          line-height: 36px;
        }
      }
      .list-box:hover {
        background-color: #d7f2fb;
        color: #3ebbf0;
      }
    }
    .r-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 10px 0;
      align-items: center;
      span: {
        margin: 0 3px;
        font-weight: 700;
      }
    }
  }
}
.pointer {
  cursor: pointer;
}
.i-lineHeight {
  line-height: 36px;
}
.danger {
  color: #ff0000;
}
</style>
