var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-col", { attrs: { span: _vm.span } }, [
    _c("div", { staticClass: "col2-block" }, [
      _vm.title
        ? _c(
            "div",
            { staticClass: "title" },
            [
              _c("span", { staticClass: "pre-point" }),
              _c("span", { domProps: { textContent: _vm._s(_vm.title) } }),
              _vm._t("headerOperate"),
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "block-content" },
        [
          _vm._t("default", function () {
            return [
              _c("div", { staticClass: "nodata-content" }, [
                _vm._v("\n          暂无数据\n        "),
              ]),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }