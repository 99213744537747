var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col2-progress-container" },
    [
      _c(
        "el-steps",
        {
          attrs: {
            space: 200,
            active: _vm.showIndex,
            "process-status": "wait",
            "finish-status": "success",
            "align-center": "",
          },
        },
        _vm._l(_vm.status, function (item, index) {
          return _c("el-step", {
            key: index,
            attrs: { title: item.name, description: item.time },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }