var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShow
    ? _c(
        "div",
        { staticClass: "mobile-preview-wrapper", on: { click: _vm.closeView } },
        [
          _c(
            "div",
            {
              staticClass: "bg-mobile",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", {
                staticClass: "closeIcon",
                on: { click: _vm.closeView },
              }),
              _c("div", { staticClass: "content-mobile" }, [
                _c("div", { staticClass: "con-top" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
                _c(
                  "div",
                  { staticClass: "con-iframe" },
                  [
                    _vm.$slots.content ? _vm._t("content") : _vm._e(),
                    _vm.iframeUrl && !_vm.$slots.content
                      ? _c("iframe", {
                          attrs: {
                            width: "320",
                            height: "520",
                            src: _vm.iframeUrl,
                            frameborder: "no",
                          },
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }