// 上传图片
const uploadURL = `${API_CONFIG.uploadURL}`
// 项目列表
const getCommunityListURL = `${API_CONFIG.butlerBaseURL}form/community/select2Community`
// 区域列表
const getAllRegionListURL = `${API_CONFIG.controlBaseURL}region/select2`
// 组织列表
const getOrgListURL = `${API_CONFIG.butlerBaseURL}orgInfo/searchOrgByKeyword`
// 服务列表
const getServiceListURL = `${API_CONFIG.baseURL}serverCodewordAction!getSubjects.action`
// 商家列表
const getShopListURL = `${API_CONFIG.baseURL}serverCodewordAction!getShops.action`
// 门店列表
const getStoreListURL = `${API_CONFIG.baseURL}communityStoreSubjectAction!viewStoreByShop.action`
// 房号列表
const getRoomListURL = `${API_CONFIG.baseURL}serverCodewordAction!getUserRooms.action`
// 查询当前所属组织列表
const getCurrentOrgListURL = `${API_CONFIG.butlerBaseURL}orgInfo/getAdminOrgInfo`

// 获取运营项目组
const getOperateRegionsURL = `${API_CONFIG.baseURL}serverCodewordAction!getOperateRegions.action`
// 获取阶段状态
const getProjectTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 项目类型
const getRoomTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getRoomTypes.action`
// 所属公司列表
const getTenantListURL = `${API_CONFIG.butlerBaseURL}tenant/select2`
// 获取数据字典列表
const sysCodewordUrl = `${API_CONFIG.butlerBaseURL}sysCodeword/getSysCodeWordByCodeLevel`
// 获取房屋类型list
const getRommTypesUrl = `${API_CONFIG.butlerBaseURL}communityRoom/getCommunityRoomNumHouseType`

// select2苑(根据项目id获取苑/组团，同一个接口)
const select2BlockURL = `${API_CONFIG.butlerBaseURL}communityRoom/select2/blockName`
// select2幢
const select2BuildingURL = `${API_CONFIG.butlerBaseURL}communityRoom/select2/buildingNumber`
// select2单元
const select2UnitURL = `${API_CONFIG.butlerBaseURL}communityRoom/select2/unit`
// select2室
const select2RoomURL = `${API_CONFIG.butlerBaseURL}communityRoom/select2/room`

export {
  uploadURL,
  getCommunityListURL,
  getAllRegionListURL,
  getOrgListURL,
  getServiceListURL,
  getShopListURL,
  getStoreListURL,
  getRoomListURL,
  getCurrentOrgListURL,
  getOperateRegionsURL,
  getProjectTypesURL,
  getRoomTypesURL,
  getTenantListURL,
  sysCodewordUrl,
  getRommTypesUrl,
  select2BlockURL,
  select2BuildingURL,
  select2UnitURL,
  select2RoomURL
}
