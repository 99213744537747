var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "qrcode-list-container" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            "popper-class": "qrcode-list-popper",
            placement: "bottom-end",
            "popper-options": _vm.popperOptions,
            offset: -16,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "reference",
                fn: function () {
                  return [_vm._t("qrcode")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        [
          _c("div", {
            staticClass: "header",
            domProps: { textContent: _vm._s(_vm.title) },
          }),
          _c(
            "ul",
            { staticClass: "qrcode-list" },
            _vm._l(_vm.qrcodeList, function (item, index) {
              return _c("li", { key: index, staticClass: "qrcode-item" }, [
                _c("div", {
                  staticClass: "img",
                  attrs: { id: "download_qrcode_img_" + index },
                }),
                _c("div", {
                  staticClass: "name",
                  domProps: { textContent: _vm._s(item.name) },
                }),
              ])
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }