<template lang="html">
  <div class="col2-progress-container">
    <el-steps :space="200" :active="showIndex" process-status="wait" finish-status="success" align-center>
      <el-step v-for="(item, index) in status" :key="index" :title="item.name" :description="item.time">
      </el-step>
    </el-steps>
  </div>
</template>

<script>
import Vue from 'vue'
import { Steps, Step } from 'element-ui'
Vue.use(Steps).use(Step)
// @group 业务组件
// @title Col2Progress Col2组件进度条
export default {
  props: {
    // 状态数组{ name:'名称', time: '2019-01-01' }
    status: {
      type: Array
    },
    // 进度条百分比（默认为到第一个没有时间的状态数组的占比）(1.23后，逐步废弃，业务方要使用currentIndex)
    percent: Number,
    // 当前进度的index（1.23新加，以后都要使用这个）从1开始
    currentIndex: {
      type: Number
    }
  },
  name: 'col2-progress',
  computed: {
    showIndex () {
      let index = 0
      //  this.status.findIndex(item => !item.time)
      // if (index === -1) {
      //   index = this.status.length
      // }
      // currentIndex优先
      if (this.currentIndex) {
        index = this.currentIndex
      } else {
        // 兼容老的逻辑
        // 根据步骤的个数，和进度  来处理显示当前的index，解决bug【RONG-3467】
        let length = this.status ? this.status.length : 0
        if (length > 0 && this.percent) {
          index = length * this.percent / 100
        }
      }
      return index
    },
    percentage () {
      if (typeof this.percent === 'undefined') {
        let index = this.status.findIndex(item => !item.time)
        return (index / this.status.length) * 100
      } else {
        return this.percent
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.col2-progress-container {
  margin: 15px 0;
  ::v-deep {
    .is-success, .is-process  {
      .el-step__icon {
        border: none;
        background-color: #7AC943;
        .el-icon-check {
          color: #fff;
        }
        .el-step__icon-inner {
          color: #fff;
        }
      }
    }
    .is-wait {
      .el-step__icon {
        border: none;
        background-color: #E6E7E6;
        .el-step__icon-inner {
          color: #aaa;
        }
      }
    }
    .is-success {
      .el-step__line {
          background-color: #7AC943;
        }
      }
      .is-process, .is-wait {
        .el-step__line {
          background-color: #E6E7E6;
        }
      }
      .el-step__main {
        .is-success {
          font-size: 14px;
          font-weight: 400;
          color: #aaa;
          white-space: nowrap;
        }
        .is-process, .is-wait {
          font-size: 14px;
          font-weight: 400;
          color: #333;
          white-space: nowrap;
        }
      }
    }
}
</style>
