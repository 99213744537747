<!--
 * @Description: 商品三级类目选择的item
 * @Author: 小广
 * @Date: 2019-05-29 10:06:37
 * @LastEditors: 小广
 * @LastEditTime: 2019-05-30 17:30:55
 -->
<template>
  <div class="category-select-item-wrapper">
    <div class="text-wrapper">
      <span class="title-text">{{ title }}</span>
    </div>
    <div class="content-wrapper">
      <v-input
        class="search-wrapper"
        v-model="searchKey"
        placeholder="请输入关键字搜索"
        :width="176"
      ></v-input>
      <div class="table-wrapper">
        <div
          v-for="(item, index) in showList"
          :class="[
            'item-wrapper',
            { 'item-selected-wrapper': isSelected(index) }
          ]"
          :key="item.id"
          @click="itemClick(index)"
        >
          <span>{{ item.categoryName }}</span>
          <span v-if="showArrow">></span>
        </div>
      </div>
      <div v-if="showAll" class="bottom-wrapper">
        <v-button
          class="all-text"
          type="text"
          text="全选"
          @click="allClick"
        ></v-button>
      </div>
    </div>
  </div>
</template>

<script >
export default {
  name: 'category-select-item',
  props: {
    title: {
      type: String,
      default: ' '
    },
    // 类目数组
    categoryList: {
      type: Array,
      // `[]`
      default: () => {
        return []
      }
    },
    // 最后一级类目是否显示按钮
    showAll: {
      type: Boolean,
      // `false`
      default: false
    },

    // 是否显示右箭头
    showArrow: {
      type: Boolean,
      // `true`
      default: true
    }
  },
  data () {
    return {
      searchKey: '',
      showList: this.categoryList
    }
  },

  watch: {
    searchKey (newValue) {
      if (newValue.length) {
        // 进行数组过滤
        this.showList = this.searchResultFilter(newValue)
      } else {
        this.showList = this.categoryList
      }
    },

    categoryList (newValue) {
      this.showList = newValue
    }
  },

  // 方法
  methods: {

    // 全部按钮点击事件
    allClick () {
      let tempList = []
      this.showList.forEach(item => {
        item.select = true
        tempList.push(item)
      })
      this.showList = tempList
      this.dealWithSelectData()
    },

    // item事件
    itemClick (index) {
      if (this.showAll) {
        // 多选的处理，（最后一级类目，若是显示全部按钮，即可多选）
        let item = this.showList[index]
        let selected = item.select
        if (selected) {
          selected = !selected
        } else {
          selected = true
        }
        item.select = selected
        this.showList.splice(index, 1, item)
      } else {
        // 再次选中同一个可以清除所选
        let tempList = []
        this.showList.forEach((item, itemIndex) => {
          // 选中
          let selected = false
          if (itemIndex === index) {
            selected = item.select
            if (selected) {
              // 这个处理是再次点击取消选中
              selected = !selected
            } else {
              selected = true
            }
          }
          item.select = selected
          tempList.push(item)
        })
        this.showList = tempList
      }
      this.dealWithSelectData()
    },

    // 处理选中的数据
    dealWithSelectData () {
      let selectData = []
      this.showList.forEach(item => {
        if (item.select) {
          selectData.push(item)
        }
      })
      // 选中的数据，返回值：对象数组
      this.$emit('selected-data', selectData)
    },

    // 判断item是否选中
    isSelected (index) {
      let item = this.showList[index]
      let select = item.select || false
      return select
    },

    // 搜索结果的筛选
    searchResultFilter (keyword) {
      return this.showList.filter(item => {
        if (item.categoryName) {
          return item.categoryName.indexOf(keyword) !== -1
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.category-select-item-wrapper {
  display: block;
  width: 216px;
  background-color: #fff;
  padding: 5px;

  .text-wrapper {
    display: -webkit-box;
    padding: 5px 0;
    .title-text {
      font-weight: bold;
      font-size: 14px;
      color: #333;
    }
  }

  .content-wrapper {
    display: flex;
    border: 1px solid #dcdcdc;
    width: 100%;
    height: 308px;
    // justify-content: center;
    flex-direction: column;
    background-color: #fff;

    .search-wrapper {
      padding: 0 20px;
      margin: 20px 0 10px 0;
    }

    .table-wrapper {
      flex: 1;
      width: 100%;
      overflow: auto;
      z-index: 10;

      .item-wrapper {
        padding: 5px 20px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        background-color: #fff;
        // 鼠标移到改节点上时，其显示的style；不带&符号，是其子类，加了&代表其本身
        &:hover {
          background-color: #dcdcdc;
        }
      }

      .item-selected-wrapper {
        background-color: #dcdcdc;
      }
    }

    // 底部按钮
    .bottom-wrapper {
      position: sticky;
      height: 34px;
      bottom: 0;
      /// 这样写，避免一些图片显示在其上；
      z-index: 100;
      display: flex;
      align-items: center;
      margin-left: 20px;

      .all-text {
        color: #409eff;
      }
    }
  }
}
</style>
