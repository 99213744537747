<template>
    <div class="m-select-container">
        <label v-if="label" v-text="label"></label>
        <el-select 
            v-model="value" 
            placeholder="查询所属项目" 
            multiple
            filterable
            remote
            clearable
            :remote-method="remoteMethod"
            :loading="loading"
            v-bind="$attrs" 
            v-on="$listeners"
            class="select-content"
        >
            <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
        </el-select>
    </div>
</template>
<script>
// 项目多选组件
    export default {
        //设置了这个属性不会在根元素上显示
        inheritAttrs:false,
        name:'v-select3',
        props:{
            label:String,
            communityParams:Object
        },
        data() {
            return {
                options: [],
                loading:false,
                value: ''
            }
        },
        methods:{
            async remoteMethod(query,flag){
                if(query !== ''){
                    this.loading = true;
                    let res = await this.$axios.get(`${this.communityParams.searchUrl}?limitCnt=20&communityName=${query}`)
                    this.loading = false;
                    this.options = res.data || [];
                    if(flag) {
                        return new Promise((resolve, reject) => {
			                this.value = [this.options[0].id]
                            resolve(this.options[0].id)
		                })                      
                    }
                }
            },
        },
        watch:{
            value(newValue){
                this.$emit('input',newValue)
            }
        }
    }
</script>
<style lang="scss" scoped>
 .m-select-container {
     display: flex;
    align-items: flex-start;
    label {
      margin-right: 5px;
      color: #333333;
       margin-top: 9px;
    }
  }
  .select-content {
      width: 144px;
      ::v-deep .el-input__suffix {
        background-color: #333333;
        background: url('./images/search.png');
        background-repeat: no-repeat;
        background-size: 17px 17px;
        background-position: center;
      }
  }
</style>