<template>
  <div class="search-panel-container" v-if="showSearchPanel"   :class="searchPanelType == 'drawer' && 'drawer-type'">
    <div class="content">
      <div  v-if="searchPanelType == 'inner'" class="control-list" ref="control" @keyup.enter="searchClick">
        <slot name="searchSlot"></slot>
      </div>
      <el-drawer ref="searchDrawer" title="搜索筛选"  :size="size" v-else :visible.sync="showSearchDrawer" :class="[searchPanelType == 'drawer' && 'drawer-type']" @open="changeSize" @closed="inited = true"  :append-to-body="true">
        <div class="search-drawer" >
          <div class="control-wrapper">
            <div class="control-list" ref="control" @keyup.enter="searchClick">
              <slot name="searchSlot"></slot>
            </div>
          </div>
          <div class="button-list">
            <!-- <v-button text="重置" type="default"></v-button> -->
            <v-button type="text" class="v-button-text" v-if="searchPanelType == 'inner' && (lessNumber < controlNumber)" @click="statusChange">
              {{ status ? '收起' : '展开' }}<i class="el-icon--right" :class="status ? openIcon : closeIcon"></i>
            </v-button>
            <v-button :text="searchName" @click="searchClick"></v-button>
          </div>
        </div>


      </el-drawer>
      <div class="button-list">
        <v-button type="text" class="v-button-text" v-if="searchPanelType == 'inner' && (lessNumber < controlNumber)" @click="statusChange">
          {{ status ? '收起' : '展开' }}<i class="el-icon--right" :class="status ? openIcon : closeIcon"></i>
        </v-button>
        <slot name="btnSlot" :searchDisabled="searchDisabled"></slot>
        <template v-if="searchVisible">
          <v-button v-if="searchPanelType == 'drawer' " :text="searchName" type="success" @click="handleShowSearch"></v-button>
          <v-button  v-else :text="searchName" type="success" :disabled="searchDisabled" @click="searchClick"></v-button>
        </template>

        <el-dropdown v-if="isMultiExport && isPermission" trigger="click" @command="exportClick">
          <v-button>
            导出<i class="el-icon-arrow-down el-icon--right"></i>
          </v-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in multiExport" :key="index" :command="index">
              <v-button ref="dropdownButton" type="text" :text="item.text" :permission="item.permission"></v-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <v-button v-if="!isMultiExport && isPermission" :text="exportName" :disabled="exportDisabled" @click="exportClick(-1)"></v-button>
      </div>
    </div>
    <!--<div class="footer" v-if="searchPanelType == 'inner' && (lessNumber < controlNumber)">-->
    <!--  <img :src="status ? closeIcon : openIcon" @click="statusChange"/>-->
    <!--</div>-->
  </div>
</template>

<script>
// import openIcon from './images/open.png'
// import closeIcon from './images/close.png'
export default {
  name: 'search-panel',
  props: {
    searchVisible: {
      type: Boolean,
      default: true
    },
    searchName: {
      type: String,
      default: '搜索'
    },
    exportName: {
      type: String,
      default: '导出'
    },
    multiExport: Array,
    exportPermission: {
      type: [Boolean, String],
      default: false
    }
  },
  data () {
    return {
      inited:false,
      showSearchPanel: true,
      size: "900px",
      showSearchDrawer:false,
      lessNumber: 4,
      status: true,
      searchDisabled: false,
      exportDisabled: false,
      controlNumber: 0,
      openIcon: 'el-icon-arrow-up',
      closeIcon: 'el-icon-arrow-down'
    }
  },
  mounted () {
    if(this.searchPanelType === 'inner') {
      this.controlChange(true)
    }else{
      this.$refs.searchDrawer.rendered = true
    }
    if (!this.$slots.searchSlot) {
      this.showSearchPanel = false
    }
  },
  computed: {
    searchPanelType() {
      return this.$store.state.theme['search-panel-type'] || 'inner'
    },

    isMultiExport () {
      return this.multiExport && this.multiExport.length > 0
    },
    isPermission () {
      return this.$store.getters.getPermission(this.exportPermission)
    }
  },
  methods: {
    changeSize(){
      this.size = '680px'
    },
    handleShowSearch(){
      this.showSearchDrawer = !this.showSearchDrawer
    },
    setSearchDisabled (status) {
      this.searchDisabled = status
    },
    setExportDisabled (status) {
      this.exportDisabled = status
    },
    searchClick (e) {
      // w-select组件enter事件冲突
      if(e.type == 'keyup' && [...e.target.classList].includes('w-select-search__field')){
        return
      }
      this.$emit('searchData')
      if(this.searchPanelType == 'drawer'){
        this.showSearchDrawer = false
      }
    },
    exportClick (index) {
      this.$emit('exportData', index)
    },
    controlChange (status) {
      let display = status ? 'flex' : 'none'

      this.controlNumber = this.$refs.control.children.length
      for (let i = this.lessNumber; i < this.controlNumber; i++) {
        this.$refs.control.children[i].style.display = display
      }
    },
    statusChange () {
      this.status = !this.status
      this.controlChange(this.status)
    }
  }
}
</script>

<style lang="scss">
.search-panel-container.drawer-type{
  .content{
    justify-content: flex-end;
  }
}
.hiddened{
  opacity:0
}
.drawer-type{
      display: flex;
      justify-content: flex-end;

      ::-webkit-scrollbar-thumb{
        background:transparent;
        // box-shadow:unset;
      }
      ::-webkit-scrollbar-track{
        background: transparent;
        // box-shadow:unset;

      }
      .search-drawer{
        height: 100%;
        position: relative;
        .control-list {
        padding: 40px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        font-size: 14px;
        font-weight: 400;

        .v-control {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          min-width: 240px;
          margin-bottom: 20px;
          margin-right: 20px;
        }
      }
      .button-list {
        margin-bottom: 20px;

        justify-self: flex-end;
        white-space: nowrap;
        > :not(:last-child) {
          margin-right: 8px;
        }
        .v-button-text {
          margin-right: 16px;
        }
      }
        .control-wrapper{
          overflow-y:auto;
          padding-bottom:80px;
          height:100%;
          box-sizing:border-box;

        }
        .button-list {
          position: absolute;
          padding-right: 12px;
          margin-right: 8px;
          box-sizing: border-box;
          width: 100%;
          padding-top: 60px;
          padding-bottom: 20px;
          margin-bottom: 0;
          display: flex;
          justify-content: flex-end;
          background: white;
          bottom: 0;
          right: 0;
        }

   }
  }
</style>
<style scoped lang="scss">
  .search-panel-container {
    background-color: #FFF;
    &.drawer-type{
      display: flex;
      justify-content: flex-end;
    }
    .content {
      display: flex;
      justify-content: space-between;
      .control-list {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        font-size: 14px;
        font-weight: 400;
        ::v-deep .v-control {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          min-width: 240px;
          margin-bottom: 20px;
          margin-right: 20px;
        }
      }
      .button-list {
        margin-bottom: 20px;

        justify-self: flex-end;
        white-space: nowrap;
        > :not(:last-child) {
          margin-right: 8px;
        }
        .v-button-text {
          margin-right: 16px;
        }
      }

    }
    .footer {
      display: flex;
      justify-content: center;
      img {
        width: 80px;
        height: 10px;
        cursor: pointer;
      }
    }
  }
</style>
