// 获取列表
const getIotCommunityListURL = `${API_CONFIG.iotURL}region/getTree`
// 获取默认的园区
const getDefaultCommunityURL = `${API_CONFIG.iotURL}userCommunityInfo/userInfoCommunity/getUserDefaultCommunityInfo`
// 设置默认园区
const setDefaultCommunityURL = `${API_CONFIG.iotURL}userCommunityInfo/userInfoCommunity/addUserDefaultCommunityInfo`

export {
  getIotCommunityListURL,
  getDefaultCommunityURL,
  setDefaultCommunityURL
}
