/**
 * jump-go分类的code
 * jump-go组件里的code 与客户端对应，基本不变后续肯能会新增，客户端根据code识别页面
 */
const jumpGoCode = {
  storeCode: '20000', // 门店
  productCode: '20001', // 商品
  secKillCode: '20019', // 秒杀
  discountTopicCode: '20002', // 促销专题
  knowledgeInfoCode: '20012', // 资讯
  postsCode: '20005', // 帖子
  groupCode: '20006', // 社群
  socialActivityCode: '30031', // 社区活动
  surveyCode: '109', // 问卷调查
  selfRuleCode: '20003', // 自治规约
  autonomyVoteCode: '20004', // 自治表决
  workRoomCode: '20007' // 社区工作室
}
export {
  jumpGoCode
}
