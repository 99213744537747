<template>
  <div class="title-panel-container">
    <div class="left" v-if="breadcrumb && breadcrumb.length">
      <i class="icon"></i>
      <span class="breadcrumb" v-text="handleBreadcrumb(breadcrumb)"></span>
      <span v-if="pageItem.helpUrl" class="help-icon" @click="openHelpCenter" title="点击此处进入帮助中心"></span>
    </div>
<!--     <div class="right">
      <div class="button-container">
        <slot name="titleSlot"></slot>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: 'title-panel',
  computed: {
    breadcrumb () {
      return this.$store.getters.getBreadcrumb
    },
    pageItem () {
      return this.$store.getters['menuStore/getPageItem']
    }
  },
  methods: {
    handleBreadcrumb (breadcrumb) {
      return breadcrumb.join(' > ')
    },
    openHelpCenter () {
      const pageItem = this.pageItem
      window.open(pageItem.helpUrl)
    }
  }
}
</script>
<style scoped lang="scss">
  .title-panel-container {
    display: flex;
    justify-content: space-between;
    height: 32px;
    padding: 20px;
    background-color: #FFF;
    .left {
      display: flex;
      align-items: center;
      height: 14px;
      line-height: 14px;
      font-size: 14px;
      .icon {
        display: inline-block;
        width: 2px;
        margin-right: 8px;
        background-color: #1B8CF2;
      }
      .breadcrumb {
        color: #aaa;
      }
      .help-icon {
        display: inline-block;
        cursor: pointer;
        width: 24px;
        height: 24px;
        margin-left: 30px;
        background-image: url(../../assets/images/help_icon.png);
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }
/*    .right {
      white-space: nowrap;
      .button-container {
        .v-control {
          &:not(:first-child) {
            margin-left: 7px;
          }
        }
      }
    }*/
  }
</style>
