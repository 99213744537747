import vButton from './v-button/v-button'
import vCheckbox from './v-checkbox/v-checkbox'
import vInput from './v-input/v-input'
import vInputNumber from './v-input-number/v-input-number'
import vSelect from './v-select/v-select'
import vUploader from './v-uploader/v-uploader'
import vUploader2 from './v-uploader/v-uploader2'
import vDatepicker from './v-datepicker/v-datepicker'
import vDatepicker2 from './v-datepicker2/v-datepicker2'
import vTimepicker from './v-timepicker/v-timepicker'
import vTimepicker2 from './v-timepicker/v-timepicker2'
import vSelect2 from './v-select2/v-select2'
import vSelect3 from './v-select3/v-select3'
import vSelect4 from './v-select4/v-select4'
import vSelect5 from './v-select5/v-select5'
import vUeditor from './v-ueditor/v-ueditor'
import vPreview from './v-preview/v-preview.vue'
import vTree from './v-tree/v-tree.vue'
import vMap from './v-map/v-map.vue'
import vTextarea from './v-textarea/v-textarea'
import vCascaderPlus from './v-cascader-plus/v-cascader-plus'
// 级联选择器
import vCascader from './v-cascader/v-cascader'
// 可带输入建议的输入框组件
import vAutocomplete from './v-autocomplete/v-autocomplete'
import vSwitch from './v-switch/v-switch'
import vTooltip from './v-tooltip/v-tooltip'
export {
  vButton,
  vCheckbox,
  vInput,
  vInputNumber,
  vSelect,
  vUploader,
  vUploader2,
  vDatepicker,
  vTimepicker,
  vSelect2,
  vSelect3,
  vSelect4,
  vSelect5,
  vUeditor,
  vPreview,
  vTree,
  vMap,
  vTextarea,
  vCascaderPlus,
  vCascader,
  vAutocomplete,
  vSwitch,
  vTooltip,
  vDatepicker2,
  vTimepicker2,
}
