var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "field-tips-wrapper" },
    [
      _vm._v("\n    " + _vm._s(_vm.tipsText) + "\n    "),
      _vm._t("customFieldTips"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }