var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "m-select-container" },
    [
      _vm.label
        ? _c("label", { domProps: { textContent: _vm._s(_vm.label) } })
        : _vm._e(),
      _c(
        "el-select",
        _vm._g(
          _vm._b(
            {
              staticClass: "select-content",
              attrs: {
                placeholder: "查询所属项目",
                multiple: "",
                filterable: "",
                remote: "",
                clearable: "",
                "remote-method": _vm.remoteMethod,
                loading: _vm.loading,
              },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            },
            "el-select",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        _vm._l(_vm.options, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.name, value: item.id },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }