/*
 * @Author: xfj
 * @Date: 2020-02-26 10:56:33
 * @LastEditors: xfj
 * @LastEditTime: 2020-02-26 11:10:36
 * @Description: file content
 */
const workTopStore = {
  namespaced: true,
  state: {
    communityId: ''
  },
  mutations: {
    setCommunityId (state, communityId) {
      state.communityId = communityId
    }
  }
}

export default workTopStore
