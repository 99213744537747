<template>
  <div class="role-permission-wrapper">
    <ul class="item-name-wrapper">
      <li class="item-name"
          v-for="(center, centerIndex) in resource"
          :key="center.id"
          :class="{active: centerIndex === currentIndex, disabled: center.disabled}"
          @click="changeCenter(centerIndex, center)">
        {{center.name}}
        <span v-if="center.centerCheckedCount > 0"
              class="dot"></span>
      </li>
    </ul>
    <div class="item-list-wrapper"
         v-for="(center, centerIndex) in resource"
         :key="center.id"
         :class="{active: centerIndex === currentIndex}">
      <div class="menu-wrapper">
        <div class="pl_20">
          <label class="inline-block">
            <div class="common-wrapper"
                 @click="checkAllMenu(center,centerIndex, $event)">
              <div class="outer">
                <!-- 全部显示是否勾选 -->
                <span class="inner"
                      v-show="center.childMenus.length === center.centerShowMenusCount"></span>
              </div>
              <div class="label">全部显示</div>
            </div>
          </label>
        </div>
        <div class="menu-list"
             v-for="level1 in center.childMenus"
             :key="level1.id"
             :class="{active: level1.isSelected === 1}"
             @click="checkLevel1Menu(level1, center,centerIndex)">{{level1.name}}</div>
      </div>
      <div class="title-wrapper"
           v-show="center.centerShowMenusCount > 0">
        <span class="title">权限配置</span>
        <label class="inline-block v-middle">
          <div class="common-wrapper"
               @click="changeAllRoleType(center,centerIndex, $event)">
            <div class="outer">
              <!-- centerCheckedCount > 0且显示的菜单授权按钮个数一样 -->
              <span class="inner"
                    v-show="center.centerCheckedCount > 0 && center.centerShowAuthCount === center.centerCheckedCount"></span>
            </div>
            <div class="label">全部勾选</div>
          </div>
        </label>
      </div>
      <!-- 权限配置的列表 -->
      <div class="pd_20 module-wrapper">
        <div v-for="level1 in center.childMenus"
             :key="level1.id">
          <div class="module-list"
               v-show="level1.isSelected===1">
            <div class="modules-header">
              <div class="module module-0">模块</div>
              <div class="module module-1">
                <label class="inline-block">
                  <div class="common-wrapper"
                       @click="changedColRoleType(1, level1, center,centerIndex, $event)">
                    <div class="outer">
                      <!-- 没有授权按钮的样式 -->
                      <span class="disabled"
                            v-show="level1.roleTypeCount1 === 0"></span>
                      <!-- 全选的样式 -->
                      <span class="inner"
                            v-show="level1.checkedRoleTypeCount1 > 0 && level1.checkedRoleTypeCount1 === level1.roleTypeCount1"></span>
                    </div>
                  </div>
                </label>
                <span class="pl_20 inline-block v-middle">可见</span>
              </div>
              <div class="module module-2">
                <label class="inline-block">
                  <div class="common-wrapper"
                       @click="changedColRoleType(2, level1, center,centerIndex, $event)">
                    <div class="outer">
                      <span class="disabled"
                            v-show="level1.roleTypeCount2 === 0"></span>
                      <span class="inner"
                            v-show="level1.checkedRoleTypeCount2 > 0 && level1.checkedRoleTypeCount2 === level1.roleTypeCount2"></span>
                    </div>
                  </div>
                </label>
                <span class="pl_20 inline-block v-middle">新增</span>
              </div>
              <div class="module module-3">
                <label class="inline-block">
                  <div class="common-wrapper"
                       @click="changedColRoleType(3, level1, center,centerIndex, $event)">
                    <div class="outer">
                      <span class="disabled"
                            v-show="level1.roleTypeCount3 === 0"></span>
                      <span class="inner"
                            v-show="level1.checkedRoleTypeCount3 > 0 && level1.checkedRoleTypeCount3 === level1.roleTypeCount3"></span>
                    </div>
                  </div>
                </label>
                <span class="pl_20 inline-block v-middle">修改</span>
              </div>
              <div class="module module-4">
                <label class="inline-block">
                  <div class="common-wrapper"
                       @click="changedColRoleType(4, level1, center,centerIndex, $event)">
                    <div class="outer">
                      <span class="disabled"
                            v-show="level1.roleTypeCount4 === 0"></span>
                      <span class="inner"
                            v-show="level1.checkedRoleTypeCount4 > 0 && level1.checkedRoleTypeCount4 === level1.roleTypeCount4"></span>
                    </div>
                  </div>
                </label>
                <span class="pl_20 inline-block v-middle">导出</span>
              </div>
              <div class="module module-5">
                <label class="inline-block">
                  <div class="common-wrapper"
                       @click="changedColRoleType(5, level1, center,centerIndex, $event)">
                    <div class="outer">
                      <span class="disabled"
                            v-show="level1.roleTypeCount5 === 0"></span>
                      <span class="inner"
                            v-show="level1.checkedRoleTypeCount5 > 0 && level1.checkedRoleTypeCount5 === level1.roleTypeCount5"></span>
                    </div>
                  </div>
                </label>
                <span class="pl_20 inline-block v-middle">其他</span>
              </div>
            </div>
            <!-- list-start -->
            <div class="modules"
                  v-for="(authItem) in level1.authList"
                  :key="`${authItem.id}$$${authItem.level}`">
              <div class="module module-0">
                 <!-- 授权列表的title的勾选框 -->
                <div class="module-c-box">
                  <label class="inline-block v-middle">
                    <!-- @click="changeRowRoleType(menu, menus, item, $event)" -->
                    <div class="common-wrapper"
                         @click="checkAuthRow(authItem,level1,center,centerIndex,$event)">
                      <div class="outer">
                        <span class="inner"
                              v-show="authCheckStatus(authItem,level1,center)"></span>
                      </div>
                    </div>
                  </label>
                </div>
                <!-- 授权列表的title -->
                <h3 v-if="authItem.level === 1" class="inline-block v-middle">{{authItem.name}}</h3>
                <div v-else class="module-name v-middle">
                  <div v-if="authItem.supName && authItem.supName.length">
                    <div>{{authItem.supName}}</div>
                    <div v-if="authItem.supCount && authItem.supCount > 1" >{{authItem.selfIndex + 1}}、{{authItem.name}}</div>
                  </div>
                  <div v-else>{{authItem.name}}</div>
                </div>
              </div>
              <!-- 可见类型授权按钮 -->
              <div class="module module-1">
                <div v-if="authItem.menuType === 2">
                  <span v-if="authItem.roleTypeCount1 === 0">/</span>
                  <template v-else
                            v-for="it in authItem.childMenus">
                    <span class="role-permission-btn"
                          v-if="it.roleType === 1"
                          :class="{selected: it.isSelected === 1}"
                          :key="it.id"
                          @click="checkAuthItem(it,authItem,level1,center,centerIndex,$event)">
                      <span class="role-permission-text">{{it.name}}</span>
                      <span class="role-permission-icon"></span>
                    </span>
                  </template>
                </div>
              </div>
              <!-- 新增类型授权按钮 -->
              <div class="module module-2">
                <div v-if="authItem.menuType === 2">
                  <span v-if="authItem.roleTypeCount2 === 0">/</span>
                  <template v-else
                            v-for="it in authItem.childMenus">
                    <span class="role-permission-btn"
                          v-if="it.roleType === 2"
                          :class="{selected: it.isSelected === 1}"
                          :key="it.id"
                          @click="checkAuthItem(it,authItem,level1,center,centerIndex,$event)">
                      <span class="role-permission-text">{{it.name}}</span>
                      <span class="role-permission-icon"></span>
                    </span>
                  </template>
                </div>
              </div>
              <!-- 修改类型授权按钮 -->
              <div class="module module-3">
                <div v-if="authItem.menuType === 2">
                  <span v-if="authItem.roleTypeCount3 === 0">/</span>
                  <template v-else
                            v-for="it in authItem.childMenus">
                    <span class="role-permission-btn"
                          v-if="it.roleType === 3"
                          :class="{selected: it.isSelected === 1}"
                          :key="it.id"
                          @click="checkAuthItem(it,authItem,level1,center,centerIndex,$event)">
                      <span class="role-permission-text">{{it.name}}</span>
                      <span class="role-permission-icon"></span>
                    </span>
                  </template>
                </div>
              </div>
              <!-- 导出类型授权按钮 -->
              <div class="module module-4">
                <div v-if="authItem.menuType === 2">
                  <span v-if="authItem.roleTypeCount4 === 0">/</span>
                  <template v-else
                            v-for="it in authItem.childMenus">
                    <span class="role-permission-btn"
                          v-if="it.roleType === 4"
                          :class="{selected: it.isSelected === 1}"
                          :key="it.id"
                          @click="checkAuthItem(it,authItem,level1,center,centerIndex,$event)">
                      <span class="role-permission-text">{{it.name}}</span>
                      <span class="role-permission-icon"></span>
                    </span>
                  </template>
                </div>
              </div>
              <!-- 其他类型授权按钮 -->
              <div class="module module-5">
                <div v-if="authItem.menuType === 2">
                  <span v-if="authItem.roleTypeCount5 === 0">/</span>
                  <template v-else
                            v-for="it in authItem.childMenus">
                    <span class="role-permission-btn"
                          v-if="it.roleType === 5"
                          :class="{selected: it.isSelected === 1}"
                          :key="it.id"
                          @click="checkAuthItem(it,authItem,level1,center,centerIndex,$event)">
                      <span class="role-permission-text">{{it.name}}</span>
                      <span class="role-permission-icon"></span>
                    </span>
                  </template>
                </div>
              </div>
            </div>
            <!-- end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
// 此组件是管理后台角色管理的组件，数据取服务端接口返回的resource列表，采用的是.sync数据同步的方式 选中的角色id没有时时计算返回 需使用者在提交时遍历去拿
// @group 业务组件
// @title RolePermissions 角色管理
export default {
  name: 'role-permissions',
  props: {
    // 类型：增加（add）、修改（edit）和收权（back）三种类型
    handleType: {
      type: String,
      default: 'edit'
    },
    // 服务端接口返回的resource数据
    roleDataList: {
      type: Array,
      // `[]`
      default () {
        return []
      }
    },
    unifyId: {
      type: Number
    },
    roleTypeId: {
      type: String
    }
  },
  data () {
    return {
      currentIndex: 0,
      resource: [],
      dataInit: false
    }
  },

  computed: {
    authCheckStatus () {
      return (authItem, level1, center) => {
        let isSelected = false
        if (authItem.menuType === 2) {
          // 代表是页面
          isSelected = authItem.allAuthCount > 0 && authItem.checkedCount === authItem.allAuthCount
        } else {
          // 代表是文件夹，也就是二级页面
          isSelected = level1.allAuthCount > 0 && level1.checkedCount === level1.allAuthCount
        }
        return isSelected
      }
    }
  },

  watch: {
    unifyId (id) {
      this.roleDataList && this.roleDataList.length && this.roleDataList.forEach(item => {
        if (item.id === id) {
          this.resource = item.childMenus || []
          this.currentIndex = 0
        }
      })
    },
    roleTypeId (id) {
      if (id !== '107') {
        this.currentIndex = 0
      }
    },
    roleDataList (data) {
      // 处理数据
      if (!this.dataInit) {
        this.dataInit = true
        this.dealWithAllMenusList()
      }
      this.roleDataList && this.roleDataList.length && this.roleDataList.forEach(item => {
        if (item.id === this.unifyId) {
          this.resource = item.childMenus || []
        }
      })
    }
  },
  methods: {

    // 新处理数据结束-----start-----
    // 处理数据
    dealWithAllMenusList () {
      // 进来就要把菜单数据处理下
      let menusList = this.roleDataList || []
      menusList.forEach((platform, platformIndex) => {
        // platform 顶级菜单，例如 服务一体化
        platform.childMenus && platform.childMenus.length && platform.childMenus.forEach((center, centerIndex) => {
          // center 一级菜单， 栗子：通用中心
          this.dealWithCenterMenus(center)
          // 如果是第一次format的话 handleType为add和back的权限要置为0 这是数据的问题 现在前端做下处理
          center.notFirstFormat = true
        })
      })
      this.dataInit = false
    },

    // 需要处理center及其以下的数据
    dealWithCenterMenus (center) {
      // xx中心一级菜单显示授权列表的个数
      center.centerShowMenusCount = 0
      // xx中心一级菜单显示的授权列表里面所有授权按钮的个数
      center.centerShowAuthCount = 0
      // xx中心一级菜单选中授权的个数
      center.centerCheckedCount = 0
      // centerCheckedCount 和 centerShowAuthCount是否相等，来判断全部显示是否勾选
      center.childMenus && center.childMenus.length && center.childMenus.forEach(level1 => {
        // level1 二级菜单， 栗子：通用中心--账号管理
        // 这一步，给level1添加了要展示的授权列表
        this.dealWithViewLevel1AuthList(level1)
        // 这一步，处理center和level1的选中授权个数和授权总个数
        this.dealWithViewRoleTypeData(center, level1)
      })
    },

    // 给level1增加授权数据list
    dealWithViewLevel1AuthList (menu) {
      let level1 = _.cloneDeep(menu)
      let authList = [level1]
      level1.childMenus && level1.childMenus.length && level1.childMenus.forEach(level2 => {
        if (level2.menuType === 2) {
          //  代表三级菜单是页面，四级是授权页面
          authList.push(_.cloneDeep(level2))
        } else {
          level2.childMenus && level2.childMenus.length && level2.childMenus.forEach((level3, level3Index) => {
            // level3四级菜单，目前的设计一定是页面
            level3.supName = level2.name // 显示会用到
            level3.selfIndex = level3Index // 显示会用到
            level3.supCount = level2.childMenus.length // 显示会用到
            authList.push(_.cloneDeep(level3))
          })
        }
      })
      menu.authList = authList
    },

    dealWithViewRoleTypeData (center, level1) {
      if (!center.notFirstFormat && (this.handleType === 'back' || this.handleType === 'add')) {
        // 在增加和收权的时候接口返回的是全选中，要处理成全不选中
        level1.isSelected = 0
      }

      if (level1.isSelected === 1) {
        // 代表二级是选中的，list个数+1
        center.centerShowMenusCount += 1
      }
      // level1CheckedCount和level1AllAuthCount 是否相等来判断二级菜单是否全选
      // 二级菜单选中授权的个数
      level1.checkedCount = 0
      // 二级菜单所有授权按钮的个数
      level1.allAuthCount = 0

      // checkedRoleTypeCount1 和 roleTypeCount1 来判断表头是否全选
      level1.checkedRoleTypeCount1 = 0
      level1.checkedRoleTypeCount2 = 0
      level1.checkedRoleTypeCount3 = 0
      level1.checkedRoleTypeCount4 = 0
      level1.checkedRoleTypeCount5 = 0
      level1.roleTypeCount1 = 0
      level1.roleTypeCount2 = 0
      level1.roleTypeCount3 = 0
      level1.roleTypeCount4 = 0
      level1.roleTypeCount5 = 0

      level1.authList && level1.authList.length && level1.authList.forEach(authMenu => {
        if (authMenu.menuType === 2) {
          // 这个判断是因为第一个元素是level1，有可能不是页面
          this.dealWithAuthMenu(center, level1, authMenu)
          level1.checkedCount += authMenu.checkedCount
          level1.allAuthCount += authMenu.allAuthCount
        }
      })
    },

    dealWithAuthMenu (center, level1, authMenu) {
      if (!center.notFirstFormat && (this.handleType === 'back' || this.handleType === 'add')) {
        // 在增加和收权的时候接口返回的是全选中，要处理成全不选中
        authMenu.isSelected = 0
      }

      if (level1.isSelected === 1) {
        // 显示的授权列表里面所有授权按钮的个数
        center.centerShowAuthCount += authMenu.childMenus ? authMenu.childMenus.length : 0
      }
      // title前的勾选按钮是否选中，由 checkedCount和allAuthCount 确定
      // 已选中个数
      authMenu.checkedCount = 0
      // 总授权个数
      authMenu.allAuthCount = 0

      authMenu.roleTypeCount1 = 0
      authMenu.roleTypeCount2 = 0
      authMenu.roleTypeCount3 = 0
      authMenu.roleTypeCount4 = 0
      authMenu.roleTypeCount5 = 0

      // authMenu.childMenus 都是授权按钮
      authMenu.childMenus && authMenu.childMenus.length && authMenu.childMenus.forEach(menu => {
        if (!center.notFirstFormat && (this.handleType === 'back' || this.handleType === 'add')) {
        // 在增加和收权的时候接口返回的是全选中，要处理成全不选中
          menu.isSelected = 0
        }
        authMenu.allAuthCount++
        if (menu.roleType === 1) {
          level1.roleTypeCount1++
          authMenu.roleTypeCount1++
        }
        if (menu.roleType === 2) {
          level1.roleTypeCount2++
          authMenu.roleTypeCount2++
        }
        if (menu.roleType === 3) {
          level1.roleTypeCount3++
          authMenu.roleTypeCount3++
        }
        if (menu.roleType === 4) {
          level1.roleTypeCount4++
          authMenu.roleTypeCount4++
        }
        if (menu.roleType === 5) {
          level1.roleTypeCount5++
          authMenu.roleTypeCount5++
        }

        if (menu.isSelected === 1) {
          // 按钮选中的处理
          if (menu.roleType === 1) {
            level1.checkedRoleTypeCount1++
          }
          if (menu.roleType === 2) {
            level1.checkedRoleTypeCount2++
          }
          if (menu.roleType === 3) {
            level1.checkedRoleTypeCount3++
          }
          if (menu.roleType === 4) {
            level1.checkedRoleTypeCount4++
          }
          if (menu.roleType === 5) {
            level1.checkedRoleTypeCount5++
          }
          // xx中心一级菜单选中授权的个数+1
          center.centerCheckedCount++
          authMenu.checkedCount++
        }
      })
    },

    // 处理中部全部显示按钮
    checkAllMenu (center, centerIndex, event) {
      if (center.disabled) {
        return false
      }
      let checked = center.childMenus.length === center.centerShowMenusCount
      if (checked) {
        // 对应中心展示的授权按钮个数和选中的授权按钮个数也要归零
        center.centerShowAuthCount = 0
        center.centerCheckedCount = 0

        // 代表已经是全选，现在要勾选掉全选，其中的授权选中状态也要去掉
        center.childMenus && center.childMenus.length && center.childMenus.forEach(level1 => {
          level1.isSelected = 0
          level1.checkedRoleTypeCount1 = 0
          level1.checkedRoleTypeCount2 = 0
          level1.checkedRoleTypeCount3 = 0
          level1.checkedRoleTypeCount4 = 0
          level1.checkedRoleTypeCount5 = 0
          level1.checkedCount = 0

          level1.authList && level1.authList.length && level1.authList.forEach(authMenu => {
            if (authMenu.menuType === 2) {
              authMenu.checkedCount = 0
              authMenu.childMenus && authMenu.childMenus.length && authMenu.childMenus.forEach(menu => {
                // 授权按钮改为未选中
                menu.isSelected = 0
              })
            }
          })
        })
        center.centerShowMenusCount = 0
      } else {
        // 展示即可，其中的授权选中状态不必改变
        // 对应中心展示的授权按钮总个数要改变
        center.centerShowAuthCount = 0
        center.childMenus && center.childMenus.length && center.childMenus.forEach(level1 => {
          level1.isSelected = 1
          level1.authList && level1.authList.length && level1.authList.forEach(authMenu => {
            if (authMenu.menuType === 2) {
              center.centerShowAuthCount += authMenu.childMenus ? authMenu.childMenus.length : 0
            }
          })
        })
        center.centerShowMenusCount = center.childMenus ? center.childMenus.length : 0
      }
      this.$set(this.resource, centerIndex, center)
      // center = center
    },

    // 选中二级菜单
    checkLevel1Menu (level1, center, centerIndex) {
      // 显示某个模块菜单或者隐藏某个模块菜单并取消权限选中

      if (center.disabled) {
        return false
      }

      if (level1.isSelected === 1) {
        // 已经是选中状态，要隐藏，同时取消授权选中状态
        level1.isSelected = 0
        level1.checkedRoleTypeCount1 = 0
        level1.checkedRoleTypeCount2 = 0
        level1.checkedRoleTypeCount3 = 0
        level1.checkedRoleTypeCount4 = 0
        level1.checkedRoleTypeCount5 = 0
        level1.checkedCount = 0

        level1.authList && level1.authList.length && level1.authList.forEach(authMenu => {
          if (authMenu.menuType === 2) {
            authMenu.checkedCount = 0
            // 显示的授权总个数减去
            center.centerShowAuthCount -= authMenu.childMenus ? authMenu.childMenus.length : 0
            authMenu.childMenus && authMenu.childMenus.length && authMenu.childMenus.forEach(menu => {
              // 授权按钮改为未选中
              if (menu.isSelected) {
                menu.isSelected = 0
                center.centerCheckedCount--
              }
            })
          }
        })
        // 展示的少一个
        center.centerShowMenusCount -= 1
      } else {
        // 展示即可，其中的授权选中状态不必改变
        level1.isSelected = 1
        level1.authList && level1.authList.length && level1.authList.forEach(authMenu => {
          if (authMenu.menuType === 2) {
            // 对应中心展示的授权按钮总个数要改变
            center.centerShowAuthCount += authMenu.childMenus ? authMenu.childMenus.length : 0
          }
        })
        center.centerShowMenusCount += 1
      }
      this.$set(this.resource, centerIndex, center)
    },

    // 全部勾选的逻辑处理
    changeAllRoleType (center, centerIndex, event) {
      if (center.disabled) {
        return false
      }

      let checked = center.centerShowAuthCount > 0 && center.centerShowAuthCount === center.centerCheckedCount
      if (checked) {
        // 代表已经是勾选，要处理成全部不勾选
        center.centerCheckedCount = 0
        // 代表已经是全选，现在要勾选掉全选，其中的授权选中状态也要去掉
        center.childMenus && center.childMenus.length && center.childMenus.forEach(level1 => {
          if (level1.isSelected === 1) {
            level1.checkedRoleTypeCount1 = 0
            level1.checkedRoleTypeCount2 = 0
            level1.checkedRoleTypeCount3 = 0
            level1.checkedRoleTypeCount4 = 0
            level1.checkedRoleTypeCount5 = 0
            level1.checkedCount = 0

            level1.authList && level1.authList.length && level1.authList.forEach(authMenu => {
              if (authMenu.menuType === 2) {
                authMenu.checkedCount = 0
                authMenu.childMenus && authMenu.childMenus.length && authMenu.childMenus.forEach(menu => {
                // 授权按钮改为未选中
                  menu.isSelected = 0
                })
              }
            })
          }
        })
      } else {
        // 要处理全部勾选
        center.centerCheckedCount = 0
        center.childMenus && center.childMenus.length && center.childMenus.forEach(level1 => {
          if (level1.isSelected === 1) {
            // 在展示的里面进行选中全部授权，之前的授权计数要初始化
            level1.checkedRoleTypeCount1 = 0
            level1.checkedRoleTypeCount2 = 0
            level1.checkedRoleTypeCount3 = 0
            level1.checkedRoleTypeCount4 = 0
            level1.checkedRoleTypeCount5 = 0
            level1.checkedCount = 0

            level1.authList && level1.authList.length && level1.authList.forEach(authMenu => {
              if (authMenu.menuType === 2) {
                authMenu.checkedCount = 0
                authMenu.childMenus && authMenu.childMenus.length && authMenu.childMenus.forEach(menu => {
                  // 授权按钮改为选中
                  menu.isSelected = 1
                  if (menu.isSelected === 1) {
                    // 按钮选中的处理
                    if (menu.roleType === 1) {
                      level1.checkedRoleTypeCount1++
                    }
                    if (menu.roleType === 2) {
                      level1.checkedRoleTypeCount2++
                    }
                    if (menu.roleType === 3) {
                      level1.checkedRoleTypeCount3++
                    }
                    if (menu.roleType === 4) {
                      level1.checkedRoleTypeCount4++
                    }
                    if (menu.roleType === 5) {
                      level1.checkedRoleTypeCount5++
                    }
                    // xx中心一级菜单选中授权的个数+1
                    center.centerCheckedCount++
                    authMenu.checkedCount++
                  }
                })
                level1.checkedCount += authMenu.checkedCount
              }
            })
          }
        })
      }
      this.$set(this.resource, centerIndex, center)
    },

    // 列的勾选逻辑处理
    changedColRoleType (tp, level1, center, centerIndex, event) {
      if (center.disabled) {
        return false
      }
      if (!level1[`roleTypeCount${tp}`] || level1[`roleTypeCount${tp}`] === 0) {
        return false
      }
      let roleTypeCount = level1[`roleTypeCount${tp}`]
      let checkedRoleTypeCount = level1[`checkedRoleTypeCount${tp}`]

      let checked = roleTypeCount === checkedRoleTypeCount

      if (checked) {
        // 代表已经是选中，要处理成选不中啊
        level1.authList && level1.authList.length && level1.authList.forEach(authMenu => {
          if (authMenu.menuType === 2) {
            authMenu.childMenus && authMenu.childMenus.length && authMenu.childMenus.forEach(menu => {
              // 对应的授权按钮改为未选中
              if (menu.isSelected === 1 && menu.roleType === tp) {
                menu.isSelected = 0
                level1[`checkedRoleTypeCount${tp}`]--
                // xx中心一级菜单选中授权的个数-1
                center.centerCheckedCount--
                authMenu.checkedCount--
                level1.checkedCount--
              }
            })
          }
        })
        if (this.handleType !== 'back' && tp === 1) {
          // 代表是可见，且不是收权，其他权限也要处理成未选中
          level1.authList && level1.authList.length && level1.authList.forEach(authMenu => {
            if (authMenu.menuType === 2) {
              authMenu.childMenus && authMenu.childMenus.length && authMenu.childMenus.forEach(menu => {
              // 对应的授权按钮改为未选中
                if (menu.isSelected === 1) {
                  menu.isSelected = 0
                  if (menu.roleType === 2) {
                    level1.checkedRoleTypeCount2--
                  }
                  if (menu.roleType === 3) {
                    level1.checkedRoleTypeCount3--
                  }
                  if (menu.roleType === 4) {
                    level1.checkedRoleTypeCount4--
                  }
                  if (menu.roleType === 5) {
                    level1.checkedRoleTypeCount5--
                  }
                  // xx中心一级菜单选中授权的个数-1
                  center.centerCheckedCount--
                  authMenu.checkedCount--
                  level1.checkedCount--
                }
              })
            }
          })
        }
      } else {
        if (this.handleType !== 'back' && tp !== 1) {
          let roleTypeCount1 = level1.roleTypeCount1
          let checkedRoleTypeCount1 = level1.checkedRoleTypeCount1
          if (roleTypeCount1 > 0 && roleTypeCount1 !== checkedRoleTypeCount1) {
            this.$alert('请先勾选可见权限！')
            event.target.checked = false
            return false
          }
        }

        // 处理成全部选中
        level1.authList && level1.authList.length && level1.authList.forEach(authMenu => {
          if (authMenu.menuType === 2) {
            authMenu.childMenus && authMenu.childMenus.length && authMenu.childMenus.forEach(menu => {
              // 对应的授权按钮改为未选中
              if (menu.isSelected === 0 && menu.roleType === tp) {
                menu.isSelected = 1
                level1[`checkedRoleTypeCount${tp}`]++
                // xx中心一级菜单选中授权的个数+1
                center.centerCheckedCount++
                authMenu.checkedCount++
                level1.checkedCount++
              }
            })
          }
        })
      }
      this.$set(this.resource, centerIndex, center)
    },

    // 选中具体收权按钮的操作
    checkAuthItem (it, authItem, level1, center, centerIndex, event) {
      if (center.disabled) {
        return false
      }

      let checked = it.isSelected === 1
      if (checked) {
        // 已选中，就要取消
        it.isSelected = 0
        level1[`checkedRoleTypeCount${it.roleType}`]--
        // xx中心一级菜单选中授权的个数-1
        center.centerCheckedCount--
        authItem.checkedCount--
        level1.checkedCount--
        if (it.roleType === 1 && this.handleType !== 'back') {
          // 代表是可见，且不是收权，其他权限也要处理成未选中
          if (authItem.menuType === 2) {
            authItem.childMenus && authItem.childMenus.length && authItem.childMenus.forEach(menu => {
              // 对应的授权按钮改为未选中
              if (menu.isSelected === 1) {
                menu.isSelected = 0
                if (menu.roleType === 2) {
                  level1.checkedRoleTypeCount2--
                }
                if (menu.roleType === 3) {
                  level1.checkedRoleTypeCount3--
                }
                if (menu.roleType === 4) {
                  level1.checkedRoleTypeCount4--
                }
                if (menu.roleType === 5) {
                  level1.checkedRoleTypeCount5--
                }
                // xx中心一级菜单选中授权的个数-1
                center.centerCheckedCount--
                authItem.checkedCount--
                level1.checkedCount--
              }
            })
          }
        }
      } else {
        // 选中处理
        if (this.handleType === 'back' || it.roleType === 1) {
          it.isSelected = 1
          level1[`checkedRoleTypeCount${it.roleType}`]++
          // xx中心一级菜单选中授权的个数+1
          center.centerCheckedCount++
          authItem.checkedCount++
          level1.checkedCount++
        } else {
          // 要先判断 可见是否选中
          let roleType1HasSelected = false
          authItem.childMenus && authItem.childMenus.length && authItem.childMenus.forEach(auth => {
            if (auth.roleType === 1 && auth.isSelected === 1) {
              roleType1HasSelected = true
            }
          })

          if (roleType1HasSelected) {
            it.isSelected = 1
            level1[`checkedRoleTypeCount${it.roleType}`]++
            // xx中心一级菜单选中授权的个数+1
            center.centerCheckedCount++
            authItem.checkedCount++
            level1.checkedCount++
          } else {
            this.$alert('请先勾选可见权限！')
          }
        }
      }
      this.$set(this.resource, centerIndex, center)
    },
    // 每一行前面的勾选框事件
    checkAuthRow (authItem, level1, center, centerIndex, event) {
      if (center.disabled) {
        return false
      }

      let checked = false
      if (authItem.menuType === 2) {
        // 代表是页面
        checked = authItem.allAuthCount > 0 && authItem.checkedCount === authItem.allAuthCount
      } else {
        // 代表是文件夹，也就是二级页面
        checked = level1.allAuthCount > 0 && level1.checkedCount === level1.allAuthCount
      }
      if (checked) {
        // 已经全选中，要取消勾选
        if (authItem.menuType === 2) {
          // 页面处理
          authItem.childMenus && authItem.childMenus.length && authItem.childMenus.forEach(menu => {
            // 对应的授权按钮改为未选中
            menu.isSelected = 0
            if (menu.roleType === 1) {
              level1.checkedRoleTypeCount1--
            }
            if (menu.roleType === 2) {
              level1.checkedRoleTypeCount2--
            }
            if (menu.roleType === 3) {
              level1.checkedRoleTypeCount3--
            }
            if (menu.roleType === 4) {
              level1.checkedRoleTypeCount4--
            }
            if (menu.roleType === 5) {
              level1.checkedRoleTypeCount5--
            }
            // xx中心一级菜单选中授权的个数-1
            center.centerCheckedCount--
            authItem.checkedCount--
            level1.checkedCount--
          })
        } else {
          // 二级菜单处理,已经选中，要取消勾选
          level1.authList && level1.authList.length && level1.authList.forEach(authMenu => {
            if (authMenu.menuType === 2) {
              authMenu.childMenus && authMenu.childMenus.length && authMenu.childMenus.forEach(menu => {
                // 对应的授权按钮改为未选中
                menu.isSelected = 0
                if (menu.roleType === 1) {
                  level1.checkedRoleTypeCount1--
                }
                if (menu.roleType === 2) {
                  level1.checkedRoleTypeCount2--
                }
                if (menu.roleType === 3) {
                  level1.checkedRoleTypeCount3--
                }
                if (menu.roleType === 4) {
                  level1.checkedRoleTypeCount4--
                }
                if (menu.roleType === 5) {
                  level1.checkedRoleTypeCount5--
                }
                // xx中心一级菜单选中授权的个数-1
                center.centerCheckedCount--
                authMenu.checkedCount--
                level1.checkedCount--
              })
            }
          })
        }
      } else {
        // 进行勾选
        if (authItem.menuType === 2) {
          // 页面处理
          authItem.childMenus && authItem.childMenus.length && authItem.childMenus.forEach(menu => {
            if (menu.isSelected === 0) {
              menu.isSelected = 1
              if (menu.roleType === 1) {
                level1.checkedRoleTypeCount1++
              }
              if (menu.roleType === 2) {
                level1.checkedRoleTypeCount2++
              }
              if (menu.roleType === 3) {
                level1.checkedRoleTypeCount3++
              }
              if (menu.roleType === 4) {
                level1.checkedRoleTypeCount4++
              }
              if (menu.roleType === 5) {
                level1.checkedRoleTypeCount5++
              }
              // xx中心一级菜单选中授权的个数+1
              center.centerCheckedCount++
              authItem.checkedCount++
              level1.checkedCount++
            }
          })
        } else {
          // 二级菜单处理
          level1.authList && level1.authList.length && level1.authList.forEach(authMenu => {
            if (authMenu.menuType === 2) {
              authMenu.childMenus && authMenu.childMenus.length && authMenu.childMenus.forEach(menu => {
                // 对应的授权按钮改为选中
                if (menu.isSelected === 0) {
                  menu.isSelected = 1
                  if (menu.roleType === 1) {
                    level1.checkedRoleTypeCount1++
                  }
                  if (menu.roleType === 2) {
                    level1.checkedRoleTypeCount2++
                  }
                  if (menu.roleType === 3) {
                    level1.checkedRoleTypeCount3++
                  }
                  if (menu.roleType === 4) {
                    level1.checkedRoleTypeCount4++
                  }
                  if (menu.roleType === 5) {
                    level1.checkedRoleTypeCount5++
                  }
                  // xx中心一级菜单选中授权的个数+1
                  center.centerCheckedCount++
                  authMenu.checkedCount++
                  level1.checkedCount++
                }
              })
            }
          })
        }
      }
      this.$set(this.resource, centerIndex, center)
    },
    changeCenter (index, item) {
      if (item.disabled) {
        return false
      }
      this.currentIndex = index
    },
    // 新处理数据结束-----end-----

    // 选择结果返回的函数
    getResultData () {
      let list = this.roleDataList || []
      let idArr = []
      let roleTypeId = this.roleTypeId
      list.length && list.forEach(source => {
        source.childMenus.length && source.childMenus.forEach((center, index) => {
          if (roleTypeId && roleTypeId === '107') {
            // 角色类别为组织时 只可操作组织中心的权限配置
            if (center.id === 80) {
              center.disabled = false
              this.currentIndex = index
            } else {
              center.disabled = true
            }
          } else if (roleTypeId && roleTypeId === '101') {
            // 角色类别为项目时 全部可操作
            center.disabled = false
          } else {
            center.disabled = false
          }

          // 取到选中的授权id
          center.childMenus && center.childMenus.length && center.childMenus.forEach(level1 => {
            level1.authList && level1.authList.length && level1.authList.forEach(authMenu => {
              if (authMenu.menuType === 2) {
                // 为页面类型，才有授权
                authMenu.childMenus && authMenu.childMenus.length && authMenu.childMenus.forEach(menu => {
                  if (menu.isSelected) {
                    idArr.push(menu.id)
                  }
                })
              }
            })
          })
        })
      })
      return idArr
    }
  }
}
</script>

<style lang="scss" scoped>
.mg_0 {
  margin: 0;
}
.pd_20 {
  padding: 20px;
}
.pl_20 {
  margin-left: 20px;
}
.inline-block {
  display: inline-block;
}
.v-middle {
  vertical-align: middle;
}
.role-permission-wrapper {
  padding: 10px;
  text-align: left;
  height: 100%;
  .common-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    .outer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid #aaa;
      background-color: #fff;
      .inner {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 4px;
        background-color: #7ac943;
      }
      .disabled {
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url('./images/checkbox-disabled.png');
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
    .label {
      padding-left: 10px;
    }
  }
  .item-name-wrapper {
    display: flex;
    .item-name {
      width: 112px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      background-color: #e3f0ff;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      cursor: pointer;
      margin-left: 10px;
      position: relative;
      .dot {
        position: absolute;
        top: 5px;
        right: 10px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background: #1b8cf2;
      }
      &.active {
        color: #fff;
        background: #1b8cf2;
        .dot {
          background: #fff;
        }
      }
      &.disabled {
        color: #333;
        background: #aaa;
        .dot {
          background: #fff;
        }
      }
    }
  }
  .item-list-wrapper {
    background: #fff;
    display: none;
    &.active {
      display: block;
    }
    .menu-wrapper {
      width: 780px;
      padding: 10px;
      border: 1px solid #eaeaea;
      border-radius: 5px;
      overflow: hidden;
      .menu-list {
        float: left;
        min-width: 80px;
        height: 36px;
        line-height: 36px;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid rgb(237, 236, 242);
        margin: 15px 0 0 20px;
        padding: 0 10px 0 40px;
        color: rgb(0, 0, 0);
        background: url(./images/invisible.png) 12px 8px no-repeat;
        background-size: 22px 22px;
      }
      .active {
        color: #fff;
        background: url(./images/visible.png) no-repeat 12px 8px #1b8cf2;
        background-size: 22px 22px;
      }
    }
    .title-wrapper {
      padding: 30px 20px 0;
    }
    .title {
      vertical-align: middle;
      margin: 0 15px;
    }
    .module-list {
      margin-bottom: 20px;
      .modules-header {
        background: #e3f0ff;
        display: flex;
        .module {
          border: 2px solid #fff;
          padding: 20px;
          display: flex;
          align-items: center;
          flex: 1;
          .module-c-box {
            width: 40px;
          }
          .module-name {
            flex: 1;
          }
          &.module-col {
            flex-direction: column;
          }
          &.module-0 {
            flex: 0 0 150px;
          }
          &.module-1 {
            flex: 0 0 120px;
          }
          &.module-2 {
            flex: 0 0 120px;
          }
          &.module-3 {
            flex: 0 0 200px;
          }
          &.module-4 {
            flex: 0 0 120px;
          }
        }
      }
      .modules {
        background: #fff;
        display: flex;
        &:nth-of-type(even) {
          background: #f2f2f2;
        }
        &:nth-of-type(odd) {
          background: #f8f8f8;
        }
        .module {
          border: 2px solid #fff;
          padding: 20px;
          display: flex;
          flex: 1;
          align-items: center;
          flex-wrap: wrap;
          .module-c-box {
            width: 40px;
          }
          .module-name {
            flex: 1;
          }
          &.module-col {
            flex-direction: column;
          }
          &.module-0 {
            flex: 0 0 150px;
          }
          &.module-1 {
            flex: 0 0 120px;
          }
          &.module-2 {
            flex: 0 0 120px;
          }
          &.module-3 {
            flex: 0 0 200px;
          }
          &.module-4 {
            flex: 0 0 120px;
          }
        }
        .role-permission-btn {
          display: inline-block;
          position: relative;
          margin-right: 0.5em;
          margin-bottom: 14px;
          line-height: 2.4em;
          text-align: center;
          min-width: 80px;
          padding: 0 15px;
          height: 35px;
          border-radius: 4px;
          border: 1px solid #aaaaaa;
          background-color: transparent;
          color: #aaa;
          cursor: pointer;
          .role-permission-text {
            white-space: nowrap;
          }
          &.selected {
            border: 1px solid #1b8cf2;
            color: #1b8cf2;
            .role-permission-text {
              color: #1b8cf2;
            }
            .role-permission-icon {
              background-image: url(./images/choosed.png);
              position: absolute;
              right: -1px;
              bottom: -1px;
              width: 18px;
              height: 12px;
              background-size: contain;
            }
          }
        }
      }
    }
  }
}
</style>
