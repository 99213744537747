var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "right-float-wrapper" }, [
    _c(
      "div",
      { staticClass: "iot-community-tree", class: { "view-open": _vm.open } },
      [
        _c(
          "div",
          { attrs: { id: "community-select-wrapper" } },
          [
            _c("div", { attrs: { id: "community-name-wrapper" } }, [
              _vm._m(0),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchFieldText,
                    expression: "searchFieldText",
                  },
                ],
                attrs: {
                  id: "community-search-field",
                  autocomplete: "off",
                  type: "text",
                  placeholder: "输入搜索选择项目",
                },
                domProps: { value: _vm.searchFieldText },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.searchFieldText = $event.target.value
                  },
                },
              }),
            ]),
            _c("v-tree", {
              ref: "tree",
              attrs: {
                id: "community-tree",
                nodeKey: _vm.nodeKey,
                data: _vm.communityList,
                defaultProps: _vm.treeProps,
                filterNodeMethod: _vm.filterNode,
                highlightCurrent: true,
                defaultExpandedKeys: _vm.defaultExpandedKeys,
              },
              on: { nodeClick: _vm.nodeClick },
            }),
          ],
          1
        ),
        _c(
          "a",
          {
            staticClass: "floating-open",
            class: { "floatin-open-show": !_vm.open },
            on: { click: _vm.actionOpenFloating },
          },
          [
            _c("img", {
              attrs: { src: require("./images/iot-community-select.png") },
            }),
          ]
        ),
        _c(
          "a",
          {
            staticClass: "floating-close",
            class: { "floatin-close-show": _vm.open },
            on: { click: _vm.actionCloseFloating },
          },
          [
            _c("img", {
              attrs: { src: require("./images/iot-community-select.png") },
            }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { id: "community-search" } }, [
      _c("img", {
        attrs: { src: require("./images/iot-community-search.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }