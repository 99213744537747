var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("form-panel", { attrs: { form: _vm.form, hasHeader: false } }, [
        _c("div", { staticClass: "main" }, [
          _c(
            "div",
            { staticClass: "box" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "项目名称",
                    prop: "projectName",
                    rules: [
                      {
                        required: true,
                        message: "请填写项目名称",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      maxlength: 30,
                      width: _vm.width,
                      placeholder: "请按标准项目名称填写,不超过30字符",
                    },
                    model: {
                      value: _vm.form.projectName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "projectName", $$v)
                      },
                      expression: "form.projectName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "项目区划",
                    rules: [
                      {
                        type: "array",
                        required: true,
                        message: "请选择项目区划",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("area-select", {
                    staticStyle: { display: "inline-block" },
                    attrs: {
                      getChinaAreaTree1: _vm.getChinaAreaTree,
                      width: _vm.width,
                      extraClass: "projectArea",
                      codes: _vm.selectedArea,
                      "detail-addr": false,
                    },
                    on: {
                      "update:codes": function ($event) {
                        _vm.selectedArea = $event
                      },
                      change: _vm.change,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "详细地址",
                    prop: "projectAddress",
                    rules: [
                      {
                        required: true,
                        message: "请填写详细地址",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("v-textarea", {
                    attrs: {
                      maxlength: 50,
                      width: _vm.width,
                      rows: 2,
                      placeholder:
                        "请输入详细地址信息,如街道、门牌号、小区、楼栋号、单元等信息,不超过50字符",
                    },
                    model: {
                      value: _vm.form.projectAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "projectAddress", $$v)
                      },
                      expression: "form.projectAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "项目邮编",
                    prop: "projectCode",
                    rules: [
                      {
                        required: true,
                        message: "请填写项目邮编",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      maxlength: 6,
                      width: _vm.width,
                      placeholder: "请输入项目邮编",
                    },
                    model: {
                      value: _vm.form.projectCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "projectCode", $$v)
                      },
                      expression: "form.projectCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "项目类型",
                    rules: [
                      {
                        type: "array",
                        required: true,
                        message: "请选择项目类型",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("v-select", {
                    attrs: { width: _vm.width, options: _vm.statusOps },
                    model: {
                      value: _vm.form.projectType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "projectType", $$v)
                      },
                      expression: "form.projectType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "建筑面积" } },
                [
                  _c("v-input", {
                    attrs: {
                      width: _vm.width,
                      placeholder: "0.00(单位:平方米)",
                      maxlength: 11,
                    },
                    model: {
                      value: _vm.form.buildingArea,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "buildingArea", $$v)
                      },
                      expression: "form.buildingArea",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "交付户数" } },
                [
                  _c("v-input", {
                    attrs: {
                      width: _vm.width,
                      placeholder: "0(单位:户)",
                      maxlength: 10,
                    },
                    model: {
                      value: _vm.form.liveNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "liveNumber", $$v)
                      },
                      expression: "form.liveNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "入住户数" } },
                [
                  _c("v-input", {
                    attrs: {
                      width: _vm.width,
                      placeholder: "0(单位:户)",
                      maxlength: 10,
                    },
                    model: {
                      value: _vm.form.residentNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "residentNumber", $$v)
                      },
                      expression: "form.residentNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "box" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所属企业",
                    prop: "affiliatedCompany",
                    rules: [
                      {
                        required: true,
                        message: "请填写所属企业",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      maxlength: 30,
                      width: _vm.width,
                      placeholder: "请填写营业执照上企业名称,不超过30字符",
                    },
                    model: {
                      value: _vm.form.affiliatedCompany,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "affiliatedCompany", $$v)
                      },
                      expression: "form.affiliatedCompany",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { height: "118px" },
                  attrs: {
                    label: "营业执照",
                    prop: "text",
                    rules: [
                      {
                        required: true,
                        message: "请上传营业执照",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("v-uploader", {
                    ref: "myUpload",
                    attrs: {
                      fileHeight: 112,
                      fileWidth: 112,
                      fileSize: 1,
                      extraData: _vm.uploadParams,
                      action: _vm.action,
                      multiple: false,
                      onlyImage: true,
                      limit: 1,
                      "auto-upload": true,
                      fileList: _vm.fileList,
                    },
                    on: {
                      "update:fileList": function ($event) {
                        _vm.fileList = $event
                      },
                      "update:file-list": function ($event) {
                        _vm.fileList = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "联系人",
                    prop: "contactPerson",
                    rules: [
                      {
                        required: true,
                        message: "请填写联系人",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      maxlength: 20,
                      width: _vm.width,
                      placeholder: "请输入联系人",
                    },
                    model: {
                      value: _vm.form.contactPerson,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contactPerson", $$v)
                      },
                      expression: "form.contactPerson",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "联系电话",
                    prop: "phoneNumber",
                    rules: [
                      {
                        required: true,
                        message: "请填写联系电话",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      maxlength: 11,
                      width: _vm.width,
                      placeholder: "请输入联系电话",
                    },
                    model: {
                      value: _vm.form.phoneNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phoneNumber", $$v)
                      },
                      expression: "form.phoneNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "验证码",
                    prop: "verificationCode",
                    rules: [
                      {
                        required: true,
                        message: "请填写验证码",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      maxlength: 6,
                      width: _vm.width,
                      placeholder: "请输入验证码",
                    },
                    model: {
                      value: _vm.form.verificationCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "verificationCode", $$v)
                      },
                      expression: "form.verificationCode",
                    },
                  }),
                  _c(
                    "span",
                    { staticClass: "code", on: { click: _vm.onSendCode } },
                    [_vm._v(_vm._s(_vm.sendCode))]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "附言" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      width: _vm.width,
                      rows: 4,
                      placeholder: "可选,不超过200字符",
                    },
                    model: {
                      value: _vm.form.postscript,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "postscript", $$v)
                      },
                      expression: "form.postscript",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "protocol" }, [
            !_vm.form.settlementAgreement
              ? _c("img", {
                  attrs: { src: require("./images/protocol.png"), alt: "" },
                  on: {
                    click: function ($event) {
                      _vm.form.settlementAgreement = true
                    },
                  },
                })
              : _c("img", {
                  attrs: {
                    src: require("./images/protocolSelect.png"),
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.form.settlementAgreement = false
                    },
                  },
                }),
            _vm._v("\n        已阅读并同意"),
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    _vm.protocolDialog = true
                  },
                },
              },
              [_vm._v("平台入驻协议")]
            ),
          ]),
          _c("div", { staticClass: "submit", on: { click: _vm.submit } }, [
            _vm._v("申请入驻"),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: { width: _vm.dialogWidth, visible: _vm.protocolDialog },
          on: {
            "update:visible": function ($event) {
              _vm.protocolDialog = $event
            },
            closed: function ($event) {
              _vm.protocolDialog = false
            },
          },
        },
        [_c("div", { domProps: { innerHTML: _vm._s(_vm.protocol.protocol) } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }