<template>
  <div class="importTemplate-container">
    <form-panel class="form-container"
                ref="formPanel">
      <col2-detail :span='24'>
        <col2-block :title="title">
          <!-- <div v-if="title" class="cell-title"><span class="pre-point"></span>{{title}}</div> -->
          <div class="cell-box">
            <div class="space-left">
              <span>*</span>
              <label> {{ uploadLabel }}：</label>
            </div>
            <div class="space-right">
              <v-uploader ref="myUpload"
                          :action="uploadUrl"
                          :onlyImage="false"
                          :fileSize="maxFileSize"
                          :multiple="true"
                          :extraData="extraData"
                          :auto-upload="autoUpload"
                          :fileList.sync="fileList"
                          :limit="1"
                          :isOrder="true"
                          :isAlertErrorMsg="false"
                          :onError="onError"
                          :onSuccess="onSuccess"
                          @storageChange="storageChange">
              </v-uploader>
            </div>
            <div class="rightBtn"
                 v-if="downloadUrl">
              <v-button :text="downloadText"
                        @click="downloadTemplate"></v-button>
            </div>
          </div>
          <slot name="formContent"></slot>
          <div class="cell-box">
            <div class="space-left">
              <label>{{ remarkLabel }}</label>
            </div>
            <div class="space-right">
              <slot name="remark"></slot>
            </div>
          </div>
        </col2-block>
      </col2-detail>
      <template #footerSlot>
        <v-button text="保存"
                  type="success"
                  @click="saveFile"></v-button>
      </template>
    </form-panel>
  </div>
</template>
<script>
import { vUploader } from 'components/control/index'
import { downloadHelper } from 'common/utils'
import { Col2Block, Col2Detail } from '../col2-detail/index'

// @group 业务组件
// @title ImportTemplate 导入
export default {
  name: 'import-template',
  components: {
    vUploader,
    Col2Block,
    Col2Detail
  },
  props: {
    // 导入Url
    uploadUrl: String,
    // 下载模板Url
    downloadUrl: String,
    // 标题
    title: String,
    // 下载按钮文字描述
    downloadText: {
      type: String,
      // `'下载导入模板'`
      default: '下载导入模板'
    },
    // 上传文件大小 M
    maxFileSize: {
      type: Number,
      // `'10'`
      default: 10
    },
    // 是否自动上传
    autoUpload: {
      type: Boolean,
      // `'false'`
      default: false
    },
    // label
    uploadLabel: {
      type: String,
      // `'下载导入模板'`
      default: '请选择导入文件'
    },
    // 下载模板方式
    downloadType: {
      type: String,
      // `'default'`  / `'key'`
      default: 'default'
    },
    // 保存url, autoUpload为true时必填
    submitUrl: String,
    //  上传时附带的额外参数
    extraData: {
      type: Object,
      // `{}`
      default: () => {
        return {
          type: ''
        }
      }
    },
    remarkLabel: {
      type: String,
      default: '备注'
    },
    // 导入前的回调函数
    saveBefore: Function
  },
  data () {
    return {
      fileList: [],
      storageList: [],
      response: {}
    }
  },
  methods: {
    previous () {
      this.$router.go(-1)
    },
    async saveFile () {
      let result = this.saveBefore ? await this.saveBefore() : true
      if (!result) {
        return
      }
      if (this.autoUpload) {
        if (this.fileList.length && this.fileList[0].url) {
          this.$emit('savingFile')
          this.$axios.post(this.submitUrl, this.response).then(res => {
            this.$emit('savingRequestFinished', res)
            if (res.status === '112') {
              if (res.msg) {
                this.submitResult(res)
              } else {
                this.$message.success('导入成功')
                this.$emit('onSuccess', res)
              }
            } else if (res.status === '100') {
              this.$message({
                type: 'success',
                message: '操作成功',
                center: true
              })
            }
          })
        } else {
          this.$message({
            type: 'error',
            message: '请选择需要上传的文件',
            center: true
          })
        }
      } else {
        if (this.storageList.length > 0) {
          this.$emit('savingFile')
          this.$refs.myUpload.submitUpload()
        } else {
          this.$message({
            type: 'error',
            message: '请选择需要上传的文件',
            center: true
          })
          return false
        }
      }
    },
    async submitResult (res) {
      let isOk = await this.$confirm(res.msg)
      isOk && downloadHelper.downloadByLocation(res.data)
    },
    storageChange (item) {
      this.storageList = item
    },
    async onError (response, file, fileList) {
      this.storageList = []
      this.$emit('savingRequestFinished', response)
      if (response.status == 120) { // eslint-disable-line
        let d = response.data
        if (d.errorRows > 0 && d.uploadUrl !== '') {
          let msg = `本次成功导入${d.successRows}条，导入失败${d.errorRows}条。下载并修改导入失败数据后，可再次进行导入操作。`
          let isOk = await this.$confirm(msg)
          // isOk && (window.location.href = d.uploadUrl)
          isOk && downloadHelper.downloadByLocation(d.uploadUrl)
        } else {
          // await this.$alert('导入成功')
          await this.$alert(`本次成功导入${d.successRows}条，导入失败${d.errorRows}条。`)
          // @arg 导入成功callback function(response)
          this.$emit('onSuccess', response)
        }
      } else {
        this.$message({
          type: 'error',
          message: response.msg,
          center: true
        })
      }
    },
    downloadTemplate () {
      if (this.downloadType === 'default') {
        this.downloadUrl && downloadHelper.downloadByLocation(this.downloadUrl)
      } else if (this.downloadType === 'key') {
        downloadHelper.downloadByApi({
          exportUrl: this.downloadUrl
        }, (res) => {
          res.data && res.data.filePath && downloadHelper.downloadByLocation(res.data.filePath)
        })
      }
    },
    onSuccess (response, file, fileList) {
      this.$emit('savingRequestFinished', response)
      this.response = response
    }
  }
}
</script>
<style lang="scss" scoped>
.importTemplate-container {
  height: 100%;
}
.header {
  position: absolute;
  top: 20px;
  right: 20px;
  :not(:last-child) {
    margin-right: 8px;
  }
}
.cell-title {
  text-align: left;
  padding: 10px;
  display: flex;
  align-items: center;
  .pre-point {
    border-radius: 50%;
    background-color: #dce2e7;
    height: 5px;
    width: 5px;
    margin-right: 5px;
  }
}
.cell-box {
  display: flex;
  .space-left {
    min-width: 150px;
    text-align: right;
    span {
      color: red;
    }
    label {
      font-size: 14px;
      color: #333333;
      font-weight: 700;
    }
  }
  .space-right {
    padding-left: 20px;
  }
  .rightBtn {
    padding-left: 20px;
  }
}
.saveBtn {
  margin-left: 170px;
  margin-top: 20px;
  width: 140px;
}
</style>
