<template>
  <div class="v-control v-select-container">
    <label v-if="label" v-text="label"></label>
    <el-select ref="select" v-model="model" :style="{ width: width + 'px' }" :placeholder="placeholder" :disabled="disabled" :filterable="filterable" :clearable="clearable">
      <span v-if="!optionGroup">
        <el-option v-for="option in options" :key="option.value" :value="option.value" :label="option.text" :disabled="option.disabled">
          <slot :optionData="option"></slot>
        </el-option>
      </span>
      <span v-if="optionGroup">
        <span v-for="group in options" :key="group.value">
          <el-option-group v-if="group.options && group.options.length" :label="group.label">
            <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-option-group>
        </span>
      </span>
    </el-select>
  </div>
</template>
<script>
import Vue from 'vue'
import { Select, Option, OptionGroup } from 'element-ui'

Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)

// @group 基础组件
// @title Select 下拉选择框
export default {
  name: 'v-select',
  props: {
    // 绑定值
    value: [Boolean, String, Number],
    // 下拉选择框左侧label
    label: String,
    // 下拉选项分组
    optionGroup: {
      type: Boolean,
      // `false`
      default: false
    },
    // 下拉选项， 对象数组，value：选中的值
    options: {
      type: Array,
      // `[]`
      default () {
        return []
      }
    },
    // 是否禁用
    disabled: Boolean,
    // 下拉选择框宽度
    width: {
      type: Number,
      // `144`
      default: 144
    },
    // 下拉选择框占位文本
    placeholder: String,
    // 异步获取下拉选项
    asyncOptions: {
      type: Boolean,
      // `false`
      default: false
    },
    // 是否可搜索
    filterable: Boolean,
    // 是否可以清空选项
    clearable: Boolean,
    // 指定变量类型 'string'、'string,number'
    valueType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      tempModel: null,
      model: this.value
    }
  },
  created () {
    if (this.asyncOptions && this.findIndex() === -1) {
      this.exchange()
      const unwatch = this.$watch('options', (newValue) => {
        this.exchange()
        unwatch()
      })
    }
  },
  watch: {
    value (newValue) {
      /** 解决选择框无法清空的问题 */
      let type = typeof newValue
      let typeList = this.valueType ? this.valueType.split(',') : []
      if (this.valueType && !typeList.includes(type)) {
        if (!newValue) {
          this.model = ''
        }
        return
      }
      /** end */
      if (this.tempModel === null) {
        this.model = newValue
        let newOption
        if (!this.optionGroup) {
          newOption = this.options.find(option => {
            return option.value === newValue
          })
        }
        // 选中值发生变化时触发
        // @arg 当前选中的值， 当前选中的值对应的下拉选项
        this.$emit('change', newValue, newOption)
      }
    },
    model (newValue) {
      if (this.tempModel === null) {
        // v-model event
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    // @vuese
    // 使 select 获取焦点
    focus () {
      this.$refs.select.focus()
    },
    // @vuese
    // 使 select 失去焦点
    blur () {
      this.$refs.select.blur()
    },
    exchange () {
      [this.tempModel, this.model] = [this.model, this.tempModel]
    },
    onChange (val) {
    },
    findIndex () {
      const index = this.options.findIndex((option) => {
        return option.value === this.value
      })
      return index
    }
  }
}
</script>
<style scoped lang="scss">
  .v-select-container {
    display: inline-block;
    label {
      margin-right: 5px;
      color: #333333;
    }
  }
</style>
