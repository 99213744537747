var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chosen-list-panel" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-row",
          { ref: "elrow", attrs: { gutter: 20 } },
          _vm._l(_vm.data, function (item, index) {
            return _c(
              "el-col",
              { key: index, attrs: { span: 6 } },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: item.text,
                      placement: "top",
                    },
                  },
                  [
                    _c("div", { staticClass: "item" }, [
                      _c("span", {
                        staticClass: "text",
                        domProps: { textContent: _vm._s(item.text) },
                      }),
                      !_vm.disabled
                        ? _c(
                            "div",
                            {
                              staticClass: "close",
                              on: {
                                click: function ($event) {
                                  return _vm.remove(index)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-error" }),
                              _c("div", { staticClass: "white" }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "footer" }, [
      _c("div", [
        _c("span", [_vm._v("已选择:")]),
        _c("strong", { domProps: { textContent: _vm._s(_vm.data.length) } }),
      ]),
      !_vm.disabled
        ? _c("div", { staticClass: "button", on: { click: _vm.select } }, [
            _c("img", {
              attrs: { src: require("./images/icon-select.png"), alt: "" },
            }),
            _vm._v("\n      点击选择\n    "),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }