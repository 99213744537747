var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-control v-textarea-container" },
    [
      _c("el-input", {
        style: { width: _vm.width + "px" },
        attrs: {
          type: "textarea",
          disabled: _vm.disabled,
          placeholder: _vm.placeholder,
          rows: _vm.rows,
          maxlength: _vm.maxlength,
          minlength: _vm.minlength,
        },
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      }),
      _vm.showCounter
        ? _c("div", {
            staticClass: "counter",
            class: { highlight: _vm.highlight },
            domProps: { textContent: _vm._s(_vm.counter) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }