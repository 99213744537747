<template>
  <div class="top-container"
       v-show="themeSetFinish">
    <div class="left" style="flex-grow:1;">
      <!-- <div class="platform"
           @click="switchPlatform">
        <span class="platform-icon"
              :style="{ backgroundImage: 'url(' + theme.top_icon_arrow_left + ')' }"></span>
        <span class="platform-text"
              :style="{ color: theme.top_center_color }">{{ $t("switch") }}</span>
      </div> -->
      <div class="info">
        <img class="logo"
             :src="logoSrc" />
        <div class="version"
             v-text="version"></div>
      </div>

      <div class="left-item" v-if="worktopShow">
        <span class="btn" :class="{ 'btn-active': workTopActive }" @click="goWorktop">工作台</span>
        <span class="bottom-line" v-show="workTopActive && menuGroupType == 'left'"></span>
      </div>

      <div style="display: flex" ref="menuRoot">
        <template v-for="menuModule in menuModules">
          <!-- 当驾驶舱为多个时使用 -->
          <div class="left-item" v-if="menuModule.url === '/pm2/main' && menuModule.childMenus.length > 1" :key="menuModule.id" >
            <el-dropdown @command="menuModuleClick1">
              <div>
                <span class="btn" :class="{ 'btn-active JSC-active': (menuModule.id == $route.query.pid) && !workTopActive }">驾驶舱<i class="el-icon-arrow-down el-icon--right"></i></span>
                <span class="bottom-line" v-show="(menuModule.id == $route.query.pid) && !workTopActive "></span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in menuModule.childMenus" :command="item.childMenus[0].id" :key="'dropdown'+index"  @click="menuModuleClick(item)">
                  <span class="btn" >{{ item.name }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="left-item" v-else :key="menuModule.id" :class="{openClass:rootMenuSelectedId === menuModule.id}" @[handleType]="menuModuleClick(menuModule)">
            <span style="display: flex;align-items: flex-start">
              <span class="btn" :class="{ 'btn-active': (menuModule.id == $route.query.pid) && !workTopActive }">{{ menuModule.name }}</span>
              <i  v-if="menuGroupType == 'top'" class="w-iconfont w-icon-chevron-down"></i>
            </span>
            <span class="bottom-line" v-show="(menuModule.id == $route.query.pid) && !workTopActive && menuGroupType == 'left'"></span>
          </div>
        </template>
        <!-- 顶部导航 -->
        <menu-group-top
        v-if="menuGroupType === 'top'"
        ref="menuTopRef"
        />
      </div>

      <data-brain-list v-if="dataBrainShow" :dataBrainMenu="dataBrainMenu.childMenus" @statusChange="dataBrainStatusChange">
        <div class="data-brain" slot="dataBrain">
          <div class="left-item left-item-popover">
            <span class="name" :class="{ 'name-active': dataBrainStatus }">{{ dataBrainMenu.name }}</span>
          </div>
        </div>
      </data-brain-list>
      <div style="flex-grow:1" />
      <div style="flex-grow:1" />
      <v-input v-model="searchTextOut"
        :width="200"
        size="small"
        class="search"
        placeholder="搜索功能、用户"
        suffixIcon="el-icon-search"
        @focus="onFocus"
      />
     <div style="flex-grow:1" />

    </div>

    <div class="right">
      <div>
        <a v-if="pageDesignShow" style="margin-right:24px;text-decoration:none;" href="#/pageDesign">页面设计器</a>
      </div>

      <div class="export" v-if="exportShow" :class="{ 'right-item-active': (exportMenu.id == $route.query.pid) && !workTopActive }" @click="exportClick">
        <el-tooltip effect="light" :content="exportMenu.name" placement="bottom">
          <img :src="exportSrc" class="icon"/>
        </el-tooltip>
        <!-- <span>{{ exportMenu.name }}</span> -->
      </div>

      <div class="setting" v-if="settingShow" :class="{ 'right-item-active': (settingMenu.id == $route.query.pid) && !workTopActive }" @click="settingClick">
        <el-tooltip effect="light" :content="settingMenu.name" placement="bottom">
          <img :src="settingSrc" class="icon"/>
        </el-tooltip>
        <!-- <span>{{ settingMenu.name }}</span> -->
      </div>

      <div class="setting" @click="setClick">
        <img :src="settingSrc" class="icon"/>
      </div>

      <!-- 下载 -->
      <div v-if="qrcodeList && qrcodeList.length > 0">
        <qrcode-list :qrcodeList="qrcodeList">
          <div class="download" slot="qrcode">
            <el-tooltip effect="light" content="下载二维码" placement="bottom">
              <img :src="downIcon" class="icon"/>
              <!-- {{ $t("download") }} -->
            </el-tooltip>
          </div>
        </qrcode-list>
      </div>

      <!-- 帮助手册-->
      <div v-if="showDoc && dynamicParams && dynamicParams.showManual">
        <help-manual-list>
          <div class="help" slot="manual">
            <el-tooltip effect="light" content="帮助手册" placement="bottom">
              <img :src="helpIcon" class="icon"/>
            </el-tooltip>
          </div>
        </help-manual-list>
      </div>

	  <div class="line"></div>

      <!-- 用户信息 -->
      <option-list :userInfo="userInfo"
                   class="option-list-wrapper">
        <div class="user-info"
             slot="option">
          <img class="avatar"
               :src="avatarSrc" />
          <div class="wrapper">
            <div class="user-name"
                 v-text="userName"></div>
          </div>
        </div>
      </option-list>

      <!-- <div>
        <div class="help">{{ $t("help") }}</div>
      </div> -->

      <!-- 语言 -->
      <div class="lang"
           v-if="i18n">
        <el-dropdown class="lang-dropdown"
                     trigger="click"
                     @command="toggleLang">
          <div v-text="currentLang"></div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in langList"
                              :key="index"
                              :command="item.value">
              <v-button type="text"
                        :text="item.text"></v-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-dialog custom-class="search-wrapper"
               :fullscreen="true"
               :visible.sync="searchVisible">
      <div class="search-container">
        <div class="left-part">
          <div class="title-text">搜索范围</div>
          <div class="check-option-item"
               @click="checkSearchModel('1')">
            <img v-if="searchModel === '1'"
                 src="./images/checked.png"
                 alt="">
            <img v-else
                 src="./images/unchecked.png"
                 alt="">
            <div>功能</div>
          </div>
          <div class="check-option-item"
               @click="checkSearchModel('2')">
            <img v-if="searchModel === '2'"
                 src="./images/checked.png"
                 alt="">
            <img v-else
                 src="./images/unchecked.png"
                 alt="">
            <div>用户</div>
          </div>
          <!-- <div class="check-option-item"
               @click="checkSearchModel('3')">
            <img v-if="searchModel === '3'"
                 src="./images/checked.png"
                 alt="">
            <img v-else
                 src="./images/unchecked.png"
                 alt="">
            <div>组织</div>
          </div> -->
        </div>
        <div class="right-part">
          <v-input ref="searchInput"
                   :width="560"
                   class="search-input"
                   placeholder="请输入搜索内容"
                   v-model="searchText"
                   @input="search" />
          <div class="result-count">
            {{total}}个搜索结果
          </div>
          <div class="result-wrapper" @scroll="onScroll" ref="result_wrapper" v-show="loading">
            <ul class="func-result-list"
                v-if="searchModel === '1'">
              <li class="func-result-item"
                  v-for="(item, index) in resultList"  :key="`func_${index}`">
                <img v-if="item.appPackageIcon" class="func-icon"
                     :src="item.appPackageIcon"
                     alt="">
                <div class="content-text">
                  <div class="content-title" @click="menuItemLink(item)">{{item.name}}</div>
                  <div class="label-wrapper">
                    <span class="label" v-for="menu in item.menuList" :key="menu.id" @click="innerMenuLink(menu)">{{menu.name}}</span>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="user-result-list"
                v-if="searchModel === '2'">
              <li class="user-result-item"
                  v-for="(item, index) in resultList" :key="`user_${index}`" @click="userLink(item)">
                <div class="user-info">
                  <div class="user-name">
                    <span class="name">{{item.userName}}</span>
                    <span class="user-type">{{userSourceMap[item.userSource]}}</span>
                  </div>
                  <div class="phone">{{item.mobileNum}}</div>
                </div>
                <div class="address">{{item.address}}</div>
              </li>
            </ul>
            <div class="no-data-img" v-if="!resultList.length">
              <img src="./images/no_data.png" alt="">
              <div class="tip-text">没有找到相关内容</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import downSrc from './images/down.png'
import downIcon from './images/downIcon_white.png'
import helpIcon from './images/icon_help.png'
import settingSrc from './images/icon_setting.png'
import exportSrc from './images/icon_export.png'
import OptionList from './option-list'
import QrcodeList from './qrcode-list'
import helpManualList from './helpManual-list'
import DataBrainList from './data-brain-list'
import { mapGetters } from 'vuex'
import { localStorageHelper } from 'common/utils'
import _ from 'lodash'
import { searchURL } from './api'
import { userSourceMap } from 'views/user/ownerMgr/ownerMgrForm/map'
import Store from '@/store'
import { menuJump, internalMenuJump, findFirstMenu } from 'common/menuHandler'
import { Tooltip } from 'element-ui'
import MenuGroupTop from './menu-group-top'

export default {
  name: 'top',
  props: {
    logoSrc: String
  },
  data () {
    return {
      pageDesignShow: false,
      avatarSrc: '',
      userName: '',
      downSrc,
      index: 0,
      langList: [{
        text: '中文',
        value: 'zh'
      }, {
        text: 'English',
        value: 'en'
      }],
      searchText: '',
      searchTextOut: '',
      searchVisible: false,
      searchModel: '1', // 1: 功能 2: 用户 3: 组织
      resultList: [],
      total: 0,
      searchPageParams: {
        curPage: 1,
        pageSize: 20
      },
      userSourceMap,
      hasMore: true,
      loading: false,
      downIcon,
      helpIcon,
      settingSrc,
      exportSrc,
      menuModules: [],
      dataBrainStatus: false,
      dataBrainShow: false,
      dataBrainMenu: {},
      exportShow: false,
      exportMenu: {},
      settingShow: false,
      settingMenu: {},
      rootMenuSelectedId:"",
      showDoc: window.location.origin !== 'https://lc.zwpzy.com',
    }
  },
  created () {
    if (process.env.NODE_ENV === 'development') {
      this.pageDesignShow = true
    }
  },
  components: {
    Tooltip,
    OptionList,
    QrcodeList,
    helpManualList,
    DataBrainList,
    MenuGroupTop,
  },
  computed: {
    ...mapGetters('menuStore', {
      menuResourceList: 'getMenuResourceList'
    }),
    handleType(){
      return this.menuGroupType === 'top'?'mouseover':'click'
    },
    userInfo () {
      return this.$store.getters.getUserInfo
    },
    qrcodeList () {
      return this.$store.state.baseConfig.qrcodeList
    },
    i18n () {
      return this.$store.state.baseConfig.i18n
    },
    version () {
      return this.$store.state.baseConfig.version
    },
    currentLang () {
      const locale = this.$i18n.locale
      const langItem = this.langList.find((item) => {
        return locale === item.value
      })
      return langItem.text
    },
    theme () {
      return this.$store.state.theme
    },
    menuGroupType(){
      return this.$store.state.theme['menu-group-type'] || 'left'
    },
    themeSetFinish () {
      return this.$store.state.themeSetFinish
    },
    worktopShow () {
      const userType = this.userInfo.userType
      return userType === '106' || userType === '100' || userType === '102' || userType === '101'
    },
    workTopActive () {
      return this.$route.name === 'worktop'
    },
    dynamicParams () {
      return this.$store.state.baseConfig.dynamicParams
    }
  },
  watch: {
    menuResourceList (newVal) {
      const menuModules = []
      const cloneMenuModules = _.cloneDeep(newVal)
      cloneMenuModules.forEach(item => {
        if ('system_export' === item.specialNo) { // 导出中心
          this.exportMenu = item
          this.exportShow = true
        } else if ('system_setting' === item.specialNo) { // 系统设置
          this.settingMenu = item
          this.settingShow = true
        } else if ('data_brain' === item.specialNo) { // 数据大脑
          if (item.childMenus && item.childMenus.length) {
            this.dataBrainMenu = item
            this.dataBrainShow = true
          }
        } else {
          // 设置杏林湾小区驾驶舱查看条件：
          // 公司为 orgId (f53c1f01-6486-43cd-8298-0dbe2adaed07)且 只有杏林湾小区才可以查看 (orgId=d93ef0d8-647b-11eb-bdca-005056b13afd)d93ef0d8-647b-11eb-bdca-005056b13afd
          if (this.userInfo.orgId !== 'f53c1f01-6486-43cd-8298-0dbe2adaed07' && this.userInfo.orgId !== 'd93ef0d8-647b-11eb-bdca-005056b13afd') {
            if (item.url === '/pm2/main') {
              let childMenus = []
              item.childMenus.length > 0 && item.childMenus.forEach((ele) => {
                let menuItemChilen = findFirstMenu(ele) // 查找到最后一层菜单获取 url
                // 权限不足过滤杏林湾小区驾驶舱
                if (menuItemChilen.url !== "top-xlwcockpit") {
                  childMenus.push(ele)
                }
              })
              item.childMenus = [...childMenus]
            }
          }
          menuModules.push(item)
        }
      })
      this.menuModules = menuModules
    },
    userInfo (newValue) {
      this.avatarSrc = this.userInfo.headPicName
      this.userName = this.userInfo.userName
    }
  },
  methods: {
    // switchPlatform () {
    //   this.$router.push({
    //     name: 'selectPlatform'
    //   })
    // },
    dataBrainStatusChange (status) {
      this.dataBrainStatus = status
    },
    find (list, id) {
      let item = list.find((item) => {
        return item.id == id // eslint-disable-line
      })
      return item
    },
    toggleLang (lang) {
      if (this.$i18n.locale !== lang) {
        this.$i18n.locale = lang
        localStorageHelper.setItem('lang', lang)
      }
    },
    goWorktop () {
      this.$router.push({
        name: 'worktop'
      })
    },
    setClick () {
      this.$router.push({
        name: 'setList'
      })
    },
    async menuModuleClick (item) {
      console.log(this.handleType,item)
      this.rootMenuSelectedId = item.id  
      const menuItem = findFirstMenu(item)
     
      if (menuItem) {
        const { url, id } = menuItem
        if (this.$route.name === 'iframePanel' && this.$route.query.src === url) {
          return
        }
        let urlWhiteList = ['cockpitNeUrl', 'cockpitLanLvUrl']
        if (urlWhiteList.includes(url)) {
          const res = await this.handleCallGetSign()
          if (res.dtatus && res.status !== 100) {
            return
          } else {
            if(this.menuGroupType === 'left') {
              internalMenuJump(id)
            }
            return
          }
        } else if (url === 'top-xlwcockpit') { // 杏林湾驾驶舱
          // 设置杏林湾小区驾驶舱查看条件： 
          // 项目账号 (userType = 101) 且 只有杏林湾小区才可以查看 (communityId=d93ef0d8-647b-11eb-bdca-005056b13afd) 
          let mainUrl = 'http://hxyt.lcfw.co:8000/publish/840bc8e95db84add963d5e3fff32c0a5'
          window.open(mainUrl)
        } else {
          if(this.menuGroupType === 'left') {
            internalMenuJump(id)
          }
        }
      }
    },
    async menuModuleClick1 (id) {
      const menuInfo = this.$store.getters['menuStore/getMenuInfo'](id)
      const { item } = menuInfo
      const { url } = item
      let urlWhiteList = ['cockpitNeUrl', 'cockpitLanLvUrl']
      if (urlWhiteList.includes(url)) {
        const res = await this.handleCallGetSign()
        if (res.status && res.status !== 100) {
          return
        } else {
          internalMenuJump(id)
          return
        }
      }else if (url === 'top-xlwcockpit') { // 杏林湾驾驶舱
        // 设置杏林湾小区驾驶舱查看条件： 
        // 项目账号 (userType = 101) 且 只有杏林湾小区才可以查看 (communityId=d93ef0d8-647b-11eb-bdca-005056b13afd) 
        let mainUrl = 'http://hxyt.lcfw.co:8000/publish/840bc8e95db84add963d5e3fff32c0a5'
        window.open(mainUrl)
      } else {
        internalMenuJump(id)
      }
    },
    async handleCallGetSign () {
      // 新版驾驶舱先调用 getSign 接口
      const token = localStorage.getItem('token');
      const companyCode = localStorage.getItem('companyCode');
      const res = await this.$axios({
        url: `${API_CONFIG.butlerBaseURL}openCockpit/getSign`,
        methods: 'get',
        headers: {
        'companyCode': companyCode,
        'token': token
        }
      })
      localStorage.setItem('signInfo', JSON.stringify(res.data))
      return res
    },
    exportClick () {
      this.menuModuleClick(this.exportMenu)
    },
    settingClick () {
      this.menuModuleClick(this.settingMenu)
    },
    onFocus () {
      this.searchVisible = true
      this.$nextTick(() => {
        this.$refs.searchInput.focus()
      })
    },
    checkSearchModel (model) {
      this.searchModel = model
      this.search()
    },
    search: _.debounce(function (searchText) {
      this.searchPageParams.curPage = 1
      this.total = 0
      this.resultList = []
      this.searchAction(searchText)
    }, 800),
    async searchAction () {
      this.loading = false
      let { data } = await this.$axios.post(searchURL, undefined, {
        params: {
          searchText: this.searchText.trim(),
          searchType: this.searchModel,
          ...this.searchPageParams
        }
      })
      this.loading = true
      this.resultList = [...this.resultList, ...data.resultList]
      this.total = data.maxRow
      this.hasMore = data.maxPage > data.curPage
    },
    innerMenuLink (item) {
      const { menuUrl, authType, urlJumpType, id } = item
      if ([1, 2].includes(urlJumpType)) {
        menuJump({
          url: menuUrl,
          authType,
          urlJumpType
        })
      } else {
        internalMenuJump(id)
      }
      this.searchVisible = false
    },
    menuItemLink (item) {
      if (item.resultType === 1) {
        const { menuUrl, authType, urlJumpType, id } = item
        if ([1, 2].includes(urlJumpType)) {
          menuJump({
            url: menuUrl,
            authType,
            urlJumpType
          })
        } else {
          internalMenuJump(id)
        }
      } else if (item.resultType === 2 && item.menuList.length) {
        const { menuUrl, authType, urlJumpType, id } = item.menuList[0]
        if ([1, 2].includes(urlJumpType)) {
          menuJump({
            url: menuUrl,
            authType,
            urlJumpType
          })
        } else {
          internalMenuJump(id)
        }
      }
      this.searchVisible = false
    },
    userLink (item) {
      let menuMap = Store.getters['menuStore/getMenuMap']

      let query = {
        id: item.userId,
        communityId: item.communityId,
        _timeline: new Date().getTime()
      }
      let routerName = 'ownerMgrForm'
      if (item.orgId === 0 && item.address === '') {
        routerName = 'registerUserMgrView'

        if (item.userSource === 1 ) {
          query = {
            ...query,
            ...menuMap['registerUser1'],
            type: 1,
            orgId: item.orgId
          }
        } else {
          query = {
            ...query,
            ...menuMap['registerUser1'],
            type: 0
          }
        }
      } else {
        if (item.userSource === 1 ) {
          query = {
            ...query,
            ...menuMap['serverOwnerAction!getAllOwnerList.action1'],
            type: 1,
            orgId: item.orgId
          }
        } else {
          query = {
            ...query,
            ...menuMap['serverOwnerAction!getAllOwnerList.action2'],
            type: 0
          }
        }
      }
      this.$router.push({
        name: routerName,
        query
      })
      this.searchVisible = false
    },
    onScroll: _.throttle(function () {
      let el = this.$refs.result_wrapper
      let bottomDiff = (el.scrollHeight - el.scrollTop) - el.clientHeight
      if (this.hasMore && bottomDiff < 100) {
        this.searchPageParams.curPage++
        this.searchAction()
      }
    }, 150),
    noop(){},
    menuFousEvent(e,rootMenuId) {
      if(this.menuGroupType === 'top') {
        /*
          这里事件的currentTarget是body,所以要用直接触发元素 e.target并判断节点从属关系
        */
       if(e.target === document.querySelector('.menu-group-top .el-drawer__container')){
          // 鼠标移入托盘蒙版时关闭托盘
          this.$refs.menuTopRef.closeDrawer()
          this.rootMenuSelectedId = ''
        }else if(e.target === document.querySelector('.menu-group-top')||document.querySelector('.menu-group-top') && document.querySelector('.menu-group-top').contains(e.target)){
          // 鼠标移入托盘时什么都不做
          this.noop()
        } else{
          // 鼠标移入导航条内非托盘位置时传递导航根菜单Id并打开托盘
          console.log(rootMenuId,1234)
          this.$refs.menuTopRef.openDrawer(rootMenuId)
        }
      }
    }, 
    menuOutEvent(e) {
      if(this.menuGroupType === 'top') {
        this.$refs.menuTopRef.closeDrawer()
        this.rootMenuSelectedId = ''

      }
    },
    menuHandle(e){
      if(this.$refs.menuRoot.contains(e.target)){
          this.menuFousEvent(e,this.rootMenuSelectedId)
        }else{
          this.menuOutEvent(e)
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
      document.body.addEventListener('mouseover',this.menuHandle)
    })
  },
  beforeDestroy(){
      document.body.removeEventListener('mouseover',this.menuHandle)
  }
}
</script>
<style lang="scss">
.top-container {
  >.left {

    .left-item {
     color: #31455C;
      .w-iconfont{
        display:inline-block;
        vertical-align:top;
        transition:transform 0.5s ease;
        transform:rotate(0deg)

      }
     &.openClass .w-iconfont{
       transform:rotate(-180deg)
     }
     .btn{
       vertical-align:top;
       color:#606266
     }
     .bottom-line{
        background:rgba(37,154,255,1);
     }
     }
  }
  .right {
     color: #31455C;
     .line{
        width: 1px;
        background:#606266;
        opacity: 0.6;
     } 

    a {
       &:link,&:visited,&:hover,&:active{
             color: #31455C;
       } 
    }
  }
}
</style>
<style scoped lang="scss">
.top-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 60px;
  // background: #F0F3F7;
  .left {
    display: flex;
    height:100%;
    padding-left: 24px;
    .platform {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 61px;
      height: 20px;
      border-right: 1px solid #2F4960;
      margin-right: 16px;
      cursor: pointer;
      .platform-icon {
        width: 8px;
        height: 14px;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .platform-text {
        font-size: 12px;
        height: 12px;
        line-height: 12px;
        margin-left: 8px;
      }
    }
    .info {
      display: flex;
      // align-items: flex-end;
      padding: 16px 64px 0 0;
      // padding: 12px 64px 0 0;
      .logo {
        // max-width: 130px;
        // max-height: 44px;
        max-width: 225px;
        height: 44px;
      }
      .version {
        margin-left: 4px;
        font-size: 10px;
        color: #90A6BA;
        display: flex;
        align-items: flex-end;
      }
    }
    .left-item {
      padding: 26px 12px 0;
      display: flex;
      height: 100%;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
    
      font-size: 14px;
      .btn {

        cursor: pointer;
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }
        &.btn-active {
          opacity: 1;
        }
        &.btn-active.JSC-active {
          color: #fff;
        }
      }
      .bottom-line {
        display:block;
        width:30px;
        height:3px;
        margin-top:10px;
      }
    }
    .left-item-popover {
      .name {
        cursor: pointer;
        opacity: 0.6;
        &.name-active {
          opacity: 1;
        }
      }
    }
    .search {
      padding: 20px 0 0 38px;
      ::v-deep .el-input {
        .el-input__inner {
          border-radius:18px;
          background: rgba(144,166,186, 0.1);
          box-shadow: inset 4px 1px 8px 0px rgba(95,122,155,0.1);
          border: none;
        }
        .el-input__suffix {
          line-height: 32px;
        }
      }
    }
  }
  .right {
    display: flex;
    margin-top: 20px;
    > div {
      &:not(:first-child) {
        // border-left: 1px solid #ddd;
      }
      display: flex;
      align-items: center;
    }
    .export,
    .setting,
    .lang {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
      cursor: pointer;
      .icon {
        width: 20px;
        height: 20px;
      }
    }
    .export, .setting {
      &.right-item-active {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          bottom: -12px;
          width: 30px;
          height: 3px;
          background: rgba(37,154,255,1);
        }
      }
    }
    .download, .help {
      width: 44px;
      height: 20px;
      text-align: left;
      cursor: pointer;
      .icon {
        width: 20px;
        height: 20px;
      }
    }
    .line {
      margin-right: 24px;
    }
    .lang {
      .lang-dropdown {
        width: 100%;
        color: inherit;
      }
    }
    .user-info {
      display: flex;
      padding-right: 20px;
      .avatar {
        width: 24px;
        height: 24px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
        border: 3px solid white;
        border-radius: 100%;
      }
      .wrapper {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        .user-name {
          padding: 0 10px;
          font-size: 12px;
        }
        .down {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
::v-deep .el-dialog.is-fullscreen.search-wrapper {
  width: 800px;
  margin-right: 0px;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    height: 100%;
    box-sizing: border-box;
    padding-right: 0px;
  }
  .search-container {
    display: flex;
    height: 100%;
    .left-part {
      box-sizing: border-box;
      width: 210px;
      padding: 0 20px;
      padding-top: 70px;
      .title-text {
        font-size: 12px;
        color: #aaa;
        margin-bottom: 30px;
        text-align: left;
      }
      img {
        width: 16px;
        height: 16px;
        padding-right: 10px;
      }
      .check-option-item {
        height: 44px;
        font-size: 14px;
        padding-left: 10px;
        display: flex;
        align-items: center;
        border-radius: 2px;
        &:hover {
          cursor: pointer;
          background: rgba(248, 248, 248, 1);
        }
      }
    }
    .right-part {
      flex: 1;
      display: flex;
      flex-direction: column;
      .search-input {
        margin-bottom: 30px;
        margin-right: 30px;
        input {
          border: 0;
          border-bottom: 2px solid #1b8cf2;
          border-radius: 0;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .result-count {
        font-size: 12px;
        color: #aaa;
        text-align: left;
        margin-bottom: 30px;
      }
      .result-wrapper {
        flex: 1;
        overflow: auto;
        padding-right: 30px;
      }
      .func-result-list {
        .func-result-item {
          display: flex;
          min-height: 60px;
          align-items: center;
          padding-left: 10px;
          &:hover {
            background: rgba(248, 248, 248, 1);
          }
          .func-icon {
            width: 40px;
            height: 40px;
            margin-right: 16px;
          }
          .content-text {
            text-align: left;
            display: flex;
            flex-direction: column;
            flex: 1;
            .content-title {
              cursor: pointer;
              font-size: 14px;
              font-weight: bold;
            }
            .label {
              margin-right: 30px;
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }
      .user-result-list {
        .user-result-item {
          text-align: left;
          min-height: 70px;
          padding-left: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          &:hover {
            cursor: pointer;
            background: rgba(248, 248, 248, 1);
          }
          .user-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            .user-name {
              .name {
                font-size: 14px;
                margin-right: 10px;
              }
              .user-type {
                font-size: 12px;
                color: #aaa;
              }
            }
            .phone {
              font-size: 12px;
            }
          }
          .address {
            font-size: 12px;
            // min-height: 18px;
          }
        }
      }
      .no-data-img {
        img {
          width: 200px;
          height: 200px;
          margin-top: 115px;
        }
      }
      .tip-text {
        font-size: 14px;
        color:rgba(170,170,170,1);
      }
    }
  }
}
</style>
