import * as select2Url from './api'
import { topicTypeMap } from './map'
import { jumpGoCode } from './code'
const typeList = [
  {
    name: '应用',
    children: [
      // { name: '智慧管家', code: 'zhgj', visible: true },
      // { name: '企业服务', code: 'qyfw', visible: true },
      // { name: '生活服务', code: 'shbh', visible: true },
      // { name: '产业服务', code: 'cyfw', visible: true },
      // { name: '互动交流', code: 'zlys', visible: true },
      // { name: '其他服务', code: 'qtfw', visible: true },
      // { name: '业主自治', code: 'yzzz' , visible: true },
      // { name: '园区健康', code: 'jkcj', visible: true },
      // { name: '园区金融', code: 'sqjr', visible: true },
      // { name: '园区学院', code: 'whjy', visible: true },
      // { name: '生活Tab页', code: 'name', visible: true }
    ]
  },
  {
    name: '内容详情页',
    children: [
      { name: '门店', code: jumpGoCode.storeCode, visible: true, type: jumpGoCode.storeCode },
      { name: '商品', code: jumpGoCode.productCode, visible: true, type: jumpGoCode.productCode },
      { name: '秒杀', code: jumpGoCode.secKillCode, visible: true, type: jumpGoCode.secKillCode },
      { name: '专题', code: jumpGoCode.discountTopicCode, visible: true, type: jumpGoCode.discountTopicCode },
      { name: '资讯', code: jumpGoCode.knowledgeInfoCode, visible: true, type: jumpGoCode.knowledgeInfoCode },
      { name: '帖子', code: jumpGoCode.postsCode, visible: true, type: jumpGoCode.postsCode },
      { name: '社群', code: jumpGoCode.groupCode, visible: true, type: jumpGoCode.groupCode },
      { name: '社区活动', code: jumpGoCode.socialActivityCode, visible: true, type: jumpGoCode.socialActivityCode },
      { name: '问卷调查', code: jumpGoCode.surveyCode, visible: true, type: jumpGoCode.surveyCode },
      { name: '自治规约', code: jumpGoCode.selfRuleCode, visible: true, type: jumpGoCode.selfRuleCode },
      { name: '自治表决', code: jumpGoCode.autonomyVoteCode, visible: true, type: jumpGoCode.autonomyVoteCode },
      { name: '社区工作室', code: jumpGoCode.workRoomCode, visible: true, type: jumpGoCode.workRoomCode }
    ]
  }
]

const select2List = [{
  type: 'sub',
  select2Params: {
    placeholder: '查询应用',
    searchUrl: select2Url.subjectUrl,
    contentType: 'application/json;charset=UTF-8',
    method: 'post',
    request: {
      text: 'subName',
      id: 'subId'
    },
    subjoin: { },
    width: 500
  },
  formatterData: function (data) {
    return data
  }
}, {
  type: jumpGoCode.storeCode,
  select2Params: {
    placeholder: '查询门店',
    searchUrl: select2Url.storeUrl,
    contentType: 'application/json;charset=UTF-8',
    method: 'post',
    request: {
      text: 'storeName',
      id: 'storeId'
    },
    subjoin: { },
    width: 500
  },
  formatterData: function (data) {
    return data
  }
}, {
  type: jumpGoCode.productCode,
  select2Params: {
    placeholder: '查询门店商品',
    searchUrl: select2Url.productUrl,
    contentType: 'application/json;charset=UTF-8',
    method: 'post',
    request: {
      text: 'productName',
      id: 'busId'
    },
    subjoin: { },
    width: 500
  },
  formatterData: function (data) {
    if (data.productPrice.toString().indexOf('.') > -1) {
      data.price = data.productPrice.toString().split('.')[0]
      data.price_dec = data.productPrice.toString().split('.')[1]
    } else {
      data.price = data.productPrice
      data.price_dec = '.00'
    }
    return data
  }
}, {
  type: jumpGoCode.secKillCode,
  select2Params: {
    placeholder: '查询秒杀团购',
    searchUrl: select2Url.discountUrl,
    contentType: 'application/json;charset=UTF-8',
    method: 'post',
    request: {
      text: 'groupName',
      id: 'id'
    },
    response: {
      text: 'groupName'
    },
    subjoin: { },
    width: 500
  },
  formatterData (data) {
    let productList = data.productList
    if (productList && productList.length) {
      productList.forEach((item) => {
        if ((item.killPrice).toString().indexOf('.') > -1) {
          item.price = item.killPrice.toString().split('.')[0]
          item.price_dec = item.killPrice.toString().split('.')[1]
        } else {
          item.price = item.killPrice
          item.price_dec = '00'
        }
      })
    }
    let disable = false
    let statusStr = ''
    let leftTimeTips = ''
    let time = ''
    if (data.status === 1) { // 未开始
      disable = false
      statusStr = '即将开始'
      leftTimeTips = '开始时间'
      time = data.startTime
    } else if (data.status === 2) { // 进行中
      disable = false
      statusStr = '正在进行'
      leftTimeTips = '结束时间'
      time = data.endTime
    } else if (data.status === 3) { // 已结束
      disable = true
      statusStr = '已结束'
    } else { // 已关闭
      disable = true
      statusStr = '已关闭'
    }
    let date = new Date(time)
    console.log(data)
    let month = date.getMonth() + 1
    let day = date.getDate()
    let hour = date.getHours()
    let minute = date.getMinutes()
    data.disable = disable
    data.statusStr = statusStr
    data.leftTimeTips = leftTimeTips
    data.time = time
    data.month = month < 10 ? '0' + month : month
    data.day = day < 10 ? '0' + day : day
    data.hour = hour
    data.minute = minute
    return data
  }
}, {
  type: jumpGoCode.discountTopicCode,
  select2Params: {
    placeholder: '查询促销专题',
    searchUrl: select2Url.topicUrl,
    contentType: 'application/json;charset=UTF-8',
    method: 'post',
    request: {
      text: 'topicName',
      id: 'id'
    },
    subjoin: { },
    width: 500
  },
  formatterData: function (data) {
    return data
  }
}, {
  type: jumpGoCode.knowledgeInfoCode,
  select2Params: {
    placeholder: '查询资讯信息',
    searchUrl: select2Url.informationUrl,
    contentType: 'application/json;charset=UTF-8',
    method: 'post',
    request: {
      text: 'title'
    },
    response: {
      text: 'title'
    },
    subjoin: { },
    width: 500
  },
  formatterData: function (data) {
    data.nonHTMLContent = data.nonHTMLContent ? data.nonHTMLContent.substr(0, 70) : '', // 安卓展示多了性能吃不消，截取掉
    data.cardTime = data.intime.substring(0, 10)
    return data
  }
}, {
  type: jumpGoCode.postsCode,
  select2Params: {
    placeholder: '查询帖子',
    searchUrl: select2Url.postUrl,
    contentType: 'application/json;charset=UTF-8',
    subjoin: { },
    width: 500
  },
  formatterData: function (data) {
    data.rightImg = data.topicPicture
    data.note = data.topicContent
    data.cardType = topicTypeMap[data.topicType]
    data.cardTime = data.intime.substring(0, 10)
    return data
  }
}, {
  type: jumpGoCode.groupCode,
  select2Params: {
    placeholder: '查询社群',
    searchUrl: select2Url.groupUrl,
    contentType: 'application/json;charset=UTF-8',
    subjoin: { },
    width: 500
  },
  formatterData: function (data) {
    data.leftImg = data.groupIcon
    data.name = data.groupTitle
    data.note = data.groupIntro
    return data
  }
}, {
  type: jumpGoCode.socialActivityCode,
  select2Params: {
    placeholder: '查询社区活动',
    searchUrl: select2Url.activityUrl,
    contentType: 'application/json;charset=UTF-8',
    method: 'post',
    request: {
      text: 'activityName',
      id: 'id'
    },
    response: {
      text: 'activityName'
    },
    subjoin: { },
    width: 500
  },
  formatterData: function (data) {
    return data
  }
}, {
  type: jumpGoCode.surveyCode,
  select2Params: {
    placeholder: '查询问卷调查',
    searchUrl: select2Url.surveyUrl,
    contentType: 'application/json;charset=UTF-8',
    method: 'post',
    request: {
      text: 'title',
      id: 'id'
    },
    width: 500,
    subjoin: {
      type: 3
    }
  },
  formatterData: function (data) {
    return data
  }
}, {
  type: jumpGoCode.selfRuleCode,
  select2Params: {
    placeholder: '查询自治规约',
    searchUrl: select2Url.ruleUrl,
    contentType: 'application/json;charset=UTF-8',
    subjoin: { },
    width: 500
  },
  formatterData: function (data) {
    data.cardType = '自治规约'
    data.cardTime = data.intime.substring(0, 10)
    data.name = data.selfRuleTitle
    data.note = data.comment
    return data
  }
}, {
  type: jumpGoCode.autonomyVoteCode,
  select2Params: {
    placeholder: '查询自治表决',
    searchUrl: select2Url.voteUrl,
    contentType: 'application/json;charset=UTF-8',
    method: 'post',
    subjoin: {
      type: 2
    },
    width: 500
  },
  formatterData: function (data) {
    data.cardType = '自治表决'
    data.cardTime = data.publishTime ? data.publishTime.substring(0, 10) : ''
    data.name = data.title
    data.note = data.comment
    return data
  }
}, {
  type: jumpGoCode.workRoomCode,
  select2Params: {
    placeholder: '查询社区工作室',
    searchUrl: select2Url.workRoomUrl,
    contentType: 'application/json;charset=UTF-8',
    subjoin: { },
    width: 500
  },
  formatterData: function (data) {
    data.leftImg = data.groupIcon
    data.name = data.groupTitle
    data.note = data.groupIntro
    return data
  }
}]

export {
  typeList,
  select2List
}
