import store from '../store/index'
import crypto from 'crypto'

let defaultExpiredTIme = 3600 * 24 * 1000 // 默认一天  毫秒时间

let storage = window.localStorage

// 获取是否有缓存
function hasCache (url) {
  let cacheKey = getCachedRequestKey(url)
  let data = cachedRequest(url)
  if (data) {
    // 判断缓存是否过期
    if (cacheValid(data)) {
      // 缓存有效
      return true
    } else {
      storage.removeItem(cacheKey)
      return false
    }
  } else {
    return false
  }
}

// 获取接口缓存的key
function getCachedRequestKey (url) {
  let userId = url + store.state.userInfo.id
  let hashKey = crypto.createHash('md5').update(userId).digest('hex')
  return hashKey
}

// 判断缓存是否有效
function cacheValid (data) {
  // 当前毫秒时间戳
  let timestamp = new Date().getTime()
  // 缓存时间 和 过期时间
  let cacheTime = data.cacheTime
  let expiredTime = data.expiredTime

  return expiredTime + cacheTime > timestamp
}

// 缓存接口
function cache (url, data, expiredTime) {
  let cacheKey = getCachedRequestKey(url)
  storage.removeItem(cacheKey)

  let timestamp = new Date().getTime()
  let cacheData = {
    data: data,
    cacheTime: timestamp,
    expiredTime: expiredTime != null ? expiredTime : defaultExpiredTIme
  }

  let strCache = JSON.stringify(cacheData)

  storage.setItem(cacheKey, strCache)
}

// 获取缓存 包含缓存时间等数据
function cachedRequest (url) {
  let cacheKey = getCachedRequestKey(url)
  let cacheData = storage.getItem(cacheKey)
  return cacheData ? JSON.parse(cacheData) : null
}

// 获取接口内容
function cachedData (url) {
  return cachedRequest(url) ? cachedRequest(url).data : null
}

export default {
  cache,
  cachedData,
  hasCache
}
