import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/store'
import Home from 'components/home/home'
import OAuth from 'components/oAuth/oAuth'
import Login from 'components/login/login'
// import SelectPlatform from 'components/select-platform/select-platform'
import { menuAuthMap } from 'common/menuHandler'
import { Message} from 'element-ui'
import _axios from '@/axios/index.js';

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

let viewsRoutes = [
  {
    path: '/home/notFound',
    name: 'notFound',
    component: resolve => require(['@/components/not-found/not-found'], resolve)
  },
  {
    path: '/home/iframePanel',
    name: 'iframePanel',
    component: resolve => require(['@/components/iframe-panel/iframe-panel'], resolve)
  }
]
const routerContext = require.context('./module', false, /\.js$/)
routerContext.keys().forEach(route => {
  const routerModule = routerContext(route)
  viewsRoutes = [
    ...viewsRoutes,
    ...(routerModule.default || routerModule)
  ]
})

let devRoutes = []
if (process.env.NODE_ENV === 'development') {
  devRoutes = [
    {
      path: '/pageDesign',
      name: 'pageDesign',
      component: resolve => require(['@/components/page-design/page-design'], resolve)
    }
  ]
}

const routes = [

  {
    path: '/',
    redirect: { name: 'zfLogin' }
  },
  {
    path: '/oAuth',
    name: 'oAuth',
    component: OAuth
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/login1',
    name: 'login1',
    component: resolve => require(['@/components/login/login1'], resolve)
  },
  {
    path: '/login2',
    name: 'login2',
    component: resolve => require(['@/components/login/login2'], resolve)
  },
  {
    path: '/login3',
    name: 'login3',
    component: resolve => require(['@/components/login/login3'], resolve)
  },
  {
    path: '/login4',
    name: 'login4',
    component: resolve => require(['@/components/login/login4'], resolve)
  },
  {
    path: '/login5',
    name: 'login5',
    component: resolve => require(['@/components/login/login5'], resolve)
  },
  {
    path: '/login6',
    name: 'login6',
    component: resolve => require(['@/components/login/login6'], resolve)
  },
  {
    path: '/login7',
    name: 'login7',
    component: resolve => require(['@/components/login/login7'], resolve)
  },
  {
    path: '/login8',
    name: 'login8',
    component: resolve => require(['@/components/login/login8'], resolve)
  },
  {
    path: '/zfLogin',
    name: 'zfLogin',
    component: resolve => require(['components/login/zfLogin.vue'], resolve)
  },
  {
    path: '/entry',
    name: 'entry',
    component: resolve => require(['@/components/login/entry'], resolve)
  },
  {
    path: '/companyRegister',
    name: 'companyRegister',
    component: resolve => require(['@/components/companyRegister/index'], resolve)
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: resolve => require(['@/components/companyRegister/welcome'], resolve)
  },
  // {
  //   path: '/selectPlatform',
  //   name: 'selectPlatform',
  //   component: SelectPlatform
  // },
  {
    path: '/home',
    name: 'home',
    component: Home,
    children: viewsRoutes
  },
  ...devRoutes,
  {
    path: '*',
    redirect: { name: 'login' }
  }
]

const router = new Router({
  routes
})

router.beforeEach(async (to, from, next) => {
  let toName = to.name
  let toQuery = to.query
  let toQueryKeys = Object.keys(toQuery)
  let hasMenuId = !!(toQueryKeys.length && (toQueryKeys.indexOf('tid') !== -1 || toQueryKeys.indexOf('sid') !== -1 || toQueryKeys.indexOf('fid') !== -1))
  let menuMap = Store.getters['menuStore/getMenuMap']
  let query = hasMenuId ? toQuery : menuMap[toName]
  let menuItem = Store.getters['menuStore/getMenuItem']
  let curMenuItem = menuItem[toName]
    if(toQuery.token && toQuery.type){
      let loadingMes = Message({
        iconClass: 'el-icon-loading',
        message: '加载中...',
        center: true,
        duration:0
      })
      let subPlatformId = '';
      let childPlatformId = '';
      let availablePlatformIds = '';
      let isReturn = false
      await _axios({
        method: 'get',
        url: `${API_CONFIG.butlerBaseURL}singleLogin/singleLogin`,
        params: {accessToken:toQuery.token,type:toQuery.type},
      })
      .then(res => {
        loadingMes.close()
        if(res.status === 100){
          let data = res.data;
          subPlatformId = data.subPlatformId;
          childPlatformId = data.childPlatformId;
          availablePlatformIds = data.availablePlatformIds;

          if (!availablePlatformIds || !availablePlatformIds.length) {
            Message({
              type: 'error',
              message: '子平台权限出错了, 联系管理员',
              center: true
            })
            return
          }
          // 联调后端接口 得到真正的 token
          localStorage.setItem('companyCode', data.companyCode)
          let token = data.token
          _axios.defaults.headers.token = token
          Store.commit('setToken', token)
          localStorage.setItem('token', token)
        }else{
          let nextRoute = {
            name: 'login',
            query: {},
            replace:true
          }
          next(nextRoute)
          isReturn = true
          return
        }

      })
      if(isReturn){
        return
      }
       // 什么时候需要初始化（home.vue）
      await _axios.get(`${API_CONFIG.baseURL}userAction!getCookie.action`)
      next({name:'home',query:{
        login: 1,
        cid: childPlatformId,
        pid:subPlatformId === 0 ? availablePlatformIds[0] : subPlatformId
        }})
    }
  if (curMenuItem) {
    let { authType } = curMenuItem
    if (to.matched.length === 0) {
      if ([0].includes(authType)) { // 授权方式：原生
        let customFormKey = 'customForm?formId=' // 自定义表单填写页
        let customFormListKey = 'customFormList?formId=' // 自定义表单结果列表页
        if (toName.indexOf(customFormKey) !== -1) {
          let formId = toName.split(customFormKey)[1]
          let nextRoute = {
            name: 'customFormRecordFormSubmit',
            query: {
              ...query,
              formId,
              noBack: 1
            }
          }
          if (to.params._replace) {
            nextRoute['replace'] = true
          }
          next(nextRoute)
          return
        } else if (toName.indexOf(customFormListKey) !== -1) {
          let formId = toName.split(customFormListKey)[1]
          let nextRoute = {
            name: 'customFormRecordList',
            query: {
              ...query,
              formId
            }
          }
          if (to.params._replace) {
            nextRoute['replace'] = true
          }
          next(nextRoute)
          return
        }
      } else if ([1, 2].includes(authType)) { // 授权方式：非授权、需授权
        let routeName = 'iframePanel'
        let menuAuth = menuAuthMap[authType]
        let src = await menuAuth(curMenuItem)
        let nextRoute = {
          name: routeName,
          query: {
            ...query,
            src
          }
        }
        next(nextRoute)
        return
      }
    }
  }
  if (to.matched.length === 0) {
    let nextRoute = {
      name: 'notFound',
      query
    }
    next(nextRoute)
  } else if (to.matched.length > 1 && to.matched[0].name === 'home' && (toName !== 'worktop' && !hasMenuId)) {
    let menuMap = Store.getters['menuStore/getMenuMap']
    let _query = menuMap[toName]
    if (!_query) {
      let { pid, cid, fid, sid, tid } = from.query
      _query = { pid, cid, fid, sid, tid }
    }
    if(toQuery.token) delete toQuery.token
    let nextRoute = {
      name: toName,
      query: {
        ..._query,
        ...toQuery
      }
    }
    if (to.params._replace) {
      nextRoute['replace'] = true
    }
    next(nextRoute)
  } else {
    if (toName === 'login') {
      let nextRoute = {
        name: toName,
        query: {
          ...toQuery
        }
      }
      if(window.location.origin === 'https://xc.wisharetec.com') {
        nextRoute.name = 'login3'
        next(nextRoute)
        return
      } else if (window.location.origin === 'https://wl.wisharetec.com') {
        nextRoute.name = 'login7'
        next(nextRoute)
        return
      } else if (window.location.origin === 'https://www.zwpzy.com') {
        nextRoute.name = 'login8'
        next(nextRoute)
        return
      }else if (window.location.origin === 'http://10.120.0.30:8080') {
        nextRoute.name = 'login9'
        next(nextRoute)
        return
      }
      const intervalId = Store.state.intervalId
      if (intervalId !== undefined) {
        clearInterval(intervalId)
        Store.commit('setIntervalId', undefined)
      }
    }
    next()

    return;
  }
})

export default router
