var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cascader-plus-container" },
    [
      _vm._l(_vm.data, function (list, index) {
        return _c("div", { key: index, staticClass: "transfer-panel" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v("选择 " + _vm._s(index + 1) + " 级" + _vm._s(_vm.label)),
          ]),
          _c(
            "div",
            { staticClass: "scroll-panel" },
            _vm._l(list, function (item, childIndex) {
              return _c(
                "li",
                {
                  key: childIndex,
                  class: {
                    active: _vm.ids[index] && item.id === _vm.ids[index].id,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.chooseItem(index, item.id, item)
                    },
                  },
                },
                [_vm._v(_vm._s(item.name))]
              )
            }),
            0
          ),
        ])
      }),
      _c(
        "div",
        { staticClass: "btn-panel" },
        [
          _c("v-button", {
            staticClass: "add-btn",
            attrs: { text: "添加>>" },
            on: { click: _vm.addData },
          }),
          _c("v-button", {
            attrs: { text: "<<删除", type: "danger" },
            on: { click: _vm.removeData },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "transfer-panel selected-panel" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("已选中 "),
          _c("span", { staticClass: "colorRed" }, [
            _vm._v(_vm._s(_vm.selectedList.length)),
          ]),
          _vm._v(" 个" + _vm._s(_vm.label)),
        ]),
        _c(
          "div",
          { staticClass: "scroll-panel" },
          _vm._l(_vm.selectedList, function (item, index) {
            return _c(
              "li",
              {
                key: index,
                class: { active: _vm.selectedItem.id === item.id },
                on: {
                  click: function ($event) {
                    return _vm.chooseSelectItem(index, item.id, item)
                  },
                },
              },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          0
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }