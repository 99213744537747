const routes = [

  // 社群成员
  {
    path: '/home/membersList', // 列表页
    name: 'members',
    component: resolve => require(['@/views/operations/members/MembersList'], resolve)
  },
  {
    path: '/home/MemberAduit', // 审核页
    name: 'MemberAduit',
    component: resolve => require(['@/views/operations/members/MemberAduit'], resolve)
  },
  {
    path: '/home/MemberAddNew', // 新增页
    name: 'MemberAddNew',
    component: resolve => require(['@/views/operations/members/MemberAddNew'], resolve)
  },
  {
    path: '/home/MemberImport', // 导入页
    name: 'MemberImport',
    component: resolve => require(['@/views/operations/members/MemberImport'], resolve)
  },
  // 焦点图管理
  {
    path: '/home/focusManageList', // 焦点图列表页
    name: 'serverFocusAction!queryFocusList.action1',
    component: resolve => require(['@/views/operations/focusManage/FocusManageList'], resolve)
  },
  {
    path: '/home/focusManageForm', // 焦点图表单页
    name: 'focusManageForm',
    component: resolve => require(['@/views/operations/focusManage/FocusManageForm'], resolve)
  }, // 邻里帖子
  {
    path: '/home/interActionList', // 列表页
    name: 'neiAction!list.action4',
    component: resolve => require(['@/views/operations/interAction/InterActionList'], resolve)
  },
  {
    path: '/home/interActionForm', // 表单页
    name: 'interActionForm',
    component: resolve => require(['@/views/operations/interAction/InterActionForm'], resolve)
  },
  {
    path: '/home/interActionCheck', // 查看页
    name: 'interActionCheck',
    component: resolve => require(['@/views/operations/interAction/interActionCheck'], resolve)
  },
  // 志愿者
  {
    path: '/home/volunteerList', // 列表页
    name: 'volunteer1',
    component: resolve => require(['@/views/operations/volunteer/VolunteerList'], resolve)
  },
  {
    path: '/home/volunteerForm', // 表单页
    name: 'volunteerForm',
    component: resolve => require(['@/views/operations/volunteer/VolunteerForm'], resolve)
  },
  {
    path: '/home/volunteerAddNewForm', // 新增表单页面
    name: 'volunteerAddNewForm',
    component: resolve => require(['@/views/operations/volunteer/volunteerAddNewForm'], resolve)
  },
  {
    path: '/home/importVolunteerPage', // 导入页
    name: 'importVolunteerPage',
    component: resolve => require(['@/views/operations/volunteer/importVolunteerPage'], resolve)
  },
  // 志愿者岗位
  {
    path: '/home/dutyList', // 列表页
    name: 'volunteer2',
    component: resolve => require(['@/views/operations/duty/DutyList'], resolve)
  },
  {
    path: '/home/dutyForm', // 表单页
    name: 'dutyForm',
    component: resolve => require(['@/views/operations/duty/DutyForm'], resolve)
  },
  // TODO 卡片管理
  {
    path: '/home/cardMgrList', // 列表页
    name: 'card1',
    component: resolve => require(['@/views/operations/cardMgr/CardMgrList'], resolve)
  },
  {
    path: '/home/cardMgrForm', // 表单页
    name: 'cardMgrForm',
    component: resolve => require(['@/views/operations/cardMgr/CardMgrForm'], resolve)
  },
  // TODO 推广管理
  {
    path: '/home/spreadMgrList', // 列表页
    name: 'broadcast3',
    component: resolve => require(['@/views/operations/spreadMgr/SpreadMgrList'], resolve)
  },
  {
    path: '/home/spreadMgrForm', // 表单页
    name: 'spreadMgrForm',
    component: resolve => require(['@/views/operations/spreadMgr/SpreadMgrForm'], resolve)
  },
  // TODO 广告业管理
  {
    path: '/home/advertisMgrList', // 列表页
    name: 'startUpManage1',
    component: resolve => require(['@/views/operations/advertisMgr/AdvertisMgrList'], resolve)
  },
  {
    path: '/home/advertisMgrForm', // 表单页
    name: 'advertisMgrForm',
    component: resolve => require(['@/views/operations/advertisMgr/AdvertisMgrForm'], resolve)
  },
  {
    path: '/home/publishProjectsList', // 列表页
    name: 'publishProjectsList',
    component: resolve => require(['@/views/operations/advertisMgr/PublishProjectsList'], resolve)
  },
  // TODO 运营位管理
  {
    path: '/home/operationPosList', // 列表页
    name: 'operationPosition1',
    component: resolve => require(['@/views/operations/operationPos/OperationPosList'], resolve)
  },
  {
    path: '/home/operationPosForm', // 表单页
    name: 'operationPosForm',
    component: resolve => require(['@/views/operations/operationPos/OperationPosForm'], resolve)
  },
  // 志愿者求助
  {
    path: '/home/ownerConsultList', // 列表页
    name: 'volunteer3',
    component: resolve => require(['@/views/operations/ownerConsult/OwnerConsultList'], resolve)
  },
  {
    path: '/home/ownerConsultForm', // 表单页
    name: 'ownerConsultForm',
    component: resolve => require(['@/views/operations/ownerConsult/OwnerConsultForm'], resolve)
  },
  // 通知管理--系统通知
  {
    path: '/home/systemNotificationList', // 系统通知列表页
    name: 'systemNotification',
    component: resolve =>
      require(['@/views/operations/systemNotification/SystemNotificationList'], resolve)
  },
  {
    // 资讯帖子
    path: '/home/informationList', // 资讯列表页
    name: 'infomationAction!list.action1',
    component: resolve => require(['@/views/operations/informationPost/InformationList'], resolve)
  },
  {
    path: '/home/informationForm', // 资讯表单页
    name: 'informationForm',
    component: resolve => require(['@/views/operations/informationPost/InformationForm'], resolve)
  },
  {
    path: '/home/postList', // 帖子列表页
    name: 'infomationAction!list.action2',
    component: resolve => require(['@/views/operations/informationPost/PostList'], resolve)
  },
  {
    path: '/home/postForm', // 帖子Form页
    name: 'postForm',
    component: resolve => require(['@/views/operations/informationPost/PostForm'], resolve)
  },
  {
    path: '/home/infoPostCategoryList', // 帖子分类配置列表页
    name: 'infomationAction!list.action3',
    component: resolve =>
      require(['@/views/operations/informationPost/InfoPostCategoryList'], resolve)
  },
  {
    path: '/home/infoPostCategoryForm', // 帖子分类配置Form页
    name: 'infoPostCategoryForm',
    component: resolve =>
      require(['@/views/operations/informationPost/InfoPostCategoryForm'], resolve)
  },
  {
    // 平台公告
    path: '/home/platformNoticeList', // 平台公告列表页
    name: 'announcement1',
    component: resolve => require(['@/views/operations/platformNotice/PlatformNoticeList'], resolve)
  },
  {
    path: '/home/platformNoticeForm', // 平台公告表单页
    name: 'platformNoticeForm',
    component: resolve => require(['@/views/operations/platformNotice/PlatformNoticeForm'], resolve)
  },
  // 后台通知
  {
    path: '/home/backstageNoticeList', // 列表页
    name: 'serverNoticesAction!queryNoticeList.action1',
    component: resolve =>
      require(['@/views/operations/backstageNotice/BackstageNoticeList'], resolve)
  },
  {
    path: '/home/backstageNoticeForm', // 表单页
    name: 'backstageNoticeForm',
    component: resolve =>
      require(['@/views/operations/backstageNotice/BackstageNoticeForm'], resolve)
  },
  // 签收概况
  {
    path: '/home/backstageNoticeList', // 列表页
    name: 'signOverviewList',
    component: resolve => require(['@/views/operations/backstageNotice/SignOverviewList'], resolve)
  },
  // 业主交流
  {
    path: '/home/commentMgrList', // 列表页
    name: 'commentAction!commentList.action3',
    component: resolve => require(['@/views/operations/commentMgr/CommentMgrList'], resolve)
  },
  {
    path: '/home/commentMgrForm', // 表单页
    name: 'commentMgrForm',
    component: resolve => require(['@/views/operations/commentMgr/CommentMgrForm'], resolve)
  },
  // 评论我的
  {
    path: '/home/commentsMyList', // 列表页
    name: 'commentAction!commentList.action1',
    component: resolve => require(['@/views/operations/commentsMy/commentsMyList'], resolve)
  },
  // TODO 菜单名称
  {
    path: '/home/myCommentsList', // 列表页
    name: 'commentAction!commentList.action2',
    component: resolve => require(['@/views/operations/myComments/MyCommentsList'], resolve)
  },
  // 企业登记
  {
    path: '/home/enterpriseRegisterList', // 列表页
    name: 'customManagement1',
    component: resolve => require(['@/views/operations/enterpriseRegister/EnterpriseRegisterList'], resolve)
  },
  {
    path: '/home/enterpriseRegisterForm', // 表单页
    name: 'enterpriseRegisterForm',
    component: resolve => require(['@/views/operations/enterpriseRegister/EnterpriseRegisterForm'], resolve)
  },
  {
    path: '/home/setTargetEnterprise', // 设置目标企业
    name: 'setTargetEnterprise',
    component: resolve => require(['@/views/operations/enterpriseRegister/SetTargetEnterprise'], resolve)
  },
  // 招商管理统计分析
  {
    path: '/home/customAnalysisList/100', // 自定义页面
    name: 'customStatistics1',
    component: resolve => require(['@/views/operations/customAnalysis/CustomAnalysisPlatform'], resolve)
  },
  {
    path: '/home/customAnalysisList/102', // 自定义页面
    name: 'customStatistics2',
    component: resolve => require(['@/views/operations/customAnalysis/CustomAnalysisRegion'], resolve)
  },
  {
    path: '/home/customAnalysisList/101', // 自定义页面
    name: 'customStatistics3',
    component: resolve => require(['@/views/operations/customAnalysis/CustomAnalysisCommunity'], resolve)
  },
  // 通知管理-信息推送
  {
    path: '/home/informationPushList', // 列表页
    name: 'infopush',
    component: resolve => require(['@/views/operations/informationPush/InformationPushList'], resolve)
  }
]

export default routes
