var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "particles-js",
    attrs: {
      id: _vm.id,
      color: _vm.color,
      particleOpacity: _vm.particleOpacity,
      linesColor: _vm.linesColor,
      particlesNumber: _vm.particlesNumber,
      shapeType: _vm.shapeType,
      particleSize: _vm.particleSize,
      linesWidth: _vm.linesWidth,
      lineLinked: _vm.lineLinked,
      lineOpacity: _vm.lineOpacity,
      linesDistance: _vm.linesDistance,
      moveSpeed: _vm.moveSpeed,
      hoverEffect: _vm.hoverEffect,
      hoverMode: _vm.hoverMode,
      clickEffect: _vm.clickEffect,
      clickMode: _vm.clickMode,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }