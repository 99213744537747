var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "area-select-wrapper" },
    [
      _c("v-cascader", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: {
          extraClass: _vm.extraClass,
          value: _vm.selectedOptions,
          width: _vm.width,
          options: _vm.areaOps,
          props: _vm.props,
          disabled: _vm.disabled,
          clearable: _vm.clearable,
          filterable: _vm.filterable,
          placeholder: _vm.placeholder,
        },
        on: {
          "update:value": function ($event) {
            _vm.selectedOptions = $event
          },
          change: _vm.change,
        },
      }),
      _vm.detailAddr
        ? _c("v-input", {
            staticClass: "input-wrapper",
            attrs: {
              placeholder: _vm.addressPlaceholder,
              width: 200,
              clearable: true,
            },
            model: {
              value: _vm.detailAddress,
              callback: function ($$v) {
                _vm.detailAddress = $$v
              },
              expression: "detailAddress",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }