<template>
  <div class="area-select-wrapper">
    <v-cascader
      v-loading="loading"
      :extraClass="extraClass"
      :value.sync="selectedOptions"
      :width="width"
      :options="areaOps"
      :props="props"
      :disabled="disabled"
      :clearable="clearable"
      :filterable="filterable"
      :placeholder="placeholder"
      @change="change"
    ></v-cascader>
    <v-input
      class="input-wrapper"
      v-if="detailAddr"
      v-model="detailAddress"
      :placeholder="addressPlaceholder"
      :width="200"
      :clearable="true"
    ></v-input>
  </div>
</template>

<script>
import { vCascader } from 'components/control/index'
import _ from 'lodash'

// @group 业务组件
// @title AreaSelect 省市区选择器
export default {
  name: 'area-select',
  components: {
    vCascader
  },
  props: {
    // 额外class类名
    extraClass: {
      type: String,
      default: ''
    },
    // 选中的区域省市区code数组，支持`.sync`
    codes: Array,
    // 详情地址输入框的绑定值 inputable为true时有值，支持`.sync`
    address: String,
    // 选择框占位文本
    placeholder: {
      type: String,
      // `请选择区域`
      default: '请选择区域'
    },
    // 详情输入框的占位文本
    addressPlaceholder: {
      type: String,
      // `请输入详细地址`
      default: '请输入详细地址'
    },
    // 区域选择类型 可选值 `province`(省), `pcity`(省市), `all`(省市区)
    type: {
      type: String,
      // `all`
      default: 'all'
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      // `false`
      default: false
    },
    // 显示框宽度
    width: {
      type: Number,
      // `180`
      default: 180
    },
    // 是否清空
    clearable: {
      type: Boolean,
      // `false`
      default: true
    },
    // 是否可搜索选项
    filterable: {
      type: Boolean,
      // `false`
      default: false
    },
    // 是否显示详细地址的输入框
    detailAddr: {
      type: Boolean,
      // `true`
      default: true
    },
    // 是否显示全部按钮
    showAll: {
      type: Boolean,
      // `false`
      default: false
    },
    // 请求地址，可选
    getChinaAreaTree1: {
      type: String
    }
  },

  data () {
    return {
      loading: false,
      // 请求地址
      //   getChinaAreaTree: `${API_CONFIG.origin}h5api/project/getProjectChinaAreaTree`,
      getChinaAreaTree: `${API_CONFIG.butlerBaseURL}common/getChinaAreaTree`,
      // 接口获取到的省市区数据
      areaList: [],
      // 显示的数据
      areaOps: [],
      selectedOptions: this.codes,
      detailAddress: this.address,
      // 配置项
      props: {
        value: 'code',
        label: 'name',
        children: 'childAreaList'
      }
    }
    /* areaArray结构如下
      let areaArray = [{
        name: '省',
        code: '省code',
        id: '省id'
        list: [{
          name: '市',
          code: '市code',
          id: '市id'
          list: [
            name: '区/县',
            code: '区/县code',
            id: '区/县id'
          ]
        }]
      }]
      */
  },

  watch: {
    selectedOptions (newValue) {
      // 选择的区域code数组
      this.$emit('update:codes', newValue)
    },
    detailAddress (newValue) {
      // 输入的详情地址
      this.$emit('update:address', newValue)
    },

    codes (newValue, oldValue) {
      this.dealWithAreaCode()
    },

    address (newValue) {
      // 处理详情地址回显
      this.detailAddress = newValue
    },

    type (newValue) {
      if (newValue) {
        this.dealWithAreaData()
      }
    }
  },

  created () {
    this.getChinaAreaTreeData()
  },

  mounted () {
    // this.dealWithEchoData()
  },

  methods: {
    // 获取所有省市区数据
    async getChinaAreaTreeData () {
      // parentId=0,获取所有的省市区数据
      let params = {
        parentId: 0
      }
      let resUrl = this.getChinaAreaTree1
        ? this.getChinaAreaTree1
        : this.getChinaAreaTree
      this.loading = true
      const { data, status } = await this.$axios.get(resUrl, { params })
      this.loading = false
      if (status === 100) {
        this.areaList = data || []
        this.dealWithAreaData()
      }
    },
    // 处理返回的省市区数据显示
    dealWithAreaData () {
      let list = _.cloneDeep(this.areaList)
      // 处理省市区数据
      list &&
        list.forEach(province => {
          // province`(省), `pcity`(省市)
          if (this.type === 'province') {
            // 只显示省，删除掉其余数据
            delete province.childAreaList
          } else {
            province.childAreaList &&
              province.childAreaList.forEach(city => {
                if (this.type === 'pcity') {
                  // 只显示省市，删除掉其余数据
                  delete city.childAreaList
                } else {
                  // 区数据
                  city.childAreaList &&
                    city.childAreaList.forEach(area => {
                      delete area.childAreaList
                    })
                  if (this.showAll) {
                    // 区，数据加上全部
                    if (city.childAreaList) {
                      city.childAreaList.unshift({
                        name: '全部',
                        code: null
                      })
                    }
                  }
                }
              })
            if (this.showAll) {
              // 市，数据加上全部
              if (province.childAreaList) {
                province.childAreaList.unshift({
                  name: '全部',
                  code: null
                })
              }
            }
          }
        })
      if (this.showAll) {
        list.unshift({
          name: '全部',
          code: null
        })
      }

      this.areaOps = list
    },

    change (values) {
      // // 返回的是所选择的省市区对象
      let selectArea = {}
      if (values && values.length) {
        selectArea['province'] = values[0]
        if (values.length > 1) {
          selectArea['city'] = values[1]
          if (values.length > 2) {
            selectArea['area'] = values[2]
          }
        }
      }
      // 注意:该控件需要进行表单验证时,使用定时器包裹验证内容
      this.$emit('change', selectArea)
    },

    // 处理区域code
    dealWithAreaCode () {
      let areaCodes = this.codes
      if (this.type === 'all' || this.type === 'pcity') {
        // 省市区类型 支持只传最后一个区code
        // 省市类型 支持只传最后一个市code
        if (areaCodes.length === 1) {
          areaCodes = this.queryCode(areaCodes[0])
        }
      }
      this.selectedOptions = areaCodes
    },

    // 根据最后一级code，查询完整的code
    queryCode (code) {
      let areaCodes = []
      this.areaList &&
        this.areaList.forEach(province => {
          province.childAreaList &&
            province.childAreaList.forEach(city => {
              // 省市类型
              if (this.type === 'pcity' && code === city.code) {
                areaCodes = [province.code, city.code]
              }
              city.childAreaList &&
                city.childAreaList.forEach(area => {
                  if (this.type === 'all' && code === area.code) {
                    areaCodes = [province.code, city.code, area.code]
                  }
                })
            })
        })
      return areaCodes
    }
  }
}
</script>

<style scoped lang="scss">
.area-select-wrapper {
  display: flex;
  justify-content: flex-start;
  height: 40px;

  .input-wrapper {
    margin-left: 20px;
  }
}
</style>
<style lang="scss">
  // .projectArea {
  //   overflow: auto;
  //   max-height: 300px;
  // }
// 解决样式bug
.area-select-wrapper {
  display: flex;
  justify-content: flex-start;
  height: 40px;

  .input-wrapper {
    margin-left: 20px;
  }
}
</style>
