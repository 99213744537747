var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.themeSetFinish,
          expression: "themeSetFinish",
        },
      ],
      staticClass: "top-container",
    },
    [
      _c(
        "div",
        { staticClass: "left", staticStyle: { "flex-grow": "1" } },
        [
          _c("div", { staticClass: "info" }, [
            _c("img", { staticClass: "logo", attrs: { src: _vm.logoSrc } }),
            _c("div", {
              staticClass: "version",
              domProps: { textContent: _vm._s(_vm.version) },
            }),
          ]),
          _vm.worktopShow
            ? _c("div", { staticClass: "left-item" }, [
                _c(
                  "span",
                  {
                    staticClass: "btn",
                    class: { "btn-active": _vm.workTopActive },
                    on: { click: _vm.goWorktop },
                  },
                  [_vm._v("工作台")]
                ),
                _c("span", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.workTopActive && _vm.menuGroupType == "left",
                      expression: "workTopActive && menuGroupType == 'left'",
                    },
                  ],
                  staticClass: "bottom-line",
                }),
              ])
            : _vm._e(),
          _c(
            "div",
            { ref: "menuRoot", staticStyle: { display: "flex" } },
            [
              _vm._l(_vm.menuModules, function (menuModule) {
                return [
                  menuModule.url === "/pm2/main" &&
                  menuModule.childMenus.length > 1
                    ? _c(
                        "div",
                        { key: menuModule.id, staticClass: "left-item" },
                        [
                          _c(
                            "el-dropdown",
                            { on: { command: _vm.menuModuleClick1 } },
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "btn",
                                    class: {
                                      "btn-active JSC-active":
                                        menuModule.id == _vm.$route.query.pid &&
                                        !_vm.workTopActive,
                                    },
                                  },
                                  [
                                    _vm._v("驾驶舱"),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-arrow-down el-icon--right",
                                    }),
                                  ]
                                ),
                                _c("span", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        menuModule.id == _vm.$route.query.pid &&
                                        !_vm.workTopActive,
                                      expression:
                                        "(menuModule.id == $route.query.pid) && !workTopActive ",
                                    },
                                  ],
                                  staticClass: "bottom-line",
                                }),
                              ]),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                _vm._l(
                                  menuModule.childMenus,
                                  function (item, index) {
                                    return _c(
                                      "el-dropdown-item",
                                      {
                                        key: "dropdown" + index,
                                        attrs: {
                                          command: item.childMenus[0].id,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.menuModuleClick(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "btn" }, [
                                          _vm._v(_vm._s(item.name)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          key: menuModule.id,
                          staticClass: "left-item",
                          class: {
                            openClass: _vm.rootMenuSelectedId === menuModule.id,
                          },
                          on: _vm._d({}, [
                            _vm.handleType,
                            function ($event) {
                              return _vm.menuModuleClick(menuModule)
                            },
                          ]),
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "flex-start",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "btn",
                                  class: {
                                    "btn-active":
                                      menuModule.id == _vm.$route.query.pid &&
                                      !_vm.workTopActive,
                                  },
                                },
                                [_vm._v(_vm._s(menuModule.name))]
                              ),
                              _vm.menuGroupType == "top"
                                ? _c("i", {
                                    staticClass:
                                      "w-iconfont w-icon-chevron-down",
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _c("span", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  menuModule.id == _vm.$route.query.pid &&
                                  !_vm.workTopActive &&
                                  _vm.menuGroupType == "left",
                                expression:
                                  "(menuModule.id == $route.query.pid) && !workTopActive && menuGroupType == 'left'",
                              },
                            ],
                            staticClass: "bottom-line",
                          }),
                        ]
                      ),
                ]
              }),
              _vm.menuGroupType === "top"
                ? _c("menu-group-top", { ref: "menuTopRef" })
                : _vm._e(),
            ],
            2
          ),
          _vm.dataBrainShow
            ? _c(
                "data-brain-list",
                {
                  attrs: { dataBrainMenu: _vm.dataBrainMenu.childMenus },
                  on: { statusChange: _vm.dataBrainStatusChange },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "data-brain",
                      attrs: { slot: "dataBrain" },
                      slot: "dataBrain",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "left-item left-item-popover" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "name",
                              class: { "name-active": _vm.dataBrainStatus },
                            },
                            [_vm._v(_vm._s(_vm.dataBrainMenu.name))]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _c("div", { staticStyle: { "flex-grow": "1" } }),
          _c("div", { staticStyle: { "flex-grow": "1" } }),
          _c("v-input", {
            staticClass: "search",
            attrs: {
              width: 200,
              size: "small",
              placeholder: "搜索功能、用户",
              suffixIcon: "el-icon-search",
            },
            on: { focus: _vm.onFocus },
            model: {
              value: _vm.searchTextOut,
              callback: function ($$v) {
                _vm.searchTextOut = $$v
              },
              expression: "searchTextOut",
            },
          }),
          _c("div", { staticStyle: { "flex-grow": "1" } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("div", [
            _vm.pageDesignShow
              ? _c(
                  "a",
                  {
                    staticStyle: {
                      "margin-right": "24px",
                      "text-decoration": "none",
                    },
                    attrs: { href: "#/pageDesign" },
                  },
                  [_vm._v("页面设计器")]
                )
              : _vm._e(),
          ]),
          _vm.exportShow
            ? _c(
                "div",
                {
                  staticClass: "export",
                  class: {
                    "right-item-active":
                      _vm.exportMenu.id == _vm.$route.query.pid &&
                      !_vm.workTopActive,
                  },
                  on: { click: _vm.exportClick },
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "light",
                        content: _vm.exportMenu.name,
                        placement: "bottom",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "icon",
                        attrs: { src: _vm.exportSrc },
                      }),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.settingShow
            ? _c(
                "div",
                {
                  staticClass: "setting",
                  class: {
                    "right-item-active":
                      _vm.settingMenu.id == _vm.$route.query.pid &&
                      !_vm.workTopActive,
                  },
                  on: { click: _vm.settingClick },
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "light",
                        content: _vm.settingMenu.name,
                        placement: "bottom",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "icon",
                        attrs: { src: _vm.settingSrc },
                      }),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "setting", on: { click: _vm.setClick } }, [
            _c("img", { staticClass: "icon", attrs: { src: _vm.settingSrc } }),
          ]),
          _vm.qrcodeList && _vm.qrcodeList.length > 0
            ? _c(
                "div",
                [
                  _c("qrcode-list", { attrs: { qrcodeList: _vm.qrcodeList } }, [
                    _c(
                      "div",
                      {
                        staticClass: "download",
                        attrs: { slot: "qrcode" },
                        slot: "qrcode",
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "light",
                              content: "下载二维码",
                              placement: "bottom",
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "icon",
                              attrs: { src: _vm.downIcon },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.showDoc && _vm.dynamicParams && _vm.dynamicParams.showManual
            ? _c(
                "div",
                [
                  _c("help-manual-list", [
                    _c(
                      "div",
                      {
                        staticClass: "help",
                        attrs: { slot: "manual" },
                        slot: "manual",
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "light",
                              content: "帮助手册",
                              placement: "bottom",
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "icon",
                              attrs: { src: _vm.helpIcon },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "line" }),
          _c(
            "option-list",
            {
              staticClass: "option-list-wrapper",
              attrs: { userInfo: _vm.userInfo },
            },
            [
              _c(
                "div",
                {
                  staticClass: "user-info",
                  attrs: { slot: "option" },
                  slot: "option",
                },
                [
                  _c("img", {
                    staticClass: "avatar",
                    attrs: { src: _vm.avatarSrc },
                  }),
                  _c("div", { staticClass: "wrapper" }, [
                    _c("div", {
                      staticClass: "user-name",
                      domProps: { textContent: _vm._s(_vm.userName) },
                    }),
                  ]),
                ]
              ),
            ]
          ),
          _vm.i18n
            ? _c(
                "div",
                { staticClass: "lang" },
                [
                  _c(
                    "el-dropdown",
                    {
                      staticClass: "lang-dropdown",
                      attrs: { trigger: "click" },
                      on: { command: _vm.toggleLang },
                    },
                    [
                      _c("div", {
                        domProps: { textContent: _vm._s(_vm.currentLang) },
                      }),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        _vm._l(_vm.langList, function (item, index) {
                          return _c(
                            "el-dropdown-item",
                            { key: index, attrs: { command: item.value } },
                            [
                              _c("v-button", {
                                attrs: { type: "text", text: item.text },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "search-wrapper",
            fullscreen: true,
            visible: _vm.searchVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.searchVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "search-container" }, [
            _c("div", { staticClass: "left-part" }, [
              _c("div", { staticClass: "title-text" }, [_vm._v("搜索范围")]),
              _c(
                "div",
                {
                  staticClass: "check-option-item",
                  on: {
                    click: function ($event) {
                      return _vm.checkSearchModel("1")
                    },
                  },
                },
                [
                  _vm.searchModel === "1"
                    ? _c("img", {
                        attrs: {
                          src: require("./images/checked.png"),
                          alt: "",
                        },
                      })
                    : _c("img", {
                        attrs: {
                          src: require("./images/unchecked.png"),
                          alt: "",
                        },
                      }),
                  _c("div", [_vm._v("功能")]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "check-option-item",
                  on: {
                    click: function ($event) {
                      return _vm.checkSearchModel("2")
                    },
                  },
                },
                [
                  _vm.searchModel === "2"
                    ? _c("img", {
                        attrs: {
                          src: require("./images/checked.png"),
                          alt: "",
                        },
                      })
                    : _c("img", {
                        attrs: {
                          src: require("./images/unchecked.png"),
                          alt: "",
                        },
                      }),
                  _c("div", [_vm._v("用户")]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "right-part" },
              [
                _c("v-input", {
                  ref: "searchInput",
                  staticClass: "search-input",
                  attrs: { width: 560, placeholder: "请输入搜索内容" },
                  on: { input: _vm.search },
                  model: {
                    value: _vm.searchText,
                    callback: function ($$v) {
                      _vm.searchText = $$v
                    },
                    expression: "searchText",
                  },
                }),
                _c("div", { staticClass: "result-count" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.total) +
                      "个搜索结果\n          "
                  ),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "result_wrapper",
                    staticClass: "result-wrapper",
                    on: { scroll: _vm.onScroll },
                  },
                  [
                    _vm.searchModel === "1"
                      ? _c(
                          "ul",
                          { staticClass: "func-result-list" },
                          _vm._l(_vm.resultList, function (item, index) {
                            return _c(
                              "li",
                              {
                                key: `func_${index}`,
                                staticClass: "func-result-item",
                              },
                              [
                                item.appPackageIcon
                                  ? _c("img", {
                                      staticClass: "func-icon",
                                      attrs: {
                                        src: item.appPackageIcon,
                                        alt: "",
                                      },
                                    })
                                  : _vm._e(),
                                _c("div", { staticClass: "content-text" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "content-title",
                                      on: {
                                        click: function ($event) {
                                          return _vm.menuItemLink(item)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "label-wrapper" },
                                    _vm._l(item.menuList, function (menu) {
                                      return _c(
                                        "span",
                                        {
                                          key: menu.id,
                                          staticClass: "label",
                                          on: {
                                            click: function ($event) {
                                              return _vm.innerMenuLink(menu)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(menu.name))]
                                      )
                                    }),
                                    0
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm.searchModel === "2"
                      ? _c(
                          "ul",
                          { staticClass: "user-result-list" },
                          _vm._l(_vm.resultList, function (item, index) {
                            return _c(
                              "li",
                              {
                                key: `user_${index}`,
                                staticClass: "user-result-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.userLink(item)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "user-info" }, [
                                  _c("div", { staticClass: "user-name" }, [
                                    _c("span", { staticClass: "name" }, [
                                      _vm._v(_vm._s(item.userName)),
                                    ]),
                                    _c("span", { staticClass: "user-type" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.userSourceMap[item.userSource]
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "phone" }, [
                                    _vm._v(_vm._s(item.mobileNum)),
                                  ]),
                                ]),
                                _c("div", { staticClass: "address" }, [
                                  _vm._v(_vm._s(item.address)),
                                ]),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    !_vm.resultList.length
                      ? _c("div", { staticClass: "no-data-img" }, [
                          _c("img", {
                            attrs: {
                              src: require("./images/no_data.png"),
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "tip-text" }, [
                            _vm._v("没有找到相关内容"),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }