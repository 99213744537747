var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "option-list-container" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            "popper-class": "option-list-popper",
            placement: "bottom-end",
            "popper-options": _vm.popperOptions,
          },
          on: { show: _vm.show, hide: _vm.hide },
          scopedSlots: _vm._u(
            [
              {
                key: "reference",
                fn: function () {
                  return [_vm._t("option")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("div", {
            staticClass: "header",
            domProps: { textContent: _vm._s(_vm.roleName) },
          }),
          _c("ul", { staticClass: "option-list" }, [
            _c(
              "li",
              { staticClass: "option-item", on: { click: _vm.setting } },
              [_vm._v("账号设置")]
            ),
            _vm.showPlateOrganize
              ? _c(
                  "li",
                  {
                    staticClass: "option-item",
                    on: { click: _vm.jumpPlateOrganize },
                  },
                  [_vm._v("平台组织")]
                )
              : _vm._e(),
            _c(
              "li",
              { staticClass: "option-item", on: { click: _vm.logout } },
              [_vm._v("退出")]
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "setting-dialog",
            title: "账号设置",
            width: "680px",
            visible: _vm.settingVisible,
          },
          on: {
            open: _vm.open,
            closed: _vm.closed,
            "update:visible": function ($event) {
              _vm.settingVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "setting-dialog-content" },
            [
              _c("div", [_vm._v("账号信息：")]),
              _c(
                "div",
                { staticClass: "info" },
                [
                  _c("v-uploader", {
                    attrs: {
                      operate: "none",
                      action: _vm.uploadAction,
                      fileList: _vm.headPic,
                    },
                    on: {
                      "update:fileList": function ($event) {
                        _vm.headPic = $event
                      },
                      "update:file-list": function ($event) {
                        _vm.headPic = $event
                      },
                    },
                  }),
                  _c("div", { staticClass: "introduction" }, [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          "登录账号：" + _vm.userInfo.loginName
                        ),
                      },
                    }),
                    _c("span", {
                      domProps: {
                        textContent: _vm._s("角色：" + _vm.roleName),
                      },
                    }),
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          "所属组织：" + (_vm.userInfo.userOrganization || "无")
                        ),
                      },
                    }),
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          "职务：" + (_vm.userInfo.adminDuty || "无")
                        ),
                      },
                    }),
                  ]),
                ],
                1
              ),
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "个人信息", name: "info" } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "info",
                          attrs: {
                            model: _vm.info,
                            "hide-required-asterisk": true,
                            "label-width": "100px",
                            rules: _vm.infoRules,
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "姓名：", prop: "userName" } },
                            [
                              _c("v-input", {
                                attrs: { maxlength: 20 },
                                model: {
                                  value: _vm.info.userName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.info, "userName", $$v)
                                  },
                                  expression: "info.userName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "手机号：", prop: "mobileNum" } },
                            [
                              _c("v-input", {
                                attrs: { disabled: !_vm.editMobile },
                                model: {
                                  value: _vm.info.mobileNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.info, "mobileNum", $$v)
                                  },
                                  expression: "info.mobileNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "昵称：", prop: "nickName" } },
                            [
                              _c("v-input", {
                                model: {
                                  value: _vm.info.nickName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.info, "nickName", $$v)
                                  },
                                  expression: "info.nickName",
                                },
                              }),
                              _c("span", { staticClass: "tips" }, [
                                _vm._v("注：该昵称将用于app上,与业主沟通交流"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-button", {
                        staticClass: "save-button",
                        attrs: { text: "保存" },
                        on: { click: _vm.saveInfo },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "修改密码", name: "password" } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "password",
                          attrs: {
                            model: _vm.password,
                            "hide-required-asterisk": true,
                            "label-width": "100px",
                            rules: _vm.passwordRules,
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "旧密码：", prop: "oldPassword" },
                            },
                            [
                              _c("v-input", {
                                attrs: {
                                  type: "password",
                                  autocomplete: "new-password",
                                },
                                model: {
                                  value: _vm.password.oldPassword,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.password, "oldPassword", $$v)
                                  },
                                  expression: "password.oldPassword",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "新密码：", prop: "newPassword" },
                            },
                            [
                              _c("v-input", {
                                attrs: {
                                  type: "password",
                                  autocomplete: "new-password",
                                },
                                model: {
                                  value: _vm.password.newPassword,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.password, "newPassword", $$v)
                                  },
                                  expression: "password.newPassword",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "确认密码：",
                                prop: "newPasswordRepeat",
                              },
                            },
                            [
                              _c("v-input", {
                                attrs: {
                                  type: "password",
                                  autocomplete: "new-password",
                                },
                                model: {
                                  value: _vm.password.newPasswordRepeat,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.password,
                                      "newPasswordRepeat",
                                      $$v
                                    )
                                  },
                                  expression: "password.newPasswordRepeat",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-button", {
                        staticClass: "save-button",
                        attrs: { text: "保存" },
                        on: { click: _vm.savePassword },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }