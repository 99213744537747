<template lang="html">
  <el-col :span="span">
    <div class="col2-item">
      <span class="label" :class="{ required: required }" v-if="label">{{label}}：</span>
      <span class="text">
        <slot>{{text}}</slot>
      </span>
    </div>
  </el-col>
</template>

<script>
// @group 业务组件
// @title Col2Item  Col2组件单元项
export default {
  name: 'col2-item',
  props: {
    // 栅格数
    span: {
      type: Number,
      default: 12
    },
    // 标签
    label: String,
    // 文本
    text: [String, Number],
    // 标签前是否有红星
    required: Boolean
  }
}
</script>

<style lang="scss" scoped>
.col2-item {
  display: flex;
  align-items: center;
  line-height: 33px;
  .label {
    min-width: 156px;
    text-align: right;
    padding-right: 12px;
    font-weight: bold;
    color: #333;
    align-self: flex-start;
    &.required:before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
  .text {
    width: 100%;
  }
}
</style>
