import Vue from "vue";

const hasOwnProperty = Object.prototype.hasOwnProperty;

/**
 * 基础工具类
 */
const basicHelper = {
  hasOwn(obj, key) {
    return hasOwnProperty.call(obj, key);
  },
  isArray(val) {
    return Object.prototype.toString.call(val) === "[object Array]";
  },
  isPlainObject(val) {
    return Object.prototype.toString.call(val) === "[object Object]";
  },
};

const _localStorage = {
  setItem(key, value) {
    window.localStorage.setItem(key, value);
  },
  getItem(key) {
    let value = window.localStorage.getItem(key);
    return value;
  },
  removeItem(key) {
    window.localStorage.removeItem(key);
  },
};

const localStorageHelper = {
  setItem(key, value) {
    if (basicHelper.isArray(value) || basicHelper.isPlainObject(value)) {
      try {
        value = JSON.stringify(value);
      } catch (e) {}
    }
    window.localStorage.setItem(key, value);
  },
  getItem(key) {
    let value = window.localStorage.getItem(key);
    try {
      value = JSON.parse(value);
    } catch (e) {}
    return value;
  },
  removeItem(key) {
    window.localStorage.removeItem(key);
  },
  clear() {
    window.localStorage.clear();
  },
};

const sessionStorageHelper = {
  setItem(key, value) {
    if (basicHelper.isArray(value) || basicHelper.isPlainObject(value)) {
      try {
        value = JSON.stringify(value);
      } catch (e) {}
    }
    window.sessionStorage.setItem(key, value);
  },
  getItem(key) {
    let value = window.sessionStorage.getItem(key);
    try {
      value = JSON.parse(value);
    } catch (e) {}
    return value;
  },
  removeItem(key) {
    window.sessionStorage.removeItem(key);
  },
  clear() {
    window.sessionStorage.clear();
  },
};

const download = require("downloadjs");
const downloadHelper = {
  downloadByAction(config) {
    // 使用iframe阻止表单提交时跳转页面
    const iframeId = "iframe_download";
    let iframe = document.getElementById(iframeId);
    if (!iframe) {
      iframe = document.createElement("iframe");
      iframe.id = iframeId;
      iframe.name = iframeId;
      iframe.style.display = "none";
      document.body.appendChild(iframe);
    }
    let form = document.createElement("form");
    form.method = config.method || "post";
    form.action = config.action;
    form.target = iframeId;
    if (config.formData) {
      for (let key in config.formData) {
        let value = config.formData[key];
        if (value !== undefined) {
          let input = document.createElement("input");
          input.type = "hidden";
          input.name = key;
          input.value = value;
          form.appendChild(input);
        }
      }
    }
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  },
  async downloadByApi(config, callback) {
    let { exportUrl, postData } = config;
    let getIsExportingURL = `${API_CONFIG.baseURL}satisfactionRateSurveyAction!getIsExporting.action`;
    let getIsExportingRes = await Vue.prototype.$axios.get(getIsExportingURL);
    if (getIsExportingRes.status == 100) {
      // eslint-disable-line
      let exportRes = await Vue.prototype.$axios.get(exportUrl, postData);
      if (exportRes.status == 100) {
        // eslint-disable-line
        let reg = new RegExp(`${API_CONFIG.origin}.*?(?=/)`);
        let exportBaseUrl = exportUrl.match(reg);
        if (exportBaseUrl) {
          let getFileExportInfoURL = `${
            exportBaseUrl[0]
          }/export/getFileExportInfo?key=${exportRes.data}&id=${Math.random()}`;
          let timer = setInterval(async () => {
            let getFileExportInfoRes = await Vue.prototype.$axios.get(
              getFileExportInfoURL
            );
            if (getFileExportInfoRes.status == 100) {
              // eslint-disable-line
              let percent =
                getFileExportInfoRes.data && getFileExportInfoRes.data.percent;
              if (percent === "100") {
                clearInterval(timer);
              }
            } else {
              clearInterval(timer);
            }
            callback && callback(getFileExportInfoRes);
          }, 2000);
        }
      }
    }
  },
  downloadFile({ data, fileName, mimeType }) {
    if (!mimeType) {
      const imageReg = /\.(png|jpe?g|gif|svg)(\?.*)?$/;
      if (imageReg.test(data)) {
        mimeType = "image/jpg";
      } else {
        mimeType = "application/pdf";
      }
    }
    const x = new XMLHttpRequest();
    x.open("GET", data, true);
    x.responseType = "blob";
    x.onload = function () {
      download(x.response, fileName, mimeType);
    };
    x.send();
  },
  downloadBySrc(config) {
    const a = document.createElement("a");
    a.href = config.src;
    if (config.target) {
      a.target = config.target;
    }
    a.download = config.fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  },
  downloadByLocation(filePath) {
    if (filePath.substring(0, 1) === "/") {
      window.location.href = `${location.origin}${location.pathname.substr(
        0,
        location.pathname.lastIndexOf("/")
      )}${filePath}`;
    } else {
      window.location.href = filePath;
    }
  },
};

// 通过opts生成map
const generateMapByOpts = (opts) => {
  let map = {};
  if (Array.isArray(opts)) {
    opts.forEach((item) => {
      if (item.value !== undefined) {
        map[item.value] = item.text;
      }
    });
  } else {
    throw new Error("opts must be an array");
  }
  return map;
};

const optionsGenerator = (options, customKey = "text") => {
  return (option) => {
    let _option = [];
    if (option) {
      if (option === 1) {
        let obj = {
          text: "全部",
          value: undefined,
          [customKey]: "全部",
        };
        _option.push(obj);
      } else if (option === 2) {
        let obj = {
          text: "请选择",
          value: undefined,
          [customKey]: "请选择",
        };
        _option.push(obj);
      } else {
        _option = [options];
      }
    }
    Array.isArray(options) &&
      options.forEach((item) => {
        let tempObj = { ...item, [customKey]: item.text };
        _option.push(tempObj);
      });
    return _option;
  };
};

const mapHelper = {
  setMap(options, customKey) {
    const map = generateMapByOpts(options, customKey);
    const setOps = optionsGenerator(options, customKey);
    return {
      map,
      setOps,
    };
  },
  setOptionGroup(list, params) {
    let { valueKey, labelKey, parentIdKey, defaultText, keys } = params;
    valueKey = valueKey || "id";
    labelKey = labelKey || "name";
    parentIdKey = parentIdKey || "parentId";

    let optionGroup = [];
    if (Array.isArray(list) && list.length) {
      let options = [];
      list.forEach((item) => {
        const parentId = item[parentIdKey];
        if ([undefined, null, ""].includes(parentId)) {
          optionGroup.push({
            label: item[labelKey],
            value: item[valueKey],
            options: [],
          });
        } else {
          options.push(item);
        }
      });
      options.forEach((option) => {
        const parentId = option[parentIdKey];
        const parentItem = optionGroup.find((item) => {
          return item.value === parentId;
        });
        const _option = {
          label: option[labelKey],
          value: option[valueKey],
        };
        if (keys && keys.length) {
          keys.forEach((key) => {
            _option[key] = option[key];
          });
        }
        parentItem.options.push(_option);
      });
    }

    if (defaultText) {
      optionGroup.unshift({
        label: defaultText,
        value: undefined,
        options: [
          {
            label: defaultText,
            value: undefined,
          },
        ],
      });
    }
    return optionGroup;
  },
};

// 根据身份证号获取性别，生日等信息的工具类
const idCardHelper = {
  // 获取生日
  getBirthday(idCard) {
    let carNum = idCard || "";
    let birthday = "";
    if (carNum.length > 0) {
      if (carNum.length === 15) {
        // 15位需要补齐年份
        birthday = "19" + carNum.slice(6, 12);
      } else if (carNum.length === 18) {
        birthday = carNum.slice(6, 14);
      }
      // 通过正则表达式来指定输出格式为:1990-01-01
      birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
    }
    return birthday;
  },

  // 获取性别
  getSex(idCard) {
    let sexStr = "";
    let carNum = idCard || "";
    if (carNum.length > 0) {
      let sexNum = -1;
      if (carNum.length === 15) {
        /// 15位身份证是最后一位判断性别
        sexNum = parseInt(carNum.slice(-1));
      } else if (carNum.length === 18) {
        /// 18位身份证是第17位判断性别
        sexNum = parseInt(carNum.slice(-2, -1));
      }
      if (sexNum !== -1) {
        if (sexNum % 2 === 1) {
          sexStr = "男";
        } else {
          sexStr = "女";
        }
      }
    }
    return sexStr;
  },
};
// 富文本中附件下载
const _ueditorFileClick = function (event, href) {
  let alink = href.getAttribute("href1");
  let fileName = alink.substr(alink.lastIndexOf("/") + 1);
  if (fileName.indexOf("_") > -1) {
    let titleArr = fileName.split("_");
    fileName = titleArr.slice(0, titleArr.length - 1).join("_");
  }
  const pdfReg = /\.(pdf|PDF)(\?.*)?$/;
  if (pdfReg.test(alink)) {
    downloadHelper.downloadFile({
      data: alink,
      fileName,
    });
  } else {
    location.href = alink;
  }
};
const deepClone = function (obj) {
  if (typeof obj !== "object" || obj === null) return obj;
  if (obj instanceof RegExp) {
    return new RegExp(obj);
  }
  if (obj instanceof Date) {
    return new Date(obj);
  }
  let newObj = new obj.constructor();
  for (let key in obj) {
    newObj[key] = deepClone(obj[key]);
  }
  return newObj;
};
//阿拉伯数字转中文数字
const NoToChinese = function (num) {
  if (!/^\d*(\.\d*)?$/.test(num)) {
    alert("Number is wrong!");
    return "Number is wrong!";
  }
  var AA = new Array(
    "零",
    "一",
    "二",
    "三",
    "四",
    "五",
    "六",
    "七",
    "八",
    "九"
  );
  var BB = new Array("", "十", "百", "千", "万", "亿", "点", "");
  var a = ("" + num).replace(/(^0*)/g, "").split("."),
    k = 0,
    re = "";
  for (var i = a[0].length - 1; i >= 0; i--) {
    switch (k) {
      case 0:
        re = BB[7] + re;
        break;
      case 4:
        if (!new RegExp("0{4}\\d{" + (a[0].length - i - 1) + "}$").test(a[0]))
          re = BB[4] + re;
        break;
      case 8:
        re = BB[5] + re;
        BB[7] = BB[5];
        k = 0;
        break;
    }
    if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0)
      re = AA[0] + re;
    if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
    k++;
  }
  if (a.length > 1) {
    //加上小数部分(如果有小数部分)
    re += BB[6];
    for (var i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
  }
  return re;
};

// 去重
const unique = function (list) {
  return Array.from(new Set(list));
};
// 并集
const unionList = function (target, source) {
  return Array.from(new Set(target.concat(source)));
};

// 交集
const intersectionList = function (target, source) {
  return Array.from(new Set(target.filter((v) => new Set(source).has(v))));
};

// 差集
const differenceNew = function (target, source) {
  return new Set(
    target
      .concat(source)
      .filter((v) => new Set(target).has(v) && !new Set(source).has(v))
  );
};

export {
  basicHelper,
  _localStorage,
  localStorageHelper,
  sessionStorageHelper,
  downloadHelper,
  generateMapByOpts,
  mapHelper,
  idCardHelper,
  _ueditorFileClick,
  deepClone,
  NoToChinese,
  unique,
  unionList,
  intersectionList,
  differenceNew,
};
