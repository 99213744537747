<template>
  <div class="v-control v-textarea-container">
    <el-input
      type="textarea"
      v-model="model"
      :disabled="disabled"
      :style="{ width: width + 'px' }"
      :placeholder="placeholder"
      :rows="rows"
      :maxlength="maxlength"
      :minlength="minlength"
    />
    <div class="counter" v-if="showCounter" :class="{ 'highlight': highlight }" v-text="counter"></div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Input } from 'element-ui'

Vue.use(Input)

// @group 基础组件
// @title Textarea 文本域
export default {
  name: 'v-textarea',
  props: {
    // 绑定值
    value: String,
    // 是否禁用
    disabled: Boolean,
    // 文本域宽度
    width: {
      type: Number,
      // `500`
      default: 500
    },
    // 文本域占位文本
    placeholder: String,
    // 文本域行数
    rows: {
      type: Number,
      // `4`
      default: 4
    },
    // 原生属性，最大输入长度
    maxlength: {
      type: Number,
      // `200`
      default: 200
    },
    // 原生属性，最小输入长度
    minlength: Number,
    // 是否显示计数器
    showCounter: {
      type: Boolean,
      // `true`
      default: true
    }
  },
  data () {
    return {
      model: this.value,
      highlight: false
    }
  },
  created () {
    if (this.model.length > this.maxlength) {
      this.model = this.model.substring(0, this.maxlength)
    } else if (this.model.length === this.maxlength) {
      this.highlight = true
    }
  },
  computed: {
    counter () {
      return `${this.model.length} / ${this.maxlength}`
    }
  },
  watch: {
    value (newValue) {
      this.model = newValue
    },
    model (newValue) {
      this.highlight = this.maxlength <= newValue.length
      // v-model event
      this.$emit('input', newValue)
    }
  }
}
</script>
<style scoped lang="scss">
  .v-textarea-container {
    position: relative;
    display: inline-block;
    .counter {
      position: absolute;
      top: 100%;
      right: 0;
      text-align: right;
      margin-top: 6px;
      font-size: 12px;
      height: 12px;
      line-height: 12px;
      color: rgb(170, 170, 170);
      &.highlight {
        color: rgb(244, 75, 30);
      }
    }
  }
</style>
