var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "checkbox-wrapper" }, [
    _vm.type === "default"
      ? _c(
          "div",
          { staticClass: "common-wrapper" },
          [
            _c(
              "el-checkbox",
              {
                staticClass: "my-checkbox",
                attrs: {
                  disabled: _vm.disabled,
                  indeterminate: _vm.indeterminate,
                },
                on: { change: _vm.onChange },
                model: {
                  value: _vm.myValue,
                  callback: function ($$v) {
                    _vm.myValue = $$v
                  },
                  expression: "myValue",
                },
              },
              [_vm._v(_vm._s(_vm.label || ""))]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.type === "radio"
      ? _c(
          "div",
          { staticClass: "common-wrapper" },
          [
            _c(
              "el-radio",
              {
                staticClass: "my-radio",
                attrs: { disabled: _vm.disabled, label: _vm.labelValue },
                on: { change: _vm.onChange },
                model: {
                  value: _vm.myValue,
                  callback: function ($$v) {
                    _vm.myValue = $$v
                  },
                  expression: "myValue",
                },
              },
              [_vm._v(_vm._s(_vm.label || ""))]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.type === "border"
      ? _c(
          "div",
          { staticClass: "border-wrapper", on: { click: _vm.onMyChange } },
          [
            _c("button", {
              class: [
                "btn",
                { "btn-active": _vm.myValue },
                { "btn-disabled": _vm.disabled },
              ],
              attrs: { type: "button" },
              domProps: { textContent: _vm._s(_vm.label) },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }