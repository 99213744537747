var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-group-top" },
    [
      _c(
        "el-drawer",
        {
          attrs: {
            title: "我是标题",
            visible: _vm.drawer,
            modal: _vm.modal,
            size: _vm.size,
            direction: _vm.direction,
            withHeader: _vm.withHeader,
            "show-close": _vm.showClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c("nav-menu-panel", {
            ref: "nav-menu-panel",
            attrs: {
              activeMenuId: _vm.activeMenuId,
              centerItem: _vm.centerItem,
              firstItem: _vm.firstItem,
              secondItem: _vm.secondItem,
              thirdItem: _vm.thirdItem,
              group: _vm.computedMenuItem,
              menuListCache: _vm.computedMenuItem.childMenus,
              status: _vm.status,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }